import { IBudgetESNUnit, IESNParams, IFinCenterOperation } from 'global/types/commos-def'

export const calculeteESN = (operation: IFinCenterOperation, params: IESNParams) => {
  let { percents, limits } = params

  let previousPeriodTotal = 0

  operation.esnTotal?.units.map((esnUnit: IBudgetESNUnit, esnUnitIndex: number) => {
    let targetESNValues: any = {}
    let curentMonthSum = operation.units[esnUnitIndex].sum
    let keyToPercents: 'full' | 'benefit' = 'full'
    type Key = keyof IESNParams['percents']

    for (let key in percents) {
      curentMonthSum = operation.units[esnUnitIndex].sum
      let fullPercentSum = 0
      if (limits[key as Key] != undefined) {
        if (previousPeriodTotal + curentMonthSum > limits[key as Key]!) {
          keyToPercents = 'benefit'
        }
        if (previousPeriodTotal < limits[key as Key]! && previousPeriodTotal + curentMonthSum > limits[key as Key]!) {
          fullPercentSum = limits[key as Key]
            ? ((limits[key as Key]! - previousPeriodTotal) * percents[key as Key]['full']) / 100
            : 0
          curentMonthSum = curentMonthSum - (limits[key as Key]! - previousPeriodTotal)
        }
      } else {
        keyToPercents = 'full'
      }

      targetESNValues[key] = (curentMonthSum * percents[key as Key][keyToPercents]!) / 100 + fullPercentSum
    }
    if (operation.esnTotal)
      operation.esnTotal.units[esnUnitIndex].sum = Object.values(targetESNValues).reduce(
        (p: any, n: any) => p + n,
        0,
      ) as number
    previousPeriodTotal += curentMonthSum
  })

  return operation
}

export const getTotals = (operation: IFinCenterOperation, totalType: 'nominal' | 'sum' | 'ESN') => {
  if (operation.units.filter((unit: any) => unit.type == 'TOTAL')[0]) {
    if (totalType == 'nominal' || totalType == 'sum') {
      return operation.units.filter((unit: any) => unit.type == 'TOTAL')[0][totalType]
    } else if (totalType == 'ESN') {
      return operation.esnTotal?.units.filter((unit: any) => unit.type == 'TOTAL')[0].sum
    }
  }
}
