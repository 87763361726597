import { FC, KeyboardEvent } from 'react'
import { ITooltipByQuantityProps } from './TooltipByQuantity.types'
import { Stack, Typography } from '@mui/material'
import Button from 'components/Button'
import { EENumber, NumberFormatPropsSchema } from 'components/editableElements/EENumber'
import { operationNominal, operationNominal_ViewMode } from 'global/styles/presets'
import { theme } from 'global/styles/theme'
import { NumberFormatValues } from 'react-number-format'
import { QuantityPerMonth } from './TooltipByQuantity.styles'

export const TooltipByQuantity: FC<ITooltipByQuantityProps> = ({ splitedQuantity, setSplitedQuantity, onSubmit }) => {
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        onSubmit()
        break

      default:
        break
    }
  }

  return (
    <Stack spacing={1} padding={'8px 16px'} onClick={(e) => e.stopPropagation()}>
      <QuantityPerMonth spacing={0.25}>
        <Typography variant="body2" fontSize={12} color={theme.palette.secondary.main}>
          &nbsp;Кол-во, объем реализации в месяц
        </Typography>
        <EENumber
          name="nominal"
          mode={'edit'}
          value={splitedQuantity}
          viewModeStyle={{ ...operationNominal_ViewMode }}
          onNumberChange={(values: NumberFormatValues) => setSplitedQuantity(values.floatValue || 0)}
          TextFieldProps={{
            sx: operationNominal,
            onKeyUp: handleKeyUp,
          }}
          NumberFormatProps={{
            ...NumberFormatPropsSchema.nominal,
            decimalScale: 2,
          }}
        />
      </QuantityPerMonth>
      <Button onClick={onSubmit} disabled={!splitedQuantity} style={{ height: '30px' }}>
        Применить
      </Button>
    </Stack>
  )
}
