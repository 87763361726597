import { LegendColors } from '../../global/variables'

export type UserRoles = 'Администратор' | 'Пользователь' | 'Без роли'
export type UserRolesEn = 'admin' | 'user' | 'none'
export type UserRoleTabLabels = 'Все пользователи' | 'Администраторы' | 'Пользователи' | 'Без роли'

export interface UserRolesEnToRu extends Record<UserRolesEn, UserRoles> {}
export interface UserRolesRuToEn extends Record<UserRoles, UserRolesEn> {}

export const userRolesRuToEn: UserRolesRuToEn = {
  Администратор: 'admin',
  Пользователь: 'user',
  'Без роли': 'none',
}

export const userRolesEnToRu: UserRolesEnToRu = {
  admin: 'Администратор',
  user: 'Пользователь',
  none: 'Без роли',
}

export const userRolesArray = Object.keys(userRolesRuToEn) as Array<keyof typeof userRolesRuToEn>
export const userRolesArrayEn = Object.keys(userRolesEnToRu) as Array<keyof typeof userRolesEnToRu>
export const userTabNamesArray: UserRoleTabLabels[] = [
  'Все пользователи',
  'Администраторы',
  'Пользователи' /* , 'Без роли' */,
]

// export const UserRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, UserRolesEn> = {
export const userRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, string> = {
  Администраторы: 'admin',
  Пользователи: 'user',
  'Без роли': 'none',
}

export const userRolesColors: Record<UserRoles, LegendColors> = {
  Администратор: 'blue',
  Пользователь: 'orange',
  'Без роли': 'disabled',
}
