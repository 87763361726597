import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { IFinanceCenter, IFinCenterOperation, TESNParamsVariant } from '../../../../global/types/commos-def'
import { BudgetAgGrid } from '../BudgetAgGrid'
import { TFormatSelectionValue } from '../FormatSelectionDialog/FormatSelectionDialog.types'

interface IFinanceCenterRecordsProps {
  total: IFinCenterOperation | null
  ESNParams: {
    ensType: TESNParamsVariant
    esnFSSPercent: number
  }
  operations: IFinCenterOperation[] | null
  availableYears?: number[]
  recalculateTotal: (prevState: IFinCenterOperation, nextState: IFinCenterOperation) => void
  deleteOperation: (operationIndex: number) => void
  fillingMode: TFormatSelectionValue
  updateDownloadButtonDisabled: (value: boolean) => void
  onCopyDialogOpen: () => void
  updateTableRowCount: (count: number) => void
  financeCenter: IFinanceCenter | null
}

export function FinanceCenterRecords(props: IFinanceCenterRecordsProps) {
  const {
    operations,
    recalculateTotal,
    total,
    deleteOperation,
    availableYears,
    updateDownloadButtonDisabled,
    financeCenter,
    ESNParams,
    fillingMode,
    onCopyDialogOpen,
    updateTableRowCount,
  } = props

  const history = useHistory()

  let query = new URLSearchParams(history.location.search)

  // const [isOldTableShown, setIsOldTableShown] = useState<boolean>(false)
  return (
    <>
      <Box sx={{ marginRight: '20px' /*  minWidth: '1200px' */ }} className="FinanceCenterRecords">
        {/* <Button sx={{ position: 'absolute', top: 0 }} onClick={() => setIsOldTableShown((prev) => !prev)}>
                    Show old table
                </Button>
                {isOldTableShown && (
                    <TableContainer
                        sx={{
                            position: 'relative',
                            // height: 'calc(100vh - 195px)',
                            '@media (max-width: 1466px)': {
                                height: 'calc(100vh - 215px)',
                            },
                            ...scrollableWrapper,
                        }}
                    >
                        <Table stickyHeader>
                            <FinCenterTableHead />
                            <TableBody>
                                {query.get('category') != 'TOTAL' &&
                                    operations &&
                                    operations.map((operation, index: number) => {
                                        return (
                                            <FinCenterRow
                                                key={operation!.id!}
                                                ESNParams={ESNParams}
                                                deleteOperation={deleteOperation}
                                                operation={operation}
                                                recalculateTotal={(prevState, newState) =>
                                                    recalculateTotal(prevState, newState)
                                                }
                                            />
                                        )
                                    })}
                            </TableBody>
                            {total && <BudgetTableFooter type="total" total={total} />}
                        </Table>
                    </TableContainer>
                )} */}
        <BudgetAgGrid
          financeCenter={financeCenter}
          ESNParams={ESNParams}
          operations={operations}
          total={total}
          availableYears={availableYears}
          fillingMode={fillingMode}
          updateDownloadButtonDisabled={updateDownloadButtonDisabled}
          onCopyDialogOpen={onCopyDialogOpen}
          updateTableRowCount={updateTableRowCount}
        />
      </Box>
    </>
  )
}
