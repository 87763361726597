import React from 'react'
import { Delete } from '@mui/icons-material'
import { TableCell, Box, Button } from '@mui/material'
import { EEString } from '../../../../../components/editableElements/EEString'
import {
  EETableRowProvider,
  EETableRowContext,
  IValidationErrors,
} from '../../../../../components/editableElements/EETableRow'
import { tableCellInput_ViewMode, tableCellInput } from '../../../../../global/styles/presets'
import { ISubBill } from '../../../../../global/types/commos-def'
import { DeleteConfirmDialog } from '../../../../../components/Dialogs/DeleteConfirmDialog'
import { AccountantAPI } from '../../../../../global/api/APIMethods/AccountantAPI'
import { ISubBillListState } from './SubBillList'

interface ISubBillListItemProps {
  subBill: ISubBill
  parentState: ISubBillListState
  setParentState: React.Dispatch<React.SetStateAction<ISubBillListState>>
}

interface ISubBillListItemState {
  subBill: ISubBill
  isDeleteConfirmOpen: boolean
}

export function SubBillListItem(props: ISubBillListItemProps) {
  const { subBill, parentState, setParentState } = props

  const [state, setState] = React.useState({
    subBill: subBill,
    isDeleteConfirmOpen: false,
  } as ISubBillListItemState)

  function changeSubBillName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setState((prevState) => ({
      ...prevState,
      subBill: {
        ...prevState.subBill,
        name: e.target.value,
      },
    }))
  }

  function switchDeleteConfirm() {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmOpen: !prevState.isDeleteConfirmOpen,
    }))
  }

  function updateSubBIll() {
    AccountantAPI.updateSubBill({
      subBillID: state.subBill.id,
      name: state.subBill.name,
    })
  }

  function deleteSubBill() {
    AccountantAPI.deleteSubBill(state.subBill.id)
      .then(() => {
        setParentState((prevState) => ({
          ...prevState,
          subBillList: prevState.subBillList?.filter((item) => item.id != subBill.id) || [],
        }))
      })
      .then(() => switchDeleteConfirm())
  }

  return (
    <EETableRowProvider
      onSwitchMode={(prevMode, currentMode) => prevMode == 'edit' && updateSubBIll()}
      validation={() => validateRow(state.subBill)}
    >
      <EETableRowContext.Consumer>
        {(value) => (
          <>
            <DeleteConfirmDialog
              open={state.isDeleteConfirmOpen}
              onClose={switchDeleteConfirm}
              onYes={deleteSubBill}
              onNo={switchDeleteConfirm}
            />
            <TableCell>
              <Box display="flex" justifyContent="center">
                <Button
                  color={'error'}
                  size="small"
                  sx={{ marginX: 'auto', p: 0.5 }}
                  onClick={() => switchDeleteConfirm()}
                >
                  <Delete color={'error'} fontSize="small" />
                </Button>
              </Box>
            </TableCell>
            <TableCell sx={{ paddingLeft: 0 }}>
              <EEString
                name={'name'}
                mode={value.mode}
                value={state.subBill.name}
                align="left"
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  error: !value?.validationErrors?.name?.isValid,
                  sx: tableCellInput,
                }}
                onChange={changeSubBillName}
              />
            </TableCell>
          </>
        )}
      </EETableRowContext.Consumer>
    </EETableRowProvider>
  )
}

function validateRow(subBill: ISubBill): IValidationErrors {
  let validationErrors = {} as IValidationErrors
  if (subBill.name == '') {
    validationErrors.name = {
      isValid: false,
      message: 'Обязательное поле',
    }
  } else if (subBill.name && subBill.name.length > 160) {
    validationErrors.name = {
      isValid: false,
      message: 'Не более 160 символов',
    }
  } else {
    validationErrors.name = {
      isValid: true,
      message: '',
    }
  }
  return validationErrors
}
