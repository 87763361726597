import { FC } from 'react'
import { ITooltipBySumProps } from './TooltipBySum.types'
import { Checkbox, Typography } from '@mui/material'
import { theme } from 'global/styles/theme'
import { TooltipWrapper } from './TooltipBySum.styles'

export const TooltipBySum: FC<ITooltipBySumProps> = ({ isChecked, onChange }) => {
  return (
    <TooltipWrapper direction={'row'} alignItems={'center'} onClick={onChange}>
      <Checkbox checked={isChecked} />

      <Typography variant="body2" color={theme.palette.text.dark}>
        Поровну разделить по месяцам
      </Typography>
    </TooltipWrapper>
  )
}
