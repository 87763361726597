import { useFormContext } from 'react-hook-form'
import { UploadTitle, UploadText } from '../../_NEW/UploadFile/style'
import { Fragment } from 'react'

export function FileChosen() {
  const methods = useFormContext()

  return (
    <Fragment>
      <UploadTitle variant="body2">Файл добавлен</UploadTitle>
      <UploadText variant="body2">{methods && methods!.getValues('file')?.name}</UploadText>
    </Fragment>
  )
}
