import { Close, HelpOutline } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  ButtonProps,
  IconButton,
  IconButtonProps,
  ModalProps,
  SvgIconProps,
  Typography,
} from '@mui/material'
import React, { JSXElementConstructor, ReactElement } from 'react'
import { ComponentType } from 'react'
import { theme } from '../../../global/styles/theme'
import { MyModal } from '../../MyModal/MyModal'

interface ModalSwitcherProps extends ButtonProps {
  disableCloseBtn?: boolean
  OpenBtnIconComponent?: ComponentType<SvgIconProps>
  ModalComponent?: ComponentType<ModalProps>
  OpenBtnComponent?: ComponentType<ButtonProps>
  CloseBtnComponent?: ComponentType<ButtonProps & IconButtonProps>
  ContentComponent?: ReactElement<any, string | JSXElementConstructor<any>>
  ModalContent?: ComponentType<any>
  FullCustomModal?: ComponentType<Partial<ModalProps>>
  closeDisabled?: boolean
}

interface IDescriptionProps {
  title?: string
  content: React.ReactNode | React.ReactNode
}

export function ModalSwitcher(props: ModalSwitcherProps) {
  const {
    OpenBtnComponent,
    CloseBtnComponent,
    OpenBtnIconComponent,
    ModalComponent,
    ContentComponent,
    ModalContent,
    FullCustomModal,
    disableCloseBtn,
    closeDisabled,
  } = props

  const [state, setState] = React.useState({
    isOpen: false,
  })

  function switchIsOpen() {
    !closeDisabled &&
      setState((prevState) => ({
        ...state,
        isOpen: !prevState.isOpen,
      }))
  }

  const defaultBthProps = {
    size: 'small',
    onClick: switchIsOpen,
    ...props,
  } as IconButtonProps & ButtonProps

  const defaultOpenBtnIconProps = {
    fontSize: 'small',
    sx: {
      color: theme.palette.primary.light,
    },
  } as any

  const defaultCloseBtnIconProps = {
    fontSize: 'medium',
  } as any

  const TargetOpenButtonComponent = OpenBtnComponent ? OpenBtnComponent : IconButton
  const TargetCloseBtnComponent = CloseBtnComponent ? CloseBtnComponent : IconButton
  const TargetOpenBtnIconComponent = (OpenBtnIconComponent && OpenBtnIconComponent) || HelpOutline
  const TargetModalComponent = MyModal.Wrapper || ModalComponent
  const TargetContentComponent = MyModal.Inner || ContentComponent

  return (
    <>
      {TargetOpenButtonComponent && (
        <TargetOpenButtonComponent {...defaultBthProps}>
          {!OpenBtnComponent && <TargetOpenBtnIconComponent {...defaultOpenBtnIconProps} />}
        </TargetOpenButtonComponent>
      )}
      {FullCustomModal ? (
        <FullCustomModal open={state.isOpen} onClose={switchIsOpen} />
      ) : (
        <TargetModalComponent open={state.isOpen} onClose={switchIsOpen}>
          <>
            <TargetContentComponent>
              {!disableCloseBtn && (
                <TargetCloseBtnComponent
                  {...defaultBthProps}
                  sx={{
                    ml: 'auto',
                    mb: 2.5,
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.bg.outlinedBtn,
                  }}
                >
                  <Close {...defaultCloseBtnIconProps} />
                </TargetCloseBtnComponent>
              )}
              {ModalContent && <ModalContent switchIsOpen={switchIsOpen} />}
            </TargetContentComponent>
          </>
        </TargetModalComponent>
      )}
    </>
  )
}
