import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  ModalProps,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material'
import { getQueryParam, getYearRange } from '../../global/functions'
import { slider } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import { ButtonGroupCustom } from '../ButtonGroupCustom/ButtonGroupCustom'
import { IFinanceCenterState } from '../../pages/BudgetPageNew/BudgetPageNew'
import { useHistory } from 'react-router-dom'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { MyModal } from '../MyModal/MyModal'

interface IYearProlongationForm extends Partial<ModalProps> {
  variant: 'finCenter' | 'prProgram'
  finCenterState: Partial<IFinanceCenterState> | IFinanceCenterState
  setFinCenterState:
    | React.Dispatch<React.SetStateAction<IFinanceCenterState>>
    | React.Dispatch<React.SetStateAction<Partial<IFinanceCenterState>>>
  onCancel: () => void
}

export function YearProlongationForm(props: IYearProlongationForm) {
  const { variant, finCenterState, setFinCenterState, onCancel, open } = props

  const history = useHistory()

  const [state, setState] = React.useState({
    isWithCopy: true,
    copyParams: {
      budgetToCopyFrom: getLastBudgetYear(finCenterState),
      prolongationPeriod: {
        from: getLastBudgetYear(finCenterState) as undefined | number | string,
        to: getLastBudgetYear(finCenterState) as undefined | number | string,
      },
    },
    isPending: false,
  })

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      copyParams: {
        ...prevState.copyParams,
        prolongationPeriod: {
          ...prevState.copyParams.prolongationPeriod,
          from: state.isWithCopy ? (getQueryParam(history, 'yearStart') as string) : undefined,
        },
      },
    }))
  }, [state.isWithCopy])

  function getLastBudgetYear(finCenterState: IYearProlongationForm['finCenterState']) {
    // console.log(finCenterState.financeCenter?.budgetYears[finCenterState.financeCenter?.budgetYears?.length - 1])
    if (finCenterState.financeCenter?.budgetYears?.length && finCenterState.financeCenter?.budgetYears) {
      return finCenterState.financeCenter.budgetYears[finCenterState.financeCenter.budgetYears.length - 1]
    }
    return 0
  }

  function getAvailableYears(finCenterState: IYearProlongationForm['finCenterState']) {
    let availableYears: any = {}
    if (finCenterState.project) {
      availableYears.yearStart = getLastBudgetYear(finCenterState) + 1
      availableYears.yearEnd = Number(finCenterState.project.dateEnd.split('.')[2])
      availableYears.yearsArr = getYearRange(availableYears.yearStart, availableYears.yearEnd)
      //console.log(availableYears)
      return availableYears
    }
  }

  function getSliderMarks(finCenterState: IYearProlongationForm['finCenterState']) {
    let availableYears = getAvailableYears(finCenterState)
    let targetMarks = availableYears.yearsArr.map((year: number | string) => {
      let label = year == availableYears.yearStart || year == availableYears.yearEnd ? year : ''
      return {
        value: year,
        label: label,
      }
    })
    //console.log(availableYears)
    return targetMarks
  }

  function isPending(bool: boolean) {
    setState((prevState) => ({
      ...prevState,
      isPending: bool,
    }))
  }

  function prolongate() {
    let targetYearsToProlongate = getYearRange(
      Number(getLastBudgetYear(finCenterState)) + 1,
      state.copyParams.prolongationPeriod.to,
    )
    isPending(true)
    finCenterState.financeCenter &&
      FinanceCenterAPI.copyBudget(finCenterState.financeCenter!.id, {
        from: state.isWithCopy ? Number(getLastBudgetYear(finCenterState)) : undefined,
        to: targetYearsToProlongate,
      })
        .then(() => {
          //@ts-ignore
          setFinCenterState((prevState: IYearProlongationForm['finCenterState']) => ({
            ...prevState,
            refresh: !prevState.refresh,
          }))
          isPending(false)
          onCancel()
        })
        .catch(() => isPending(false))
  }

  function changeIsWithCopy(isWithCopy: boolean) {
    setState((prevState) => ({
      ...prevState,
      isWithCopy: isWithCopy,
    }))
  }

  function changeProlongationPeriodEnd(yearEnd: string | number): void {
    setState((prevState) => ({
      ...prevState,
      copyParams: {
        ...prevState.copyParams,
        prolongationPeriod: {
          ...prevState.copyParams.prolongationPeriod,
          to: Number(yearEnd),
        },
      },
    }))
  }

  return (
    <MyModal.Wrapper {...props} open={state.isPending || (open as boolean)}>
      <MyModal.Inner width={400}>
        <MyModal.Title>Продлить бюджет</MyModal.Title>
        <MyModal.Guide>
          Для продления бюджета, укажите год до которого хотите продлить бюджет.
          {variant == 'finCenter' && 'Так же Вы можете скопировать значения из прошлого периода.'}
        </MyModal.Guide>
        <MyModal.Guide>На какой период продлить бюджет?</MyModal.Guide>
        <Box width={'320px'} marginY={4}>
          {finCenterState.project && (
            <Slider
              valueLabelDisplay="auto"
              sx={slider.horizontal}
              size={'small'}
              defaultValue={Number(getLastBudgetYear(finCenterState))}
              step={1}
              marks={getSliderMarks(finCenterState)}
              min={Number(getAvailableYears(finCenterState).yearStart)}
              max={Number(getAvailableYears(finCenterState).yearEnd)}
              onChangeCommitted={(event, value) => changeProlongationPeriodEnd(value as number)}
            />
          )}
        </Box>
        {variant == 'finCenter' && (
          <>
            <MyModal.Guide>Скопировать значения из прошлого периода?</MyModal.Guide>
            <MyModal.ModalRadioGroup
              value={state.isWithCopy ? 'withCopy' : 'withoutCopy'}
              onChange={(e, value) => changeIsWithCopy(value == 'withCopy')}
            >
              <MyModal.ModalRadio label="Не копировать значения" value="withoutCopy" />
              <MyModal.ModalRadio label={`Скопировать из ${getLastBudgetYear(finCenterState)} года`} value="withCopy" />
            </MyModal.ModalRadioGroup>
            {state.isWithCopy && (
              <MyModal.Description>
                Все операции бюджета, вместе со связями этих операций с субсчетами и формами БДР/БДДС будут скопированы
                из последнего существующего года в выбранный диапазон лет
              </MyModal.Description>
            )}
            {!state.isWithCopy && (
              <MyModal.Description>
                Будет будет продлен без копирования значений на выбранный диапазон лет
              </MyModal.Description>
            )}
          </>
        )}
        <ButtonGroupCustom>
          <Button
            // disabled={state.copyParams.prolongationPeriod.to == state.copyParams.budgetToCopyFrom}
            onClick={() => prolongate()}
            color="success"
            variant={'contained'}
            size={'medium'}
            disabled={state.isPending}
            endIcon={
              state.isPending && (
                <CircularProgress size={20} sx={{ top: 'calc(50% - 10px)', left: 'calc(50% - 10px)' }} />
              )
            }
          >
            {state.isPending ? '' : 'Продлить'}
          </Button>
          <Button
            onClick={(e) => onCancel()}
            variant={'contained'}
            color="primary"
            size={'medium'}
            disabled={state.isPending}
          >
            Отменить
          </Button>
        </ButtonGroupCustom>
      </MyModal.Inner>
    </MyModal.Wrapper>
  )
}
