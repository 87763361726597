import { Box, BoxProps, Typography, TypographyProps } from '@mui/material'
import { theme } from '../../global/styles/theme'

interface IImagePropse extends BoxProps {
  src: string
}

export const UserGuideStep = {
  Wrapper: function Wrapper(props: BoxProps) {
    const { children } = props

    return (
      <Box display="flex" height="220px" {...props}>
        {children}
      </Box>
    )
  },
  Image: function Image(props: IImagePropse) {
    const { src } = props

    return (
      <Box minWidth="160px" height="160px" margin={theme.spacing(3.75, 3.75, 3.75, 0)} {...props}>
        <img height="100%" width="100%" style={{ objectFit: 'fill' }} src={src} />
      </Box>
    )
  },
  Title: function Title(props: TypographyProps) {
    const { children } = props

    return (
      <Typography textAlign={'left'} variant="h2" color={'primary'} {...props}>
        {children}
      </Typography>
    )
  },
  Content: function Content(props: BoxProps) {
    const { children } = props

    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        textAlign={'left'}
        alignItems="flex-start"
        sx={{ '.MuiTypography-root': { textAlign: 'left' } }}
        {...props}
      >
        {children}
      </Box>
    )
  },
}
