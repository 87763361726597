import { Button } from '@mui/material'
import { ButtonGroupCustom } from '../ButtonGroupCustom/ButtonGroupCustom'
import { MyModal } from '../MyModal/MyModal'
import { IDialogProps } from './definitions'

export function DeleteConfirmDialog(props: IDialogProps) {
  const { open, onClose, onYes, onNo } = props

  return (
    <MyModal.Wrapper open={open as boolean} onClose={onClose}>
      <MyModal.Inner>
        <MyModal.Title>Вы уверены, что хотите удалить строку?</MyModal.Title>
        <ButtonGroupCustom>
          <Button variant="contained" color="success" size="medium" onClick={(e) => onYes(e)}>
            Да
          </Button>
          <Button variant="contained" size="medium" color="primary" onClick={(e) => onNo(e)}>
            Нет
          </Button>
        </ButtonGroupCustom>
      </MyModal.Inner>
    </MyModal.Wrapper>
  )
}
