import createTheme, { Theme, ThemeOptions } from '@mui/material/styles/createTheme'

export let theme = createTheme({
  palette: {
    primary: {
      main: '#0044B4',
      light: '#6D9ADC',
      dark: '#00358C',
    },
    secondary: {
      main: '#5C6E8C',
      gray: '#7890B2',
      light: 'rgba(24,118,210,0.08)',
    },
    error: {
      main: '#F46B6B',
    },
    warning: {
      main: '#ED6C02',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
      light: '#F8FCFC',
    },
    text: {
      dark: '#2B3648',
      light: '#ffffff',
      green: '#2E7D32',
      red: '#F46B6B',
    },
    legends: {
      blue: '#6D9ADC',
      green: '#8DD4C8',
      red: '#FFB1B1',
      yellow: '#FFE08F',
      skyblue: '#68C9FF',
      lightblue: '#9CC4FF',
      darkblue: '#576F92',
      orange: '#FE9B3F',
      purple: '#D0AFFF',
      lightgreen: '#6FCCBC',
      lightLime: '#D4E157',
      darkLime: '#C0CA33',
      olive: '#A5D6A7',
      gray: '#D2DCFF',
      disabled: '#9AA2B0',
    },
    bg: {
      gray: '#EDEFF3',
      white: '#ffffff',
      shades: '#F6F7FB',
      outlinedBtn: 'rgba(25,118,210,0.08)',
    },
    purple: '#9427D7',
    disabled: {
      main: '#9AA2B0',
      button: '#bdbdbd',
    },
    grey: {
      100: '#EDEFF3',
      200: '#F6F7FB',
      300: '#E6E9EF',
    },
  },
})

theme = createTheme(theme, {
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          display: 'flex',
          alignItems: 'flex-start',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: theme.palette.secondary.main,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          ':hover': {
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        text: {
          '& .MuiButtonBase-root': {
            borderRight: 'none !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: '#2B3648',
          minWidth: 'auto',
          boxSizing: 'border-box',
        },
        root: {
          padding: '8px 16px',
          minWidth: 'auto',
          boxSizing: 'border-box',
        },
        outlined: {
          // border: "none",
          border: `1px solid ${theme.palette.primary.main}`,
          background: theme.palette.secondary.light,
          // ": hover": {
          //   border: "none"
          // },
          ':disabled': {
            svg: {
              color: 'rgba(154, 162, 176, 1)',
            },
            background: 'rgba(154, 162, 176, 0.2)',
            border: '1px solid rgba(154, 162, 176, 1) !important',
          },
        },
        sizeLarge: {
          padding: theme.spacing(1, 2),
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '26px',
          letterSpacing: '0.46px',
        },
        sizeMedium: {
          padding: theme.spacing(1, 2),
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
        },
        sizeSmall: {
          padding: theme.spacing(1, 1),
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '20px',
          letterSpacing: '0.46px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '24px',
        },
        sizeMedium: {
          fontSize: '20px',
        },
        sizeSmall: {
          fontSize: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          ':disabled': {
            '.MuiButtonBase-root': {
              color: theme.palette.disabled!.main,
            },
          },
          '.MuiButtonBase-root': {
            ...theme.typography.subtitle2,
            fontSize: '13px',
            padding: `${theme.spacing(0.88, 1)} !important`,
            minHeight: 'auto',
            minWidth: 'auto',
          },
          '.MuiTabs-flexContainer': {
            gap: theme.spacing(1),
          },
        },
        root: {
          minHeight: 'auto',
        },
        indicator: ({ ownerState }) => ({
          ...(ownerState.disabled && {
            backgroundColor: theme.palette.disabled!.main,
          }),
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: '250px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#7890B2',
        },
        footer: {
          background: 'rgb(237,244,251)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: 'inherit',
          color: 'white',
          padding: '11px 8px',
          textAlign: 'center',
          '&:first-of-type': {
            borderRadius: '8px 0px 0px 0px !important',
          },
          '&:last-of-type': {
            borderRadius: '0px 8px 0px 0px',
          },
        },
        body: {
          padding: theme.spacing(1),
          background: 'white',
          border: 'none',
          boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
        },
        footer: {
          padding: theme.spacing(1),
          color: '#0044B4',
          background: 'rgb(237,244,251)',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '130%',
          letterSpacing: '0.1px',
          border: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: `"Roboto","Segoe UI","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif`,
    h1: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
    },
    h2: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '29px',
      letterSpacing: '0.15px',
    },
    h3: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
    },
    tooltip: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '130%',
    },
    buttonMedium: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '171%',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      color: `${theme.palette.secondary.dark}`,
    },
    buttonSmall: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '20px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      color: `${theme.palette.primary.main}`,
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '150%',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '175%',
      letterSpacing: '0.15px',
      textTransform: 'none',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '130%',
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.17px',
      textTransform: 'none',
    },
  },
} as ThemeOptions) as Theme
