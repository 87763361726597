import { Buffer } from 'buffer'

export function downloadFile(base64: string, fileName: string) {
  const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(base64, 'base64')]))
  const link = document.createElement('a')

  link.href = blobUrl
  link.setAttribute('download', fileName)
  link.click()

  window.URL.revokeObjectURL(blobUrl)
}
