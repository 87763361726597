import { IFinanceCenter } from '../../types/commos-def'
import { errorHandler, req, successHandler } from '../api'
import { BudgetForServer } from '../constructors'
import {
  IBudgetListResponse,
  ICopyBudgetByCategoryQueryParams,
  ICopyBudgetQueryParams,
  INewBudgetBody,
  IRmBindBody,
} from '../definitions'

export class FinanceCenterAPI {
  static baseURL: string = '/finance-center'

  static async getBudgets(projectID: number | string): Promise<IBudgetListResponse> {
    return await req
      .get(this.baseURL + '/list', { projectID: projectID })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getBudgetById(financeCenterID: number | string | undefined): Promise<IFinanceCenter> {
    return await req
      .get(this.baseURL + '/' + financeCenterID + '/get')
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async createNewBudget(params: INewBudgetBody) {
    console.log(params)
    return await req
      .post(this.baseURL + '/create', new BudgetForServer(params))
      .then(({ data }) => {
        successHandler('Бюджет создан')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updateBudget(budgetID: number, body: INewBudgetBody) {
    return await req
      .post(this.baseURL + `/${budgetID}` + '/update', new BudgetForServer(body))
      .then(({ data }) => {
        successHandler('Бюджет обновлен')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteBudget(budgetID: number) {
    return await req
      .delete(this.baseURL + `/${budgetID}` + '/delete')
      .then(({ data }) => {
        successHandler('Бюджет удален')
      })
      .catch((error) => errorHandler(error))
  }

  static async copyBudget(budgetID: number, query: ICopyBudgetQueryParams) {
    return await req
      .get(this.baseURL + `/${budgetID}` + '/copy-budget', query)
      .then(({ data }) => {
        successHandler('Бюджет продлен')
      })
      .catch((error) => errorHandler(error))
  }

  static async copyBudgetByCategory(
    financeCenterID: number,
    query: ICopyBudgetByCategoryQueryParams,
    controller?: AbortController,
  ) {
    return await req
      .get(this.baseURL + `/${financeCenterID}` + '/copy-budget-by-category', query, undefined, controller)
      .then(() => {
        successHandler('Бюджет скопирован')
      })
      .catch((error) => errorHandler(error))
  }
  // /finance-center/{financeCenterID}/rm-bind
  static async RMbind(financeCenterID: number | string, body: IRmBindBody) {
    return await req
      .post(this.baseURL + `/${financeCenterID}` + '/rm-bind', body)
      .then(({ data }) => {
        successHandler('Проект из RM  привязан')
      })
      .catch((error) => errorHandler(error))
  }
}
