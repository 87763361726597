import { FC, useCallback } from 'react'
import { IBdrResultParametersProps } from './BdrResultParameters.types'
import { BdrAnalysItem } from '../BdrPeriodItem/BdrPeriodItem.styles'
import { BdrPeriodSwitches } from '../BdrPeriodItem/components/BdrPeriodSwitches'
import { useFormikContext } from 'formik'
import { IBdrParametersFormData, TBdrPeriodSwitch } from '../BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { theme } from '../../../../../../global/styles/theme'
import { Typography } from '@mui/material'

export const BdrResultParameters: FC<IBdrResultParametersProps> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>() || {}
  const { result, isResultShown } = values || {}

  const changeResultValueByKey = useCallback(
    (periodKey: TBdrPeriodSwitch, checked: any) => {
      const localResult = { ...result, [periodKey]: checked }

      setFieldValue('result', localResult)

      if (Object.values(localResult).every((item) => !item)) setFieldValue('isResultShown', false)
    },
    [result],
  )

  return (
    <BdrAnalysItem isDisabled={!isResultShown}>
      <BdrPeriodSwitches
        item={result}
        changeSwitchValueByKey={changeResultValueByKey}
        title="Итого"
        isDisabled={disabled}
        isResult={true}
      />
    </BdrAnalysItem>
  )
}
