import React, { FC, useEffect, useMemo } from 'react'
import { useGetProfileQuery } from '../../api/profile'
import Progress from '../../components/Progress'
import { ProfileState, updateProfileState } from '../../store/slices/profile'
import { useDispatch } from 'react-redux'

interface AppLayoutProps {
  children: JSX.Element
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const dispatch = useDispatch()
  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
    isSuccess: profileIsSuccess,
  } = useGetProfileQuery()

  const value = useMemo<ProfileState>(() => {
    return {
      profile: profileError ? ({} as ProfileState['profile']) : profile || ({} as ProfileState['profile']),
      currentCompanyId: profile?.company?.companyID || 0,
      isLogout: false,
    }
  }, [profile, profileError])

  const isLoading = profileIsLoading
  const isError = profileError

  useEffect(() => {
    if (profileIsSuccess) {
      dispatch(updateProfileState(value))
    }
  }, [profileIsSuccess])

  useEffect(() => {
    if (isError) {
      console.log('Ошибка')
    }
  }, [isError])

  return isLoading ? <Progress /> : children
}

export default AppLayout
