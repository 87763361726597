import { Box, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useGetPaymentTemplatesQuery } from '../../../../../../api/template'
import { IPaymentTemplate } from '../../../../../../api/template/types'
import { BDDSSubBillRecord } from '../../../../../../global/api/definitions'
import { scrollableWrapper } from '../../../../../../global/styles/presets'
import { PaymentTermsRow } from './PaymentTermsRow/PaymentTermsRow'
import { StyledHeadTd, StyledTableBody, StyledTableContainer, StyledTableHead, StyledBox, StyledHead } from './styles'

interface PTTtableProps {
  subBillRecordList: BDDSSubBillRecord[]
  financeCenterID: number
  changePayTempl: (paymentTemplateID: number, recordID: number) => void
  changeNds: (newCpTax: number, recordID: number) => void
}
export const PTTtable: React.FC<PTTtableProps> = ({
  subBillRecordList,
  financeCenterID,
  changePayTempl,
  changeNds,
}) => {
  const { data, isLoading, isFetching } = useGetPaymentTemplatesQuery()
  const paymentTemplates = data ? data : ([] as IPaymentTemplate[]) //

  const isDataLoading = isFetching || isLoading

  return (
    <Stack
      className="className-TableContainer"
      sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)', pb: 0, minWidth: '885px' }}
    >
      <StyledTableContainer className="StyledTableContainer">
        <StyledHead>
          <StyledTableHead className="HEAD">
            <StyledHeadTd minWidth={150}>Строка бюджета</StyledHeadTd>
            <StyledHeadTd
              style={{
                alignItems: 'flex-start',
                minWidth: '185px',
              }}
            >
              Наименование субсчета
            </StyledHeadTd>
            <StyledHeadTd>
              <StyledBox>Наименование счета</StyledBox>
            </StyledHeadTd>
            <StyledHeadTd style={{ alignItems: 'flex-start', paddingLeft: 12 }}>
              <StyledBox>Условие оплаты</StyledBox>
            </StyledHeadTd>
            <StyledHeadTd style={{ alignItems: 'flex-start' }}>
              <StyledBox sx={{ ml: '42px' }}>НДС</StyledBox>
            </StyledHeadTd>
          </StyledTableHead>
        </StyledHead>
        <Box>
          <StyledTableBody className="body">
            {!isDataLoading &&
              subBillRecordList?.map((element) => {
                return (
                  <PaymentTermsRow
                    key={element.id}
                    subBillRecord={element}
                    paymentTemplates={paymentTemplates}
                    financeCenterID={financeCenterID}
                    changePayTempl={changePayTempl}
                    changeNds={changeNds}
                  />
                )
              })}
          </StyledTableBody>
        </Box>
      </StyledTableContainer>
    </Stack>
  )
}
