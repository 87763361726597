import { Box, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { IPaymentTemplate } from '../../../../../api/template/types'
import { scrollableWrapper } from '../../../../../global/styles/presets'
import { PaymentTemplateRow } from './PaymentTemplateRow/PaymentTemplateRow'

import { StyledHeadTd, StyledTableBody, StyledTableContainer, StyledTableHead, StyledBox, StyledHead } from './styles'

interface PaymentTemplateTableProps {
  openEditForm: (id: number) => void
  onEditPaymentTemplateClick: (id: number) => void
  paymentTemplates: IPaymentTemplate[]
}

export const PaymentTemplateTable: React.FC<PaymentTemplateTableProps> = ({
  openEditForm,
  paymentTemplates,
  onEditPaymentTemplateClick,
}) => {
  /****************************************************************************************/

  return (
    <Stack
      className="className-TableContainer"
      sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)', pb: 0, minWidth: '885px' }}
    >
      <StyledTableContainer className="StyledTableContainer">
        <StyledHead>
          <StyledTableHead className="HEAD">
            <StyledHeadTd
              style={{
                alignItems: 'flex-start',
                marginLeft: '12px',
                minWidth: '185px',
              }}
            >
              Полное название шаблона
            </StyledHeadTd>
            <StyledHeadTd></StyledHeadTd>
            <StyledHeadTd>
              <StyledBox sx={{}}>Условие 1</StyledBox>
            </StyledHeadTd>
            <StyledHeadTd>
              <StyledBox sx={{}}>Условие 2</StyledBox>
            </StyledHeadTd>
            <StyledHeadTd>
              <StyledBox sx={{}}>Условие 3</StyledBox>
            </StyledHeadTd>
            <StyledHeadTd style={{ minWidth: 60, color: '#7890B2', padding: 0, border: 'none' }}></StyledHeadTd>
          </StyledTableHead>
        </StyledHead>
        <Box>
          <StyledTableBody className="body">
            {paymentTemplates?.map((element) => {
              return (
                <PaymentTemplateRow
                  openEditForm={openEditForm}
                  onEditPaymentTemplateClick={onEditPaymentTemplateClick}
                  key={element.id}
                  paymentTemplate={element}
                />
              )
            })}
          </StyledTableBody>
        </Box>
      </StyledTableContainer>
    </Stack>
  )
}
