import qs from 'qs'
import React, { memo } from 'react'
import { Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  SxProps,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DeleteConfirmDialog } from '../../../components/Dialogs/DeleteConfirmDialog'
import { EENumber } from '../../../components/editableElements/EENumber'
import { EEString } from '../../../components/editableElements/EEString'
import {
  EETableRowProvider,
  EETableRowContext,
  IValidationErrors,
} from '../../../components/editableElements/EETableRow'
import { ProductionProgramAPI } from '../../../global/api/APIMethods/ProductionProgramAPI'
import { IProductionProgramEstimateGetListQuery } from '../../../global/api/definitions'
import { getDeepCopy } from '../../../global/functions'
import { usePrevState } from '../../../global/hooks'
import { scrollableWrapper, tableCellInput_ViewMode, tableCellInput, table } from '../../../global/styles/presets'
import { theme } from '../../../global/styles/theme'
import {
  IPrPrEstimateOperation,
  TEEMode,
  IPrPrEstimateTotal,
  ProjectType,
  IPrPrEstimateOperationWithLvl,
} from '../../../global/types/commos-def'
import { IProductionProgramState } from '../ProductionProgram-def'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface IEstimateRowProps {
  operation: IPrPrEstimateOperationWithLvl
  prPrState: IProductionProgramState
  setPrPrState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
  projectType: ProjectType
  isRowHidden: (id: number) => boolean
  updateHiddenRows: (id: number) => () => void
}

interface IEstimateRowState {
  changed: boolean
  mode: TEEMode
  operation: IPrPrEstimateOperationWithLvl
  delta: Partial<IPrPrEstimateTotal>
  isDeleteConfirmOpen: boolean
}

interface IEstimateTotalProps {
  total: IPrPrEstimateTotal
  projectType: ProjectType
}

interface IEstimateHeadingProps extends TableRowProps {
  projectType: ProjectType
}

export const EstimateTable = {
  Wrapper: function Wrapper(props: TableProps & { onScroll: any }) {
    const { children, onScroll } = props
    return (
      <Box mr={3} onScroll={onScroll}>
        <TableContainer
          {...props}
          sx={{ ...scrollableWrapper, height: 'calc(100vh - 190px)', ...props.sx, pl: 0, ml: 2.5 }}>
          <Table stickyHeader>{children}</Table>
        </TableContainer>
      </Box>
    )
  },
  Workload: {
    Headings: function Headings(props: IEstimateHeadingProps) {
      return (
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                border: 'none',
              },
            }}>
            <TableCell width="44px" sx={{ minWidth: '44px' }}></TableCell>
            <TableCell width="88px"></TableCell>
            <TableCell colSpan={3} />
            {props.projectType === 'GEN' ? (
              <>
                <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
                  ГЕНПОДРЯД
                </TableCell>
                <TableCell colSpan={3} sx={{ background: theme.palette.secondary.main }}>
                  СУБПОДРЯД
                </TableCell>
                <TableCell colSpan={2} />
              </>
            ) : (
              <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
                Затраты
              </TableCell>
            )}
          </TableRow>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                borderRadius: '0 !important',
              },
            }}>
            <TableCell width="44px" sx={{ minWidth: '44px' }}></TableCell>
            <TableCell width="88px"></TableCell>
            <TableCell sx={{ width: '350px', textAlign: 'left', paddingX: 2 }}> Наименование работ</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во</TableCell>
            <TableCell sx={{ width: '140px' }}>Ед. измерения</TableCell>
            <TableCell
              sx={{
                background: theme.palette.secondary.main,
                ...(props.projectType === 'GEN' ? { width: '140px' } : { minWidth: '140px' }),
              }}>
              Цена за единицу
            </TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за единицу</TableCell>
                <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
                <TableCell sx={{ background: theme.palette.secondary.main, width: '200px' }}>Субподрядчик</TableCell>
                <TableCell sx={{ minWidth: '140px' }}>Прибыль</TableCell>
                <TableCell sx={{ minWidth: '140px' }}>Рентабельность</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
      )
    },
    Row: memo((props: IEstimateRowProps) => {
      const { operation, setPrPrState, prPrState, updateHiddenRows, isRowHidden } = props

      const [state, setState] = React.useState({
        mode: 'view',
        changed: false,
        operation: operation,
        isDeleteConfirmOpen: false,
        delta: {
          genContractTotal: 0,
          subContractTotal: 0,
          income: 0,
          rentability: 0,
        },
      } as IEstimateRowState)

      React.useEffect(() => {
        setState((prev) => ({
          ...prev,
          operation: operation,
          changed: false,
        }))
      }, [operation])

      const prevDelta = usePrevState(state.delta) as unknown as IEstimateRowState['delta']

      let timeout: NodeJS.Timeout

      React.useEffect(() => {
        state.changed &&
          applyDiffOperationDeltaToEstimateTotal(
            getDiffBetweenOperationDeltas(prevDelta, state.delta),
            state.operation.parentIds,
            state.operation.id || 0,
          )
      }, [state.delta])

      React.useEffect(() => {
        if (state.changed)
          timeout = setTimeout(
            () =>
              recalculateDeltaBetweenInitialAndChangedOperation(
                operation as IPrPrEstimateOperation,
                state.operation as IPrPrEstimateOperation,
                state.delta,
              ),
            300,
          )
        return () => {
          clearTimeout(timeout)
        }
      }, [state.operation])

      const history = useHistory()

      function updateOperation() {
        ProductionProgramAPI.updateOperation(state.operation as IPrPrEstimateOperation)
        let operationsCopy: IPrPrEstimateOperationWithLvl[] = prPrState.operations && getDeepCopy(prPrState.operations)
        let operationIndex = operationsCopy.findIndex((el) => el.id === state.operation.id)
        operationsCopy[operationIndex] = { ...state.operation }
        setPrPrState((prevState) => ({
          ...prevState,
          operations: operationsCopy,
        }))
      }

      function switchDeleteConfirm() {
        setState((prevState) => ({
          ...prevState,
          isDeleteConfirmOpen: !prevState.isDeleteConfirmOpen,
        }))
      }

      function recalculateWorkloadOperation(
        value: string | number | boolean | undefined,
        key: keyof IPrPrEstimateOperation,
        operation: IPrPrEstimateOperation,
      ) {
        // @ts-ignore
        let copy = getDeepCopy(operation)
        copy[key] = value

        function calculate(value: Array<keyof IPrPrEstimateOperation>) {
          if (value.includes('subContractTotal')) {
            copy.subContractTotal = (copy.quantity || 0) * (copy.subContractUnitPrice || 0)
          }
          if (value.includes('genContractTotal')) {
            copy.genContractTotal = (copy.quantity || 0) * (copy.genContractUnitPrice || 0)
          }
          if (value.includes('income')) {
            copy.income = (copy.genContractTotal || 0) - (copy.subContractTotal || 0)
          }
          if (value.includes('rentability')) {
            copy.rentability =
              copy.genContractTotal && copy.genContractTotal > 0 ? (copy.income / copy.genContractTotal) * 100 : 0
          }
        }
        switch (key) {
          case 'quantity':
            calculate(['genContractTotal', 'subContractTotal', 'income', 'rentability'])
            break
          case 'genContractUnitPrice':
            calculate(['genContractTotal', 'income', 'rentability'])
            break
          case 'subContractUnitPrice':
            calculate(['genContractTotal', 'subContractTotal', 'income', 'rentability'])
            break
        }
        // let copy = getDeepCopy(operation)
        setState((prevState) => ({
          ...prevState,
          operation: copy,
          changed: true,
        }))
      }

      function changeOperationStringValue(key: 'name' | 'unitType' | 'subContractContractor', value: string) {
        setState((prevState) => ({
          ...prevState,
          operation: {
            ...state.operation,
            [key]: value,
          },
        }))
      }

      function recalculateDeltaBetweenInitialAndChangedOperation(
        propsOperation: IPrPrEstimateOperation,
        stateOperation: IPrPrEstimateOperation,
        delta: IEstimateRowState['delta'],
      ) {
        let deltaCopy = delta && getDeepCopy(delta)
        for (let key in deltaCopy) {
          deltaCopy[key] =
            (stateOperation[key as keyof IPrPrEstimateTotal] || 0) -
            (propsOperation[key as keyof IPrPrEstimateTotal] || 0)
        }
        setState((prevState) => ({
          ...prevState,
          delta: deltaCopy,
        }))
      }

      function getDiffBetweenOperationDeltas(
        prevDelta: IEstimateRowState['delta'],
        newDelta: IEstimateRowState['delta'],
      ): IEstimateRowState['delta'] {
        let targetDelta = {} as IEstimateRowState['delta']
        for (let key in prevDelta) {
          targetDelta[key as keyof IEstimateRowState['delta']] =
            (newDelta[key as keyof IPrPrEstimateTotal] || 0) - (prevDelta[key as keyof IPrPrEstimateTotal] || 0)
        }
        return targetDelta
      }

      function applyDiffOperationDeltaToEstimateTotal(
        targetDelta: IEstimateRowState['delta'],
        parentIds: number[],
        id: number,
      ) {
        let totalCopy = prPrState.recalculatedTotal && getDeepCopy(prPrState.recalculatedTotal)
        let operationsCopy: IPrPrEstimateOperationWithLvl[] = prPrState.operations && getDeepCopy(prPrState.operations)
        let operationIndex = operationsCopy.findIndex((el) => el.id === id)
        for (let key in totalCopy) {
          totalCopy[key as keyof IEstimateRowState['delta']] +=
            targetDelta[key as keyof IEstimateRowState['delta']] || 0
        }
        if (totalCopy) {
          totalCopy['rentability'] = totalCopy['genContractTotal']
            ? ((totalCopy['income'] || 0) / totalCopy['genContractTotal']) * 100
            : 0
        }
        // Определяем тип для ключей, которые точно соответствуют числовым свойствам
        type NumericKeys = 'genContractTotal' | 'subContractTotal' | 'income'

        const operationKeys: NumericKeys[] = ['genContractTotal', 'subContractTotal', 'income']

        operationsCopy.forEach((el) => {
          if (el.id && parentIds.includes(el.id)) {
            operationKeys.forEach((key) => {
              // Проверяем, что свойство существует, не равно null и является числом
              if (key in el && el[key] != null && typeof el[key] === 'number') {
                // Получаем значение из targetDelta и убеждаемся, что оно тоже число
                const deltaValue = targetDelta[key as keyof IEstimateRowState['delta']]
                if (typeof deltaValue === 'number') {
                  el[key] = (el[key] as number) + deltaValue
                }
              }
            })
          }
        })
        operationsCopy[operationIndex] = { ...state.operation }
        setPrPrState((prevState) => ({
          ...prevState,
          recalculatedTotal: totalCopy,
          operations: operationsCopy,
        }))
      }

      function deleteOperation() {
        ProductionProgramAPI.deleteOperation(state.operation as IPrPrEstimateOperation, {
          ...(qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          }) as unknown as IProductionProgramEstimateGetListQuery),
          analysisType: 'PLAN',
        }).then(() => {
          let newTotal = prPrState.total && getDeepCopy(prPrState.total)
          for (let key in newTotal) {
            newTotal[key as keyof IEstimateRowState['delta']] -=
              state.operation[key as keyof IEstimateRowState['delta']] || 0
          }

          setPrPrState((prevState) => {
            const operationIdToRemove = state.operation.id
            const newOperations = prevState.operations!.filter((operation) => operation.id !== operationIdToRemove)

            // Обновление hasChild для родительских операций
            const parentIds: number[] = state.operation.parentIds || []

            parentIds.forEach((parentId) => {
              // остались ли еще элементы у которых parentId как у удаленной строки
              const isStillHasChild = newOperations.some((op) =>
                (op as IPrPrEstimateOperationWithLvl).parentIds.includes(parentId),
              )
              // находим род строку
              const parentIndex = newOperations.findIndex((op) => op.id === parentId)
              // находим оне есть и у нее изменилось свойство hasChild, меняем ее в массиве
              if (
                parentIndex !== -1 &&
                (newOperations[parentIndex] as IPrPrEstimateOperationWithLvl).hasChild !== isStillHasChild
              ) {
                newOperations[parentIndex] = {
                  ...newOperations[parentIndex],
                  hasChild: isStillHasChild,
                }
              }

              // пересчитываем суммы у род строк
              type NumericKeys = 'genContractTotal' | 'subContractTotal' | 'income'
              const operationKeys: NumericKeys[] = ['genContractTotal', 'subContractTotal', 'income']

              operationKeys.forEach((key) => {
                if (
                  key in newOperations[parentIndex] &&
                  (newOperations[parentIndex] as IPrPrEstimateOperationWithLvl)[key] != null &&
                  typeof (newOperations[parentIndex] as IPrPrEstimateOperationWithLvl)[key] === 'number'
                ) {
                  const deltaValue = state.operation[key as keyof IEstimateRowState['delta']] || 0
                  if (typeof deltaValue === 'number') {
                    ;(newOperations[parentIndex] as IPrPrEstimateOperationWithLvl)[key] =
                      ((newOperations[parentIndex] as IPrPrEstimateOperationWithLvl)[key] as number) + deltaValue
                  }
                }
              })
            })

            return {
              ...prevState,
              operations: newOperations as IPrPrEstimateOperationWithLvl[],
              recalculatedTotal: {
                ...newTotal,
              },
              total: {
                ...newTotal,
              },
            }
          })
        })
        switchDeleteConfirm()
      }
      return (
        <EETableRowProvider
          disableSwitchMode={!state.operation.unitType}
          onSwitchMode={(prevMode, currentMode) => prevMode === 'edit' && updateOperation()}
          validation={
            state.operation.hasChild ? undefined : () => validateRow(state.operation as IPrPrEstimateOperation)
          }>
          <DeleteConfirmDialog
            open={state.isDeleteConfirmOpen}
            onClose={switchDeleteConfirm}
            onYes={deleteOperation}
            onNo={switchDeleteConfirm}
          />
          <EETableRowContext.Consumer>
            {(value) => (
              <>
                <TableCell>
                  {state.operation.unitType && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        color={'error'}
                        size="small"
                        sx={{ marginX: 'auto', p: 0.5 }}
                        onClick={() => switchDeleteConfirm()}>
                        <Delete color={'error'} fontSize="small" />
                      </Button>
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="center" gap={1.5}>
                    {state.operation.hasChild ? (
                      <IconButton
                        sx={{
                          width: '20px',
                          height: '20px',
                          padding: 0,
                        }}
                        color="inherit"
                        component="button"
                        onClick={() => {
                          if (state.operation.id) {
                            updateHiddenRows(state.operation.id)()
                          }
                        }}>
                        {state.operation.id && isRowHidden(state.operation.id) ? (
                          <KeyboardArrowUpIcon
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        )}
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{
                          width: '20px',
                          height: '20px',
                          opacity: 0,
                          pointerEvents: 'none',
                        }}
                        color="inherit"
                      />
                    )}
                    <Typography
                      sx={{
                        width: '31px',
                        height: '20px',
                        lineHeight: '20px',
                        fontSize: '10px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        ...getLvlStyles(state.operation.lvl),
                      }}>
                      УР {state.operation.lvl}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <EEString
                    align="left"
                    name="name"
                    maxLength={201}
                    mode={!state.operation.unitType ? 'view' : value.mode}
                    value={state.operation.name || ''}
                    onChange={(e) => changeOperationStringValue('name', e.target.value)}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      error: !value?.validationErrors?.name?.isValid,
                      sx: tableCellInput,
                    }}
                  />
                </TableCell>
                <TableCell>
                  {state.operation.unitType ? (
                    <EENumber
                      name="quantity"
                      mode={value.mode}
                      value={state.operation.quantity || 0}
                      onNumberChange={(values) =>
                        recalculateWorkloadOperation(
                          values.floatValue,
                          'quantity',
                          state.operation as IPrPrEstimateOperation,
                        )
                      }
                      viewModeStyle={tableCellInput_ViewMode}
                      TextFieldProps={{
                        sx: tableCellInput,
                      }}
                      /* NumberFormatProps={{
                                                decimalScale: 0,
                                            }} */
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>
                  <EEString
                    name="unitType"
                    mode={!state.operation.unitType ? 'view' : value.mode}
                    value={state.operation.unitType || ''}
                    onChange={(e) => changeOperationStringValue('unitType', e.target.value)}
                    viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                    TextFieldProps={{
                      sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                      error: !value?.validationErrors?.unitType?.isValid,
                    }}
                  />
                </TableCell>
                {props.projectType === 'GEN' && (
                  <>
                    <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                      {state.operation.unitType ? (
                        <EENumber
                          name="genContractUnitPrice"
                          mode={!state.operation.unitType ? 'view' : value.mode}
                          value={state.operation.genContractUnitPrice || 0}
                          onNumberChange={(values) =>
                            recalculateWorkloadOperation(
                              values.floatValue,
                              'genContractUnitPrice',
                              state.operation as IPrPrEstimateOperation,
                            )
                          }
                          viewModeStyle={tableCellInput_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInput,
                          }}
                          /* NumberFormatProps={{
                                                  decimalScale: 0,
                                              }} */
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="genContractTotal"
                        mode={'view'}
                        value={state.operation.genContractTotal || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        /*  NumberFormatProps={{
                                                  decimalScale: 0,
                                              }} */
                      />
                    </TableCell>
                  </>
                )}
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  {state.operation.unitType ? (
                    <EENumber
                      name="subContractUnitPrice"
                      mode={!state.operation.unitType ? 'view' : value.mode}
                      value={state.operation.subContractUnitPrice || 0}
                      onNumberChange={(values) =>
                        recalculateWorkloadOperation(
                          values.floatValue,
                          'subContractUnitPrice',
                          state.operation as IPrPrEstimateOperation,
                        )
                      }
                      viewModeStyle={tableCellInput_ViewMode}
                      TextFieldProps={{
                        sx: tableCellInput,
                      }}
                      /* NumberFormatProps={{
                                              decimalScale: 0,
                                          }} */
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>
                  <EENumber
                    name="subContractTotal"
                    mode={'view'}
                    value={state.operation.subContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                              decimalScale: 0,
                                          }} */
                  />
                </TableCell>
                {props.projectType === 'GEN' && (
                  <>
                    <TableCell sx={{ borderRight: `1px solid ${theme.palette.grey[300]}` }}>
                      <EEString
                        name="subContractContractor"
                        mode={!state.operation.unitType ? 'view' : value.mode}
                        value={state.operation.subContractContractor || ''}
                        onChange={(e) => changeOperationStringValue('subContractContractor', e.target.value)}
                        viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                        TextFieldProps={{
                          sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="income"
                        mode={'view'}
                        value={state.operation.income || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        NumberFormatProps={{
                          allowNegative: true,
                          //decimalScale: 0,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {state.operation.unitType ? (
                        <EENumber
                          name="rentability"
                          mode={'view'}
                          value={state.operation.rentability || 0}
                          viewModeStyle={tableCellInput_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInput,
                          }}
                          NumberFormatProps={{
                            allowNegative: true,
                            suffix: ' %',
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </>
                )}
              </>
            )}
          </EETableRowContext.Consumer>
        </EETableRowProvider>
      )
    }),
    Total: function Total(props: IEstimateTotalProps) {
      const { total } = props
      return (
        <TableFooter sx={table.footer.sticky}>
          <TableRow>
            <TableCell width="44px" />
            <TableCell width="88px" />
            <TableCell colSpan={3} sx={{ paddingX: 2 }}>
              ВСЕГО
            </TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell></TableCell>
                <TableCell align="center">
                  <EENumber
                    name="genContractTotal"
                    mode={'view'}
                    value={total.genContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                        decimalScale: 0,
                                    }} */
                  />
                </TableCell>
              </>
            )}
            <TableCell></TableCell>
            <TableCell align="center">
              <EENumber
                name="subContractTotal"
                mode={'view'}
                value={total.subContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell></TableCell>
                <TableCell align="center">
                  <EENumber
                    name="income"
                    mode={'view'}
                    value={total.income || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      //decimalScale: 0,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <EENumber
                    name="rentability"
                    mode={'view'}
                    value={total.rentability || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      suffix: ' %',
                    }}
                  />
                </TableCell>
              </>
            )}
          </TableRow>
        </TableFooter>
      )
    },
  },
  Laborious: {
    Headings: function Headings(props: IEstimateHeadingProps) {
      return (
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                border: 'none',
              },
            }}>
            <TableCell width="44px"></TableCell>
            <TableCell width="88px"></TableCell>
            <TableCell colSpan={3} />
            {props.projectType === 'GEN' ? (
              <>
                <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
                  ГЕНПОДРЯД
                </TableCell>
                <TableCell colSpan={3} sx={{ background: theme.palette.secondary.main }}>
                  СУБПОДРЯД
                </TableCell>
                <TableCell colSpan={3} />
              </>
            ) : (
              <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
                Затраты
              </TableCell>
            )}
          </TableRow>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                borderRadius: '0 !important',
              },
            }}>
            <TableCell width="44px"></TableCell>
            <TableCell width="88px"></TableCell>
            <TableCell sx={{ width: '350px', textAlign: 'left', paddingX: 2 }}> Наименование работ</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во ч/ч</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во м/ч</TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за час</TableCell>
                <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
              </>
            )}
            <TableCell
              sx={{
                background: theme.palette.secondary.main,
                ...(props.projectType === 'GEN' ? { width: '140px' } : { minWidth: '140px' }),
              }}>
              Цена за час
            </TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell sx={{ background: theme.palette.secondary.main, width: '200px' }}>Субподрядчик</TableCell>
                <TableCell sx={{ minWidth: '140px' }}>Прибыль в час</TableCell>
                <TableCell sx={{ minWidth: '140px' }}>Прибыль всего</TableCell>
                <TableCell sx={{ minWidth: '140px' }}>Рентабельность</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
      )
    },
    Row: memo((props: IEstimateRowProps) => {
      const { operation, setPrPrState, prPrState, isRowHidden, updateHiddenRows } = props

      const [state, setState] = React.useState({
        mode: 'view',
        changed: false,
        operation: operation,
        isDeleteConfirmOpen: false,
        delta: {
          humanHoursQuantity: 0,
          machineryHoursQuantity: 0,
          genContractPricePerHour: 0,
          genContractTotal: 0,
          subContractPricePerHour: 0,
          subContractTotal: 0,
          incomePerHour: 0,
          income: 0,
          rentability: 0,
        },
      } as IEstimateRowState)

      const prevDelta = usePrevState(state.delta) as unknown as IEstimateRowState['delta']

      let timeout: NodeJS.Timeout

      React.useEffect(() => {
        state.changed && applyDiffOperationDeltaToEstimateTotal(getDiffBetweenOperationDeltas(prevDelta, state.delta))
      }, [state.delta])

      React.useEffect(() => {
        if (state.changed)
          timeout = setTimeout(
            () =>
              recalculateDeltaBetweenInitialAndChangedOperation(
                operation as IPrPrEstimateOperation,
                state.operation as IPrPrEstimateOperation,
                state.delta,
              ),
            300,
          )
        return () => {
          clearTimeout(timeout)
        }
      }, [state.operation])

      React.useEffect(() => {
        setState((prev) => ({
          ...prev,
          operation: operation,
          changed: false,
        }))
      }, [operation])

      function updateOperation() {
        ProductionProgramAPI.updateOperation(state.operation as IPrPrEstimateOperation)
        let operationsCopy: IPrPrEstimateOperationWithLvl[] = prPrState.operations && getDeepCopy(prPrState.operations)
        let operationIndex = operationsCopy.findIndex((el) => el.id === state.operation.id)
        operationsCopy[operationIndex] = { ...state.operation }
        setPrPrState((prevState) => ({
          ...prevState,
          operations: operationsCopy,
        }))
      }

      function recalculateLaboriousOperation(
        value: string | number | boolean | undefined,
        key: keyof IPrPrEstimateOperation,
        operation: IPrPrEstimateOperation,
      ) {
        // @ts-ignore
        let copy = getDeepCopy(operation)
        copy[key] = value

        function calculate(value: Array<keyof IPrPrEstimateOperation>) {
          let totalHoursQty = (Number(copy.humanHoursQuantity) || 0) + (Number(copy.machineryHoursQuantity) || 0)
          if (value.includes('genContractPricePerHour')) {
            copy.genContractPricePerHour = copy.genContractTotal / totalHoursQty
          }
          if (value.includes('subContractPricePerHour')) {
            copy.subContractPricePerHour = totalHoursQty > 0 ? copy.subContractTotal / totalHoursQty : 0
          }
          if (value.includes('incomePerHour')) {
            copy.incomePerHour = Number(copy.income) / totalHoursQty
          }
        }
        switch (key) {
          case 'humanHoursQuantity':
            calculate(['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour'])
            break
          case 'machineryHoursQuantity':
            calculate(['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour'])
            break
        }
        // let copy = getDeepCopy(operation)
        setState((prevState) => ({
          ...prevState,
          operation: copy,
          changed: true,
        }))
      }

      function changeOperationStringValue(key: keyof Partial<IPrPrEstimateOperation>, value: string) {
        setState((prevState) => ({
          ...prevState,
          operation: {
            ...state.operation,
            [key]: value,
          },
        }))
      }

      function recalculateDeltaBetweenInitialAndChangedOperation(
        propsOperation: IPrPrEstimateOperation,
        stateOperation: IPrPrEstimateOperation,
        delta: IEstimateRowState['delta'],
      ) {
        let deltaCopy = delta && getDeepCopy(delta)
        for (let key in deltaCopy) {
          deltaCopy[key] =
            (stateOperation[key as keyof IPrPrEstimateTotal] || 0) -
            (propsOperation[key as keyof IPrPrEstimateTotal] || 0)
        }
        setState((prevState) => ({
          ...prevState,
          delta: deltaCopy,
        }))
      }

      function getDiffBetweenOperationDeltas(
        prevDelta: IEstimateRowState['delta'],
        newDelta: IEstimateRowState['delta'],
      ): IEstimateRowState['delta'] {
        let targetDelta = {} as IEstimateRowState['delta']
        for (let key in prevDelta) {
          targetDelta[key as keyof IEstimateRowState['delta']] =
            (newDelta[key as keyof IPrPrEstimateTotal] || 0) - (prevDelta[key as keyof IPrPrEstimateTotal] || 0)
        }
        return targetDelta
      }

      function applyDiffOperationDeltaToEstimateTotal(targetDelta: IEstimateRowState['delta']) {
        let totalCopy = prPrState.recalculatedTotal && getDeepCopy(prPrState.recalculatedTotal)

        let averageValues = ['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour']
        for (let key in totalCopy) {
          if (averageValues.includes(key)) {
            let operationsArrLength = prPrState.totalRecords
            let average = totalCopy[key as keyof IEstimateRowState['delta']] || 0
            let delta = targetDelta[key as keyof IEstimateRowState['delta']] || 0
            let sum = average * operationsArrLength
            let newSum = sum + delta
            totalCopy[key as keyof IEstimateRowState['delta']] = newSum / operationsArrLength
          } else {
            totalCopy[key as keyof IEstimateRowState['delta']] +=
              targetDelta[key as keyof IEstimateRowState['delta']] || 0
          }
        }
        if (totalCopy) {
          totalCopy['rentability'] = totalCopy['genContractTotal']
            ? ((totalCopy['income'] || 0) / totalCopy['genContractTotal']) * 100
            : 0
        }
        setPrPrState((prevState) => ({
          ...prevState,
          recalculatedTotal: totalCopy,
        }))
      }

      return (
        <EETableRowProvider
          onSwitchMode={(prevMode, currentMode) => prevMode == 'edit' && updateOperation()}
          disableSwitchMode={!state.operation.unitType}>
          <EETableRowContext.Consumer>
            {(value) => (
              <>
                <TableCell />
                <TableCell>
                  <Box display="flex" justifyContent="center" gap={1.5}>
                    {state.operation.hasChild ? (
                      <IconButton
                        sx={{
                          width: '20px',
                          height: '20px',
                          padding: 0,
                        }}
                        color="inherit"
                        component="button"
                        onClick={() => {
                          if (state.operation.id) {
                            updateHiddenRows(state.operation.id)()
                          }
                        }}>
                        {state.operation.id && isRowHidden(state.operation.id) ? (
                          <KeyboardArrowUpIcon
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        )}
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{
                          width: '20px',
                          height: '20px',
                          opacity: 0,
                          pointerEvents: 'none',
                        }}
                        color="inherit"
                      />
                    )}
                    <Typography
                      sx={{
                        width: '31px',
                        height: '20px',
                        lineHeight: '20px',
                        fontSize: '10px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        ...getLvlStyles(state.operation.lvl),
                      }}>
                      УР {state.operation.lvl}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <EEString
                    align="left"
                    name="name"
                    mode={'view'}
                    value={state.operation.name || ''}
                    onChange={(e) => changeOperationStringValue('name', e.target.value)}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="humanHoursQuantity"
                    mode={!state.operation.unitType ? 'view' : value.mode}
                    value={state.operation.humanHoursQuantity || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(
                        values.floatValue,
                        'humanHoursQuantity',
                        state.operation as IPrPrEstimateOperation,
                      )
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="machineryHoursQuantity"
                    mode={!state.operation.unitType ? 'view' : value.mode}
                    value={state.operation.machineryHoursQuantity || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(
                        values.floatValue,
                        'machineryHoursQuantity',
                        state.operation as IPrPrEstimateOperation,
                      )
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                {props.projectType === 'GEN' && (
                  <>
                    <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                      <EENumber
                        name="genContractPricePerHour"
                        mode={'view'}
                        value={state.operation.genContractPricePerHour || 0}
                        onNumberChange={(values) =>
                          recalculateLaboriousOperation(
                            values.floatValue,
                            'genContractPricePerHour',
                            state.operation as IPrPrEstimateOperation,
                          )
                        }
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        /* NumberFormatProps={{
                                                decimalScale: 0,
                                            }} */
                      />
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="genContractTotal"
                        mode={'view'}
                        value={state.operation.genContractTotal || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        /*  NumberFormatProps={{
                                                decimalScale: 0,
                                            }} */
                      />
                    </TableCell>
                  </>
                )}
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <EENumber
                    name="subContractPricePerHour"
                    mode={'view'}
                    value={state.operation.subContractPricePerHour || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(
                        values.floatValue,
                        'subContractPricePerHour',
                        state.operation as IPrPrEstimateOperation,
                      )
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="subContractTotal"
                    mode={'view'}
                    value={state.operation.subContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                {props.projectType === 'GEN' && (
                  <>
                    <TableCell sx={{ borderRight: `1px solid ${theme.palette.grey[300]}` }}>
                      <EEString
                        name="subContractContractor"
                        mode={'view'}
                        value={state.operation.subContractContractor || ''}
                        onChange={(e) => changeOperationStringValue('subContractContractor', e.target.value)}
                        viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                        TextFieldProps={{
                          sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="incomePerHour"
                        mode={'view'}
                        value={state.operation.incomePerHour || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        /*  NumberFormatProps={{
                                                decimalScale: 0,
                                            }} */
                      />
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="income"
                        mode={'view'}
                        value={state.operation.income || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        NumberFormatProps={{
                          allowNegative: true,
                          // decimalScale: 0,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <EENumber
                        name="rentability"
                        mode={'view'}
                        value={state.operation.rentability || 0}
                        viewModeStyle={tableCellInput_ViewMode}
                        TextFieldProps={{
                          sx: tableCellInput,
                        }}
                        NumberFormatProps={{
                          allowNegative: true,
                          suffix: ' %',
                        }}
                      />
                    </TableCell>
                  </>
                )}
              </>
            )}
          </EETableRowContext.Consumer>
        </EETableRowProvider>
      )
    }),
    Total: function Total(props: IEstimateTotalProps) {
      const { total } = props

      return (
        <TableFooter sx={{ ...table.footer.sticky, bottom: -2 }}>
          <TableRow>
            <TableCell width="44px" />
            <TableCell width="88px" />
            <TableCell sx={{ paddingX: 2 }}>ВСЕГО</TableCell>
            <TableCell align="center">
              <EENumber
                name="humanHoursQuantity"
                mode={'view'}
                value={total.humanHoursQuantity || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="machineryHoursQuantity"
                mode={'view'}
                value={total.machineryHoursQuantity || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell align="center">
                  <EENumber
                    name="genContractPricePerHour"
                    mode={'view'}
                    value={total.genContractPricePerHour || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*   NumberFormatProps={{
                                        decimalScale: 0,
                                    }} */
                  />
                </TableCell>
                <TableCell align="center">
                  <EENumber
                    name="genContractTotal"
                    mode={'view'}
                    value={total.genContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                        decimalScale: 0,
                                    }} */
                  />
                </TableCell>
              </>
            )}
            <TableCell align="center">
              <EENumber
                name="subContractPricePerHour"
                mode={'view'}
                value={total.subContractPricePerHour || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                        decimalScale: 0,
                                    }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="subContractTotal"
                mode={'view'}
                value={total.subContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
              />
            </TableCell>
            {props.projectType === 'GEN' && (
              <>
                <TableCell />
                <TableCell align="center">
                  <EENumber
                    name="incomePerHour"
                    mode={'view'}
                    value={total.incomePerHour || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
                  />
                </TableCell>
                <TableCell align="center">
                  <EENumber
                    name="income"
                    mode={'view'}
                    value={total.income || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      // decimalScale: 0,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <EENumber
                    name="rentability"
                    mode={'view'}
                    value={total.rentability || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      suffix: ' %',
                    }}
                  />
                </TableCell>
              </>
            )}
          </TableRow>
        </TableFooter>
      )
    },
  },
}

function validateRow(operation: IPrPrEstimateOperation): IValidationErrors {
  let validationErrors = {} as IValidationErrors
  if (operation.name == '') {
    validationErrors.name = {
      isValid: false,
      message: 'Поле "Наименование работ" - обязательное поле',
    }
  } else if (operation.name && operation.name.length > 200) {
    validationErrors.name = {
      isValid: false,
      message: 'Поле "Наименование работ" - не более 200 символов',
    }
  } else {
    validationErrors.name = {
      isValid: true,
      message: '',
    }
  }
  if (operation.unitType == '') {
    validationErrors.unitType = {
      isValid: false,
      message: 'Поле "Ед. изм." - обязательное поле',
    }
  } else if (operation.unitType && operation.unitType.length > 20) {
    validationErrors.unitType = {
      isValid: false,
      message: 'Поле "Ед. изм." - не более 20 символов',
    }
  } else {
    validationErrors.unitType = {
      isValid: true,
      message: '',
    }
  }

  // else {
  //     validationErrors.name = {
  //         isValid: true,
  //         message: ""
  //     }
  // }
  return validationErrors
}
export function getLvlStyles(lvl: number): SxProps {
  switch (lvl) {
    case 0:
      return {
        backgroundColor: '#0044B4',
        color: '#fff',
      }
    case 1:
      return {
        backgroundColor: '#0044B4',
        color: '#fff',
      }
    case 2:
      return {
        backgroundColor: '#1360E0',
        color: '#fff',
      }
    case 3:
      return {
        backgroundColor: '#448AFF',
        color: '#fff',
      }
    case 4:
      return {
        backgroundColor: '#72A7FF',
        color: '#fff',
      }
    case 5:
      return {
        backgroundColor: '#9FC3FF',
        color: '#0044B4',
      }
    case 6:
      return {
        backgroundColor: '#C9DDFF',
        color: '#0044B4',
      }
    default:
      return {
        backgroundColor: '#14B8A6',
        color: '#fff',
      }
  }
}
