import React, { useState } from 'react'

//import add from '../../../../../img/userGuides/addNew.svg';
import edit from '../../../../../img/userGuides/edit.svg'
import _delete from '../../../../../img/userGuides/delete.svg'
import addNew from '../../../../../assets/userGuides/addNew.svg'

import { Edit } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { IUserGuideStep } from '../../../../../components/UserGuide/UserGuide'

export const PAYMENT_TEMPLATES_GUIDE_STEPS = [
  {
    title: 'Добавление шаблона',
    content: (
      <>
        <Typography>
          Чтобы создать новый шаблон, нажмите кнопку "ДОБАВИТЬ ШАБЛОН". Откроется форма, которую необходимо будет
          заполнить.
        </Typography>
        <Typography>После внесения данных, кликнете на кнопку "СОХРАНИТЬ".</Typography>
      </>
    ),
    img: addNew,
  },
  {
    title: 'Редактирование шаблона',
    content: (
      <>
        <Typography>
          Чтобы отредактировать шаблон, нажмите на иконку "РЕДАКТИРОВАТЬ" (
          <Edit sx={{ pb: 0.25, fontSize: '16px', verticalAlign: 'middle' }} />) в конце строки таблицы с шаблонами.
        </Typography>
        <Typography>После изменения данных, кликнете на кнопку "СОХРАНИТЬ".</Typography>
      </>
    ),
    img: edit,
  },
  {
    title: 'Удаление шаблона',
    content: (
      <>
        <Typography>
          Удаление шаблона осуществляется через форму "РЕДАКТИРОВАТЬ ШАБЛОН" (
          <Edit sx={{ pb: 0.25, fontSize: '16px', verticalAlign: 'middle' }} />) в конце строки таблицы с шаблонами.
        </Typography>
        <Typography>Для этого кликнете на кнопку "УДАЛИТЬ ШАБЛОН" внизу формы.</Typography>
      </>
    ),
    img: _delete,
  },
] as IUserGuideStep[]
