import { TFunction } from 'i18next'

import { IFinanceCenter, ProjectType } from '../../global/types/commos-def'
import { ColDef, ColGroupDef } from 'ag-grid-community'
import { CellRenderer } from '../../components/TableAGGrid/CellRenderer/CellRenderer'
import { tableCellInput, tableCellInput_ViewMode } from '../../global/styles/presets'
import { connectNames } from '../../utils/connectNames'

interface IGenerateColumns {
  t: TFunction
  openEditForm(budget: IFinanceCenter): void
  projectType: ProjectType
}

export const budgetListPageGenerateTable = {
  columnDefs: function (props: IGenerateColumns) {
    const columns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      {
        valueGetter: 'node.rowIndex + 1',
        headerName: '№',
        minWidth: 24,
        flex: 1,
        headerClass: 'column-center',
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      {
        field: 'name',
        headerName: 'Наименование ЦФО',
        minWidth: 146,
        flex: 5,
      },
      {
        field: 'type',
        headerName: 'Тип бюджета',
        minWidth: 206,
        flex: 7,
        valueGetter: (params) => {
          return props.t('FINANCE_CENTER_TYPES.' + params.data?.type)
        },
      },
      {
        field: 'createdAt',
        headerName: 'Дата создания',
        minWidth: 112,
        flex: 4,
        headerClass: 'column-center',
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      {
        field: 'budgetYears',
        headerName: 'Период бюджета',
        minWidth: 112,
        flex: 4,
        valueGetter: (params) => {
          return `${params.data.budgetYears[0]} - ${params.data.budgetYears[params.data.budgetYears.length - 1]}`
        },
        headerClass: 'column-center',
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      ...(props.projectType === 'GEN'
        ? [
            {
              field: 'incomePlan',
              headerName: 'Доходы (план)',
              minWidth: 112,
              flex: 4,
              headerClass: 'column-center',
              cellRenderer: CellRenderer.Number,
              cellRendererParams: {
                EENumberProps: {
                  align: 'center',
                  viewModeStyle: { tableCellInput_ViewMode },
                  TextFieldProps: {
                    sx: tableCellInput,
                  },
                },
              },
            },
          ]
        : []),
      {
        field: 'expensesPlan',
        headerName: props.projectType === 'GEN' ? 'Расходы (план)' : 'Затраты',
        minWidth: 112,
        flex: 4,
        headerClass: 'column-center',
        cellRenderer: CellRenderer.Number,
        cellRendererParams: {
          EENumberProps: {
            align: 'center',
            viewModeStyle: { tableCellInput_ViewMode },
            TextFieldProps: {
              sx: tableCellInput,
            },
          },
        },
      },
      {
        field: 'supervisor',
        headerName: 'Ответственный',
        minWidth: 122,
        flex: 4,
        headerClass: 'column-center',
        cellRenderer: (info: any) => {
          const supervisor = info.getValue()
          return supervisor ? (
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>
              {connectNames(supervisor)}
            </span>
          ) : (
            ''
          )
        },
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      {
        valueGetter: 'node',
        minWidth: 52,
        flex: 2,
        sortable: false,
        cellRenderer: CellRenderer.Button.Edit,
        cellRendererParams: {
          onCellClicked: (value: any) => props.openEditForm(value?.data),
          align: 'center',
        },
      },
    ]
    return columns
  },
}
