import styled from "styled-components"
import { Box } from "@mui/material"
import { LegendColors } from "../../global/variables"

interface StyledBoxProps {
  bgColor: LegendColors | "status"
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  display: flex;
  width: fit-content;
  padding: 5px 8px 3px;
  background-color: ${(props) => (props.bgColor === "status" ? "rgba(25, 118, 210, 0.08)" : "")};
  background-color: ${(props) => (props.bgColor !== "status" ? props.theme.palette.legends[props.bgColor] : "")};
  border-radius: 4px;

  & .MuiTypography-root {
    font-weight: ${(props) => (props.bgColor === "status" ? 400 : 500)};
    color: ${(props) =>
      props.bgColor === "status" ? props.theme.palette.primary.main : props.theme.palette.text.light};
  }
`
