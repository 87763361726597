import { FC } from 'react'
import { IMenuProps } from './Menu.types'
import { Menu as MUIMenu, MenuItem, Stack, Typography } from '@mui/material'
import { theme } from 'global/styles/theme'

export const Menu: FC<IMenuProps> = ({ anchorEl, onClose, menuData }) => {
  return (
    <MUIMenu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      {menuData.map((item) => (
        <MenuItem onClick={item.action}>
          <Stack direction={'row'} spacing={1}>
            {item.icon}
            <Typography color={item.labelColor || theme.palette.text.dark} variant="body2">
              {item.label}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </MUIMenu>
  )
}
