import styled from "styled-components"

import { StyledAgGrid, WrapperTableAGGrid } from "../../components/TableAGGrid/StyledAgGrid/StyledAgGrid"

export const WrapperTableAGGridBudgetListPage = styled(WrapperTableAGGrid)`
  height: calc(100vh - 140px);
  padding-left: 20px;
  padding-right: 8px;
`

export const StyledAgGridBudgetBudgetListPage = styled(StyledAgGrid)`
  .ag-row {
    cursor: pointer;
  }
  .ag-row div {
    cursor: pointer;
    user-select: none;
  }
`
