import InfoIcon from '@mui/icons-material/Info'
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material'
import { changeQueryParams, getQueryParam } from 'global/functions'
import { theme } from 'global/styles/theme'
import { ChangeEvent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { ICreateNextRowCheckboxProps } from './CreateNextRowCheckbox.types'

export const CreateNextRowCheckbox: React.FC<ICreateNextRowCheckboxProps> = () => {
  const history = useHistory()
  const isCreateNextRow = getQueryParam(history, 'isCreateNextRow')
  const checked = useMemo(() => isCreateNextRow === 'ACTIVE', [isCreateNextRow])

  const onChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    changeQueryParams(history, {
      isCreateNextRow: checked ? 'ACTIVE' : 'INACTIVE',
    })
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <FormControlLabel
        label={
          <Typography
            variant="body2"
            color={theme.palette.text.dark}
            sx={{
              letterSpacing: 0,
            }}
          >
            Создать следующую строку
          </Typography>
        }
        control={<Checkbox checked={checked} onChange={onChange} size="small" />}
        sx={{
          m: 0,
        }}
      />
      <Tooltip title="При выборе данной опции будет автоматически создана новая позиция бюджета" placement="top-start">
        <InfoIcon sx={{ color: theme.palette.primary.main }} fontSize="small" />
      </Tooltip>
    </Stack>
  )
}
