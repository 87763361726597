import { config } from '../config'

const appHost = document.location.hostname

export let socketChanel: WebSocket
// if (appHost === "localhost") {
// 	socketChanel = new WebSocket("wss://dev.api.finance.pragma.info/single-task");
// } else {
socketChanel = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`)
// }
