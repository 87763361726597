import {
  Box,
  BoxProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  SvgIcon,
  SvgIconProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import React, { MutableRefObject } from 'react'
import { ComponentType } from 'react'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { FormContextProvider } from '../../../../components/Form/Form'
import { OrganizationAPI } from '../../../../global/api/APIMethods/OrganizationAPI'
import { INewUserPreferences } from '../../../../global/api/definitions'
import { usePrevState } from '../../../../global/hooks'
import { theme } from '../../../../global/styles/theme'
import { useDidMountEffect } from '../../../../hooks/useDidMountEffect'
import { StyledTextField } from '../../../ReferencePage/subPages/PaymentTemplatesPage/components/PaymentTemplateDrawerForm/MyFormFields'

interface SettingsItemOptionProps extends BoxProps {
  title?: string
  InputComponent?: ComponentType<TextFieldProps>
  optionName: keyof Partial<INewUserPreferences>
  onOptionChange?: (value: string | number | undefined) => void
  number?: boolean
  numberFormatProps?: NumberFormatProps
}

interface InputProps extends Partial<FilledTextFieldProps> {
  rules?: RegisterOptions
  optionName: keyof Partial<INewUserPreferences>
  // InputComponent?: ComponentType<TextFieldProps>,
  onOptionChange?: (value: string | number | undefined) => void
  TextFieldProps?: TextFieldProps
  number?: boolean
  numberFormatProps?: NumberFormatProps
}

export const SettingCard = {
  Wrapper: function Wrapper(props: BoxProps) {
    const { children } = props

    return (
      <Box
        className="className-SettingCard"
        sx={{
          borderRadius: theme.spacing(2.5),
          p: theme.spacing(3.75),
          background: 'white',
          // mb: 2.5,
          // minHeight: "400px",
          height: '100%',
          //  overflow: 'hidden',
        }}
        {...props}
      >
        {children}
      </Box>
    )
  },
  Title: function Title(props: TypographyProps) {
    const { children } = props

    return (
      <Typography
        variant="h2"
        color="primary"
        sx={{
          mb: 2.5,
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        {...props}
      >
        {children}
      </Typography>
    )
  },
  Content: function Content(props: BoxProps) {
    const { children } = props

    return (
      <Box
        className="className-Content"
        //  width="100%"
        display="flex"
        height={'calc(100% - 29px)'}
        sx={{ overflow: 'hidden' }}
        {...props}
      >
        {children}
      </Box>
    )
  },
  Image: function Image(props: BoxProps & { src: string }) {
    const { src } = props

    return (
      <Box
        className="className-Image"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: 180,
          height: 180,
          background: `no-repeat url("${src}")`,
          marginTop: 'auto',
        }}
        {...props}
      >
        {/* <img width="200px" {...props} /> */}
      </Box>
    )
  },
  OptionList: function OptionList(props: BoxProps) {
    const { children } = props

    return (
      <Box
        {...props}
        display="flex"
        flexDirection="column"
        width="200px"
        height="100%"
        // height="calc(100% - 50px)"
        mr={5}
      >
        {children}
      </Box>
    )
  },
  Option: {
    Wrapper: function Wrapper(props: BoxProps) {
      const { children } = props
      return (
        <Box minWidth={'200px'} {...props} mb={1.5}>
          {children}
        </Box>
      )
    },
    Title: function Title(props: TypographyProps) {
      const { children } = props

      return (
        <Typography variant="tooltip" sx={{ mb: 0.5 }} component="div">
          {children}
        </Typography>
      )
    },
    Input: function Input(props: InputProps) {
      const { optionName, onOptionChange, number, numberFormatProps, TextFieldProps, rules } = props

      const methods = useForm({
        mode: 'all',
      })

      const initialValue = numberFormatProps?.defaultValue

      const [state, setState] = React.useState({
        savedValue: (numberFormatProps?.defaultValue || 0) as number | undefined,
        currentValue: (numberFormatProps?.defaultValue || 0) as number | undefined,
      })

      const inputRef = React.useRef() as MutableRefObject<HTMLTableRowElement>

      function changeOptionValue(optionName: keyof INewUserPreferences, value: string | number | undefined) {
        let body: Partial<INewUserPreferences>
        body = {
          [optionName]: value || 0,
        }
        OrganizationAPI.updateUserPreferences(body).then(() => {
          setState((prevState) => ({
            ...prevState,
            savedValue: value as number | undefined,
          }))
        })
      }

      const defaultTextfieldProps = {
        sx: {
          width: '200px',
          '.MuiSelect-select': {
            ...theme.typography.body1,
            textAlign: 'left !important',
            padding: `${theme.spacing(1.3, 2)} !important`,
          },
          input: {
            ...theme.typography.body1,
            padding: `${theme.spacing(1.3, 2)} !important`,
            textAlign: 'left',
          },
        },
        inputProps: {
          style: {
            textAlign: 'left',
          },
        },
        variant: 'outlined',
        size: 'small',
      } as OutlinedTextFieldProps

      return (
        <FormContextProvider {...methods}>
          {number ? (
            <Controller
              name={optionName}
              render={({ field }) => (
                //@ts-ignore
                <NumberFormat
                  {...field}
                  key={optionName}
                  onBlur={(e) => {
                    state.currentValue != state.savedValue &&
                      !!!methods.formState.errors[optionName] &&
                      changeOptionValue(optionName, state.currentValue || 0)
                  }}
                  //@ts-ignore
                  customInput={StyledTextField}
                  {...defaultTextfieldProps}
                  {...TextFieldProps}
                  {...numberFormatProps}
                  inputProps={{
                    ref: inputRef,
                  }}
                  value={state.currentValue}
                  onValueChange={(values) => {
                    setState((prevState) => ({
                      ...prevState,
                      currentValue: values.floatValue,
                    }))
                  }}
                  error={!!methods.formState.errors[optionName]}
                  focused={!!methods.formState.errors[optionName] || defaultTextfieldProps.focused}
                  helperText={methods.formState.errors && methods.formState.errors[optionName]?.message}
                />
              )}
              control={methods.control}
              defaultValue={numberFormatProps?.defaultValue}
              rules={{
                ...rules,
              }}
            />
          ) : (
            <StyledTextField
              {...TextFieldProps}
              key={optionName}
              onChange={(e) => {
                onOptionChange && onOptionChange(e.target.value)
                changeOptionValue(optionName, e.target.value)
              }}
              {...props}
              {...defaultTextfieldProps}
            />
          )}
        </FormContextProvider>
      )
    },
    Info: function Info(props: BoxProps) {
      const { children } = props

      return <Box>{children}</Box>
    },
  },
}
