import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import { theme } from '../../../../../../global/styles/theme'
import { PercentIcon } from '../../../../../../img/customIcons/PercentIcon'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import NumberFormat from 'react-number-format'
import { StyledFieldForm, StyledOutlinedInput, StyledStack, StyledPercentOfAmount } from './styles'
import { useField, useFormikContext } from 'formik'
import { ICondition } from '../../../../../../api/template/types'

interface ITemplateConditionProps {
  order: number
  index: number
  condition: ICondition
  onDelete: () => void
}
export const TemplateCondition: React.FC<ITemplateConditionProps> = (props) => {
  const { order, onDelete, condition, index } = props

  /**************************************** Switching Offset Number **********************************************/

  const [offsetedNumber, setOffsetedNumber] = useState<number>(0)
  const { offset, percent } = condition
  const [{ value: formValue }, { touched, error }, { setValue, setTouched, setError }] = useField({
    name: `data[${index}]offset`,
  })

  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    setOffsetedNumber(offset)
  }, [offset])

  const month = useMemo(() => {
    switch (true) {
      case offsetedNumber == 0:
        return 'месяцев'
      case offsetedNumber == 1 || offsetedNumber == -1:
        return `месяц`
      case (offsetedNumber > 1 && offsetedNumber <= 4) || (offsetedNumber < -1 && offsetedNumber >= -4):
        return `месяца`
      case offsetedNumber >= 5 || offsetedNumber <= -5:
        return `месяцев`
      default:
        break
    }
  }, [offsetedNumber])

  const handleClickPrev = () => {
    if (offsetedNumber > -12 && offsetedNumber <= 12) {
      setOffsetedNumber(offsetedNumber - 1)
      setValue(offsetedNumber - 1)
    }
  }

  const handleClickNext = () => {
    if (offsetedNumber >= -12 && offsetedNumber < 12) {
      setOffsetedNumber(offsetedNumber + 1)
      setValue(offsetedNumber + 1)
    }
  }

  const [percentSt, setPercent] = useState(0)

  useEffect(() => {
    setPercent(percent)
  }, [percent])

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Box display="flex" justifyContent="space-between">
        <Typography color={theme.palette.primary.main} variant="body2">
          Условие {order}
        </Typography>
        {index >= 1 && (
          <Typography
            color={'error'}
            variant="body2"
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onDelete}
          >
            yдалить условие
          </Typography>
        )}
      </Box>
      <StyledFieldForm
        version="template"
        name={`data[${index}]title`}
        size="small"
        fullWidth
        placeholder="Название условия"
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <PercentIcon style={{ fontSize: 20, color: theme.palette.secondary.main }} />
          <Typography variant="body2" component="span" sx={{ color: theme.palette.secondary.main }}>
            Процент от суммы:
          </Typography>
        </Stack>
        <NumberFormat
          allowNegative={false}
          // format={'###'}
          name={`data[${index}]percent`}
          onValueChange={({ floatValue }) => {
            setFieldValue(`data[${index}]percent`, floatValue)
            setPercent(floatValue!)
          }}
          // defaultValue={percent}
          customInput={StyledPercentOfAmount as ComponentType<TextFieldProps>}
          size="small"
          sx={{
            width: '135px',
            input: {
              textAlign: 'center',
            },
          }}
          placeholder="0"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputProps: { min: 0, max: 100 },
          }}
          helperText={''}
          value={percentSt}
        />
      </Stack>
      <Stack className="offset" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <AccessTimeIcon style={{ fontSize: 20, color: theme.palette.secondary.main }} />
          <Typography variant="body2" component="span" sx={{ color: theme.palette.secondary.main }}>
            Период оплаты:
          </Typography>
        </Stack>
        <StyledStack sx={{ width: '135px' }} className="SwitchingPaymentOffset" direction="row" alignItems="center">
          <StyledOutlinedInput
            type="text"
            value={offsetedNumber}
            name={`data[${index}]offset`} // период оплаты
            startAdornment={
              <InputAdornment position="end" sx={{ margin: 0 }}>
                <IconButton
                  sx={{ padding: '7px 8px', borderRadius: 0 }}
                  aria-label="toggle password visibility"
                  onClick={handleClickPrev}
                  edge="end"
                >
                  <RemoveIcon sx={{ width: '16px', height: '16px' }} />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <>
                <InputAdornment
                  position="end"
                  sx={{
                    margin: 0,
                    '& .MuiTypography-root': {
                      fontSize: '14px',
                      color: theme.palette.text.dark,
                    },
                  }}
                >
                  {month}
                </InputAdornment>
                <InputAdornment position="end" sx={{ margin: 0 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickNext}
                    edge="end"
                    sx={{ padding: '7px 8px', borderRadius: 0 }}
                  >
                    <AddIcon sx={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </InputAdornment>
              </>
            }
          ></StyledOutlinedInput>
        </StyledStack>
      </Stack>
    </Box>
  )
}
