import Add from '@mui/icons-material/Add'
import { Box, CircularProgress } from '@mui/material'
import { Subcategory } from 'pages/BudgetPageNew/components/ControlPanel/SubcategoryTabs/SubcategoryTabs'
import qs from 'qs'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { HelpPlug } from '../../components/HelpPlug/HelpPlug'
import { MyDrawer } from '../../components/MyDrawer/MyDrawer'
import { UserGuide } from '../../components/UserGuide/UserGuide'
import { InterfaceContext } from '../../contexts/context.interface'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { parseObjectToQueryString } from '../../global/api/api'
import { scrollableWrapper } from '../../global/styles/presets'
import { IFinanceCenter, IProjectInfo } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { profileSelector } from '../../store/slices/profile'
import { useTypedSelector } from '../../store/store'
import { budgetListPageGenerateTable } from './BudgetListPage.generateTable'
import { StyledAgGridBudgetBudgetListPage, WrapperTableAGGridBudgetListPage } from './BudgetListPage.styles'
import { BudgetForm } from './components/BudgetForm'
import { BUDGET_USER_GUIDE_STEPS } from './components/BudgetUserGuide'
import { getQueryParam } from '../../global/functions'
import { useGetCompanyQuery } from '../../api/profile'

type SubBill = {
  id: number
  name: string
}

class BudgetForForm {
  name: string | undefined
  type: string | undefined
  billType: any
  budgetYearStart: string
  budgetYearEnd: string
  ecIncomeSubBillID: object | undefined
  ecExpenseSubBillID: object | undefined
  finIncomeSubBillID: object | undefined
  finExpenseSubBillID: object | undefined
  genContractSubBill: object | undefined
  subContractSubBill: object | undefined

  constructor(project: any, budget: any) {
    this.name = budget.name
    this.type = budget.type
    this.billType = project.billType
    this.budgetYearStart = project.dateStart.split('.')[2] ?? ''
    this.budgetYearEnd = project.dateEnd.split('.')[2] ?? ''
    this.ecIncomeSubBillID = this.getAutocompleteValue(budget?.incomeSubBill)
    this.ecExpenseSubBillID = this.getAutocompleteValue(budget?.expenseSubBill)
    this.finIncomeSubBillID = this.getAutocompleteValue(budget?.finIncomeSubBill)
    this.finExpenseSubBillID = this.getAutocompleteValue(budget?.finExpenseSubBill)
    this.genContractSubBill = this.getAutocompleteValue(budget?.genContractSubBill)
    this.subContractSubBill = this.getAutocompleteValue(budget?.subContractSubBill)
  }

  private isSubBill(data: unknown): data is SubBill {
    return (data as SubBill)?.id !== undefined && (data as SubBill)?.name !== undefined
  }
  private getAutocompleteValue(data: Record<string, unknown> | undefined) {
    if (!data) return
    if (!data?.subBill) return
    if (!this.isSubBill(data.subBill)) return

    const { id: value, name: label } = data.subBill

    return {
      ...data,
      value,
      label,
    }
  }
}

type BudgetDialogTrigger = 'closeForm' | 'deleteBudget'

export function BudgetListPage() {
  const [state, setState] = React.useState({
    project: null as IProjectInfo | null,
    isUserGuideOpen: false,
    budgets: null as IFinanceCenter[] | null,
    openForm: false,
    formMode: 'add' as 'add' | 'edit',
    budgetToEdit: null as IFinanceCenter | null,
    refresh: true,
  })

  const { projectID } = useParams() as Params

  const interfaceCTX = useContext(InterfaceContext)

  const { data: company } = useGetCompanyQuery()

  const history = useHistory()
  const { t } = useTranslation('ENUMS')

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  React.useEffect(() => {
    // console.log('useEffect-ProjectsAPI:');
    ProjectsAPI.getProjectById(projectID).then((data) => {
      initiateBreadcrumbs(data)
      setState((prevState) => ({
        ...prevState,
        project: data,
      }))
    })
  }, [])

  React.useEffect(() => {
    // console.log('useEffect-FinanceCenterAPI:');
    setState((prevState) => ({
      ...prevState,
      budgets: null,
    }))
    FinanceCenterAPI.getBudgets(Number(projectID)).then((data) =>
      setState((prevState) => ({
        ...prevState,
        budgets: data?.data,
      })),
    )
  }, [state.refresh])

  // =============================================================================================

  function openForm() {
    setState((v) => ({
      ...v,
      formMode: 'add',
      openForm: true,
      budgetToEdit: null,
    }))
  }

  function openEditForm(budget: IFinanceCenter) {
    setState((v) => ({
      ...v,
      formMode: 'edit',
      openForm: true,
      budgetToEdit: budget,
    }))
  }

  function closeForm() {
    setState((v) => ({
      ...v,
      openForm: false,
      budgetToEdit: null,
      // refresh: !v.refresh,
    }))
  }

  function getSortedBudgetsByName(budgets: IFinanceCenter[]) {
    return [...budgets.sort((prev, next) => prev.name.toLowerCase().localeCompare(next.name.toLowerCase()))]
  }

  function createNewBudget(data: any) {
    console.log(data)
    data.projectID = projectID
    FinanceCenterAPI.createNewBudget(data).then((response) => {
      setState((v) => ({
        ...v,
        budgets: getSortedBudgetsByName([...(v.budgets || []), response]),
      }))
      onDrawerClose(true)
    })
  }

  function updateBudget(budgetID: number, data: any) {
    FinanceCenterAPI.updateBudget(budgetID, data).then((data) => {
      setState((v) => ({
        ...v,
        budgets: getSortedBudgetsByName(
          (v.budgets as IFinanceCenter[]).map((budget) => (budget.id == data.id ? data : budget)),
        ),
      }))
      onDrawerClose(true)
    })
  }

  function deleteBudget(budgetID: number) {
    FinanceCenterAPI.deleteBudget(budgetID).then(() => {
      setState((v) => ({
        ...v,
        budgets: getSortedBudgetsByName((v.budgets as IFinanceCenter[]).filter((budget) => budget.id != budgetID)),
      }))
      onDrawerClose(true)
    })
  }

  function openUserGuide() {
    setState((prevState) => ({
      ...prevState,
      isUserGuideOpen: !prevState.isUserGuideOpen,
    }))
  }

  function redirectToBudgetPage(finCenter: IFinanceCenter) {
    const { id, type, budgetYears, incomeSubBill, expenseSubBill } = finCenter

    if (type == 'PRODUCTION_PROGRAM') {
      history.push({
        // pathname: `/projects/${projectID}/productionProgram/${finCenter.id}/${finCenter.budgetYears[0]}/ESTIMATE`
        pathname: `/projects/${projectID}/productionProgram/${id}`,
        search: qs.stringify({
          financeCenterID: id,
          projectID: projectID,
          viewType: 'ESTIMATE',
          estimateType: 'WORKLOAD',
          type: 'pp',
        }),
      })
    } else {
      history.push(
        `/projects/${projectID}/budgets/budget/${id}` +
          parseObjectToQueryString({
            budgetType: 'bdr',
            financeCenterID: id,
            projectID: projectID,
            analysisType: 'PLAN',
            category:
              company?.userCompanyName === 'Черное море' || state.project?.type === 'INVEST'
                ? expenseSubBill
                  ? 'OPERATING_EXPENSES'
                  : 'WAGE_FUND'
                : incomeSubBill
                ? 'INCOME'
                : expenseSubBill
                ? 'WAGE_FUND'
                : 'TOTAL',
            subcategory: incomeSubBill ? Subcategory.INCOME : Subcategory.WAGE_FUND,
            isCreateNextRow: 'INACTIVE',
            reportType: 'MONTHLY',
            monthStart: 'JANUARY',
            monthEnd: 'DECEMBER',
            yearStart: budgetYears[0],
            yearEnd: budgetYears[0],
          }),
      )
    }
  }

  function initiateBreadcrumbs(project: IProjectInfo) {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'link',
        title: `Проект ${project.name}`,
        path: `/projects/${project.id}/menu`,
      },
      {
        variant: 'title',
        title: `Бюджеты`,
      },
    ])
  }

  const profile = useTypedSelector(profileSelector)

  const { firstName } = profile

  function onDeleteClick() {
    setConfirmDialogTrigger('deleteBudget')
    openConfirm()
  }

  function onDrawerClose(immediately?: boolean) {
    // methods.
    if (immediately || !dirty) {
      closeForm()
      // methods.reset()
    } else {
      setConfirmDialogTrigger('closeForm')
      openConfirm()
    }
  }

  const handleCloseForm = useCallback((confirm: boolean) => {
    if (confirm) closeForm()
  }, [])

  function handleDeleteBudget(confirm: boolean) {
    if (confirm) deleteBudget(state.budgetToEdit!.id)
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<BudgetDialogTrigger>('closeForm')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeForm: {
      handleConfirm: handleCloseForm,
      title: 'Подтвердить закрытие формы?',
      body: 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.',
      confirmButtonText: 'Закрыть',
    },
    deleteBudget: {
      handleConfirm: handleDeleteBudget,
      title: 'Вы действительно хотите удалить бюджет?',
      body: 'Выбранный Вами бюджет удалится безвозвратно.',
      confirmButtonText: 'Удалить',
      confirmButtonColor: 'error',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <Box>
      {state.budgets != null && state.budgets.length == 0 && (
        <>
          <HelpPlug.Wrapper height="calc(100vh - 100px)">
            <HelpPlug.Title>
              Здравствуйте {firstName}, у Вас нет бюджетов по этому проекту,
              <br />
              Вы можете ввести первые данные, нажав на кнопку ниже.
            </HelpPlug.Title>
            <HelpPlug.Btn onClick={openForm} startIcon={<Add />}>
              Добавить бюджет
            </HelpPlug.Btn>
            <HelpPlug.Image />
            <HelpPlug.HelpCenterLink openUserGuide={openUserGuide} />
          </HelpPlug.Wrapper>
          <UserGuide open={state.isUserGuideOpen} onClose={openUserGuide} steps={BUDGET_USER_GUIDE_STEPS} />
        </>
      )}
      {state.budgets == null && <CircularProgress />}
      <ControlPanel.Wrapper justifyContent={'flex-end'}>
        {state.budgets && state.budgets.length > 0 && (
          <ControlPanel.Btn onClick={openForm} startIcon={<Add />}>
            Добавить бюджет
          </ControlPanel.Btn>
        )}
      </ControlPanel.Wrapper>

      {state.budgets && state.budgets.length > 0 && (
        <Box mr={1.25}>
          <WrapperTableAGGridBudgetListPage
            style={{
              ...scrollableWrapper,
            }}>
            <StyledAgGridBudgetBudgetListPage
              rowData={state.budgets}
              onRowClicked={(params) => {
                if ((params.event?.target as HTMLElement).className?.includes('MuiButtonBase-root')) return
                params.data && redirectToBudgetPage(params.data as IFinanceCenter)
              }}
              columnDefs={budgetListPageGenerateTable.columnDefs({
                t: t,
                openEditForm: openEditForm,
                projectType: state.project?.type || 'GEN',
              })}
            />
          </WrapperTableAGGridBudgetListPage>
        </Box>
      )}

      <MyDrawer.Wrapper open={state.openForm} onClose={() => onDrawerClose()}>
        <MyDrawer.Title>{state.formMode == 'add' ? 'Добавить бюджет' : 'Редактировать бюджет'}</MyDrawer.Title>

        <MyDrawer.Content className="className-MyDrawer.Content">
          {state.project && (
            <BudgetForm
              mode={state.formMode}
              project={state.project}
              isCapital={state.project.type === 'INVEST'}
              // @ts-ignore
              values={
                state.formMode == 'edit' && state.budgetToEdit
                  ? new BudgetForForm({ ...state.project, billType: state.budgetToEdit.billType }, state.budgetToEdit)
                  : undefined
              }
              dirty={dirty}
              onSubmit={(data) => {
                state.formMode == 'add' ? createNewBudget(data) : updateBudget(state.budgetToEdit!.id, data)
              }}
              onDeleteClick={onDeleteClick}
              onCancel={onDrawerClose}
              setDirty={setDirty}
            />
          )}
        </MyDrawer.Content>
      </MyDrawer.Wrapper>

      <ConfirmDialog />
    </Box>
  )
}
