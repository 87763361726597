import * as yup from 'yup'

import { format, isDate } from 'date-fns'

export function parseDateString(value: any, originalValue: any) {
  if (!originalValue) return null
  const firstFormattedDate = format(originalValue, 'MM/dd/yy')
  const receivedYear = firstFormattedDate.split('/')[2]
  const finalYear = receivedYear.length === 2 ? `20${receivedYear}` : receivedYear
  const secondFormattedDate = [...firstFormattedDate.split('/').slice(0, 2), finalYear]

  const formattedDate = new Date(secondFormattedDate.join('/'))
  const parsedDate = isDate(formattedDate) ? formattedDate : null

  return parsedDate
}

const today = new Date()

export const validationProject = yup.object({
  shortName: yup.string().trim().required('common:errors.required'),
  fullName: yup.string().trim().required('common:errors.required'),
  status: yup.string().not(['placeholder'], 'common:errors.required').required('common:errors.required'),
  startDate: yup
    .date()
    .transform(parseDateString)
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
  endDate: yup
    .date()
    .transform(parseDateString)
    .min(today, 'Дата не может быть раньше сегодняшней')
    .min(yup.ref('startDate'), 'Дата окончания должна быть позже даты начала')
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
})

/* export const validationPaymentTemplate = yup.object({
  name: yup.string().trim().required('Required'),
}); */
export const validationPaymentTemplate = yup.object({
  title: yup.string().trim().required('Обязательное поле'),
  data: yup.array().of(
    yup.object().shape({
      //percent: yup.number().matches(/^([1-9]|[1-9]\\d|100)$/,`common:${''}${''}`),
      title: yup.string().trim().required('Обязательное поле'),
    }),
  ),
  /* .required()
    .test('sum', 'Сумма процентов больше 100%', (data = []) => {
      const sumPercent = data.reduce((sum, current, index) => {
        return sum + (current.percent || 0);
      }, 0);
      return sumPercent === 100;
    }), */
})
/*
.when('isBig', {
  is: true, // alternatively: (val) => val == true
  then: yup.number().min(5),
  otherwise: yup.number().min(0),
})*/
/*  export const validationPaymentTemplate = yup.object().shape({
  num1: yup.number().positive().required('This field is required.'),
  num2: yup.number().positive().required('This field is required.'),
  num3: yup.number().positive().required('This field is required.')
     .when(['num1', 'num2'], (num1, num2, schema) => {
          return num1 > 0 && num2 > 0 ? schema.max(num1 / num2) : schema.max(0);
      })
}); */

/* .array()
    .of(
      Yup.object().shape( */
