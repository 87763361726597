import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { DATA } from './TypeSelect.constants'
import { styles } from './TypeSelect.styles'
import { changeQueryParams, getQueryParam } from 'global/functions'

export const TypeSelect: React.FC = () => {
  const history = useHistory()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeQueryParams(history, {
      type: e.target.value,
    })
  }

  return (
    <TextField select defaultValue={getQueryParam(history, 'type')} onChange={handleChange} sx={styles}>
      {DATA.map((v) => (
        <MenuItem key={v.value} value={v.value}>
          {v.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
