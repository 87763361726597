import React, { FC } from 'react'
import { StyledCard } from './styles'
import { UserCardHeader, UserCardHeaderData } from './UserCardHeader'
import { UserCardProps } from './UserCard.types'
import { Divider } from '../../../../components/Divider'
import { UserCardContent, UserCardContentData } from './UserCardContent'

export const UserCard: FC<UserCardProps> = ({ data, onClick }) => {
  const { id, firstName, middleName, lastName, avatarPreview, company, email, phone, role } = data

  const headerData: UserCardHeaderData = { firstName, middleName, lastName, avatarPreview, email, phone, role }
  const contentData: UserCardContentData = { company }

  return (
    <StyledCard onClick={() => onClick(id)} textColor="dark">
      <UserCardHeader data={headerData} />
      <Divider />
      <UserCardContent data={contentData} />
    </StyledCard>
  )
}
