import styled from "styled-components"
import { Typography } from "@mui/material"

export const StyledTextFieldTitle = styled(Typography)`
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  text-align: left;
  color: ${(props) => props.theme.palette.secondary.gray};
  text-transform: none;
`
