import { Typography } from '@mui/material'
import add from '../../../img/userGuides/addNew.svg'
import edit from '../../../img/userGuides/edit.svg'
import _delete from '../../../img/userGuides/delete.svg'
import { Edit } from '@mui/icons-material'

export const BUDGET_USER_GUIDE_STEPS = [
  {
    title: 'Добавление бюджета',
    content: (
      <>
        <Typography>
          Чтобы создать новый бюджет, нажмите кнопку “ДОБАВИТЬ БЮДЖЕТ”. Откроется форма, которую необходимо будет
          заполнить.
        </Typography>
        <Typography>После внесения данных, кликнете на кнопку "СОХРАНИТЬ".</Typography>
      </>
    ),
    img: add,
  },
  {
    title: 'Редактирование бюджета',
    content: (
      <>
        <Typography>
          Чтобы отредактировать бюджет, нажмите на иконку "РЕДАКТИРОВАТЬ" (
          <Edit sx={{ pb: 0.25, fontSize: '16px', verticalAlign: 'middle' }} />) в конце строки таблицы с бюджетами.
        </Typography>
        <Typography>После изменения данных, кликнете на кнопку "СОХРАНИТЬ".</Typography>
      </>
    ),
    img: edit,
  },
  {
    title: 'Удаление бюджета',
    content: (
      <>
        <Typography>
          Удаление бюджета осуществляется через форму "РЕДАКТИРОВАТЬ БЮДЖЕТ" (
          <Edit sx={{ pb: 0.25, fontSize: '16px', verticalAlign: 'middle' }} />) в конце строки таблицы с бюджетами.
        </Typography>
        <Typography>Для этого кликнете на кнопку "УДАЛИТЬ БЮДЖЕТ" внизу формы.</Typography>
      </>
    ),
    img: _delete,
  },
]
