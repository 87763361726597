import React, { FC, useEffect, useMemo } from 'react'
import { ISubaccountProps } from './Subaccount.types'
import { Autocomplete, Box, CircularProgress, Select, Stack, SxProps, TextField, Typography } from '@mui/material'
import { theme } from 'global/styles/theme'
import { changeQueryParams, getQueryParam } from 'global/functions'
import { useHistory } from 'react-router-dom'
import { IReportRelationshipItem, TOperationalActivityCategory } from 'global/types/commos-def'
import { ReportAPI } from '../../../../../global/api/APIMethods/ReportAPI'
import { STYLED } from '../../../../../global/styles/presets'
import { SubBillAssign } from '../../../../../components/SubBillAssign/SubBillAssign'
import { Form } from '../../../../../components/Form/Form'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { AutocompleteTextfield } from '../../../../ProductionProgram/components/IntegrationDrawerForm/components/AutocompleteTextfield'
import search from '../../../../../components/Header/Search'

export const Subaccount: FC<ISubaccountProps> = ({ financeCenter }) => {
  const history = useHistory()
  const category = getQueryParam(history, 'category') as TOperationalActivityCategory
  const budgetType = getQueryParam(history, 'budgetType')

  const subaccountValueByCategory = useMemo(() => {
    if (budgetType === 'bdds') {
      switch (category) {
        case 'INCOME':
          return financeCenter?.finIncomeSubBill?.subBill?.name

        case 'OTHER_INCOME':
          return financeCenter?.finIncomeSubBill?.subBill?.name

        default:
          return financeCenter?.finExpenseSubBill?.subBill?.name
      }
    } else {
      switch (category) {
        case 'INCOME':
          return financeCenter?.incomeSubBill?.subBill?.name

        case 'OTHER_INCOME':
          return financeCenter?.incomeSubBill?.subBill?.name

        default:
          return financeCenter?.expenseSubBill?.subBill?.name
      }
    }
  }, [category, financeCenter, budgetType])

  const subaccountTitleByCategory = useMemo(() => {
    switch (category) {
      case 'INCOME':
        return 'Субсчет доходов:'

      case 'OTHER_INCOME':
        return 'Субсчет доходов:'

      default:
        return 'Субсчет расходов:'
    }
  }, [category])

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Typography variant="body2" color={theme.palette.secondary.main}>
        {subaccountTitleByCategory}
      </Typography>
      <Box width="100%">
        <Typography variant="subtitle2" color={theme.palette.text.dark}>
          {/*{!open?*/}
          {subaccountValueByCategory}
          {/*:*/}
          {/*	<Controller*/}
          {/*		control={methods.control}*/}
          {/*		name={"search"}*/}
          {/*		render={({ field, fieldState, formState }) => {*/}
          {/*			// console.log(field.value);*/}
          {/*			return <Autocomplete*/}
          {/*				{...field}*/}
          {/*				value={currentSubBill}*/}
          {/*				inputValue={field.value}*/}
          {/*				sx={{*/}
          {/*					'.MuiAutocomplete-inputRoot':{*/}
          {/*						padding:'0 !important',*/}
          {/*						// width:'fit-content'*/}
          {/*					}*/}
          {/*				}}*/}
          {/*				onChange={(event, value, reason, details) => {*/}
          {/*					// console.log(event, value, reason, details);*/}
          {/*					field.onChange(value?.subBill.name || "");*/}
          {/*					settCurrentSubBill(value ?? undefined);*/}
          {/*					localStorage.setItem("subBillID", value!.subBill!.id!.toString());*/}
          {/*					setOpen(false)*/}
          {/*				}}*/}
          {/*				renderInput={(props) => {*/}
          {/*					// console.log(props);*/}
          {/*					return (*/}
          {/*						<TextField  {...props}*/}
          {/*							onBlur={()=>setOpen(false)}*/}
          {/*												inputProps={{ ...props.inputProps,style: { width: 'fit-content' } }}*/}
          {/*												InputProps={{ ...props.InputProps, endAdornment: null, disableUnderline: true,*/}
          {/*													sx:{fontSize:'inherit',fontFamily:'inherit',fontWeight:'inherit',width: 'fit-content'}*/}
          {/*												}}*/}
          {/*												variant={"standard"}*/}
          {/*												onChange={(e) => field.onChange(e.target.value)} />*/}
          {/*					);*/}
          {/*				}}*/}
          {/*				open={true}*/}
          {/*				renderOption={(props, option) => (*/}
          {/*					<SubBillAssign.AutocompleteOptionWrapper optionProps={props} key={option.subBill.id}>*/}
          {/*						<SubBillAssign.AutocompleteOptionItem width="100%">*/}
          {/*							{option.label}*/}
          {/*						</SubBillAssign.AutocompleteOptionItem>*/}
          {/*					</SubBillAssign.AutocompleteOptionWrapper>*/}
          {/*				)}*/}
          {/*				groupBy={(option) => option.gostBill.number.toString()}*/}
          {/*				PopperComponent={(props) => <SubBillAssign.AutocompletePopper {...props}  sx={{ boxShadow: '0 1.5px 6px 2px gray',minWidth:'300px' }} />}*/}
          {/*				PaperComponent={(props) => {*/}
          {/*					return (*/}
          {/*						<SubBillAssign.AutocompletePaper>*/}
          {/*							<SubBillAssign.AutocompleteOptionHeadings>*/}
          {/*								<SubBillAssign.AutocompleteOptionHeadingItem width="100%">*/}
          {/*									Наименование субсчета*/}
          {/*								</SubBillAssign.AutocompleteOptionHeadingItem>*/}
          {/*							</SubBillAssign.AutocompleteOptionHeadings>*/}
          {/*							{props.children}*/}
          {/*						</SubBillAssign.AutocompletePaper>*/}
          {/*					);*/}
          {/*				}}*/}
          {/*				options={options} />;*/}
          {/*		}}*/}
          {/*	/>*/}
          {/*}*/}
          {/*{inputMode === 'close'?methods.getValues('search'):*/}

          {/*}*/}
        </Typography>
      </Box>
      {/*{subaccountValueByCategory || '—'}*/}
    </Stack>
  )
}
