import { Dialog, Typography, Box } from '@mui/material'
import { StyledBtn } from '../UploadFileDrawer.styles'
import { IConfirmDialog } from './ConfirmDialog.def'
import { DialogPaper } from './ConfirmDialog.styles'

export function ConfirmDialog(props: IConfirmDialog) {
  const { open, title, message, onConfirm, onCancel } = props

  return (
    <Dialog open={open} PaperComponent={DialogPaper}>
      <Typography variant={'subtitle1'} color="primary">
        {title}
      </Typography>
      {message && <Typography variant={'body2'}>{message}</Typography>}
      <Box display={'flex'} gap={1} width={'100%'}>
        <StyledBtn color="success" onClick={onConfirm?.action}>
          {onConfirm?.btnText || 'Да'}
        </StyledBtn>
        <StyledBtn color="primary" onClick={onCancel?.action}>
          {onCancel?.btnText || 'Нет'}
        </StyledBtn>
      </Box>
    </Dialog>
  )
}
