import { Download } from '@mui/icons-material'
import { Typography, TypographyProps } from '@mui/material'

export function DefaultDownloadTemplate(props: TypographyProps) {
  return (
    <Typography
      variant="subtitle2"
      color={'primary'}
      display={'flex'}
      alignItems={'center'}
      gap={1}
      m={'0 auto'}
      textTransform={'uppercase'}
      sx={{
        cursor: 'pointer',
      }}
      {...props}
    >
      <Download fontSize={'small'} />
      Скачать форму файла
    </Typography>
  )
}
