import { Box, CircularProgressProps, Typography } from '@mui/material'
import { theme } from '../../../global/styles/theme'
import { StyledCircularProgress, StyledTimeLabel } from './style'

export interface CircularProgressPercentProps extends CircularProgressProps {
  time: string
}

const CircularProgress = ({ size = 100, strokeWidth = 9 }) => {
  const viewBox = `0 0 ${size} ${size}`
  const radius = (size - strokeWidth) / 2

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="rgba(25, 118, 210, 0.08)"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
    </svg>
  )
}

export function CircularProgressPercent(props: CircularProgressPercentProps & { value: number; time: string }) {
  return (
    <Box
      className="className-CircularProgressPercent"
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box style={{ height: '100px', width: '100px', position: 'relative', margin: '40px 0' }}>
        <Box sx={{ height: '100%' }}>
          <StyledCircularProgress aria-busy variant="determinate" {...props} />
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100px',
              width: '100px',
            }}
          >
            <CircularProgress />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100px',
              width: '100px',
            }}
          >
            <StyledTimeLabel>{props.value}%</StyledTimeLabel>
          </Box>
        </Box>
      </Box>
      {props.value! > 0 ? (
        <Typography variant="subtitle1" sx={{ color: theme.palette.legends.orange, lineHeight: '40px' }}>
          {props.time}
        </Typography>
      ) : (
        <Typography variant="body2" align="center" sx={{ color: theme.palette.secondary.gray }}>
          В настоящее время мы получаем данные, пожалуйста, дождитесь окончания загрузки
        </Typography>
      )}
    </Box>
  )
}
