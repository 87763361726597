import qs from 'qs'
import React from 'react'
import { TableBody } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { TBDRArticleType } from '../../../../../../global/types/commos-def'
import { IMappingArticlesState, IMappingArticleQuerySchema } from '../../MappingArticles'
import { ArticleListItem } from './ArticleListItem'
import { IBDRArticle, IBDDSArticle } from '../../../../../../global/types/commos-def'
import { IMappingPageSubPageProps } from '../../../../MappingPage'
import { MappingArticlesAPI } from '../../../../../../global/api/APIMethods/MappingArticlesAPI'
import { INewArticleForServer } from '../../../../../../global/api/definitions'
import { useDidMountEffect } from '../../../../../../hooks/useDidMountEffect'

interface IArticleListProps extends IMappingPageSubPageProps {
  parentState: IMappingArticlesState
  setParentState: React.Dispatch<React.SetStateAction<IMappingArticlesState>>
}

export function ArticleList(props: IArticleListProps) {
  const { parentState, setParentState, controlsState, setControlsState, setIsRefetch } = props

  const location = useLocation()

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingArticleQuerySchema
  const parentsCounter = parentState.articleData?.parents?.length ?? 0

  useDidMountEffect(() => {
    controlsState?.addArticleBtn?.clicked && addNewArticle()
    setControlsState((prevState) => ({
      ...prevState,
      addArticleBtn: {
        ...prevState.addArticleBtn,
        clicked: false,
      },
    }))
  }, [controlsState?.addArticleBtn?.clicked])

  function addNewArticle() {
    let parentArticleID =
      parentState.articleData?.parents?.[parentState.articleData?.parents.length - 1]?.id || undefined

    let newArticle = {
      name: 'Новая статья',
      parentArticle: parentArticleID,
    } as INewArticleForServer

    if (queryParams.budgetType == 'bdr' && queryParams.BDRArticleType) {
      newArticle.type = queryParams.BDRArticleType as TBDRArticleType
    }

    MappingArticlesAPI.createArticle(queryParams.budgetType, {
      body: newArticle,
      params: { parentsCounter },
    }).then((data) => {
      if (setIsRefetch) {
        setIsRefetch((prev) => !prev)
      } else {
        setParentState((prevState) => ({
          ...prevState,
          articleData: {
            ...prevState.articleData,
            data: [
              ...(prevState.articleData?.data ? prevState.articleData?.data : []),
              ...[
                {
                  id: data.id,
                  name: newArticle.name,
                  type: newArticle.type,
                } as IBDRArticle | IBDDSArticle,
              ],
            ],
          },
        }))
      }
    })
  }

  // const articleOrder: { [key: string]: number } = {
  //   INCOME: 1,
  //   OUTCOME: 2,
  //   OTHER_INCOMES: 3,
  //   OTHER_OUTCOMES: 4,
  // }

  return (
    <>
      <TableBody>
        {parentState.articleData?.data
          // ?.sort((a, b) => {
          //   if (a.name === 'КОСВЕННЫЕ РАСХОДЫ') {
          //     return 1
          //   } else if (a.name === 'ПРЯМЫЕ РАСХОДЫ') {
          //     return -1
          //   } else if (a.name === b.name) {
          //     return 0
          //   } else if (articleOrder[a.type] > articleOrder[b.type]) {
          //     return 1
          //   } else if (articleOrder[a.type] === articleOrder[b.type]) {
          //     return 0
          //   } else {
          //     return -1
          //   }
          // })
          ?.map((article) => {
            return (
              <ArticleListItem
                key={article.id || article.name}
                article={article}
                parentState={parentState}
                setParentState={setParentState}
                refetch={() => {
                  if (setIsRefetch) setIsRefetch((prev) => !prev)
                }}
              />
            )
          })}
      </TableBody>
    </>
  )
}
