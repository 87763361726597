import styled from "styled-components"
import { Stack, Box, TextField, Typography } from "@mui/material"

export const StyledRow = styled(Box)`
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: minmax(185px, 100%) 80px repeat(3, 175px) 60px;
  grid-template-rows: 72px;
  grid-template-areas: ". . . . . . .";
  gap: 0px 0px;

  width: 100%;
  height: 100%;
  max-height: 72px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  // box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  /* &:last-child {
    box-shadow: inset 0px 0px 0px rgba(0, 36, 95, 0.1);
  } */
  color: #2b3648;
`
export const StyledCondition = styled(Stack)`
  height: 100%;
  min-width: 80px;
  width: 100%;
  text-align: center;
`

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const StyledBoxEdit = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
}
`
export const StyledBoxPercent = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 157px;
  height: 24px;

  background: #f6f7fb;
  border-radius: 4px;
`

export const StyledTypography = styled(Typography)`
  color: #5c6e8c;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`
