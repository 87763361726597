import React from 'react'
import { Button, SvgIcon, Typography } from '@mui/material'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { IPage, PAGES, TSubPageGroup } from '../../global/routing'
import { getActivePage } from '../../global/functions'
import { InterfaceContext } from '../../contexts/context.interface'
import { IProjectInfo } from '../../global/types/commos-def'

interface INavBtn {
  page: IPage
  type: 'main' | 'additional'
}

export function NavigationBtn(props: INavBtn) {
  const [state, setState] = React.useState({
    isActive: false,
  })

  const interfaceCTX = React.useContext(InterfaceContext)

  const { page, type } = props

  const location = useLocation()

  const pageGroups = {
    Проекты: {
      parametr: ':projectID',
      value: String(interfaceCTX.activeProjectID),
    },
  }

  React.useEffect(() => {
    let activePage = getActivePage(location.pathname)
    let isActivePageInternal = activePage.pageType == 'internal'
    if (isActivePageInternal) {
      activePage = PAGES.filter((page: IPage) => page.title == activePage.groupBy)[0]
    }
    setState((prevState) => ({ ...prevState, isActive: activePage.title == page.title }))
  }, [location.pathname])

  if (type == 'main') {
    return (
      <Link to={page.path} style={{ width: '100%', textDecoration: 'none', overflow: 'hidden' }}>
        <Button
          sx={{
            width: 'inherit',
            justifyContent: 'flex-start',
            maxHeight: '64px',
            borderRadius: '6px',
            whiteSpace: 'pre-wrap',
            justifyItems: 'flex-start',
            '-webkit-line-clamp': '2',
            transition: 'all 0.3s ease-in-out',
          }}
          variant={state.isActive ? 'contained' : 'text'}
          startIcon={
            <SvgIcon sx={{ /* marginLeft: 1, */ marginRight: 3, fontSize: '24px !important' }}>{page.icon}</SvgIcon>
          }
        >
          <Typography variant="button" sx={{ textTransform: 'none', textAlign: 'left', minWidth: '119px' }}>
            {page.title}
          </Typography>
        </Button>
      </Link>
    )
  }
  if (type == 'additional') {
    let pageGroup = pageGroups[page.groupBy as TSubPageGroup]
    return (
      <Link
        to={page.path.replace(pageGroup.parametr, pageGroup.value)}
        style={{ width: '100%', textDecoration: 'none', overflow: 'hidden' }}
      >
        <Button
          sx={{
            width: 'inherit',
            justifyContent: 'flex-start',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            color: 'var(--ThemeBluePrimaryLight)',
            // '-webkit-line-clamp': '2',
            backgroundColor: state.isActive ? 'var(--LightPrimaryShades8p)' : 'transparent',
            borderRadius: '6px',
            transition: 'all 0.3s ease-in-out',
          }}
          variant={'text'}
          startIcon={<SvgIcon sx={{ marginLeft: 1, marginRight: 3, fontSize: '24px !important' }}>{page.icon}</SvgIcon>}
        >
          {page.title}
        </Button>
      </Link>
    )
  }
  return <></>
}
