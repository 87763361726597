import { PublishedWithChanges } from '@mui/icons-material'
import { Box, Button, Tab, Tabs } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { InterfaceContext } from '../../contexts/context.interface'
import { changeQueryParams, getQueryParam } from '../../global/functions'
import { scrollableWrapper } from '../../global/styles/presets'
import { Taxes } from './subPages/Taxes/Taxes'

export function SettingsPageNew() {
  const history = useHistory()

  const location = useLocation()

  const interfaceCTX = useContext(InterfaceContext)

  const subPages = [
    {
      title: 'Налоги и пошлины',
      queryParamValue: 'Taxes',
      component: <Taxes />,
    },
  ]

  React.useEffect(() => {
    initiateBreadcrumbs()
  }, [])

  React.useEffect(() => {
    if (getQueryParam(history, 'subPage') == null) {
      changeQueryParams(history, {
        subPage: 'Taxes',
      })
    }
  }, [getQueryParam(history, 'subPage'), location.search])

  function initiateBreadcrumbs() {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'title',
        title: `Настройки`,
      },
    ])
  }

  return (
    <Box className="className-SettingsPageNew">
      <ControlPanel.Wrapper>
        <ControlPanel.InnerContainer align="left">
          <Tabs
            value={getQueryParam(history, 'subPage')}
            onChange={(e, value) =>
              changeQueryParams(history, {
                subPage: 'Taxes',
              })
            }
          >
            {subPages.map((subPage) => {
              return <Tab label={subPage.title} value={subPage.queryParamValue} />
            })}
          </Tabs>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>

      <Box mr={1} className="className-subPages">
        {getQueryParam(history, 'subPage') &&
          subPages.filter((subPage) => subPage.queryParamValue == getQueryParam(history, 'subPage'))[0].component}
      </Box>
    </Box>
  )
}
