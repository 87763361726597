import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { IEditableCellProps } from './EditableCell.types'
import { Tooltip } from '@mui/material'
import { CellValue, EditableCellValue, EditableCellWrapper, TooltipErrorStyles } from './EditableCell.styles'
import { theme } from 'global/styles/theme'
import { EditableCellTooltipError } from './components/EditableCellTooltipError'
import { isElementOverflow } from 'utils/isElementOverflow'
import { BudgetCellWrapper } from '../../BudgetAgGrid.styles'

export const EditableCell: FC<IEditableCellProps> = ({
  value = '',
  onChangeValue,
  maxLines = 1,
  isTooltipEnable = true,
  isRequired = false,
  maxLength = Infinity,
  placeholder,
  viewCellProps,
  isNumberCell,
  handleFocus,
  handleBlur,
  forceFocus,
  handleTab,
}) => {
  const [localValue, setLocalValue] = useState<string>(value)
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const cellValueRef = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }, [isEditMode])

  useEffect(() => {
    if (forceFocus) onCellValueClick()
  }, [forceFocus])

  const checkCellValueForOverflow = (e: any) => {
    if (!isTooltipEnable) return

    isElementOverflow(e) && openTooltip()
  }

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  const onCellValueClick = () => {
    if (!onChangeValue || isEditMode) return

    setIsEditMode(true)
    setIsTooltipOpen(false)
    !forceFocus && handleFocus && handleFocus()
  }

  const onBlur = (shouldHandleBlur: boolean = true) => {
    if (error) return

    if (localValue !== value && onChangeValue) onChangeValue(localValue)

    shouldHandleBlur && handleBlur && handleBlur()
    setIsEditMode(false)
  }

  const onLocalChangeValue = (newValue: string) => {
    setLocalValue(newValue)

    if (!newValue && isRequired) {
      setError('Обязательное поле')
      return
    }

    if (newValue && newValue.length > maxLength) {
      setError(`Максимум ${maxLength} символов`)
      return
    }

    setError(null)
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        onBlur()
        break

      case 'Escape':
        setIsEditMode(false)
        onLocalChangeValue(value)
        handleBlur && handleBlur()
        break

      case 'Tab':
        onBlur(false)
        handleTab && handleTab()
        break

      default:
        break
    }
  }

  return (
    <BudgetCellWrapper>
      <Tooltip title={error ? <EditableCellTooltipError text={error} /> : ''} PopperProps={TooltipErrorStyles}>
        <EditableCellWrapper isEditMode={isEditMode} onClick={onCellValueClick} isError={!!error}>
          {!isEditMode && (
            <Tooltip open={isTooltipOpen} title={value} placement="top-end">
              <CellValue
                onMouseEnter={checkCellValueForOverflow}
                onMouseLeave={closeTooltip}
                ref={cellValueRef}
                variant="body2"
                maxLines={maxLines}
                color={!value ? theme.palette.text.disabled : theme.palette.text.dark}
                {...viewCellProps}
              >
                {value || placeholder}
              </CellValue>
            </Tooltip>
          )}

          {isEditMode && (
            <EditableCellValue
              type={isNumberCell ? 'number' : 'text'}
              onKeyUp={handleKeyUp}
              onChange={(e) => onLocalChangeValue(e.target.value)}
              onBlur={() => onBlur()}
              ref={inputRef}
              value={localValue}
            />
          )}
        </EditableCellWrapper>
      </Tooltip>
    </BudgetCellWrapper>
  )
}
