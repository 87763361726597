import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { CardSubtitle, StyledAvatar } from '../styles'
import { StyledCardHeader, StyledTypography } from './styles'
import { UserCardHeaderProps } from './UserCardHeader.types'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import { ColoredTitle } from '../../../../../components/ColoredTitle'
import { UserRoles, userRolesColors, userRolesEnToRu } from '../../../Users.types'
import { formatMaskPhone } from '../../UserManagement/components/PhoneFieldForm'

export const UserCardHeader: React.FC<UserCardHeaderProps> = ({ data }) => {
  const { email, firstName, middleName, avatarPreview, lastName, phone, role: roleEn } = data
  const formattedName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  )
  const role: UserRoles = userRolesEnToRu[roleEn]

  const formattedPhone = () => {
    return phone ? formatMaskPhone(phone) : phone
  }

  return (
    <StyledCardHeader
      avatar={
        <Box>
          <ColoredTitle body={role} color={userRolesColors[role]} />
          <StyledAvatar src={avatarPreview}>
            <PersonSharpIcon sx={{ width: '80px', height: '80px' }} />
          </StyledAvatar>
        </Box>
      }
      title={
        <StyledTypography
          variant="subtitle1"
          style={{
            marginBottom: 1,
            WebkitLineClamp: 2,
          }}
        >
          {formattedName}
        </StyledTypography>
      }
      subheader={
        <Stack spacing={0.5} alignItems="start">
          <CardSubtitle>Контакты:</CardSubtitle>
          <StyledTypography variant="body2">{formattedPhone()}</StyledTypography>
          <StyledTypography variant="body2">{email}</StyledTypography>
        </Stack>
      }
    />
  )
}
