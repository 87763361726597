import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import qs from 'qs'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AccountantAPI } from '../../../../global/api/APIMethods/AccountantAPI'
import { hoverableRow, scrollableWrapper } from '../../../../global/styles/presets'
import { IGostBill } from '../../../../global/types/commos-def'
import { TControlsState } from '../../ReferencePage'
import { SubBillList } from './subPages/SubBillList'

interface IBillsState {
  gostBillList: (IGostBill & { countSubBills: number })[] | null
}

export interface IBillQuerySchema {
  subPage: 'Bills'
  gostBillID: string
}

interface IBillsProps {
  controls: TControlsState
  setControls: React.Dispatch<React.SetStateAction<TControlsState>>
}

export function Bills(props: IBillsProps) {
  const { controls, setControls } = props

  const [state, setState] = React.useState({
    gostBillList: null,
  } as IBillsState)

  const history = useHistory()

  const location = useLocation()

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IBillQuerySchema

  React.useEffect(() => {
    !queryParams.gostBillID && initiateData()
    queryParams.gostBillID &&
      setState((prevState) => ({
        ...prevState,
        gostBillList: null,
      }))
  }, [queryParams.gostBillID])

  function initiateData() {
    AccountantAPI.getGostBills().then(({ data }) => {
      setState((prevState) => ({
        ...prevState,
        gostBillList: data,
      }))
    })
  }

  function goToSubBillList(id: number) {
    history.push({
      pathname: 'reference',
      search: qs.stringify({
        subPage: 'Bills',
        gostBillID: String(id),
      }),
    })
  }

  return (
    <Box mr={1}>
      {!queryParams.gostBillID && (
        <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)', pb: 0 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: 40 }}>
                <TableCell width={'44px'}>№</TableCell>
                <TableCell sx={{ textAlign: 'left' }}>Наименование счета</TableCell>
                <TableCell width={300}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.gostBillList?.map((gostBill) => {
                const { id, number, name, countSubBills } = gostBill
                return (
                  <TableRow onClick={(e) => goToSubBillList(id)} sx={hoverableRow}>
                    <TableCell sx={{ textAlign: 'center' }} align="center">
                      {number}
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>Кол-во субсчетов {countSubBills}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {queryParams.gostBillID && <SubBillList controls={controls} setControls={setControls} />}
    </Box>
  )
}
