import { Dialog, MenuItem, Stack } from '@mui/material'
import Button from 'components/Button'
import { ControlPanel } from 'components/ControlPanel/ControlPanel'
import { BudgetAPI } from 'global/api/APIMethods/BudgetAPI'
import { FinanceCenterAPI } from 'global/api/APIMethods/FinCenterAPI'
import { theme } from 'global/styles/theme'
import { FC, useEffect, useRef, useState } from 'react'
import { currentCompanyIdSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { ConfirmText, SelectContainer, SelectErrorLabel, SelectLabel, Title } from './CopyBudgetDialog.styles'
import { TCopyBudgetDialogProps } from './CopyBudgetDialog.types'

type TableFillingState = 'INIT' | 'FILL' | 'EMPTY'
export const CopyBudgetDialog: FC<TCopyBudgetDialogProps> = ({
  isOpen,
  financeCenterID,
  projectID,
  category,
  currentYear,
  availableYears = [],
  hasDataInTable,
  onClose,
  toggleStateRefresh,
}) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null)
  const [isOnlyStructure, setIsOnlyStructure] = useState(true)
  const [tableFillingState, setTableFillingState] = useState<TableFillingState>('INIT')
  const [stage, setStage] = useState<'COPY' | 'CONFIRM'>('COPY')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isCheckLoading, setIsCheckLoading] = useState(false)
  const companyID = useTypedSelector(currentCompanyIdSelector)

  const abortController = useRef<AbortController | null>(null)

  useEffect(() => {
    if (!isOpen) return
    setSelectedYear(getInitYear())
  }, [isOpen, currentYear, availableYears])

  useEffect(() => {
    if (!selectedYear) return

    setIsCheckLoading(true)
    setTableFillingState('INIT')
    BudgetAPI.checkYearFilling({
      year: selectedYear!,
      organisationId: companyID,
      projectID,
      financeCenterID,
      category,
      analysisType: 'PLAN',
    })
      .then((isFilled) => setTableFillingState(isFilled ? 'FILL' : 'EMPTY'))
      .finally(() => setIsCheckLoading(false))
  }, [selectedYear])

  const getInitYear = () => {
    if (!availableYears || availableYears.length === 0) return null
    const filteredYears = availableYears.filter((year) => year !== currentYear)
    return filteredYears[0]
  }

  const handleSubmit = () => {
    if (stage === 'COPY' && hasDataInTable) {
      setStage('CONFIRM')
      return
    }
    if (!financeCenterID) return

    setIsSubmitLoading(true)
    abortController.current = new AbortController()
    FinanceCenterAPI.copyBudgetByCategory(
      financeCenterID,
      {
        from: selectedYear!,
        to: currentYear,
        category,
        isOnlyStructureToCopy: isOnlyStructure,
      },
      abortController.current,
    )
      .then(() => {
        toggleStateRefresh()
        setIsSubmitLoading(false)
        handleClose()
      })
      .finally(() => setIsSubmitLoading(false))
  }

  const handleYearSelectChange = (year: string) => setSelectedYear(Number(year))

  const handleStructureParameterChange = (value: string) => setIsOnlyStructure(value === 'structure')

  const handleClose = () => {
    if (abortController.current) {
      abortController.current.abort()
    }
    onClose()
    resetState()
  }

  const resetState = () => {
    setSelectedYear(null)
    setStage('COPY')
    setTableFillingState('INIT')
    setIsSubmitLoading(false)
    setIsCheckLoading(false)
  }

  const isSubmitDisabled = [isSubmitLoading, isCheckLoading, !selectedYear, tableFillingState === 'EMPTY'].includes(
    true,
  )

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Stack padding={'30px'} width={'400px'} alignItems={'center'}>
        <Title variant="h3" stage={stage}>
          {
            {
              COPY: 'Копирование бюджета',
              CONFIRM: 'Подтвердите копирование',
            }[stage]
          }
        </Title>

        {
          {
            COPY: (
              <>
                <SelectContainer>
                  <SelectLabel variant="body1" fontSize={13}>
                    Выберите год копирования
                  </SelectLabel>
                  <ControlPanel.Select
                    error={tableFillingState === 'EMPTY'}
                    disabled={isCheckLoading}
                    value={selectedYear}
                    onSelectChange={handleYearSelectChange}
                    SelectProps={{
                      MenuProps: {
                        sx: {
                          maxHeight: '296px !important',
                        },
                      },
                    }}
                    sx={{
                      maxWidth: '120px',
                      width: '100%',
                      '.MuiSelect-select': {
                        p: '5px 8px',
                        textAlign: 'left',
                        fontSize: '14px !important',
                        lineHeight: '22px',
                        background: '#F6F7FB',
                      },
                    }}>
                    {availableYears.map((year) => {
                      return (
                        <MenuItem key={year} value={year} disabled={year === currentYear}>
                          {year}
                        </MenuItem>
                      )
                    })}
                  </ControlPanel.Select>
                  <SelectErrorLabel error={tableFillingState === 'EMPTY'} variant="body1">
                    В выбранном году нет данных
                  </SelectErrorLabel>
                </SelectContainer>

                <SelectContainer mb={1}>
                  <ControlPanel.Select
                    disabled={isCheckLoading}
                    value={isOnlyStructure ? 'structure' : 'all'}
                    onSelectChange={handleStructureParameterChange}
                    SelectProps={{
                      MenuProps: {
                        sx: {
                          maxHeight: '296px !important',
                        },
                      },
                    }}
                    sx={{
                      width: '100%',
                      marginLeft: 0,
                      '.MuiSelect-select': {
                        p: '5px 8px',
                        textAlign: 'left',
                        fontSize: '14px !important',
                        lineHeight: '22px',
                        background: '#F6F7FB',
                      },
                    }}>
                    <MenuItem key="structure" value="structure">
                      Копировать только структуру
                    </MenuItem>
                    <MenuItem key="all" value="all">
                      Копировать структуру и данные
                    </MenuItem>
                  </ControlPanel.Select>
                </SelectContainer>
              </>
            ),
            CONFIRM: (
              <ConfirmText variant="body1">Строки будут удалены и заменены на данные из выбранного года</ConfirmText>
            ),
          }[stage]
        }
        <Stack direction={'row'} spacing={1.25} width={'100%'}>
          <Button
            color="success"
            disabled={isSubmitDisabled}
            width={'100%'}
            onClick={handleSubmit}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: theme.palette.disabled?.main,
                color: theme.palette.text.light,
              },
            }}>
            Создать
          </Button>
          <Button width={'100%'} onClick={handleClose}>
            отменить
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
