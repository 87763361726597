import { AddLink, Link, LinkOff, Search } from '@mui/icons-material'
import {
  AutocompleteRenderInputParams,
  Box,
  BoxProps,
  Button,
  Popper,
  PopperProps,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { getQueryParam } from '../../global/functions'
import { scrollableWrapper } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IFinCenterOperation,
  IPrPrEstimateOperation,
  IPrPrEstimateOperationWithLvl,
  IRelationshipRecord,
  IReportRelationshipItem,
} from '../../global/types/commos-def'
import _delete from '../../img/userGuides/delete.svg'
import edit from '../../img/userGuides/edit.svg'
import indication from '../../img/userGuides/indication.svg'
import search from '../../img/userGuides/search.svg'
import subBillAssign from '../../img/userGuides/subBillAssign.svg'
import { TSubBillAssignOperation } from '../SubBillAssignMode/SubBillAssignMode'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getLvlStyles } from 'pages/ProductionProgram/components/EstimateTable'

interface ISubbillAssignRecordProps {
  record: IRelationshipRecord
}

interface IAutocompleteOption {
  optionProps: React.HTMLAttributes<HTMLLIElement>
  children: React.ReactNode | React.ReactNode[]
}

interface IRecordRowProps extends TableRowProps {
  isSubBillToAssignChosen: boolean
  isSubbillAssigned: boolean
  linkSubBill: () => void
  unLinkSuBBill: () => void
  operation: TSubBillAssignOperation
  activeRelationshipItem: IReportRelationshipItem | null
  operationType: 'finCenter' | 'productionProgram'
  isRowHidden?: (id: number) => boolean
  updateHiddenRows?: (id: number) => () => void
}

interface IHeadingsProps extends TableRowProps {
  operationType: 'finCenter' | 'productionProgram'
}

export const SubBillAssign = {
  TableWrapper: function TableWrapper(props: TableProps) {
    const { children } = props

    return (
      <Box mr={1}>
        <TableContainer {...props} sx={{ ...props.sx, ...scrollableWrapper, height: 'calc(100% - 195px)' }}>
          <Table stickyHeader>{children}</Table>
        </TableContainer>
      </Box>
    )
  },
  Body: function Body(props: TableBodyProps) {
    const { children } = props
    return <TableBody>{children}</TableBody>
  },
  Headings: function Headings(props: IHeadingsProps) {
    const { children, operationType } = props

    return (
      <TableHead>
        <TableRow>
          {operationType === 'finCenter' ? (
            <>
              <TableCell width="44px"></TableCell>
              <TableCell width="44px"></TableCell>
            </>
          ) : (
            <TableCell width="88px"></TableCell>
          )}
          {children}
        </TableRow>
      </TableHead>
    )
  },
  RecordRow: function Row(props: IRecordRowProps) {
    const {
      children,
      isSubbillAssigned,
      isSubBillToAssignChosen,
      linkSubBill,
      unLinkSuBBill,
      operation,
      activeRelationshipItem,
      operationType,
      isRowHidden,
      updateHiddenRows,
    } = props

    const history = useHistory()

    let operationSubBillID

    let contractType = getQueryParam(history, 'contractType') as 'genContract' | 'subContract' | null

    if (contractType) {
      operationSubBillID = (operation as IPrPrEstimateOperation).relationships[contractType]?.subBill.id
    } else {
      operationSubBillID = (operation as IFinCenterOperation).relationships?.subBill.id
    }

    const TooltipPopperProps = {
      disablePortal: true,
      sx: {
        textTransform: 'none',
        width: 'max-content',
        zIndex: 1,
      },
    } as PopperProps

    return (
      <TableRow>
        {operationType === 'finCenter' ? (
          <>
            <TableCell align="center" sx={{ position: 'relative' }}>
              <Button
                size="small"
                sx={{ p: 0, position: 'relative' }}
                disabled={!isSubBillToAssignChosen && !isSubbillAssigned}>
                {isSubBillToAssignChosen && operationSubBillID != activeRelationshipItem!.subBill.id ? (
                  <Tooltip
                    key={'isReadyForAssign'}
                    title={'Привязать субсчет'}
                    placement="right"
                    PopperProps={{ ...TooltipPopperProps }}>
                    <AddLink
                      fontSize="small"
                      color={'primary'}
                      onClick={() => isSubBillToAssignChosen && linkSubBill()}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    key={'alreadyAssigned'}
                    title={'Субсчет привязан'}
                    placement="right"
                    sx={{ cursor: 'default' }}
                    PopperProps={TooltipPopperProps}>
                    <Link fontSize="small" color={isSubbillAssigned ? 'success' : 'disabled'} />
                  </Tooltip>
                )}
              </Button>
            </TableCell>
            <TableCell align="center">
              <Button size="small" disabled={!isSubbillAssigned} sx={{ p: 0 }} color={'error'} onClick={unLinkSuBBill}>
                <Tooltip title={'Отвязать субсчет'} placement="right" PopperProps={TooltipPopperProps}>
                  <LinkOff fontSize="small" color={isSubbillAssigned ? 'error' : 'disabled'} />
                </Tooltip>
              </Button>
            </TableCell>
          </>
        ) : (
          <TableCell align="center" sx={{ position: 'relative' }}>
            <Box display="flex" justifyContent="center" gap={1.5}>
              {(operation as IPrPrEstimateOperationWithLvl).hasChild ? (
                <IconButton
                  sx={{
                    width: '20px',
                    height: '20px',
                    padding: 0,
                  }}
                  color="inherit"
                  component="button"
                  onClick={() => {
                    if (operation.id && updateHiddenRows) {
                      updateHiddenRows(operation.id)()
                    }
                  }}>
                  {operation.id && isRowHidden && isRowHidden(operation.id) ? (
                    <KeyboardArrowUpIcon
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  )}
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    width: '20px',
                    height: '20px',
                    opacity: 0,
                    pointerEvents: 'none',
                  }}
                  color="inherit"
                />
              )}
              <Typography
                sx={{
                  width: '31px',
                  height: '20px',
                  lineHeight: '20px',
                  fontSize: '10px',
                  textAlign: 'center',
                  borderRadius: '4px',
                  ...getLvlStyles((operation as IPrPrEstimateOperationWithLvl).lvl),
                }}>
                УР {(operation as IPrPrEstimateOperationWithLvl).lvl}
              </Typography>
            </Box>
          </TableCell>
        )}
        {children}
      </TableRow>
    )
  },
  AutocompleteTextfield: function AutocompleteTextfield(props: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: props.inTable ? null : <Search color={'disabled'} />,
        }}
        placeholder={
          props.inTable ? undefined : 'Введите здесь название субсчета для поиска или выберите его из списка'
        }
        sx={props.inTable ? { '& input': { fontSize: '14px' } } : {}}
      />
    )
  },
  AutocompleteOptionHeadings: function AutocompleteOptionHeadings(props: BoxProps) {
    const { children } = props

    return (
      <Box
        display="flex"
        gap={2}
        width="100%"
        // justifyContent="space-between"
        alignItems={'center'}
        sx={{
          p: theme.spacing(1.25, 2),
          background: theme.palette.secondary.gray,
        }}
        {...props}>
        {children}
      </Box>
    )
  },
  AutocompleteOptionHeadingItem: function AutocompleteOptionHeadingItem(props: BoxProps) {
    const { children } = props

    return (
      <Box textAlign="left" width="50%" {...props}>
        <Typography variant="subtitle2" color={'white'}>
          {children}
        </Typography>
      </Box>
    )
  },
  AutocompletePaper: function AutocompletePaper(props: React.HTMLAttributes<HTMLElement>) {
    return (
      <Box {...props} sx={{ ...props.style, background: 'white' }}>
        {props.children}
      </Box>
    )
  },
  AutocompleteOptionWrapper: function AutocompleteOptionWrapper(props: IAutocompleteOption) {
    const { optionProps, children } = props

    return (
      <Box component={'li'} {...optionProps} display="flex" gap={2}>
        {children}
      </Box>
    )
  },
  AutocompleteOptionItem: function AutocompleteOptionItem(props: BoxProps) {
    const { children } = props

    return (
      <Box textAlign="left" ml="auto" {...props} sx={{ wordBreak: 'break-word' }}>
        <Typography variant="body2">{children}</Typography>
      </Box>
    )
  },
  AutocompletePopper: function AutocompletePopper(props: PopperProps & { inTable?: boolean }) {
    return (
      <Popper
        {...props}
        placement="bottom-start"
        slotProps={
          props.inTable
            ? {
                root: {
                  style: { minWidth: '500px' },
                },
              }
            : {}
        }
        sx={{
          zIndex: '3 !important',
          overflow: 'hidden',
          '& .MuiAutocomplete-option': {
            paddingLeft: `${theme.spacing(2)} !important`,
          },
          '& .MuiAutocomplete-listbox': {
            pt: 0,
          },
          '& .MuiAutocomplete-groupLabel': {
            top: '0 !important',
            lineHeight: 'inherit !important',
            textAlign: 'left',
            paddingY: 0.5,
            backgroundColor: '#edf4fb',
            color: theme.palette.primary.main,
          },
          ...(props?.sx ?? {}),
        }}>
        {props.children}
      </Popper>
    )
  },
}

export const SUBBILL_ASSIGN_USER_GUIDE_STEPS = [
  {
    title: 'Поиск субсчета',
    content: (
      <>
        <Typography>
          Для поиска субсчета нажмите на область поиска/выбора субсчетов, в появившемся окне выберите необходимый
          субсчет.
        </Typography>
        <TextField
          size="small"
          sx={{
            pointerEvents: 'none',
            input: {
              p: theme.spacing(1, 1),
            },
          }}
          InputProps={{
            startAdornment: <Search color={'disabled'} />,
          }}
          fullWidth
          defaultValue="Например: Прибыль от результатов"
        />
        <Typography>Для более быстрого поиска, начните вводить название субсчета.</Typography>
      </>
    ),
    img: search,
  },
  {
    title: 'Индикация кнопок управления',
    content: (
      <>
        {/* <Typography>Кнопки могут менять свой статус</Typography> */}
        <Box display="flex" alignItems="flex-start" gap={2}>
          <AddLink fontSize="small" color={'primary'} />
          <Typography textAlign="left">Вы выбрали субсчет, и теперь его можно привязать к операции</Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" gap={2}>
          <Link fontSize="small" color={'success'} />
          <Typography textAlign="left">Вы не выбрали субсчет, но у операции уже есть привязанный субсчет</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <LinkOff fontSize="small" color={'error'} />
          <Typography textAlign="left">Отвязать привязанный субсчет</Typography>
        </Box>
      </>
    ),
    img: indication,
  },
  {
    title: 'Привязка операции к субсчету',
    content: (
      <>
        <Typography>
          Чтобы привязать выбраный субсчет к необходимой операции, нажмите на кнопку привязки в строке с нужной
          операцией:
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <AddLink fontSize="small" color={'primary'} />
          <Typography>Привязать субсчет</Typography>
        </Box>
        <Typography>После привязки индикация изменится</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Link fontSize="small" color={'success'} />
          <Typography>Субсчет привязан</Typography>
        </Box>
      </>
    ),
    img: subBillAssign,
  },
  {
    title: 'Изменить привязку субсчета',
    content: (
      <>
        <Typography>
          Привязанный субсчет всегда можно изменить, выбрав нужный субсчет из предложенного списка и нажать на кнопку:
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <AddLink fontSize="small" color={'primary'} />
          <Typography>Привязать субсчет</Typography>
        </Box>
        <Typography>Если у операции уже был субсчет, он перезапишется</Typography>
      </>
    ),
    img: edit,
  },
  {
    title: 'Удалить привязку субсчета',
    content: (
      <>
        {' '}
        <Typography>Чтобы удалить привязанный к операции субсчет, нажмите на кнопку</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <LinkOff fontSize="small" color={'error'} />
          <Typography>Отвязать субсчет</Typography>
        </Box>
      </>
    ),
    img: _delete,
  },
]
