import { Search } from '@mui/icons-material'
import { AutocompleteRenderInputParams, Select } from '@mui/material'
import TextField from '@mui/material/TextField'
import { StyledTextField } from './styles'

export function AutocompleteTextfield(props: AutocompleteRenderInputParams) {
  return (
    <StyledTextField
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: <Search color={'disabled'} />,
      }}
      inputProps={{
        ...props.inputProps,
        maxLength: 80,
      }}
      placeholder="Введите название проекта"
    />
  )
}
