import {
  IProjectInfo,
  IOperationsList,
  IDownloadsList,
  ExcelUploadResponse,
  IProjectInfoV2,
} from '../../types/commos-def'
import { errorHandler, errorUploadHandler, req, successHandler } from '../api'
import { ProjectForServer } from '../constructors'
import { INewProjectBody } from '../definitions'
import { Buffer } from 'buffer'

export class ProjectsAPI {
  static baseURL: string = '/project'

  static async getProjects(): Promise<IProjectInfo[]> {
    return await req
      .get(this.baseURL + '/list')
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getProjectByIdV2(projectID: number | string | undefined): Promise<IProjectInfoV2> {
    return await req
      .get(this.baseURL + `/${Number(projectID)}/get/v2`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        errorHandler(error)
      })
  }

  static async getProjectById(projectID: number | string | undefined): Promise<IProjectInfo> {
    return await req
      .get(this.baseURL + `/${Number(projectID)}/get`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        // console.log('error-getProjectById: ', error);
        // if (error.status === 401)
        // setTimeout(req.get, 2000)
        errorHandler(error)
      })
  }

  static async createNewProject(body: INewProjectBody) {
    return await req
      .post(this.baseURL + '/create', new ProjectForServer(body))
      .then(({ data }) => {
        successHandler('Проект создан')
      })
      .catch((error) => errorHandler(error))
  }

  static async updateProject(projectID: number, body: INewProjectBody) {
    return await req
      .post(this.baseURL + `/${projectID}` + '/update', new ProjectForServer(body))
      .then(({ data }) => {
        successHandler('Проект обновлен')
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteProject(projectID: number) {
    return await req
      .delete(this.baseURL + `/${projectID}` + '/delete')
      .then(({ data }) => {
        successHandler('Проект удален')
      })
      .catch((error) => errorHandler(error))
  }

  static async downloadTemplateFact(name: string, projectID: number, factType: string): Promise<void> {
    return await req
      .get(`/fact/template?projectID=${projectID}&factType=${factType}`)
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute(
          'download',
          `Факт ${
            factType === 'TRANSACTIONS' ? 'Бухгалтерские проводки (ШАБЛОН)' : 'Банковские выписки (ШАБЛОН)'
          } ${name.replace(/[\"]/gm, '')}.xlsx`,
        )
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }
  static async uploadTemplateFact(projectID: string, factType: string, body: FormData): Promise<ExcelUploadResponse> {
    return await req
      .post(`/fact/upload?projectID=${projectID}&factType=${factType}`, body)
      .then(({ data }) => {
        if (data.success) {
          successHandler(`Факт загружен`)
        }
        if (!data.success) {
          errorUploadHandler(data.error, 'upload')
        }
        return data
      })
      .catch((error) => {
        errorUploadHandler('Неприемлемые данные', 'upload')
      })
  }

  static async getTransactionsList(projectID: string): Promise<IOperationsList> {
    return await req
      .get(`/fact/transactions/list?projectID=${projectID}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
  static async getExtractsList(projectID: string): Promise<IOperationsList> {
    return await req
      .get(`/fact/extracts/list?projectID=${projectID}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
  static async getDownloadList(projectID: number, factType: string): Promise<IDownloadsList> {
    return await req
      .get(`/fact/downloads/list?projectID=${projectID}&factType=${factType}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async downloadFile(fileID: number, downloadTo: string): Promise<void> {
    return await req
      .get(`/fact/downloads/get?fileID=${fileID}`)
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', `Факт-${downloadTo}.xlsx`)
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }
  // fact/downloads/delete?fileID=1
  static async deleteFact(fileID: number): Promise<void> {
    return await req
      .get(`/fact/downloads/delete?fileID=${fileID}`)
      .then(({ data }) => {
        successHandler('Факт удален')
      })
      .catch((error) => errorHandler(error))
  }
}
