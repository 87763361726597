import { IProjectInfo } from '../../global/types/commos-def'
import { api } from '../api'

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<IProjectInfo[], void>({
      query: () => '/project/listV2',
      providesTags: ['Projects'],
    }),
    updateProject: build.mutation<IProjectInfo, { projectID: number; body: any }>({
      query: ({ projectID, body }) => ({
        url: `/project/${projectID}/update`,
        method: 'POST',
        body: body,
      }),
      async onQueryStarted({ projectID, body }, { dispatch, queryFulfilled }) {
        const { data: updatedProject } = await queryFulfilled
        dispatch(
          projectsApi.util.updateQueryData('getProjects', undefined, (draft) => {
            const targetIndex = draft.findIndex((project) => project.id === updatedProject.id)
            draft.splice(targetIndex, 1, updatedProject)
          }),
        )
      },
    }),
    createProject: build.mutation<IProjectInfo, { body: any }>({
      query: ({ body }) => ({
        url: `/project/create`,
        method: 'POST',
        body: body,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        const { data: newProject } = await queryFulfilled
        dispatch(
          projectsApi.util.updateQueryData('getProjects', undefined, (draft) => {
            draft.unshift(newProject)
          }),
        )
      },
    }),
    deleteProject: build.mutation<IProjectInfo, { projectID: number }>({
      query: ({ projectID }) => ({
        url: `/project/${projectID}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectID }, { dispatch, queryFulfilled }) {
        await queryFulfilled

        dispatch(
          projectsApi.util.updateQueryData('getProjects', undefined, (draft) => {
            const targetIndex = draft.findIndex((project) => project.id === projectID)
            draft.splice(targetIndex, 1)
          }),
        )
      },
    }),
  }),
})

export const { useGetProjectsQuery, useUpdateProjectMutation, useCreateProjectMutation, useDeleteProjectMutation } =
  projectsApi
