import { FC } from 'react'
import { useParams } from 'react-router'
import { UserManagement } from '../components/UserManagement'
import Progress from '../../../components/Progress'
import { useGetUserByIdQuery } from '../../../api/users'

export const EditUser: FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const {
    data: profile,
    error: profileError,
    isFetching: profileIsFetching,
  } = useGetUserByIdQuery({ userId: Number(userId) }, { refetchOnMountOrArgChange: true })

  return <>{profileIsFetching ? <Progress /> : <UserManagement profile={profile} />}</>
}
