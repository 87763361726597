import { IBdrPeriod } from '../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'

const getCountPrevItems = (periods: IBdrPeriod[]) => {
  const countPrevItems = periods.reduce((acc, currentValue) => acc + currentValue.columnsHeader.length, 0)
  return 0 || countPrevItems
}

export const findMatchingPeriod = (periods: IBdrPeriod[], index: number) => {
  let matchingPeriod: IBdrPeriod | null = null

  for (let periodIndex = 0; periodIndex < periods.length; periodIndex++) {
    let currentPeriod = periods[periodIndex]
    // это количество предыдущих items
    let startItemIndex = 0
    // это количество предыдущих items + period.items - 1
    let endItemIndex = startItemIndex + currentPeriod.columnsHeader.length - 1

    if (periodIndex !== 0) {
      startItemIndex = getCountPrevItems(periods.slice(0, periodIndex))
      endItemIndex = startItemIndex + currentPeriod.columnsHeader.length - 1
    }

    // теперь беру индекс тройной ячейки, если он startItemIndex <= cellIndex <= endItemIndex
    // то беру отображение от текущего периода и выхожу из цикла
    if (startItemIndex <= index && index <= endItemIndex) {
      matchingPeriod = currentPeriod

      break
    }
  }
  // console.log(`cellIndex (${index}), matchingPeriod items (${matchingPeriod?.items})`)

  return matchingPeriod
}
