import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { IPaymentTemplate } from '../../../../../../api/template/types'
import { StyledCondition, StyledBox, StyledRow, StyledBoxPercent, StyledBoxEdit } from './styles'
import { useMemo } from 'react'
import { Condition } from './Condition'
import EditIcon from '@mui/icons-material/Edit'
import { theme } from '../../../../../../global/styles/theme'

interface IPaymentTemplateProps {
  paymentTemplate: IPaymentTemplate
  openEditForm: (id: number) => void
  onEditPaymentTemplateClick: (id: number) => void
}

export const PaymentTemplateRow: React.FC<IPaymentTemplateProps> = ({
  paymentTemplate,
  openEditForm,
  onEditPaymentTemplateClick,
}) => {
  const { title, data, id, isDefault } = paymentTemplate

  return (
    <StyledRow
      className="StyledRow"
      sx={{
        boxShadow: isDefault ? 'inset 0px 1px 0px rgb(226 229 233)' : 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
        background: isDefault ? 'rgb(242 246 251)' : '#fff',
      }}
    >
      <Box sx={{ marginLeft: '12px', minWidth: '185px' }}>
        <Tooltip title={title} placement="bottom-start">
          <Typography
            variant="body2"
            style={{
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
        </Tooltip>
      </Box>
      <StyledCondition>
        <StyledBox sx={{ gridArea: 'percent' }}>Процент </StyledBox>
        <StyledBox sx={{ gridArea: 'offset' }}>Сдвиг</StyledBox>
      </StyledCondition>
      {data.map((element, index) => {
        return <Condition key={index} condition={element}></Condition>
      })}
      {data.length === 1 && (
        <>
          <StyledCondition>
            <StyledBox>
              <Typography variant="subtitle2">-</Typography>
            </StyledBox>
            <StyledBox>-</StyledBox>
          </StyledCondition>
          <StyledCondition>
            <StyledBox>
              <Typography variant="subtitle2">-</Typography>
            </StyledBox>
            <StyledBox>-</StyledBox>
          </StyledCondition>
        </>
      )}
      {data.length === 2 && (
        <StyledCondition>
          <StyledBox>
            <Typography variant="subtitle2">-</Typography>
          </StyledBox>
          <StyledBox>-</StyledBox>
        </StyledCondition>
      )}

      <StyledBoxEdit>
        <IconButton
          onClick={() => {
            openEditForm(id)
            onEditPaymentTemplateClick(id)
          }}
          sx={{ color: theme.palette.disabled?.main }}
        >
          <EditIcon sx={{ width: '20px', height: '20px', color: theme.palette.primary.main }} />
        </IconButton>
      </StyledBoxEdit>
      <Box></Box>
    </StyledRow>
  )
}
