import { format } from 'date-fns'
import {
  IBudgetESNUnit,
  IOperationUnit,
  IOperationUnitScale,
  IReportRelationshipItem,
  ProjectType,
  TFinanceCenterType,
  TMonthName,
  TOperationCategory,
  TOperationType,
  TReportType,
} from '../types/commos-def'
import { MONTHS } from '../variables'
import { IFinCenterBudgetQuery } from './definitions'

export class OperationForCreateOrUpdate {
  id?: number
  new: boolean
  type: 'DEFAULT' | 'TOTAL'
  esnTotal:
    | {
        units: IBudgetESNUnit[]
      }
    | undefined
  category?: TOperationCategory
  name: string
  operationType?: TOperationType
  unitPriceWithoutVAT: number
  units: IOperationUnit[]

  constructor(query: IFinCenterBudgetQuery | null, type?: 'DEFAULT' | 'TOTAL') {
    this.new = true
    this.type = type || 'DEFAULT'
    this.category = query?.category
    this.name = 'Новая строка'
    this.unitPriceWithoutVAT = 0
    this.esnTotal =
      (query?.category as TOperationCategory) == 'WAGE_FUND'
        ? {
            units: [
              ...MONTHS.map((month: TMonthName) => {
                return {
                  nominal: 0,
                  reportType: 'MONTHLY',
                  scale: {
                    month: month,
                    quarter: undefined,
                    reportType: 'MONTHLY' as TReportType,
                    year: undefined,
                  },
                  sum: 0,
                  type: 'MONTH' as IOperationUnit['type'],
                } as IBudgetESNUnit
              }),
            ].concat([
              {
                nominal: 0,
                sum: 0,
                type: 'TOTAL' as IOperationUnit['type'],
              } as IBudgetESNUnit,
            ]),
          }
        : undefined
    this.units = [
      ...MONTHS.map((month: TMonthName) => {
        return {
          nominal: query?.budgetType == 'bdds' ? undefined : 0,
          reportType: 'MONTHLY',
          scale: {
            month: month,
            quarter: undefined,
            reportType: 'MONTHLY' as TReportType,
            year: undefined,
          },
          sum: 0,
          type: 'MONTH' as IOperationUnit['type'],
        } as IOperationUnit
      }),
    ].concat([
      {
        nominal: 0,
        sum: 0,
        plannedAmount: 0,
        actualAmount: 0,
        discrepancy: 0,
        growth: 0,
        type: 'TOTAL' as IOperationUnit['type'],
      },
    ])
  }
}

export class BudgetForServer {
  name: string
  type: TFinanceCenterType
  budgetYear: number
  billType: ProjectType
  genContractSubBillID: number
  subContractSubBillID: number
  ecIncomeSubBillID: number
  ecExpenseSubBillID: number
  finIncomeSubBillID: number
  finExpenseSubBillID: number
  projectID: number
  supervisorID: number

  constructor(data: any) {
    this.name = data.name
    this.type = data.type
    this.budgetYear = data.budgetYear
    this.billType = data.billType
    // this.budgetYear = data.budgetYear && Number(format(data.budgetYear, 'yyyy'))
    this.genContractSubBillID = data.genContractSubBill?.value ?? null
    this.subContractSubBillID = data.subContractSubBill?.value ?? null
    this.ecIncomeSubBillID = data.ecIncomeSubBillID?.value ?? null
    this.ecExpenseSubBillID = data.ecExpenseSubBillID?.value ?? null
    this.finIncomeSubBillID = data.finIncomeSubBillID?.value ?? null
    this.finExpenseSubBillID = data.finExpenseSubBillID?.value ?? null
    this.projectID = data.projectID
    this.supervisorID = data.supervisorID
  }
}

export class ProjectForServer {
  name: string
  address: string
  dateStart: string
  dateEnd: string
  type?: ProjectType

  constructor(data: any) {
    this.name = data.name
    this.address = data.address
    this.dateStart = format(data.dateStart, 'dd.MM.yyyy')
    this.dateEnd = format(data.dateEnd, 'dd.MM.yyyy')
    this.type = data.type
  }
}

export class ZeroSumReportRecordUnit {
  nominal?: number
  reportType?: string
  scale?: IOperationUnitScale | undefined
  sum: number
  type: 'MONTH' | 'QUARTER' | 'YEAR' | 'TOTAL' | 'UNKNOWN'
  plannedAmount: number
  actualAmount: number
  discrepancy: number
  growth: number

  constructor(unitType: IOperationUnit['type']) {
    this.nominal = 0
    this.scale = {
      month: undefined,
      quarter: undefined,
      year: undefined,
    }
    this.sum = 0
    this.type = unitType
    this.plannedAmount = 0
    this.actualAmount = 0
    this.discrepancy = 0
    this.growth = 0
  }
}

export class EstimateOperation {
  genContractPricePerHour: number | null
  genContractTotal: number | null
  genContractUnitPrice: number | null
  humanHoursQuantity: number | null
  id: number | null
  income: number
  incomePerHour: number
  machineryHoursQuantity: number | null
  name: string | null
  quantity: number | null
  relationships: {
    genContract: IReportRelationshipItem | null
    subContract: IReportRelationshipItem | null
  }
  rentability: number
  subContractContractor: string | null
  subContractPricePerHour: number | null
  subContractTotal: number | null
  subContractUnitPrice: number | null
  unitType: string | null
  constructor() {
    this.genContractPricePerHour = 0
    this.genContractTotal = 0
    this.genContractUnitPrice = 0
    this.humanHoursQuantity = 0
    this.id = null
    this.income = 0
    this.incomePerHour = 0
    this.machineryHoursQuantity = 0
    this.name = 'Новая работа'
    this.quantity = 0
    this.relationships = {
      genContract: null,
      subContract: null,
    }
    this.rentability = 0
    this.subContractContractor = ''
    this.subContractPricePerHour = 0
    this.subContractTotal = 0
    this.subContractUnitPrice = 0
    this.unitType = 'ед. изм.'
  }
}

export class EstimateTotal {
  genContractPricePerHour: number | null
  genContractTotal: number | null
  humanHoursQuantity: number | null
  income: number
  incomePerHour: number
  machineryHoursQuantity: number | null
  rentability: number
  subContractPricePerHour: number | null
  subContractTotal: number | null
  constructor() {
    this.genContractPricePerHour = 0
    this.genContractTotal = 0
    this.humanHoursQuantity = 0
    this.income = 0
    this.incomePerHour = 0
    this.machineryHoursQuantity = 0
    this.rentability = 0
    this.subContractPricePerHour = 0
    this.subContractTotal = 0
  }
}
