import React, { useCallback, useEffect, useState } from 'react'
import { FactUploadDrawerProps as FactUploadDrawerProps, QuestItemsTypes } from './FactUploadDrawer.types'
import {
  FactUploadDrawerTopBar,
  FactUploadDrawerWrapper,
  StyledFactUploadDrawer,
  StyledFormControlLabel,
  StyledFormLabel,
} from './styles'
import { Box, FormControl, Radio, RadioGroup, Typography } from '@mui/material'
import { theme } from '../../../../global/styles/theme'
import FactUploadDrawerForm from '../FactUploadDrawerForm'
import { Divider } from '../../../../components/Divider'
import { ExcelParseError, TFactType } from '../../../../global/types/commos-def'
import { ProjectsAPI } from '../../../../global/api/APIMethods/ProjectsAPI'
import { useParams } from 'react-router-dom'
import { Params } from '../../../../global/types/params-def'

const FactUploadDrawer: React.FC<FactUploadDrawerProps> = ({
  open,
  onClose,
  projectName,
  handleRefresh,
  connectWS,
  factType,
  setFactType,
  onErrorsListDrawerOpen,
  setFile,
  file,
  downloadsList,
  setUploaded,
  isUploaded,
  setCloseUpload,
  errorList,
  setErrorList,
  setProgressTime,
  setProgressPercent,
  progressPercent,
  progressTime,
  uploadRecords,
  setUploadRecords,
  // socketChanelState
}) => {
  const { projectID } = useParams() as Params

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useEffect(() => {
    if (isUploaded === 'start') {
      setProgressTime!('')
      setProgressPercent!(0)
    }
    if (isUploaded === 'canceled') {
      setCloseUpload(false)
      setUploaded('start')
    }
    if (isUploaded === 'error') {
      setCloseUpload(false)
      // setUploaded('start')
    }
  }, [open])

  const typeData: QuestItemsTypes[] = [
    {
      value: 'TRANSACTIONS',
      label: 'Бухгалтерские проводки',
    },
    {
      value: 'EXTRACTS',
      label: 'Банковские выписки',
    },
  ]

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as TFactType
    setFactType(value)
    if (isUploaded === 'success') {
      setCloseUpload(false)
      setUploaded('start')
    }
  }

  const onDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      switch (isUploaded) {
        case 'loading':
          onClose(true, false)
          break
        case 'start':
          onClose(dirty, !dirty)
          break
        case 'canceled':
          onClose(false, true)
          break
        case 'success':
          onClose(false, true)
          setUploaded('start')
          break
        case 'error':
          onClose(true, false)
          // setUploaded('start');
          break
        default:
          break
      }
    },
    [isUploaded, dirty],
  )

  return (
    <StyledFactUploadDrawer anchor="right" open={open} onClose={() => onDrawerClose(dirty)}>
      <FactUploadDrawerWrapper justifyContent="flex-start">
        <FactUploadDrawerTopBar>
          <Typography variant="h1" color={theme.palette.primary.main}>
            Загрузка факта
          </Typography>
        </FactUploadDrawerTopBar>
        <Divider />
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
            Вы можете добавить отчет с помощью exсel документа с уже подготовленными данными.
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.legends.orange, mt: 3 }}>
            ВНИМАНИЕ!
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
            При загрузке отчета на дату, для которой существует другой отчет, он будет полностью перезаписан данными из
            нового файла.
          </Typography>
        </Box>
        <FormControl
          sx={{
            padding: '20px',
            border: '1px solid #EDEFF3',
            margin: '0 20px',
            borderRadius: '8px',
          }}
        >
          <StyledFormLabel style={{ color: theme.palette.primary.main, fontWeight: 500, fontSize: '14px' }}>
            Тип данных
          </StyledFormLabel>
          <RadioGroup defaultValue="1" name="radio-buttons-group" value={factType} onChange={handleChangeChecked}>
            {typeData.map((item) => (
              <StyledFormControlLabel
                disabled={!['start', 'success'].includes(isUploaded)}
                key={item.value}
                value={item.value}
                checked={factType === item.value}
                control={<Radio onChange={handleChangeChecked} />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FactUploadDrawerForm
          onClose={onDrawerClose}
          onFormChange={onFormChange}
          projectName={projectName}
          factType={factType}
          handleRefresh={handleRefresh}
          connectWS={connectWS}
          onErrorsListDrawerOpen={onErrorsListDrawerOpen}
          setUploaded={setUploaded}
          isUploaded={isUploaded}
          setFile={setFile}
          file={file}
          downloadsList={downloadsList}
          setProgressTime={setProgressTime}
          progressTime={progressTime}
          uploadRecords={uploadRecords}
          setUploadRecords={setUploadRecords}
          progressPercent={progressPercent}
          setProgressPercent={setProgressPercent}
          setCloseUpload={setCloseUpload}
          setErrorList={setErrorList}
          errorList={errorList!}
          // socketChanelState={socketChanelState}
        />
      </FactUploadDrawerWrapper>
    </StyledFactUploadDrawer>
  )
}

export default FactUploadDrawer
