import { Box, FormControl, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { IPaymentTemplate } from '../../../../../../../api/template/types'
import { StyledRow, StyledTextField } from './styles'
import { ChangeEvent, useMemo, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { theme } from '../../../../../../../global/styles/theme'
import { BDDSSubBillRecord } from '../../../../../../../global/api/definitions'
import { Add, Download, ExpandMore, List } from '@mui/icons-material'
import { paginationSelect } from '../../../../../../../global/styles/presets'
import { useGetPaymentTemplateByIdQuery } from '../../../../../../../api/template'
import { BddsSubBills } from '../../../../../../../global/api/APIMethods/BudgetAPI'

interface IPaymentTermsRowProps {
  subBillRecord: BDDSSubBillRecord
  paymentTemplates: IPaymentTemplate[]
  financeCenterID: number
  changePayTempl: (paymentTemplateID: number, recordID: number) => void
  changeNds: (newCpTax: number, recordID: number) => void
}

export const PaymentTermsRow: React.FC<IPaymentTermsRowProps> = ({
  subBillRecord,
  paymentTemplates,
  financeCenterID,
  changePayTempl,
  changeNds,
}) => {
  const { id: recordID, gostBill, recordName, subBill, cpTax, paymentTemplateID } = subBillRecord

  const handleChangePT = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const paymentTemplateID = Number(event.target.value)
    const body = {
      nds: cpTax,
      paymentTemplateID,
    }
    BddsSubBills.updateBddsSubBillsRecord(financeCenterID, recordID, body).then(() => {
      changePayTempl(paymentTemplateID, recordID)
    })
  }
  const handleChangeNds = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newCpTax = Number(event.target.value)
    const body = {
      nds: newCpTax,
      paymentTemplateID,
    }
    BddsSubBills.updateBddsSubBillsRecord(financeCenterID, recordID, body).then(() => {
      changeNds(newCpTax, recordID)
    })
  }

  return (
    <StyledRow>
      <Box textAlign="center">{recordName}</Box>
      <Box sx={{ minWidth: '185px' }}>
        <Typography
          variant="body2"
          style={{
            textAlign: 'left',
          }}
        >
          {subBill}
        </Typography>
      </Box>
      <Box sx={{ minWidth: '185px' }}>
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
          }}
        >
          {gostBill.number + ' ' + gostBill.name}
        </Typography>
      </Box>
      <Box sx={{ minWidth: '185px' }}>
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
          }}
        ></Typography>
        <StyledTextField
          select
          SelectProps={{
            IconComponent: (props) => <ExpandMore {...props} />,
            MenuProps: {
              PaperProps: {
                style: {
                  maxWidth: '395px',
                },
              },
            },
          }}
          value={paymentTemplateID}
          onChange={(event) => {
            handleChangePT(event)
          }}
          sx={{
            ...paginationSelect,
            '.MuiInputBase-root': {
              textAlign: 'left',
            },
            width: '100%',
            '.MuiSelect-select': {
              padding: '12px',
            },
          }}
        >
          {paymentTemplates?.map((paymentTemplate) => (
            <MenuItem
              style={{ textAlign: 'left' }}
              value={paymentTemplate.id}
              key={paymentTemplate.id}
              selected={paymentTemplate.id === paymentTemplateID}
            >
              {paymentTemplate.title}
            </MenuItem>
          ))}
        </StyledTextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledTextField
          select
          SelectProps={{
            IconComponent: (props) => <ExpandMore {...props} />,
            MenuProps: {
              PaperProps: {
                style: { minWidth: 'fit-content' },
              },
            },
          }}
          defaultValue={cpTax}
          onChange={(event) => {
            handleChangeNds(event)
          }}
          sx={{
            ...paginationSelect,
            width: '100%',
            '.MuiSelect-select': {
              paddingLeft: '30px',
            },
          }}
        >
          <MenuItem value={20}>{20 + '%'}</MenuItem>
          <MenuItem value={10}>{10 + '%'}</MenuItem>
          <MenuItem value={0}> {' ' + 0 + '%'}</MenuItem>
        </StyledTextField>
      </Box>
    </StyledRow>
  )
}
