import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import briefcaseSVG from '../../assets/briefcase.svg'
import calendar2SVG from '../../assets/calendar2.svg'
import coinSVG from '../../assets/coin.svg'
import convertshape2SVG from '../../assets/convertshape2.svg'
import creditCardImageSVG from '../../assets/credit-cards.svg'
import development from '../../assets/development.svg'
import diagramSVG from '../../assets/diagram.svg'
import discountshapeSVG from '../../assets/discountshape.svg'
import moneysSVG from '../../assets/moneys.svg'
import walletcheckSVG from '../../assets/walletcheck.svg'
import walletmoneySVG from '../../assets/walletmoney.svg'
import Progress from '../../components/Progress'
import { EENumber } from '../../components/editableElements/EENumber'
import { InterfaceContext } from '../../contexts/context.interface'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { theme } from '../../global/styles/theme'
import { IProjectInfoV2 } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import {
  StyledAnalysis,
  StyledBDDS,
  StyledBDR,
  StyledBody,
  StyledBudgets,
  StyledCapital,
  StyledDesc,
  StyledExpensesPlan,
  StyledFirst,
  StyledGridContainer,
  StyledHead,
  StyledInfo,
  StyledInfoItem,
  StyledInfoSum,
  StyledInfoType,
  StyledNetProfit,
  StyledProjectMenuGrid,
  StyledRentability,
  StyledSec,
  StyledTimetable,
  StyledTypography,
} from './styles'

export function ProjectMenu() {
  const history = useHistory()

  const { projectID } = useParams() as Params

  const interfaceCTX = useContext(InterfaceContext)

  const [state, setState] = React.useState({
    project: null as IProjectInfoV2 | null,
  })

  React.useEffect(() => {
    ProjectsAPI.getProjectByIdV2(projectID).then((data) => {
      setState((prevState) => ({
        ...prevState,
        project: data,
      }))
      initiateBreadcrumbs(data)
      return data
    })
  }, [])

  const { address, dateStart, dateEnd, cleanCashFlow, operationalFlow, name, income, profit, margin, type } =
    state.project! ?? {}

  const isCapital = React.useMemo(() => {
    return Boolean(type === 'INVEST')
  }, [type])

  function initiateBreadcrumbs(project: IProjectInfoV2) {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'link',
        title: `Проект ${project.name}`,
        path: `/projects/${project.id}/menu`,
      },
      {
        variant: 'title',
        title: `Меню проекта`,
      },
    ])
  }
  return (
    <>
      {state.project ? (
        <StyledGridContainer className="GridContainer">
          <StyledProjectMenuGrid className="ProjectMenuGrid">
            <StyledFirst className="First" borderRadius={5}>
              <StyledHead className="Head">
                <Stack sx={{ maxHeight: 183, minHeight: 173 }}>
                  <img src={creditCardImageSVG} alt="img" width={275} height={'100%'} />
                </Stack>
                <Stack sx={{ justifyContent: 'space-between' }}>
                  <StyledTypography style={{ WebkitLineClamp: 2 }} variant="h2">
                    {'Бюджет строительства '}
                    {name}
                  </StyledTypography>
                  <StyledDesc spacing={1.25}>
                    <Stack spacing={0.75}>
                      <Typography variant="tooltip" sx={{ color: theme.palette.secondary.gray }}>
                        Адрес объекта:
                      </Typography>
                      <StyledTypography variant="body2" sx={{ color: theme.palette.text.dark }}>
                        {address}
                      </StyledTypography>
                    </Stack>
                    <Stack spacing={0.75}>
                      <Typography variant="tooltip" sx={{ color: theme.palette.secondary.gray }}>
                        Срок реализации:
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
                        {dateStart} - {dateEnd}
                      </Typography>
                    </Stack>
                  </StyledDesc>
                </Stack>
              </StyledHead>
              <StyledBody className="Body" borderRadius={5}>
                <StyledNetProfit className="NetProfit" borderRadius={5}>
                  <Stack sx={{ justifyContent: 'space-between', height: ' 100%' }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h3" sx={{ color: theme.palette.primary.main, wordBreak: 'break-word' }}>
                        {isCapital ? 'Бюджет' : 'Доходы'}
                      </Typography>
                      <img src={walletcheckSVG} alt="img" width={30} height={30} />
                    </Stack>
                    <StyledDesc>
                      <Tooltip title={isCapital ? Math.abs(profit) : income}>
                        <StyledTypography variant="h2" sx={{ color: theme.palette.text.dark }}>
                          <EENumber
                            name="Доходы"
                            mode="view"
                            value={isCapital ? Math.abs(profit) : income}
                            NumberFormatProps={{
                              allowNegative: true,
                              suffix: ' р.',
                            }}
                            viewModeStyle={{ whiteSpace: 'break-spaces' }}
                          />
                        </StyledTypography>
                      </Tooltip>
                    </StyledDesc>
                  </Stack>
                </StyledNetProfit>
                {!isCapital && (
                  <React.Fragment>
                    <StyledExpensesPlan className="ExpensesPlan" borderRadius={5}>
                      <Stack sx={{ justifyContent: 'space-between', height: ' 100%' }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="h3" sx={{ color: theme.palette.primary.main, wordBreak: 'break-word' }}>
                            Прибыль до налогообложения
                          </Typography>
                          <img src={walletmoneySVG} alt="img" width={30} height={30} />
                        </Stack>
                        <StyledDesc>
                          <Tooltip title={profit}>
                            <StyledTypography variant="h2" sx={{ color: theme.palette.text.dark }}>
                              <EENumber
                                name="Прибыль до налогообложения"
                                mode="view"
                                value={profit}
                                NumberFormatProps={{
                                  allowNegative: true,
                                  suffix: ' р.',
                                }}
                                viewModeStyle={{ whiteSpace: 'break-spaces' }}
                              />
                            </StyledTypography>
                          </Tooltip>
                        </StyledDesc>
                      </Stack>
                    </StyledExpensesPlan>
                    <StyledRentability className="Rentability" borderRadius={5}>
                      <Stack sx={{ justifyContent: 'space-between', height: ' 100%' }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="h3" sx={{ color: theme.palette.primary.main, wordBreak: 'break-word' }}>
                            Рентабельность
                          </Typography>
                          <img src={discountshapeSVG} alt="img" width={30} height={30} />
                        </Stack>
                        <StyledDesc>
                          <Tooltip title={margin}>
                            <StyledTypography variant="h2" sx={{ color: theme.palette.text.dark }}>
                              <EENumber
                                name="Рентабельность"
                                mode="view"
                                value={margin || 0}
                                NumberFormatProps={{
                                  suffix: ' %',
                                  allowNegative: true,
                                }}
                              />
                            </StyledTypography>
                          </Tooltip>
                        </StyledDesc>
                      </Stack>
                    </StyledRentability>
                  </React.Fragment>
                )}
              </StyledBody>
            </StyledFirst>
            <StyledSec className="Sec">
              <StyledBudgets
                className="Budgets"
                borderRadius={5}
                sx={{ cursor: 'pointer' }}
                onClick={(e) => history.push(`/projects/${projectID}/budgets`)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    Бюджеты по проекту
                  </Typography>
                  <img src={briefcaseSVG} alt="img" width={30} height={30} />
                </Stack>
              </StyledBudgets>
              <StyledBDR
                className="BDR"
                borderRadius={5}
                onClick={(e) => history.push(`/projects/${projectID}/reports/${isCapital ? 'capital' : 'bdr'}`)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    {isCapital ? 'Капвложения' : 'Бюджет Доходов и Расходов'}
                  </Typography>
                  <img src={coinSVG} alt="img" width={30} height={30} />
                </Stack>
                <StyledInfo>
                  {/* <StyledInfoItem>
                                        <StyledInfoType>Чистая прибыль (ПЛАН)</StyledInfoType>
                                        <StyledInfoSum>
                                            <EENumber
                                                name="Планируемые расходы"
                                                mode="view"
                                                value={profit || 0}
                                                NumberFormatProps={{
                                                    allowNegative: true,
                                                    suffix: ' р.',
                                                }}
                                                viewModeStyle={{ whiteSpace: 'break-spaces' }}
                                            />
                                        </StyledInfoSum>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <StyledInfoType>Маржа по ЧП (ПЛАН)</StyledInfoType>
                                        <StyledInfoSum>
                                            <EENumber
                                                name="Планируемые расходы"
                                                mode="view"
                                                value={margin || 0}
                                                NumberFormatProps={{
                                                    allowNegative: true,
                                                    suffix: ' %',
                                                }}
                                                viewModeStyle={{ whiteSpace: 'break-spaces' }}
                                            />
                                        </StyledInfoSum>
                                    </StyledInfoItem> */}
                </StyledInfo>
              </StyledBDR>
              <StyledBDDS
                className="BDDS"
                borderRadius={5}
                onClick={(e) => history.push(`/projects/${projectID}/reports/bdds`)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    Бюджет Движения Денежных Средств
                  </Typography>
                  <img src={convertshape2SVG} alt="img" width={30} height={30} />
                </Stack>
                <StyledInfo>
                  <StyledInfoItem>
                    <StyledInfoType>Операционный денежный поток</StyledInfoType>
                    <StyledInfoSum>
                      <EENumber
                        name="Операционный денежный поток"
                        mode="view"
                        value={operationalFlow || 0}
                        NumberFormatProps={{
                          allowNegative: true,
                          suffix: ' р.',
                        }}
                        viewModeStyle={{ whiteSpace: 'break-spaces' }}
                      />
                    </StyledInfoSum>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledInfoType>Чистый денежный поток</StyledInfoType>
                    <StyledInfoSum>
                      <EENumber
                        name="Чистый денежный поток"
                        mode="view"
                        value={cleanCashFlow || 0}
                        NumberFormatProps={{
                          allowNegative: true,
                          suffix: ' р.',
                        }}
                        viewModeStyle={{ whiteSpace: 'break-spaces' }}
                      />
                    </StyledInfoSum>
                  </StyledInfoItem>
                </StyledInfo>
              </StyledBDDS>
              <StyledTimetable className="Timetable" borderRadius={5} sx={{ position: 'relative' }}>
                <Stack direction="row" justifyContent="space-between" sx={{ opacity: 0.1 }}>
                  <StyledTypography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    {'Штатное расписание'}
                  </StyledTypography>
                  <img src={calendar2SVG} alt="img" width={30} height={30} />
                </Stack>
                <Box sx={{ position: 'absolute', bottom: 20, left: 20 }}>
                  <img src={development} alt="img" />
                </Box>
              </StyledTimetable>
              <StyledAnalysis className="Analysis" borderRadius={5} sx={{ position: 'relative' }}>
                <Stack direction="row" justifyContent="space-between" sx={{ opacity: 0.1 }}>
                  <StyledTypography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    {'Анализ'}
                  </StyledTypography>
                  <img src={diagramSVG} alt="img" width={30} height={30} />
                </Stack>
                <Box sx={{ position: 'absolute', bottom: 20, left: 20 }}>
                  <img src={development} alt="img" />
                </Box>
              </StyledAnalysis>
              <StyledCapital className="Capital" borderRadius={5} sx={{ position: 'relative' }}>
                <Stack direction="row" justifyContent="space-between" sx={{ opacity: 0.1 }}>
                  <StyledTypography variant="h3" sx={{ color: theme.palette.primary.main }}>
                    {'Капитал'}
                  </StyledTypography>
                  <img src={moneysSVG} alt="img" width={30} height={30} />
                </Stack>
                <Box sx={{ position: 'absolute', bottom: 20, left: 20 }}>
                  <img src={development} alt="img" />
                </Box>
              </StyledCapital>
            </StyledSec>
            {/* <StyledThird className="Third" borderRadius={5} sx={{ position: 'relative' }}>
                            <ChartIncomeAndOutcome /> */}
            {/*<img*/}
            {/*	src={Frame}*/}
            {/*	alt="img"*/}
            {/*	style={{ width: "100%", height: "100%", maxHeight: "423px" }}*/}
            {/*/>*/}
            {/* <Box sx={{ position: 'absolute', top: '50%', left: '50%', ml: '-102px', mt: '-104px' }}>
                                <img src={Frame2} alt="img" />
                            </Box> */}
            {/*  <StyledIncomeAndExpenses className="IncomeAndExpenses" borderRadius={5}>
                            Доходы и расходы по проекту
                            в млн. рублей
                        </StyledIncomeAndExpenses>
                        <StyledIncome className="Income" borderRadius={5}></StyledIncome>
                        <StyledExpenses className="Expenses" borderRadius={5}></StyledExpenses>
                        <StyledDiagram className="Diagram" borderRadius={5}></StyledDiagram> */}
            {/* </StyledThird>
                        <StyledFourth
                            className="Fourth"
                            borderRadius={5}
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => history.push(`/projects/${projectID}/fact`)}
                        >
                            <TableRecentIncomeAndExpenses />
                        </StyledFourth> */}
          </StyledProjectMenuGrid>
        </StyledGridContainer>
      ) : (
        <Progress />
      )}
    </>
  )
}

const MemoizedChildComponent = React.memo(ProjectMenu)

const MainComponent = () => {
  const [selectedId, setSelectedId] = React.useState('1')

  return (
    <div>
      <MemoizedChildComponent />
    </div>
  )
}
