import styled from "styled-components"
import { CardHeader, Typography } from "@mui/material"

export const StyledTypography = styled(Typography)`
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
`
