export const DATA = [
  { value: 'bdr', label: 'Экономическая деятельность' },
  {
    value: 'bdds',
    label: 'Финансовая деятельность',
  },
  {
    value: 'ptt',
    label: 'Условия оплаты и налоги',
  },
]
