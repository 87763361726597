import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { PaymentTemplateDrawerFormWrapper, StyledFieldForm } from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { Form, FormikProvider, Formik } from 'formik'
import { useForm } from '../../../../../../hooks/useForm'
import { theme } from '../../../../../../global/styles/theme'
import Button from '../../../../../../components/Button'
import { validationPaymentTemplate, validationProject } from './validation'
import { PaymentTemplateFormProps, PaymentTemplateFormData } from './PaymentTemplateDrawerForm.types'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { Add } from '@mui/icons-material'
import {
  CreatePaymentTemplateRequest,
  CreatePaymentTemplateResponse,
  ICondition,
  IPaymentTemplate,
} from '../../../../../../api/template/types'
import { TemplateCondition } from './TemplateCondition'
import { useCreatePaymentTemplateMutation, useEditPaymentTemplateMutation } from '../../../../../../api/template'
import { AlertDialog } from '../../../../../../components/AlertCustom/AlertDialog'

const PaymentTemplateDrawerForm: React.FC<PaymentTemplateFormProps> = ({
  onClose,
  onFormChange,
  paymentTemplate,
  isDeleteClicked,
  onDeleteCancel,
  onDeleteSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { id, isDefault } = paymentTemplate || {}

  const [state, setState] = React.useState({
    title: '',
    data: [
      {
        title: '',
        offset: 0,
        percent: 0,
        position: 0,
      },
    ],
  } as IPaymentTemplate)

  const paymentTemplateData = paymentTemplate ? paymentTemplate : state

  useEffect(() => {
    if (paymentTemplate) {
      setState((prev) => ({
        ...prev,
        ...{
          title: paymentTemplate.title,
          data: [
            ...paymentTemplate.data.map((condition) => {
              const { title, offset, percent, position } = condition
              return {
                title: title,
                offset: offset,
                percent: percent,
                position: position,
              }
            }),
          ],
        },
      }))
    }
  }, [paymentTemplate])

  const initialValues: PaymentTemplateFormData = useMemo(
    () => ({
      title: paymentTemplateData!.title || '',
      //   percent: '',
      data: [
        ...paymentTemplateData!.data.map((condition, index) => {
          const { title, offset, percent, position } = condition
          return {
            title: title || '',
            offset: offset || 0,
            percent: percent || 0,
            position: position || 0,
          }
        }),
      ],
    }),
    [paymentTemplate],
  )

  const [createPaymentTemplate, createPaymentTemplateResponse] = useCreatePaymentTemplateMutation()

  const [editPaymentTemplate, editPaymentTemplateResponse] = useEditPaymentTemplateMutation()

  const onSubmit = useCallback(
    (values: PaymentTemplateFormData) => {
      const dataForRequest: CreatePaymentTemplateRequest = {
        title: values.title,
        data: [
          ...values.data.map((item, index) => {
            return { ...item, position: index + 1 }
          }),
        ],
      }
      if (id) {
        editPaymentTemplate({
          id,
          ...dataForRequest,
        })
      } else {
        createPaymentTemplate(dataForRequest)
      }
    },
    [], // paymentTemplate
  )

  const handleValidateForm = (values: PaymentTemplateFormData) => {
    let errors = {}

    const sumPercent = values.data.reduce((sum, current, index) => {
      return sum + Number(current.percent ?? 0)
    }, 0)

    if (sumPercent > 100) {
      errors = {
        percent: 'Сумма процентов больше 100%',
        data: [{ percent: 'Неверная сумма' }, { percent: 'Неверная сумма' }, { percent: 'Неверная сумма' }],
      }
    }

    if (sumPercent < 100) {
      errors = {
        percent: 'Сумма процентов меньше 100%',
        data: [{ percent: 'Неверная сумма' }, { percent: 'Неверная сумма' }, { percent: 'Неверная сумма' }],
      }
    }
    return errors
  }

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationPaymentTemplate,
    validate: handleValidateForm,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const { values, setValues, setFieldValue, dirty, errors, setErrors, isValid } = formik

  const sumPercent = values.data.reduce((sum, current, index) => {
    return sum + Number(current.percent ?? 0)
  }, 0)

  const messageValidation = useMemo(() => {
    switch (true) {
      case sumPercent === 100:
        return {
          color: '#2E7D32',
          message: 'Сумма процентов равна 100%',
        }
      case sumPercent === 0:
        return {
          color: '#2E7D32',
          message: 'Сумма процентов меньше 100%',
        }
      case sumPercent > 100:
        return {
          color: '#D32F2F',
          message: 'Сумма процентов больше 100%',
        }
      case sumPercent < 100:
        return {
          color: '#ED6C02',
          message: 'Сумма процентов меньше 100%',
        }
      default:
        break
    }
  }, [sumPercent])

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(createPaymentTemplateResponse, (success: CreatePaymentTemplateResponse) => {
    if (!success) {
      enqueueSnackbar(success, { variant: 'error' })
    }
    onClose(false, true)
  })

  useMutationHandlers(editPaymentTemplateResponse, (success: CreatePaymentTemplateResponse) => {
    /*  if (success) {
      enqueueSnackbar(success, { variant: 'error' });
    } */
    onClose(false, true)
  })

  const MAX_QTY_OF_CONDITIONS = 3

  function addNewCondition() {
    if (state.data.length < MAX_QTY_OF_CONDITIONS || !state.data) {
      let newCondition = {
        title: '',
        offset: 0,
        percent: 0,
        position: 0,
      }
      setState((prevState) => {
        return {
          ...prevState,
          // data: [...(prevState.data || []), newCondition],
          data: [
            ...(values.data.map((condition, index) => {
              return { ...condition, position: index + 1 }
            }) || []),
            newCondition,
          ],
        }
      })
      setValues((values) => {
        let template = {
          data: [...values.data, newCondition],
        }
        return { ...values, ...template }
      })
    }
  }

  function deleteCondition(delIndex: number) {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.filter((condition, index) => index != delIndex),
    }))
    setValues((values) => {
      let template = {
        data: [...values.data.filter((condition, index) => index != delIndex)],
      }
      return { ...values, ...template }
    })
  }

  /**************************************************************************************/

  return (
    <PaymentTemplateDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} sx={{ height: '100%' }} justifyContent="space-between">
          <Stack spacing={2.5}>
            <StyledFieldForm
              disabled={isDefault}
              version="template"
              name="title"
              size="small"
              fullWidth
              placeholder="Наименование шаблона"
            />
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              <Button
                startIcon={
                  <Add
                    sx={{
                      color: state.data?.length === MAX_QTY_OF_CONDITIONS ? 'disabled' : theme.palette.primary.main,
                    }}
                  />
                }
                size="small"
                sx={{
                  color: theme.palette.primary.main,
                  margin: '0 auto',
                }}
                variant="text"
                onClick={addNewCondition}
                disabled={state.data?.length == MAX_QTY_OF_CONDITIONS}
              >
                Добавить условие
              </Button>
            </Box>
            <Box display="flex" flexDirection="column" gap={1.25} className="TemplateCondition">
              {state.data?.map((condition, index) => {
                return (
                  <TemplateCondition
                    key={index}
                    order={index + 1}
                    index={index}
                    condition={condition}
                    onDelete={() => deleteCondition(index)}
                  />
                )
              })}
            </Box>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Typography color={messageValidation?.color} variant="body2">
                {messageValidation?.message}
              </Typography>
            </Stack>
          </Stack>

          <Box>
            {isDeleteClicked! && (
              <>
                <Box sx={{ position: 'relative' }}>
                  <Box sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                    <AlertDialog
                      message={'Вы действительно хотите удалить шаблон оплаты?  Это действие нельзя отменить.'}
                      title={'Удалить'}
                      open={true}
                      severity={'warning'}
                      onYes={onDeleteSubmit!}
                      onNo={onDeleteCancel!}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Stack direction="row" spacing={2}>
              {!id ? (
                <Button type="submit" color="success" size="medium" fullWidth>
                  Сохранить
                </Button>
              ) : (
                <Button disabled={!dirty} type="submit" color="success" size="medium" fullWidth>
                  Сохранить
                </Button>
              )}

              <Button size="medium" fullWidth onClick={() => onClose(dirty)}>
                Отменить
              </Button>
            </Stack>
          </Box>
        </Stack>
      </FormikProvider>
    </PaymentTemplateDrawerFormWrapper>
  )
}

export default PaymentTemplateDrawerForm
