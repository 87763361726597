import { Box, BoxProps } from '@mui/system'

export function ButtonGroupCustom(props: BoxProps) {
  const { gap, children, sx } = props
  return (
    <Box
      display="flex"
      gap={gap || '10px'}
      {...props}
      sx={{
        '*': {
          flex: '1 1 0',
        },
        ...props.sx,
      }}
    >
      {children}
    </Box>
  )
}
