import { SvgIcon } from '@mui/material'

export function PragmaLogo(props: any) {
  return (
    <SvgIcon {...props} width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.4 3.71533H0.606506V19.5411H29.4V3.71533Z" fill="url(#paint0_linear_180_34673)" />
      <path
        opacity="0.77"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.606506 3.71191H25.4139V20.7878L0.606506 19.9938V3.71191Z"
        fill="url(#paint1_linear_180_34673)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3878 3.49713H29.3951L29.3906 4.71928H29.3872C29.3771 4.93401 29.0407 5.14817 28.3747 5.31151L17.4566 7.98825C16.1021 8.32006 13.8857 8.32006 12.5306 7.98825L1.61252 5.31151C0.948179 5.14817 0.611226 4.93401 0.599976 4.71928V4.70786V3.52854V3.49655H0.603351C0.637665 3.28925 0.971805 3.08365 1.6159 2.92546L12.534 0.249285C13.8891 -0.0830949 16.1054 -0.0830949 17.46 0.249285L28.3781 2.92603C29.0222 3.08365 29.3563 3.28925 29.3878 3.49713ZM29.3878 19.2623H29.3951L29.3906 20.4816H29.3872C29.3771 20.6969 29.0407 20.9111 28.3747 21.0744L17.4566 23.7512C16.1021 24.083 13.8857 24.083 12.5306 23.7512L1.61252 21.0744C0.948179 20.9111 0.611226 20.6969 0.599976 20.4816V20.4719V19.2937V19.2617H0.603351C0.637665 19.0544 0.971805 18.8483 1.6159 18.6906L12.534 16.0139C13.8891 15.6821 16.1054 15.6821 17.46 16.0139L28.3781 18.6912C29.0222 18.8483 29.3563 19.0544 29.3878 19.2623Z"
        fill="#2B3648"
      />
      <defs>
        <linearGradient
          id="paint0_linear_180_34673"
          x1="15.0032"
          y1="6.53599"
          x2="15.0032"
          y2="22.1881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5E99" />
          <stop offset="1" stopColor="#FFEB69" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_180_34673"
          x1="0.580652"
          y1="15.5823"
          x2="25.388"
          y2="15.5823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A09EEA" />
          <stop offset="0.42" stopColor="#0095FF" />
          <stop offset="0.8" stopColor="#0095FF" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
