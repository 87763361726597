import styled from "styled-components"
import { Box, TextField, Stack, OutlinedInput } from "@mui/material"
import { theme } from "../../../../../../global/styles/theme"
// import { ReactComponent as StagesIcon } from '../../../../assets/icons/stages.svg'
import FieldForm from "../../../../../../components/FieldForm/FieldForm"

export const PaymentTemplateDrawerFormWrapper = styled(Box)`
  padding: 20px;
  height: 100%;
`
export const StyledFieldForm = styled(FieldForm)`
  .Mui-error {
    border: 1px solid red;
  }
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .Mui-error {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        color: red;
      }
    }
  }
  & .MuiFormHelperText-root {
    border: 0;
    position: absolute;
    top: 33px;
  }
`

export const StyledStack = styled(Stack)`
  width: 189px;
  height: 30px;
  background: #f6f7fb;
  border: 1px solid rgba(120, 144, 178, 0.1);
  border-radius: 4px;
  padding: 5px 0px;
`

export const StyledOutlinedInput = styled(OutlinedInput)`
  display: grid;
  grid-template-columns: 45px 30px 1fr 32px;
  grid-template-rows: 30px;
  padding: 0;
  width: 100%;
  font-size: 14px;

  input {
    color: transparent;
    text-shadow: 0 0 0 black;
  }

  & .MuiInputBase-input {
    text-align: center;
    padding: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`
export const StyledPercentOfAmount = styled(FieldForm)`
  font-size: ${theme.typography.body2.fontSize};
  .MuiInputBase-input {
    border: 1px solid rgba(92, 110, 140, 0.1);
    background: ${theme.palette.bg.shades};
    font-size: ${theme.typography.body2.fontSize};
    font-weight: ${theme.typography.body2.fontWeight};
    line-height: ${theme.typography.body2.lineHeight};
    padding: ${(props) => props.size == "small" && theme.spacing(0.5, 1)};
    border-radius: 4px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  background: ${theme.palette.bg.shades};
  padding: 0;

  .MuiInputBase-input {
    border: 0;
    width: 28px;
    text-align: right;
    padding: 4px 3px 5px 0;
  }
  .MuiInputBase-root {
    justify-content: center;
    padding: 0;
  }
  .MuiInputAdornment-root {
    margin: 0;
    font-size: ${theme.typography.body2.fontSize};
  }
  .MuiTypography-root {
    font-size: 14px;
    color: ${theme.palette.text.dark};
  }
  .MuiFormHelperText-root {
    margin: 0;
    text-align: center;
    background: ${theme.palette.bg.white};
    display: none;
  }
  & .Mui-error {
    .MuiInputBase-input {
      color: ${theme.palette.error.main};
    }
    .MuiInputAdornment-root {
      .MuiTypography-root {
        color: ${theme.palette.error.main};
      }
    }
  }
`
