import { Stack } from "@mui/material"
import { theme } from "global/styles/theme"
import styled from "styled-components"

export const TooltipWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  padding: 5px 16px 5px 7px;

  &:hover {
    cursor: pointer;
  }
`

export const TooltipBySumStyles = {
  sx: {
    "& .MuiTooltip-tooltip": {
      padding: 0,
      backgroundColor: theme.palette.bg.white,
      boxShadow: "0px 3px 14px 2px #10182814",
    },
    // zIndex: 1,
  },
}
