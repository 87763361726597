import { FormControl, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { theme } from '../../../../../../../../global/styles/theme'
import { useFormikContext } from 'formik'
import { IBdrParametersFormData, IBdrPeriod } from '../../../BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { periodSelect } from '../../../../../../../../global/styles/presets'
import { IBdrPeriodFieldsProps } from './BdrPeriodFields.types'
import { BdrBudgetControls } from '../../../../../../../../components/BdrBudgetControls/BdrBudgetControls'
import { IProjectInfo } from '../../../../../../../../global/types/commos-def'

export const BdrPeriodFields: FC<IBdrPeriodFieldsProps> = ({ availableYears, periodId }) => {
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>()

  const currentPeriodValue = useMemo(() => {
    const { periods } = values || {}
    return periods[periods.findIndex((period) => period.id === periodId)]
  }, [values.periods])

  const onChangeRange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, periodKey: keyof IBdrPeriod) => {
    const value = e.target.value

    const localPeriods = [...values.periods]
    const localPeriodIndex = localPeriods.findIndex((period) => period.id === periodId)

    // @ts-ignore
    localPeriods[localPeriodIndex][periodKey] = Number(value)

    setFieldValue('periods', localPeriods)
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1" fontSize={12} color={theme.palette.secondary.main} textAlign={'start'}>
        Период
      </Typography>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
        <BdrBudgetControls variant="report" periodId={periodId} availableYears={availableYears} />
      </Stack>
    </Stack>
  )
}
