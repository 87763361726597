const AZ_LOWER_CASE_LETTERS = Array.from({ length: 122 - 97 }, (_, idx) => String.fromCharCode(idx + 97))
const AZ_UPPER_CASE_LETTERS = AZ_LOWER_CASE_LETTERS.map((letter) => letter.toUpperCase())

const NUMBERS = Array.from({ length: 9 }, (_, idx) => String(idx))

const SYMBOLS = ['!', '?', '@']

interface GenerateRandomStringProps {
  length?: number
  lower?: boolean
  upper?: boolean
  numbers?: boolean
  symbols?: boolean
}

const getRandomItem = <TValues>(array: TValues[]): TValues => array[Math.floor(Math.random() * array.length)]

export const generateRandomString = ({
  length = 8,
  lower = true,
  upper = true,
  numbers,
  symbols,
}: GenerateRandomStringProps = {}) => {
  const characters = [
    lower && AZ_LOWER_CASE_LETTERS,
    upper && AZ_UPPER_CASE_LETTERS,
    numbers && NUMBERS,
    symbols && SYMBOLS,
  ].filter(Boolean) as unknown as string[][]

  let finalCharacters = ''
  for (let i = 0; i < length; i++) {
    const character = getRandomItem(characters)
    finalCharacters = finalCharacters.concat(getRandomItem(character))
  }

  return finalCharacters
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')
}
