import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useHistory } from 'react-router'
import { Limit, NUMBER_OF_ROWS_PER_USERS_PAGE, XXL_FOR_LAYOUT } from '../../global/variables'
import { useTypedSelector } from '../../store/store'
import { currentCompanyIdSelector } from '../../store/slices/profile'
import { Fragment, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { userRoleByTabLabel, UserRoleTabLabels, userTabNamesArray } from './Users.types'
import { TabData } from '../../global/types/commos-def'
import { EmptyPage, EmptyPageData, getEmptyPageData } from '../../components/EmptyPage'
import { UsersLegend } from './components/UsersLegend'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import Progress from '../../components/Progress'
import { UserCard } from './components/UserCard'
import { GetUsersResponse, PublicUserProfile } from '../../api/users/types'
import { useGetUsersQuery } from '../../api/users'
import { scrollableWrapper, SWWithShaddowedInnerItems } from '../../global/styles/presets'
import React from 'react'
import { InterfaceContext } from '../../contexts/context.interface'
import useSearch from '../../hooks/useSearch'
import { filterByFieldNames } from '../../global/utils/filterByFieldNames'

export const Users = () => {
  const [users, setUsers] = React.useState<PublicUserProfile[]>([])

  const { searchValue } = useSearch()

  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const history = useHistory()

  const [currentTab, setCurrentTab] = useState<UserRoleTabLabels>('Все пользователи')
  const tabNames: UserRoleTabLabels[] = userTabNamesArray

  const onTabChange = useCallback((e: SyntheticEvent, tabValue: UserRoleTabLabels) => {
    setCurrentTab(tabValue)
    setPage(1)
  }, [])

  const onAddUserClick = useCallback(() => {
    history.push('users/add')
  }, [])

  /****************************************** Pagination ********************************************/
  const limitValueStorage = localStorage.getItem('limitValue')
  const pageStorage = localStorage.getItem('page')

  React.useEffect(() => {
    localStorage.setItem('limitValue', String(limitValueStorage ?? 100))
    localStorage.setItem('page', '1')
  }, [])

  const [limitValue, setLimitValue] = useState<number>(Number(limitValueStorage ?? 100))

  const [page, setPage] = useState(Number(pageStorage))

  const offset = useMemo(() => limitValue! * (page - 1), [limitValue, page])

  const { data, isLoading, isFetching } = useGetUsersQuery({
    limit: 100, // limitValue!,
    offset: 0, // offset,
    // refetch: refresh,
  })

  const { data: usersData, total } = data || ({} as GetUsersResponse)

  React.useEffect(() => {
    if (usersData) {
      setUsers((prev) => {
        return [...usersData]
      })
    }
  }, [usersData])

  const filteredUsersSearch = useMemo(() => {
    return searchValue && users?.length
      ? filterByFieldNames<PublicUserProfile>(
          users,
          [
            'email',
            'phone',
            'firstName' as keyof PublicUserProfile,
            'lastName' as keyof PublicUserProfile,
            'company.userCompanyName' as keyof PublicUserProfile,
            'company.userPosition' as keyof PublicUserProfile,
          ],
          searchValue,
        )
      : users
  }, [users, searchValue])

  const filteredUsersByRole = useMemo(() => {
    return currentTab === 'Все пользователи'
      ? filteredUsersSearch
      : filteredUsersSearch?.filter((user) => user.role === userRoleByTabLabel[currentTab])
  }, [filteredUsersSearch, currentTab])

  const countUsers = useMemo(() => filteredUsersByRole.length, [filteredUsersByRole])

  const countPagination = useMemo(() => Math.ceil(countUsers / limitValue!) || 1, [countUsers, limitValue])

  const filteredUsersPortions = useMemo(() => {
    let pages = [] as PublicUserProfile[]
    if (filteredUsersByRole.length > 0) {
      for (let i = offset; i <= page * limitValue - 1; i++) {
        pages.push(filteredUsersByRole[i])
      }
    }
    return pages.length > 0 ? pages : filteredUsersByRole
  }, [filteredUsersByRole, limitValue, page])

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(limit)
      localStorage.setItem('limitValue', String(limit))
      setPage(1)
      localStorage.setItem('page', '1')
    },
    [setLimitValue, setPage],
  )

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page)
      localStorage.setItem('page', String(page))
    },
    [setPage, filteredUsersByRole],
  )

  const onUserCardClick = (userId: number) => {
    history.push(`/users/edit/${userId}`)
  }

  const tabsData: TabData<UserRoleTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => ({ value: tabName, label: tabName }))
  }, [tabNames])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют пользователи, соответствующие результатам запроса.</>,
  )

  const interfaceCTX = useContext(InterfaceContext)

  React.useEffect(() => {
    initiateBreadcrumbs()
  }, [])

  function initiateBreadcrumbs() {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'title',
        title: `Пользователи`,
      },
    ])
  }

  return (
    <Stack flex={1}>
      <UsersLegend
        currentTab={currentTab}
        tabsData={tabsData}
        onTabChange={onTabChange}
        onAddClick={onAddUserClick}
        countPagination={countPagination}
        limit={limitValue!}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
        page={page}
        numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
      />

      {isLoading || isFetching ? (
        <Progress />
      ) : filteredUsersPortions?.length ? (
        <Grid
          sx={{
            ...scrollableWrapper,
            ...SWWithShaddowedInnerItems,
            width: '100%',
            height: 'calc(100vh - 105px)',
            pt: 0,
          }}
          spacing={2.5}
          container
        >
          {filteredUsersPortions?.map((user) => {
            if (user) {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={xxl ? 2 : 3}
                  container
                  justifyContent="center"
                  key={user.id}
                  maxHeight="380px"
                >
                  <UserCard data={user} onClick={onUserCardClick} />
                </Grid>
              )
            } else return <></>
          })}
        </Grid>
      ) : (
        <EmptyPage data={emptyFilteredData} forFilter />
      )}
    </Stack>
  )
}
