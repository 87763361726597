import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLoginByTokenMutation } from '../../api/auth'
import { config, currentCompanyName } from '../../global/config'
import { useMutationHandlers } from '../../hooks/useMutationHandlers'
import useQuery from '../../hooks/useQuery'
import { isAuthSelector, setCredentials } from '../../store/slices/auth'
import { isLogoutSelector } from '../../store/slices/profile'
import { useTypedSelector } from '../../store/store'
import AppLayout from '../AppLayout'

export const Layout: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const dispatch = useDispatch()
  const tokenHandler = useQuery()
  const tokenQuery: string | null = tokenHandler.get('token')
  const [token, setToken] = useState<string | null>(tokenQuery)

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({}))
    }
  }, [token])

  const isAuth = useTypedSelector(isAuthSelector)
  const isLogout = useTypedSelector(isLogoutSelector)

  const [loginByToken, loginByTokenResponse] = useLoginByTokenMutation()

  useEffect(() => {
    if (!isAuth && !isLogout) {
      token
        ? loginByToken({ token: token! })
        : window.location.replace(
            `${config.coreURL}?source=finance${currentCompanyName ? `&company=${currentCompanyName}` : ''}`,
          )
    }
  }, [isAuth, token, isLogout])

  useMutationHandlers(loginByTokenResponse, () => {
    tokenHandler.remove('token')
    setToken(null)
  })

  return isAuth ? <AppLayout>{children}</AppLayout> : null
}
