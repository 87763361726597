import { api } from '../api'
import { PublicUserProfile, UserCompanyInfo } from '../users/types'

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<PublicUserProfile, void>({
      query: () => '/profile/get',
      providesTags: ['Profile'],
    }),
    getCompany: build.query<UserCompanyInfo, void>({
      query: () => '/user/company/get',
      providesTags: ['Company'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetProfileQuery, useGetCompanyQuery } = profileApi
