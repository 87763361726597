import React, { useContext } from 'react'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  MobileStepper,
  MobileStepperProps,
  Typography,
  TypographyProps,
} from '@mui/material'

interface IStepperPageProps extends BoxProps {
  stepNumber: number
}

export type TChangeActiveStepFn = (prevOrNext: 'back' | 'next') => void

interface ContextProps {
  stepsAmmount: number
  activeStep: number
  goNext: () => void
  goBack: () => void
  setStepsAmmount: (stepsAmmout: number) => void
}

export const StepperContext = React.createContext({} as ContextProps)
export function StepperContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [value, setValue] = React.useState({
    stepsAmmount: 0,
    activeStep: 0,
  } as unknown as ContextProps)

  function goNext() {
    console.log('s')
    setValue((prevValue) => ({
      ...prevValue,
      activeStep: prevValue.activeStep + 1,
    }))
  }

  function goBack() {
    setValue((prevValue) => ({
      ...prevValue,
      activeStep: prevValue.activeStep - 1,
    }))
  }

  function setStepsAmmount(stepsAmmout: number) {
    setValue((prevValue) => ({
      ...prevValue,
      stepsAmmount: stepsAmmout,
    }))
  }

  return (
    <StepperContext.Provider
      value={{
        ...value,
        setStepsAmmount: setStepsAmmount,
        goNext: goNext,
        goBack: goBack,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}

export const MyStepper = {
  Wrapper: function Wrapper(props: BoxProps) {
    const { children } = props

    return (
      <StepperContextProvider>
        <Box {...props}>{children}</Box>
      </StepperContextProvider>
    )
  },
  Step: function Step(props: IStepperPageProps) {
    const stepCTX = useContext(StepperContext)

    const { children, stepNumber } = props

    return <Box>{stepCTX.activeStep == stepNumber ? children : <></>}</Box>
  },
  Pagination: function Pagination(props: MobileStepperProps) {
    const { steps, nextButton, backButton } = props

    const stepCTX = useContext(StepperContext)

    const hideBtnConditions = {
      hideGoNext: stepCTX.activeStep == stepCTX.stepsAmmount - 1,
      hideGoBack: stepCTX.activeStep == 0,
    }

    React.useEffect(() => {
      stepCTX.setStepsAmmount(props.steps)
    }, [props.steps])

    return (
      <MobileStepper
        variant="dots"
        position="static"
        activeStep={stepCTX.activeStep}
        nextButton={
          <Box sx={{ visibility: hideBtnConditions.hideGoNext ? 'hidden' : '' }} onClick={stepCTX.goNext}>
            {nextButton}
          </Box>
        }
        backButton={
          <Box sx={{ visibility: hideBtnConditions.hideGoBack ? 'hidden' : '' }} onClick={stepCTX.goBack}>
            {backButton}
          </Box>
        }
        steps={steps}
        sx={{ mt: 2.5, p: 0, bgcolor: 'white' }}
      />
    )
  },
  BackBtn: function BackBtn(props: ButtonProps) {
    const { children } = props

    return (
      <Button variant="text" size="small" color="secondary" startIcon={<ArrowBack />} {...props}>
        {'Назад' || children}
      </Button>
    )
  },
  NextBtn: function NextBtn(props: ButtonProps) {
    const { children } = props

    return (
      <Button variant="text" size="small" color="secondary" endIcon={<ArrowForward />} {...props}>
        {'Далее' || children}
      </Button>
    )
  },
}
