import { Delete } from '@mui/icons-material'
import { Button, TableCell, Tooltip } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DeleteConfirmDialog } from '../../../../../../components/Dialogs/DeleteConfirmDialog'
import { EEString } from '../../../../../../components/editableElements/EEString'
import {
  EETableRowContext,
  EETableRowProvider,
  IValidationErrors,
} from '../../../../../../components/editableElements/EETableRow'
import { MappingArticlesAPI } from '../../../../../../global/api/APIMethods/MappingArticlesAPI'
import { changeQueryParams, getQueryParam } from '../../../../../../global/functions'
import { hoverableRow, tableCellInput, tableCellInput_ViewMode } from '../../../../../../global/styles/presets'
import {
  IBDDSArticle,
  IBDRArticle,
  TBDDSArticleFlow,
  TBDRArticleType,
  TBudgetType,
} from '../../../../../../global/types/commos-def'
import { IMappingArticleQuerySchema, IMappingArticlesState } from '../../MappingArticles'
import { useTranslation } from 'react-i18next'

interface IArticleLitItemProps {
  parentState: IMappingArticlesState
  setParentState: React.Dispatch<React.SetStateAction<IMappingArticlesState>>
  article: IBDRArticle | IBDDSArticle
  refetch: () => void
}

interface IArticleLitItemState {
  article: IBDRArticle | IBDDSArticle
  isDeleteConfirmOpen: boolean
}

export function ArticleListItem(props: IArticleLitItemProps) {
  const { article, parentState, setParentState, refetch } = props
  const { t } = useTranslation('ENUMS')
  const [state, setState] = React.useState({
    article: article,
    isDeleteConfirmOpen: false,
  } as IArticleLitItemState)

  const history = useHistory()

  const location = useLocation()

  let timeout: NodeJS.Timeout

  let queryParams: IMappingArticleQuerySchema = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as IMappingArticleQuerySchema

  const isEditable = getIsEditable()

  function getIsEditable() {
    return true
    /*   if (queryParams.BDRArticleType || queryParams.currentArticleID) {
            if ((state.article as IBDDSArticle).editable != undefined) {
                return (state.article as IBDDSArticle).editable
            } else {
                return true
            }
        } else {
            return false
        } */
  }

  function goDeep(id: number | string | undefined, type?: TBDRArticleType) {
    changeQueryParams(history, {
      currentArticleID: id,
      isRoot: 'false',
      BDRArticleType: type,
    })
  }

  function changeArticleName(value: string) {
    setState((prevState) => ({
      ...prevState,
      article: {
        ...state.article,
        name: value,
      },
    }))
  }

  function updateArticle() {
    MappingArticlesAPI.updateArticle(getQueryParam(history, 'budgetType') as TBudgetType, {
      articles: [
        {
          id: state.article.id,
          name: state.article.name,
          type: (getQueryParam(history, 'BDRArticleType') as TBDRArticleType) ?? 'INCOME',
        },
      ],
    }).then(() => refetch())
  }

  function switchDeleteConfirm() {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmOpen: !prevState.isDeleteConfirmOpen,
    }))
  }

  function deleteOperation() {
    MappingArticlesAPI.deleteArticle(queryParams.budgetType, article.id).then(() => {
      switchDeleteConfirm()
      setParentState((prevState) => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          data: prevState.articleData?.data?.filter((stateArticle) => article.id != stateArticle.id),
        },
      }))
    })
  }

  function validateRow(article: IBDRArticle | IBDDSArticle): IValidationErrors {
    let validationErrors = {} as IValidationErrors
    if (article.name == '') {
      validationErrors.name = {
        isValid: false,
        message: 'Обязательное поле',
      }
    } else if (article.name && article.name.length > 300) {
      validationErrors.name = {
        isValid: false,
        message: 'Не более 300 символов',
      }
    } else {
      validationErrors.name = {
        isValid: true,
        message: '',
      }
    }
    return validationErrors
  }

  return (
    <>
      <DeleteConfirmDialog
        open={state.isDeleteConfirmOpen}
        onClose={switchDeleteConfirm}
        onYes={deleteOperation}
        onNo={switchDeleteConfirm}
      />
      <EETableRowProvider
        onSwitchMode={(prevMode, currentMode) => {
          clearTimeout(timeout)
          if (prevMode == 'edit') {
            updateArticle()
          }
        }}
        onDoubleClick={(e, switchMode, value) => {
          clearTimeout(timeout)
          switchMode(value.mode)
        }}
        onClick={(e, value) => {
          e.stopPropagation()
          e.preventDefault()

          isEditable && clearTimeout(timeout)
          timeout = setTimeout(() => {
            value.mode == 'edit' && clearTimeout(timeout)
            if (value.mode == 'view') {
              let queryParams: IMappingArticleQuerySchema = qs.parse(location.search, {
                ignoreQueryPrefix: true,
              }) as unknown as IMappingArticleQuerySchema
              // goDeep(article.id || undefined, article.type )
              goDeep(article.id || undefined, queryParams.budgetType == 'bdr' ? article.type : undefined)
            }
            // }
          }, 200)
        }}
        validation={() => validateRow(state.article)}
        TableRowProps={{
          id: 'editableRow',
          sx: {
            ...hoverableRow,
            '.MuiTableCell-root': {
              paddingY: 1,
            },
          },
        }}>
        <EETableRowContext.Consumer>
          {(value) => (
            <>
              <TableCell>
                {isEditable && (
                  <Button
                    color="error"
                    size="small"
                    sx={{ marginX: 'auto', p: 0.5 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      switchDeleteConfirm()
                    }}>
                    <Delete color={'error'} fontSize="small" />
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <EEString
                  align="left"
                  name="name"
                  mode={isEditable ? value.mode : 'view'}
                  maxLength={160}
                  value={state.article.name || ''}
                  onChange={(e) => changeArticleName(e.target.value)}
                  viewModeStyle={{
                    ...tableCellInput_ViewMode,
                    ...(isEditable && {
                      ':hover': {
                        cursor: 'text !important',
                        height: 'auto !important',
                      },
                    }),
                    width: 'auto',
                    display: 'inline-block',
                    overflowWrap: 'anywhere',
                  }}
                  TextFieldProps={{
                    sx: tableCellInput,
                  }}
                  tooltip={true}
                  tooltipTitle={
                    <>
                      {state.article.type ? t('BDR_ARTICLE_CATEGORY.' + state.article.type) : ''}
                      {state.article.type && 'flow' in state.article && state.article.flow && <br />}
                      {'flow' in state.article && state.article.flow
                        ? t('BDDS_ARTICLE_FLOW.' + state.article.flow)
                        : ''}
                    </>
                  }
                />
              </TableCell>
            </>
          )}
        </EETableRowContext.Consumer>
      </EETableRowProvider>
    </>
  )
}
