import { Stack } from "@mui/material"
import styled from "styled-components"

export const QuantityPerMonth = styled(Stack)`
  div {
    div {
      width: 100%;

      input {
        text-align: start;
        background-color: ${(props) => props.theme.palette.bg.shades};
      }
    }
  }
`
