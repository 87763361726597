import { Stack, Switch, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from '../../../../../../../../global/styles/theme'
import { IBdrPeriodSwitchesProps } from './BdrPeriodSwitches.types'
import {
  IBdrParametersFormData,
  TBdrPeriodSwitch,
} from '../../../BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { useFormikContext } from 'formik'

export const BdrPeriodSwitches: FC<IBdrPeriodSwitchesProps> = ({
  item,
  changeSwitchValueByKey,
  title,
  isDisabled,
  isResult,
}) => {
  const { plan, fact, deviation } = item
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>()
  const switchData = { plan, fact, deviation }

  const switchTextByKey: Record<TBdrPeriodSwitch, string> = {
    plan: 'План',
    fact: 'Факт',
    deviation: 'Отклонение',
  }

  const getSwitchStatus = (switchType: TBdrPeriodSwitch) => {
    if (!isResult) return false

    if (isDisabled) return true

    if (!values.periods.filter((period) => period[switchType]).length) return true
    else return false
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1" fontSize={12} color={theme.palette.secondary.main} textAlign={'start'}>
        {title}
      </Typography>

      <Stack spacing={0.5}>
        {Object.keys(switchData).map((switchKey: any) => (
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="body2" color={theme.palette.text.dark}>
              {switchTextByKey[switchKey as TBdrPeriodSwitch]}
            </Typography>
            <Switch
              checked={switchData[switchKey as TBdrPeriodSwitch]}
              onChange={(e, checked) => changeSwitchValueByKey(switchKey, checked)}
              disabled={getSwitchStatus(switchKey)}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
