import { FC, useCallback, useMemo } from 'react'
import { BdrAnalysItem, BdrPeriodCloseWrapper } from './BdrPeriodItem.styles'
import { theme } from '../../../../../../global/styles/theme'
import { Stack, Switch, Typography } from '@mui/material'
import { IBdrPeriodItemProps } from './BdrPeriodItem.types'
import { Close as CloseIcon } from '@mui/icons-material'
import { useFormikContext } from 'formik'
import {
  IBdrParametersFormData,
  IBdrPeriod,
  TBdrPeriodSwitch,
} from '../BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { BdrPeriodSwitches } from './components/BdrPeriodSwitches'
import { BdrPeriodFields } from './components/BdrPeriodFields'
import { cloneDeep } from 'lodash'

export const BdrPeriodItem: FC<IBdrPeriodItemProps> = ({ availableYears, periodItem, isDeleteBtnShown }) => {
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>()

  const currentPeriodValue = useMemo(() => {
    const { periods } = values || {}
    return periods[periods.findIndex((period) => period.id === periodItem.id)]
  }, [values.periods])

  const onDeleteBdrPeriod = (bdrPeriodId: number) => {
    setFieldValue(
      'periods',
      values.periods.filter((periodItem) => periodItem.id !== bdrPeriodId),
    )
  }

  const changePeriodValueByKey = useCallback(
    (periodKey: TBdrPeriodSwitch, checked: any) => {
      const localPeriods = [...values.periods]
      const localPeriodIndex = localPeriods.findIndex((period) => period.id === periodItem.id)

      const localPeriod = cloneDeep(localPeriods[localPeriodIndex])

      localPeriods[localPeriodIndex] = { ...localPeriod, [periodKey]: checked }

      if (!checked && !((values.periods.filter((period) => period[periodKey]).length || 1) - 1)) {
        setFieldValue('result', { ...values.result, [periodKey]: false })
      }

      setFieldValue('periods', localPeriods)
    },
    [values.periods, values.result],
  )

  const periodErrors = useMemo(() => {
    const { plan, fact, deviation, start, end } = currentPeriodValue || {}

    const periodErrors: string[] = []

    if (!plan && !fact && !deviation) {
      periodErrors.push('Активируйте хотя бы один показатель.')
    }

    if (
      !start.year ||
      !end.year ||
      (values.periodType === 'MONTHLY' && (!start.month || !end.month)) ||
      (values.periodType === 'QUARTERLY' && (!start.quarter || !end.quarter))
    ) {
      periodErrors.push('Выберите период отображения.')
    }

    return periodErrors.length ? periodErrors : null
  }, [values.periods])

  return (
    <BdrAnalysItem key={periodItem.id}>
      <BdrPeriodFields availableYears={availableYears} periodId={periodItem.id} />

      <BdrPeriodSwitches item={periodItem} changeSwitchValueByKey={changePeriodValueByKey} title="Показатель" />

      {periodErrors?.length && (
        <Stack spacing={1}>
          {periodErrors.map((item) => (
            <Typography variant="body2" color={theme.palette.text.red} fontSize={12}>
              {item}
            </Typography>
          ))}
        </Stack>
      )}

      {isDeleteBtnShown && (
        <BdrPeriodCloseWrapper onClick={() => onDeleteBdrPeriod(periodItem.id)}>
          <CloseIcon color="primary" fontSize="small" />
        </BdrPeriodCloseWrapper>
      )}
    </BdrAnalysItem>
  )
}
