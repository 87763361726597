import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosStatic, CancelToken } from 'axios'
import qs from 'qs'
import ReactDOM from 'react-dom'
import { Snack } from '../../components/Snack/Snack'
import { config, currentCompanyName } from '../config'

export interface IQueryObj {
  [key: string]: string | number | undefined
}

export function parseObjectToQueryString(query: any) {
  if (!query) {
    return ''
  } else {
    return '?' + qs.stringify(query, { arrayFormat: 'repeat' })
  }
}

// 1я попытка обновлять токен
// axios.interceptors.response.use((response) => {
//     return response;
// }, async (error) => {
//     if (error.config
//         && error.response
//         && error.response.status === 401
//         && !error.config._retry
//     ) {
//         error.config._retry = true;
//         const currentToken = JSON.parse(localStorage.getItem('auth')!).refreshToken;
//         await req.post('/auth/refresh', { token: currentToken })
//             .then((data) => {
//                 localStorage.setItem('auth', JSON.stringify(data.data))
//                 error.config.headers["Authorization"] = "Bearer " + data.data.token
//                 axios.defaults.headers["Authorization"] = "Bearer " + data.data.token
//                 isRefreshingToken = false
//                 return axios(error.config)
//             })
//     }
//     return Promise.reject(error);
// });

export interface ITokenData {
  refreshDurationMs: number
  refreshToken: string
  token: string
  tokenDurationMs: number
}

const shouldIntercept = (error: AxiosError) => {
  try {
    return error?.response?.status === 401
  } catch (e) {
    return false
  }
}

export const setTokenData = (tokenData = {} as ITokenData, axiosClient?: any) => {
  localStorage.setItem('auth', JSON.stringify(tokenData))
}

export const handleTokenRefresh = () => {
  const refreshToken = JSON.parse(localStorage.getItem('auth')!).refreshToken
  return new Promise((resolve, reject) => {
    req
      .post('/auth/refresh', { token: refreshToken })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const attachTokenToRequest = (request: AxiosRequestConfig, token: string) => {
  if (request.headers) request.headers['Authorization'] = 'Bearer ' + token
}

function applyAppTokenRefreshInterceptor(axiosClient: AxiosStatic, customOptions = {}) {
  let isRefreshing = false
  let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  }
  const processQueue = (error: AxiosError | null, token = null as string | null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const interceptor = (error: any) => {
    if (!options.shouldIntercept(error)) {
      if (error.config.url.includes('/auth/refresh')) {
        localStorage.setItem('auth', '{}')
        window.location.replace(
          `${config.coreURL}?source=finance${currentCompanyName ? `&company=${currentCompanyName}` : ''}`,
        )
        isRefreshing = false
        return
      }
      return Promise.reject(error)
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token: unknown) => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, token as string)
          return axiosClient.request(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(error) // Игнорирует перехватчик, и возвращает ошибку с кодом != 401 дальше в промис
        })
    }

    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData as ITokenData, axiosClient)
          options.attachTokenToRequest(originalRequest, (tokenData as ITokenData).token)
          processQueue(null, (tokenData as ITokenData).token)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}

applyAppTokenRefreshInterceptor(axios)

export const req = new (class {
  private getHeaders() {
    const token = JSON.parse(localStorage!.getItem('auth')!).token
    return token ? { Authorization: 'Bearer ' + token } : undefined
  }

  get(url: string, params?: Object, token?: { Authorization: string }, controller?: AbortController): AxiosPromise {
    return axios.get(config.apiHost + url, {
      params,
      headers: token ?? this.getHeaders(),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      signal: controller?.signal,
    })
  }

  post(
    url: string,
    body?: Object,
    params?: Object,
    cancelToken?: CancelToken,
    token?: { Authorization: string },
  ): AxiosPromise {
    let URLWithParams = url + parseObjectToQueryString(params)
    return axios.post(config.apiHost + URLWithParams, body, { headers: token ?? this.getHeaders(), cancelToken })
  }

  put(url: string, body?: Object, token?: { Authorization: string }): AxiosPromise {
    return axios.put(config.apiHost + url, body, { headers: token ?? this.getHeaders() })
  }

  delete(url: string, params?: Object, token?: { Authorization: string }): AxiosPromise {
    return axios.delete(config.apiHost + url, { params, headers: token ?? this.getHeaders() })
  }
})()

export function successHandler(message: string) {
  ReactDOM.render(
    <Snack message={message} randomKey={Math.random()} severity="success" />,
    document.getElementById('snack'),
  )
}

export function errorHandler(error: AxiosError | any) {
  console.log('errorHandler: ', error)
  ReactDOM.render(
    <Snack error={error.response} randomKey={Math.random()} severity="error" />,
    document.getElementById('snack'),
  )
}
export function errorUploadHandler(error: AxiosError | any, type?: string) {
  console.log('errorUploadHandler: ', error)
  ReactDOM.render(
    <Snack error={error} randomKey={Math.random()} severity="error" type={type} />,
    document.getElementById('snack'),
  )
}
function dispatch(arg0: any) {
  throw new Error('Function not implemented.')
}
