import React, { useCallback, useEffect, useState } from 'react'
import { UploadDrawerProps as UploadDrawerProps } from './UploadDrawer.types'
import { UploadDrawerWrapper, StyledUploadDrawer, UploadDrawerTopBar } from './styles'
import { Box, Typography } from '@mui/material'
import { theme } from '../../../../global/styles/theme'
import UploadDrawerForm from '../UploadDrawerForm'
import { Divider } from '../../../../components/Divider'
//import { useGetCalculationByIdQuery } from '../../../../api/calculations';

const UploadDrawer: React.FC<UploadDrawerProps> = ({
  open,
  onClose,
  financeCenter,
  handleRefresh,
  onErrorsListDrawerOpen,
  setFile,
  file,
  isUploaded,
  setUploaded,
  setCloseUpload,
  errorList,
  setErrorList,
  //setProgressTime,
  progressTimeProps,
  progressPercentProps,
  uploadRecords,
  setUploadRecords,
}) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const [progressTime, setProgressTime] = React.useState<string>(progressTimeProps || '')
  const [progressPercent, setProgressPercent] = React.useState<number>(progressPercentProps || 0)

  const onDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      switch (isUploaded) {
        case 'loading':
          onClose(true, false)
          setCloseUpload(true)
          break
        case 'error':
          onClose(true, false)
          break
        case 'start':
          onClose(dirty, !dirty)
          break
        case 'canceled':
          onClose(false, true)
          break
        case 'success':
          onClose(false, true)
          break
        default:
          break
      }
    },
    [isUploaded, dirty],
  )

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useEffect(() => {
    setProgressTime(progressTimeProps!)
    setProgressPercent(progressPercentProps!)
  }, [])

  useEffect(() => {
    if (isUploaded === 'canceled') {
      setProgressTime!('')
      setProgressPercent!(0)
      setUploaded('start')
    }
    if (isUploaded === 'success') {
      setProgressTime!('')
      setProgressPercent!(0)
      setUploaded('start')
    }
    // setUploaded('start');

    setCloseUpload(false)
  }, [open])

  return (
    <StyledUploadDrawer
      anchor="right"
      open={open}
      onClose={() => {
        onDrawerClose(dirty)
      }}
    >
      <UploadDrawerWrapper justifyContent="flex-start">
        <UploadDrawerTopBar>
          <Typography variant="h1" color={theme.palette.primary.main}>
            {' '}
            Загрузить Excel{' '}
          </Typography>
        </UploadDrawerTopBar>
        <Divider />
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: theme.palette.legends.orange }}>
            ВНИМАНИЕ!
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
            При загрузке отчета, он будет полностью перезаписан данными из нового файла.
          </Typography>
        </Box>
        <UploadDrawerForm
          onClose={onClose}
          onFormChange={onFormChange}
          financeCenter={financeCenter}
          handleRefresh={handleRefresh}
          onErrorsListDrawerOpen={onErrorsListDrawerOpen}
          setFile={setFile}
          file={file}
          isUploaded={isUploaded}
          setUploaded={setUploaded}
          setProgressTime={setProgressTime}
          progressTime={progressTime}
          uploadRecords={uploadRecords}
          setUploadRecords={setUploadRecords}
          progressPercent={progressPercent}
          setProgressPercent={setProgressPercent}
          setErrorList={setErrorList}
          errorList={errorList}
        />
      </UploadDrawerWrapper>
    </StyledUploadDrawer>
  )
}

export default UploadDrawer
