import React, { useCallback } from 'react'
import { RadioGroup, RadioGroupProps } from '@mui/material'
import { FieldHookConfig, useField } from 'formik'

export type RadioGroupFieldFormProps = {
  name: string
  fieldProps?: Partial<FieldHookConfig<string>>
} & Omit<RadioGroupProps, 'name'>

export const RadioGroupFieldForm: React.FC<RadioGroupFieldFormProps> = ({ fieldProps, onBlur, children, ...props }) => {
  const [{ onBlur: onFieldBlur, ...field }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const handleBlur = useCallback(
    (e: any) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur],
  )

  return (
    <RadioGroup {...field} onBlur={handleBlur} {...props}>
      {children}
    </RadioGroup>
  )
}
