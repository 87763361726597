import React from 'react'
import { Box, Button, IconButton, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import { UserTopBarProps } from './UserTopBar.types'
import { theme } from '../../../../../global/styles/theme'

const UserTopBar: React.FC<UserTopBarProps> = ({ onReturnClick, onDeleteClick, isEditUser }) => {
  return (
    <Stack
      className="UserTopBar"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: 3.75, pt: 3.75, pb: 2.5 }}
    >
      <Box>
        <Button
          variant="text"
          style={{ color: theme.palette.text.dark }}
          startIcon={<ArrowBackIcon />}
          onClick={onReturnClick}
        >
          Назад
        </Button>
      </Box>
      {isEditUser ? (
        <Box>
          <IconButton size="small" color="error" onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : null}
    </Stack>
  )
}

export default UserTopBar
