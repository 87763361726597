import React, {
  LegacyRef,
  MutableRefObject,
  ReactElement,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Button, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { theme } from '../../../global/styles/theme'
import {
  ErrorsListButtonWrapper,
  ErrorsListDrawerTopBar,
  ErrorsListDrawerWrapper,
  ErrorsListEntireWrapper,
  ErrorsListWrapper,
} from './styles'
import { UploadItemErrorProps } from './ErrorsItem'
import { Divider } from '../../../components/Divider'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CancelIcon from '@mui/icons-material/Cancel'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { errorTextByType, ExcelParseError } from '../../../global/types/commos-def'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { ErrorRowText, StyledTypography } from './ErrorsItem/styles'

export interface ErrorsListDrawerProps {
  open: boolean
  onClose: (dirty: boolean, immediately?: boolean) => void
  errorList: ExcelParseError[]
}

const ErrorsListDrawer: React.FC<ErrorsListDrawerProps> = ({ open, onClose, errorList }) => {
  const [count, setCount] = useState<number>(0)

  const listRef = React.createRef() as RefObject<FixedSizeList<UploadItemErrorProps[]>>

  const scrollToNextRow = () => {
    setCount(count + 1)
  }

  const scrollToPrevRow = () => {
    setCount(count - 1)
  }

  useEffect(() => {
    if (listRef.current === null) return
    if (listRef) listRef.current!.scrollToItem(count, 'start')
  }, [listRef, count])

  useEffect(() => {
    setCount(0)
  }, [open])

  const dataForErrorItemsList: UploadItemErrorProps[] = useMemo(() => {
    return errorList?.map(({ type, message, row, cell, col }) => ({
      title: errorTextByType[type],
      icon: <WarningAmberIcon fontSize="medium" color="error" />,
      text: message,
      row: row,
      col: col,
      cell: cell,
    }))
  }, [errorList])

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => onClose(false, true)}
      PaperProps={{
        style: { overflow: 'hidden' },
      }}
    >
      <ErrorsListDrawerWrapper>
        <ErrorsListDrawerTopBar>
          <Typography variant="h1" justifyContent="center" color={theme.palette.primary.main}>
            Информация об ошибках
          </Typography>
          <IconButton
            onClick={() => onClose(false, true)}
            sx={{ position: 'fixed', top: 18, right: 20, p: 0.5 }}
            disableTouchRipple
          >
            <CancelIcon color="secondary" />
          </IconButton>
        </ErrorsListDrawerTopBar>

        <Divider />

        <ErrorsListEntireWrapper /* ref={measuredRef} */>
          {/*  <Button variant="text"
            onClick={() => scrollToPrevRow()}
            disabled={count === 0}
            sx={{ width: '100%', backgroundColor: 'rgb(240,240,240)' }}>
            <KeyboardArrowUpIcon />
          </Button> */}

          <ErrorsListWrapper className="ErrorsList">
            <FixedSizeList
              itemData={dataForErrorItemsList}
              innerElementType="div"
              itemCount={dataForErrorItemsList.length}
              itemSize={92}
              height={document.documentElement.clientHeight}
              width={400}
              ref={listRef}
              //style={{ overflow: 'hidden' }}
            >
              {({ data, index, style }) => {
                return (
                  <Stack
                    className="city"
                    style={{ ...style, padding: '10px 20px', boxShadow: 'inset 0px -2px 0px #d1d8fa' }}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Stack className="icon" direction="row" spacing={1.25} alignItems="center" flex={1}>
                        {dataForErrorItemsList[index].icon}
                        <Typography variant="subtitle2" color={theme.palette.primary.main}>
                          Ошибка: {dataForErrorItemsList[index].title}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                      <StyledTypography variant="body1">
                        {dataForErrorItemsList[index].text}: <br /> "{dataForErrorItemsList[index].cell}"
                      </StyledTypography>
                      <Stack>
                        <ErrorRowText variant="tooltip">Строка: {dataForErrorItemsList[index].row}</ErrorRowText>
                        <ErrorRowText variant="tooltip">Столбец: {dataForErrorItemsList[index].col}</ErrorRowText>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              }}
            </FixedSizeList>
          </ErrorsListWrapper>
          {/*  <Button variant="text"
            onClick={() => scrollToNextRow()}
            disabled={count === dataForErrorItemsList.length}
            sx={{ width: '100%', backgroundColor: 'rgb(240,240,240)' }}>
            <KeyboardArrowDownIcon />
          </Button> */}
        </ErrorsListEntireWrapper>
      </ErrorsListDrawerWrapper>
    </Drawer>
  )
}

export default ErrorsListDrawer
