import { SelectChangeEvent } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { IDownloadsItem, IOperations, IProjectInfo, TFactType, UploadType } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { Fact } from './Fact'
import Progress from '../../components/Progress'
import qs from 'qs'
import { getQueryParam } from '../../global/functions'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { socketChanel } from '../../global/api/socket'
import { InterfaceContext } from '../../contexts/context.interface'

export interface IStateFact {
  operations: null | IOperations[] //
  refresh: boolean
  downloadsList: IDownloadsItem[]
}

export interface IOperationsTypes {
  value: string
  label: string
}

export function FactContainer() {
  const { projectID } = useParams() as Params
  const history = useHistory()

  const [state, setState] = React.useState({
    operations: null,
    refresh: false,
    downloadsList: [],
  } as IStateFact)

  const interfaceCTX = useContext(InterfaceContext)
  // const socketChanel = interfaceCTX.websocket;

  const [project, setProject] = React.useState<IProjectInfo>()
  const [socketChanelState, setSocketChanel] = React.useState<WebSocket>()

  const factTypeUrl = getQueryParam(history, 'factType')
  const typeTableUrl = getQueryParam(history, 'typeTable')

  const [factType, setFactType] = React.useState<TFactType>((factTypeUrl! as TFactType) ?? 'TRANSACTIONS')
  const [typeTable, setTypeTable] = React.useState<'DOWNLOAD' | 'TABLE'>(
    (typeTableUrl! as 'DOWNLOAD' | 'TABLE') ?? 'DOWNLOAD',
  )

  const handleChangeOperation = React.useCallback((event: SelectChangeEvent<unknown>) => {
    setFactType(event.target.value as TFactType)
  }, [])

  useEffect(() => {
    history.push(`?factType=${factType}&typeTable=${typeTable}`)
  }, [factType, typeTable])

  useEffect(() => {
    const filterParams = history.location.search.substring(1)
    const filtersFromParams = qs.parse(filterParams)
    setFactType(filtersFromParams.factType as TFactType)
    setTypeTable(filtersFromParams.typeTable as 'DOWNLOAD' | 'TABLE')
  }, [])

  React.useEffect(() => {
    ProjectsAPI.getDownloadList(Number(projectID), factType).then((data) => {
      setState((prevState) => ({
        ...prevState,
        downloadsList: data?.data,
      }))
    })
    switch (factType) {
      case 'TRANSACTIONS':
        ProjectsAPI.getTransactionsList(projectID).then((data) => {
          setState((prevState) => ({
            ...prevState,
            operations: data?.data,
          }))
        })
        break
      case 'EXTRACTS':
        ProjectsAPI.getExtractsList(projectID).then((data) => {
          setState((prevState) => ({
            ...prevState,
            operations: data?.data,
          }))
        })
        break
      default:
        break
    }
  }, [factType, state.refresh])

  React.useEffect(() => {
    ProjectsAPI.getProjectById(projectID).then((data) => {
      setProject(data)
      return data
    })
  }, [])

  const handleRefresh = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      refresh: !prevState.refresh,
    }))
  }, [])

  const connectWS = useCallback(() => {
    let socketChanel = new WebSocket('wss://redesign.api.finance.pragma.info/single-task')
    const auth = JSON.parse(localStorage.getItem('auth') || '{}')

    const authWS = {
      bodyJson: JSON.stringify({ token: auth.token }),
      id: projectID,
      type: 'auth',
    }

    /*  socketChanel!.onopen = function (event) {
       socketChanel!.send(JSON.stringify(authWS));
       setSocketChanel(socketChanel!);
     } */
    socketChanel!.send(JSON.stringify(authWS))
    setSocketChanel(socketChanel!)
  }, [])

  /****************************************** ConfirmDialog ********************************/

  const [fileId, setFileId] = React.useState<number | null>(null)

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        ProjectsAPI.deleteFact(fileId!).then(() => {
          handleRefresh()
        })
      }
    },
    [fileId],
  )

  const body = 'Это действие нельзя отменить.'

  const title = 'Вы уверены, что хотите удалить файл?'

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    body,
    title,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDeleteFact = useCallback((id: number) => {
    setFileId(id)
    openConfirm()
  }, [])

  const endLoading = Boolean(state.operations) && Boolean(project)

  return endLoading ? (
    <>
      <Fact
        operations={state.operations!}
        downloadsList={state.downloadsList}
        project={project!}
        handleChangeOperation={handleChangeOperation}
        factType={factType}
        setFactType={setFactType}
        connectWS={connectWS}
        handleRefresh={handleRefresh}
        setTypeTable={setTypeTable}
        typeTable={typeTable}
        onDeleteFact={onDeleteFact}
        // socketChanel={socketChanelState! || socketChanel}
      />
      <ConfirmDialog />
    </>
  ) : (
    <Progress />
  )
}
