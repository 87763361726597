import React, { useCallback, useEffect, useMemo, useState } from 'react'
//import { useNavigate } from 'react-router-dom';

import {
  StyledFactUploadDrawerFormWrapper,
  StyledDownloadTemplate,
  StyledUploadStatusContainer,
  StyledUploadStatusCard,
  StyledUploadLabelCard,
} from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import { useForm } from '../../../../hooks/useForm'
import Button from '../../../../components/Button'
import { FactUploadDrawerFormProps } from './FactUploadDrawerForm.types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../global/styles/theme'
import { useHistory, useParams } from 'react-router-dom'
import { Params } from '../../../../global/types/params-def'
import { ProjectsAPI } from '../../../../global/api/APIMethods/ProjectsAPI'
import { svgDownloadSecond } from '../../../../img/customIcons/svgDownloadSecond'
import UploadFile from '../../../../components/_NEW/UploadFile'
import { ExcelParseError } from '../../../../global/types/commos-def'
import { socketChanel } from '../../../../global/api/socket'
import { getBase64 } from '../../../../global/functions'
import { successHandler } from '../../../../global/api/api'
import { CircularProgressPercent } from '../../../../components/_NEW/CircularProgressPercent/CircularProgressPercent'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

/* **************************************************************************** */

const FactUploadDrawerForm: React.FC<FactUploadDrawerFormProps> = ({
  onClose,
  onFormChange,
  projectName,
  factType,
  handleRefresh,
  connectWS,
  onErrorsListDrawerOpen,
  setUploaded,
  isUploaded,
  setFile,
  file,
  downloadsList,
  setProgressTime,
  setProgressPercent,
  progressPercent,
  progressTime,
  setCloseUpload,
  setErrorList,
  errorList,
  //socketChanel
}) => {
  // const isUploaded = 'canceled';//'canceling'

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('user')

  const { projectID, financeCenterID } = useParams() as Params

  const history = useHistory()

  const [uploadRecords, setUploadRecords] = useState<number | undefined>(0)

  useEffect(() => {
    if (isUploaded === 'success') setUploadRecords(downloadsList?.at(0)?.totalRecords)
  }, [downloadsList])

  const onUploadFile = useCallback(() => {
    if (socketChanel.readyState === 3) {
      console.log('onUploadFile-readyState: ', socketChanel.readyState)
    }
    if (file)
      getBase64(file).then((result) => {
        // @ts-ignore
        const base64String = result.replace('data:', '').replace(/^.+,/, '')
        const fileWS = {
          type: 'upload_excel_fact',
          id: projectID,
          bodyJson: JSON.stringify({
            param: {
              projectID: projectID,
              financeCenterID: financeCenterID,
              factType: factType,
            },
            fileBase64: base64String,
          }),
        }
        socketChanel.send(JSON.stringify(fileWS))
        setUploaded('loading')
      })
    socketChanel.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, код=${event.code} причина=${event.reason}`)
      } else {
        console.log('[socketChanel-close] Соединение прервано')
      }
    }
    socketChanel.onerror = function (error) {
      console.log(`[socketChanel-error]`, error)
    }
  }, [socketChanel, file, factType])

  socketChanel.onmessage = function (event) {
    let status = JSON.parse(event.data).type
    let syncId = JSON.parse(event.data).id

    if (status === 'upload_progress' && Number(projectID) === syncId) {
      setUploaded('loading')

      let bodyJson = JSON.parse(event.data).bodyJson
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress
      setProgressPercent!(Number(progress))
      let totalRecords = Number(JSON.parse(bodyJson).total)
      setUploadRecords!(totalRecords)
    }
    if (status === 'status_ok' && isUploaded === 'loading') {
      setUploaded('success')
      // setTime!('')
      handleRefresh()
      successHandler('Файл успешно загружен')
    }
    if (status === 'canceled') {
      setUploaded('canceled')
    }
    if (status === 'canceling') {
      setUploaded('canceling')
    }

    if (status === 'error') {
      let bodyJson = JSON.parse(event.data).bodyJson
      let msgError = JSON.parse(bodyJson).msg

      let formData = new FormData()
      formData.append('file', file)
      ProjectsAPI.uploadTemplateFact(projectID, factType, formData!)
        .then(({ success, error, errorList }) => {
          if (success) {
            handleRefresh()
            setUploaded('success')
          }
          if (!success) {
            setErrorList(errorList!)
            setUploaded('error')
          }
        })
        .catch((error) => {
          const errorCustom: ExcelParseError = {
            cell: '',
            col: 0,
            row: 0,
            message: String(error),
            type: 'invalid_cell_value',
          }
          setErrorList([errorCustom])
          setUploaded('error')
        })
    }
  }

  const initialValues: {} = useMemo(() => ({ files: null }), [])

  const onSubmit = useCallback(
    (values) => {
      onUploadFile()
      // handleUploadFact(file)
    },
    [factType, file],
  )

  const { formik, isSubmitDisabled } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const { values, setFieldValue, dirty, isValid } = formik

  console.log(dirty)
  console.log(values)

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const handleDownloadTemplate = () => {
    ProjectsAPI.downloadTemplateFact(projectName, Number(projectID), factType)
  }

  const onDrawerClose = useCallback(() => {
    switch (isUploaded) {
      case 'loading':
        onClose(true, false)
        setCloseUpload(true)
        break
      case 'start':
        onClose(dirty, !dirty)
        break
      case 'canceled':
        onClose(false, true)
        break
      case 'success':
        onClose(false, true)
        setUploaded('start')
        break
      case 'error':
        onClose(true, false)

        break
      default:
        break
    }
  }, [isUploaded, dirty])

  return (
    <StyledFactUploadDrawerFormWrapper sx={{ height: '100%' }}>
      <FormikProvider value={formik}>
        <Stack sx={{ height: '100%', justifyContent: 'space-between' }} component={Form} onSubmit={formik.handleSubmit}>
          <StyledUploadStatusContainer>
            {isUploaded === 'start' || isUploaded === 'error' ? (
              <UploadFile
                errorList={errorList}
                onErrorsListDrawerOpen={onErrorsListDrawerOpen}
                setFile={setFile}
                isUploaded={isUploaded}
                setUploaded={setUploaded}
              />
            ) : (
              <StyledUploadStatusCard className="StyledUploadStatusCard">
                {isUploaded === 'loading' && (
                  <CircularProgressPercent thickness={3.6} value={progressPercent!} time={progressTime!} />
                )}
                <StyledUploadLabelCard>
                  {isUploaded === 'canceled' && (
                    <Box sx={{ mt: '80px', mb: '75px' }}>
                      <WarningAmberOutlinedIcon
                        sx={{ fontSize: '48px', mt: '31px', color: theme.palette.error.main }}
                      />
                    </Box>
                  )}
                  {isUploaded === 'canceling' && <Box sx={{ mt: '80px', mb: '75px', height: '79px' }}></Box>}
                  <Box>
                    {isUploaded === 'success' && (
                      <CheckCircleIcon sx={{ mt: '74px', color: '#6FCCBC', fontSize: '48px' }} />
                    )}
                  </Box>
                  {isUploaded === 'canceled' && (
                    <Typography
                      variant="body2"
                      sx={{
                        justifyContent: 'center',
                        color: theme.palette.error.main,
                      }}
                    >
                      Загрузка отменена
                    </Typography>
                  )}
                  {isUploaded === 'canceling' && (
                    <Typography
                      variant="body2"
                      sx={{
                        justifyContent: 'center',
                        color: theme.palette.secondary.gray,
                      }}
                    >
                      Идет процесс отмены загрузки...
                    </Typography>
                  )}
                  {isUploaded === 'success' && (
                    <Typography mt={'20px'} variant="body2" align="center" sx={{ color: theme.palette.secondary.main }}>
                      Успешно обработано! <br />
                      Всего добавлено строк: {uploadRecords}
                    </Typography>
                  )}
                </StyledUploadLabelCard>
              </StyledUploadStatusCard>
            )}
            <StyledDownloadTemplate className={'style.awd_download'} onClick={handleDownloadTemplate}>
              {svgDownloadSecond}
              <Typography
                variant="h4"
                sx={{ marginLeft: '10px', textTransform: 'uppercase', color: theme.palette.primary.main }}
              >
                {factType === 'TRANSACTIONS' ? 'Бухгалтерские проводки (ШАБЛОН)' : 'Банковские выписки (ШАБЛОН)'}
              </Typography>
            </StyledDownloadTemplate>
          </StyledUploadStatusContainer>
          <Stack direction="row" spacing={2} style={{ width: '100%' }}>
            {isUploaded !== 'success' && (
              <>
                <Button
                  type="submit"
                  color="success"
                  size="medium"
                  fullWidth
                  disabled={!dirty || isUploaded == 'error' || isUploaded == 'loading' || isUploaded === 'canceled'}
                >
                  Обработать
                </Button>
                {isUploaded === 'loading' && (
                  <Button size="medium" disabled={!progressTime} fullWidth onClick={onDrawerClose}>
                    Отменить
                  </Button>
                )}
                {isUploaded !== 'loading' && (
                  <Button size="medium" fullWidth onClick={onDrawerClose}>
                    Закрыть
                  </Button>
                )}
              </>
            )}
            {isUploaded === 'success' && (
              <Button style={{ maxWidth: '100%' }} size="medium" color="success" fullWidth onClick={onDrawerClose}>
                Готово
              </Button>
            )}
          </Stack>
        </Stack>
      </FormikProvider>
    </StyledFactUploadDrawerFormWrapper>
  )
}

export default FactUploadDrawerForm
