import { SvgIcon } from '@mui/material'

export function ProjectsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M8.17 5.69995L1 10.48V21H6V13H10V21H15V10.25L8.17 5.69995Z" />
        <path d="M10 3V4.51L12 5.84L13.73 7H15V7.85L17 9.19V11H19V13H17V15H19V17H17V21H23V3H10ZM19 9H17V7H19V9Z" />
      </svg>
    </SvgIcon>
  )
}
