import styled from 'styled-components'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import DownloadingIcon from '@mui/icons-material/Downloading'

export const UploadExcelBtn = styled(ControlPanel.Btn)`
  height: 36px;
`

UploadExcelBtn.defaultProps = {
  startIcon: <DownloadingIcon />,
}
