export const truncatedTextStyles = {
  display: "block",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 84,
}

export const CELL_MAX_WIDTH_BY_REPORT_TYPE = {
  MONTHLY: 83,
  QUARTERLY: 133,
  YEARLY: 83,
}
