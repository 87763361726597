import styled from "styled-components"
import { Box, CardHeader, Typography } from "@mui/material"

export const StyledTypography = styled(Typography)`
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const StyledBox = styled(Box)`
  gap: 5px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
`
