import { Box, BoxProps, SvgIcon, Tooltip, Typography } from '@mui/material'
import { EENumber } from '../../editableElements/EENumber'
import { StyledTypography } from '../styles'
import { StyledBox } from './styles'

interface IProjectStatProps {
  title: string
  icon: React.ReactNode
  value?: number | string
  detailType?: 'string' | 'number'
  MUIBoxProps?: BoxProps
}

export function ProjectDetail(props: IProjectStatProps) {
  const { title, icon, value, detailType, MUIBoxProps } = props

  return (
    <StyledBox display="flex" p={0.5} {...MUIBoxProps}>
      <Box display="flex" alignItems="center">
        <SvgIcon sx={{ color: '#5C6E8C', mr: 1 }} fontSize="small">
          {icon}
        </SvgIcon>
        <Typography variant="body2" sx={{ color: '#5C6E8C', whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
      </Box>

      {value && detailType == 'number' ? (
        <StyledTypography textAlign="right" variant="body2" sx={{ maxWidth: '133px' }}>
          <EENumber
            name="ProjectDetail"
            mode="view"
            value={value}
            NumberFormatProps={{
              allowNegative: true,
              suffix: ' р.',
            }}
            viewModeStyle={{ whiteSpace: 'break-spaces' }}
          />
        </StyledTypography>
      ) : (
        <StyledTypography textAlign="right" variant="body2">
          {value}
        </StyledTypography>
      )}
    </StyledBox>
  )
}
