export const AddTableRowUpIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0205 9.69064H7.83301L10.7497 6.8335L13.6663 9.69064H11.4788V11.8335H10.0205V9.69064Z"
        fill="#5C6E8C"
      />
      <rect x="0.333008" y="0.166748" width="13.3333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="3.5" width="13.3333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="6.8335" width="5.83333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="10.1667" width="5.83333" height="1.66667" fill="#5C6E8C" />
    </svg>
  )
}
export const AddTableRowDownIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0205 8.97635H7.83301L10.7497 11.8335L13.6663 8.97635H11.4788V6.8335H10.0205V8.97635Z"
        fill="#5C6E8C"
      />
      <rect x="0.333008" y="0.166748" width="13.3333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="3.5" width="13.3333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="6.8335" width="5.83333" height="1.66667" fill="#5C6E8C" />
      <rect x="0.333008" y="10.1667" width="5.83333" height="1.66667" fill="#5C6E8C" />
    </svg>
  )
}
