import { Stack, Typography } from "@mui/material"
import { theme } from "global/styles/theme"
import styled from "styled-components"

export const TABLE_CELL_HEIGHT: number = 40

export const TOTAL_ROW_BACKGROUND_COLOR = "#D1DFF5"

export const BudgetTotalCellStyle = {
  color: theme.palette.primary.main,
  fontWeight: 500,
  textAlign: "center",
  height: "fit-content",
  fontSize: "14px",
}

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CellValue = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const BudgetCellWrapper = styled(Stack)`
  border-right: 1px solid #d1d8fa;
  border-bottom: 1px solid #d1d8fa;
`
