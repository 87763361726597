import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { IPaymentTemplate } from '../../../../api/template/types'
import { HelpPlug } from '../../../../components/HelpPlug/HelpPlug'
import { UserGuide } from '../../../../components/UserGuide/UserGuide'
import { StyledDataGrid } from '../../../../global/styles/StyledDataGrid'
import { IFinanceCenter } from '../../../../global/types/commos-def'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import PaymentTemplateDrawer from './components/PaymentTemplateDrawer'
import { PAYMENT_TEMPLATES_GUIDE_STEPS } from './components/PaymentTemplatesUserGuide'
import { PaymentTemplateTable } from './PaymentTemplateTable/PaymentTemplateTable'

interface IPaymentTemplatesPagePageState {
  formMode?: 'add' | 'edit'
  openForm?: boolean
  paymentTemplateId?: number | null
  isUserGuideOpen?: boolean
  paymentTemplates: IPaymentTemplate[]
  //formShow: boolean;
  isDrawerOpen: boolean
  addPaymentTemplate: (formShow: boolean) => void
  openUserGuide: () => void
  onEditPaymentTemplateClick: (paymentTemplateId: number) => void
  onDrawerClose: (dirty: boolean, immediately?: boolean) => void
}

export const PaymentTemplatesPage: React.FC<IPaymentTemplatesPagePageState> = ({
  paymentTemplates,
  openUserGuide,
  //formShow,
  addPaymentTemplate,
  onEditPaymentTemplateClick,
  onDrawerClose,
  paymentTemplateId,
  isDrawerOpen,
}) => {
  const [state, setState] = React.useState({
    formMode: 'add',
    openForm: false,
    paymentTemplateId: undefined,
    isUserGuideOpen: false,
  } as IPaymentTemplatesPagePageState)

  function handleOpenForm() {
    setState((v) => ({
      ...v,
      formMode: 'add',
      //  openForm: true,
      paymentTemplateId: undefined,
    }))
  }

  function openEditForm(id: number) {
    setState((v) => ({
      ...v,
      formMode: 'edit',
      //   openForm: true,
      paymentTemplateId: id,
    }))
  }

  function closeForm() {
    addPaymentTemplate(false)
    setState((v) => ({
      ...v,
      openForm: false,
      formShow: false,
      paymentTemplateId: undefined,
    }))
  }

  /**************************************************************************************/

  return (
    <>
      <PaymentTemplateTable
        paymentTemplates={paymentTemplates}
        openEditForm={openEditForm}
        onEditPaymentTemplateClick={onEditPaymentTemplateClick}
      ></PaymentTemplateTable>
      <PaymentTemplateDrawer
        openUserGuide={openUserGuide}
        addPaymentTemplate={addPaymentTemplate}
        open={isDrawerOpen}
        onClose={onDrawerClose}
        paymentTemplateId={paymentTemplateId!}
      />
    </>
  )
}

{
  /*  <StyledDataGrid
            rows={paymentTemplates}
            columns={columns}
            // onRowClick={(params) => onTomItemClick(params.row.id)}
            hideFooter
            headerHeight={40}
            rowHeight={40}
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            disableColumnMenu
          /> */
}
