import React from 'react'
import { ButtonData, EmptyPageData, EmptyPageProps } from './EmptyPage.types'
import { EmptyPageWrapper } from './styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import emptyImage from '../../assets/empty.svg'

export const getEmptyPageData = (text: React.ReactNode, buttons?: ButtonData[]): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick,
    })) || [],
})

export const EmptyPage: React.FC<EmptyPageProps> = ({ data, forFilter = false }) => {
  const { text, buttons } = data

  return (
    <EmptyPageWrapper spacing={forFilter ? 3 : 7.5} justifyContent="center" alignItems="center" forFilter>
      <Stack spacing={2.5} alignItems="center">
        <Typography variant="h2">{text}</Typography>
        <Stack direction="row" spacing={1.5}>
          {buttons.map((button) => (
            <Button startIcon={<button.icon />} onClick={() => button.onClick()} key={button.text}>
              {button.text}
            </Button>
          ))}
        </Stack>
      </Stack>

      <Box>
        <img src={emptyImage} width={630} height={400} />
      </Box>

      {/*{!forFilter*/}
      {/*  ? (*/}
      {/*    <Box>*/}
      {/*      <Typography variant='h1' color={theme.palette.primary.main}>Нужна помощь?</Typography>*/}
      {/*      <Typography variant='h1'>*/}
      {/*        <EmptyLink to='#'>Перейти в справочный центр </EmptyLink>*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  )*/}
      {/*  : null*/}
      {/*}*/}
    </EmptyPageWrapper>
  )
}
