import styled from "styled-components"
import { Stack, Box, TextField, Typography } from "@mui/material"
//import { ColorsBgLevel } from '../../../../../../types/global'

export const StyledHead = styled(Box)`
  background-color: #edeff3;
  position: sticky;
  top: 0;
  z-index: 1;
`
export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // height: 100%;
  // overflow: hidden;
  background: #fff;
  min-width: 855px;
`
export const StyledTableHead = styled(Box)`
  overflow: hidden;
  display: grid;
  grid-auto-flow: column dense;
  grid-auto-rows: 40px;
  grid-template-columns: minmax(185px, 100%) 80px repeat(3, 175px) 60px;
  grid-template-rows: 40px;
  grid-template-areas: ". . . . . . .";
  gap: 0px 0px;

  width: 100%;
  height: 100%;
  max-height: 40px;

  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledTableBody = styled(Box)`
  // overflow: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
    border-left: 1px solid rgba(0, 36, 95, 0.1);
  }
`

export const StyledTypography = styled(Typography)`
  color: #5c6e8c;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`
export const StyledHeadRow = styled(Stack)`
  align-items: flex-end;
  width: 100%;
  height: 100px;
`
export const StyledHeadTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
