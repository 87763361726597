import styled from "styled-components"
import { MenuItem } from "@mui/material"

export const StyledSelectMenuItem = styled(MenuItem)`
  max-width: 276px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }
`

export const DisabledSelectMenuItem = styled(StyledSelectMenuItem)`
  &.Mui-disabled {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`
