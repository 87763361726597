import styled from "styled-components"
import { Box, Stack } from "@mui/material"

export const ErrorsListDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`

export const ErrorsListDrawerTopBar = styled(Box)`
  padding: 20px 20px 15px;
  text-align: center;
`

export const ErrorsListEntireWrapper = styled(Box)`
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  /* position: relative;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @supports (-moz-appearance:none) {
    scrollbar-width: none;
  }

  & .MuiDivider-root {
    height: 1px;
  }*/
`

export const ErrorsListWrapper = styled(Stack)`
  overflow: hidden;
  height: 100%;
`

interface UploadResultsButtonWrapperProps {
  disabled: boolean
}

export const ErrorsListButtonWrapper = styled(Stack)<UploadResultsButtonWrapperProps>`
  position: sticky;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  background-color: ${(props) => props.theme.palette.bg.white};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  &:hover {
    background-color: ${(props) => props.theme.palette.bg.shades};
  }
  & .MuiSvgIcon-root {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }
`
