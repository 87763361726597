import { useContext, useEffect } from 'react'
import { InterfaceContext } from '../contexts/context.interface'

interface SearchData {
  searchValue: string
}

const useSearch = (): SearchData => {
  const interfaceCTX = useContext(InterfaceContext)

  const { searchValue } = interfaceCTX

  useEffect(() => {
    return () => {
      interfaceCTX.setShowSearch(false)
    }
  }, [])

  return {
    searchValue,
  }
}

export default useSearch
