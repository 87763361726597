import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material'
import {
  Box,
  Button,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getDeviationCellColor } from 'utils/getDeviationCellColor'
import { isElementOverflow } from 'utils/isElementOverflow'
import { ZeroSumReportRecordUnit } from '../../global/api/constructors'
import {
  IFinanceCenterTotalReportResponse,
  IReportQuery,
  IReportResponse,
  IReportStat,
} from '../../global/api/definitions'
import { getQueryParam, getReportValueName, getYearRange } from '../../global/functions'
import { scrollableInner, scrollableWrapper, table, tableCellInputSmall_ViewMode } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IOperationUnit,
  IReportRecord,
  TBudgetType,
  TOperationCategory,
  TReportType,
  periodByReportType,
  TMonthName,
  TQuarterName,
} from '../../global/types/commos-def'
import { IBdrPeriod } from '../../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { bdrParametersSelector } from '../../store/slices/bdrParameters/selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { findMatchingPeriod } from '../../utils/findMatchingPeriod'
import { BdrCalendarHeadings } from '../BdrCalendarHeadings/BdrCalendarHeadings'
import { CalendarHeadings } from '../CalendarHeadings/CalendarHeadings'
import { PercentBadge } from '../PercentBadge/PercentBadge'
import { EENumber } from '../editableElements/EENumber'
import { CELL_MAX_WIDTH_BY_REPORT_TYPE, truncatedTextStyles } from './Report.styles'
import { getReportRowStyleByLvl, getReportSaldoCellStyle } from './Report.utils'
import { useGetCompanyQuery } from '../../api/profile'

export const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'decimal',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

interface IReportRow {
  lvl: number
  maxDeep?: number
  record: IReportRecord
  globalLvl?: number
}

interface IReportProps extends TableProps {
  sx?: SxProps
  children: React.ReactNode | React.ReactNode[] | JSX.Element
  financeCenters?: number[] | string[]
}

interface IFullReportBodyProps {
  data: IReportResponse['data']
  globalLvl?: number
  maxDeep?: number
}

interface ISaldoProps {
  saldo: IFinanceCenterTotalReportResponse['saldo']
}

interface IReportStatsProps {
  stats: IReportStat[]
}

interface ILvlControlProps {
  disabled?: boolean
  onLvlChange: (lvl: number) => void
  maxDeep: number
}

export const Report = {
  Wrapper: function Wrapper(props: IReportProps) {
    const { children, sx } = props

    return (
      <Box mr={1}>
        <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 200px)', pb: 3, ...sx }}>
          <Table sx={scrollableInner} stickyHeader>
            {children}
          </Table>
        </TableContainer>
      </Box>
    )
  },
  Headings: function Headings() {
    const history = useHistory()
    const budgetType = getQueryParam(history, 'budgetType') as TBudgetType

    if (budgetType === 'bdr') {
      return (
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{ minWidth: '300px', textAlign: 'left', padding: theme.spacing(2) }}>
              Наименование статьи/субсчета
            </TableCell>
            <BdrCalendarHeadings />
          </TableRow>
          <BdrCalendarHeadings inner={true} />
        </TableHead>
      )
    } else {
      return (
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: '300px', textAlign: 'left', padding: theme.spacing(2) }}>
              Наименование статьи/субсчета
            </TableCell>
            <CalendarHeadings />
          </TableRow>
        </TableHead>
      )
    }
  },
  LvlControl: function LvlControl(props: ILvlControlProps) {
    const { maxDeep, onLvlChange, disabled } = props
    const lvlStorage = Number(localStorage.getItem('lvl'))

    const [state, setState] = React.useState({
      lvl: lvlStorage,
    })

    function goForward() {
      setState((prevState) => ({
        ...prevState,
        lvl: prevState.lvl != maxDeep ? prevState.lvl + 1 : prevState.lvl,
      }))
    }

    function goBack() {
      if (maxDeep > 1) {
        setState((prevState) => ({
          ...prevState,
          lvl: prevState.lvl != 1 ? prevState.lvl - 1 : prevState.lvl,
        }))
      }
    }

    function goToMaxDeep() {
      setState((prevState) => ({
        ...prevState,
        lvl: maxDeep,
      }))
    }

    function resetToFirstLvl() {
      setState((prevState) => ({
        ...prevState,
        lvl: 1,
      }))
    }

    React.useEffect(() => {
      onLvlChange(state.lvl)
      localStorage.setItem('lvl', String(state.lvl))
    }, [state.lvl])

    React.useEffect(() => {
      if (maxDeep === 0)
        setState((prevState) => ({
          ...prevState,
          lvl: 0,
        }))
    }, [])

    React.useEffect(() => {
      if (state.lvl < maxDeep)
        setState((prevState) => ({
          ...prevState,
          lvl: 1,
        }))
      if (state.lvl < maxDeep && state.lvl > 1)
        setState((prevState) => ({
          ...prevState,
          lvl: state.lvl,
        }))
    }, [state.lvl])

    React.useEffect(() => {
      if (state.lvl > maxDeep) {
        setState((prevState) => ({
          ...prevState,
          lvl: maxDeep,
        }))
        localStorage.setItem('lvl', String(maxDeep))
      }
    }, [state.lvl, maxDeep])

    return (
      <Box className="LvlControl" display="flex" alignItems="center">
        {/*<Box display="flex" alignItems="center">*/}
        {/*    <Button*/}
        {/*        size="small"*/}
        {/*        onClick={resetToFirstLvl}*/}
        {/*        disabled={state.lvl == 1 || disabled || maxDeep <= 1}*/}
        {/*    >*/}
        {/*        <FirstPage />*/}
        {/*    </Button>*/}
        {/*    <Button size="small" onClick={goBack} disabled={state.lvl == 1 || disabled || maxDeep <= 1}>*/}
        {/*        <KeyboardArrowLeft />*/}
        {/*    </Button>*/}
        {/*    <Box marginX={1}>*/}
        {/*        <Typography variant="body2">{'Уровень ' + state.lvl}</Typography>*/}
        {/*    </Box>*/}
        {/*    <Button size="small" onClick={goForward} disabled={state.lvl == maxDeep || disabled}>*/}
        {/*        <KeyboardArrowRight />*/}
        {/*    </Button>*/}
        {/*    <Button size="small" onClick={goToMaxDeep} disabled={state.lvl == maxDeep || disabled}>*/}
        {/*        <LastPage />*/}
        {/*    </Button>*/}
        {/*</Box>*/}
      </Box>
    )
  },
  ReportRow: function ReportRow(props: IReportRow) {
    const { t } = useTranslation('ENUMS')
    const { data: company } = useGetCompanyQuery()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { lvl, record, globalLvl, maxDeep } = props
    // const rowData = getAllUnitsWithZeroSum(
    //     Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IReportQuery,
    //     record.value.units
    // )
    const [hoveredCellIndex, setHoveredCellIndex] = useState<number | null>(null)

    const checkCellValueForOverflow = (index: number) => (e: any) => {
      isElementOverflow(e) && setHoveredCellIndex(index)
    }

    const closeTooltip = () => setHoveredCellIndex(null)

    const rowData = record.value.units

    const reportType = getQueryParam(history, 'reportType') as TReportType
    const category = getQueryParam(history, 'category') as TOperationCategory

    const eeNumberAlign = category === 'TOTAL' ? 'center' : 'right'

    const scaleKey = periodByReportType[reportType]

    const [state, setState] = React.useState({
      openChildren: lvl !== 0 ? false : true,
      isOpenByClick: false,
      isOpenByGlobalLvl: false,
    })

    let recursionCounter = lvl
    recursionCounter += 1

    React.useEffect(() => {
      globalLvl &&
        setState((prevState) => ({
          ...prevState,
          openChildren: globalLvl >= recursionCounter,
          isOpenByClick: false,
          isOpenByGlobalLvl: true,
        }))
    }, [globalLvl])

    return (
      <>
        {!record.isRoot && (
          <TableRow
            sx={getReportRowStyleByLvl(lvl, record, category)}
            onClick={(e) =>
              setState((prevState) => ({
                ...prevState,
                openChildren: !prevState.openChildren,
                isOpenByClick: true,
                isOpenByGlobalLvl: false,
              }))
            }
          >
            <>
              <TableCell
                style={{
                  fontWeight: 500,
                }}
              >
                {getReportValueName(record, t, company?.userCompanyName)}
              </TableCell>
              {rowData.map((unit, index) => {
                return (
                  <TableCell>
                    <Stack>
                      <Tooltip
                        open={hoveredCellIndex === index}
                        title={formatter.format(Number(unit.sum))}
                        placement={'top-start'}
                      >
                        <Stack justifyContent="center" alignItems="center">
                          <EENumber
                            align={eeNumberAlign}
                            mode={'view'}
                            name={unit.scale?.month || String(index)}
                            value={unit.sum}
                            NumberFormatProps={{
                              format: (value) => formatter.format(Number(value)),
                              onMouseEnter: checkCellValueForOverflow(index),
                              onMouseLeave: closeTooltip,
                            }}
                            viewModeStyle={{
                              ...tableCellInputSmall_ViewMode,
                              ...truncatedTextStyles,
                              maxWidth: CELL_MAX_WIDTH_BY_REPORT_TYPE[reportType],
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                )
              })}
            </>
          </TableRow>
        )}
        {state.openChildren &&
          record.children &&
          record.children.map((child) => {
            if (maxDeep && child.value.subBill && globalLvl && globalLvl < maxDeep && state.isOpenByGlobalLvl) {
              return null
            }
            return <Report.ReportRow maxDeep={maxDeep} globalLvl={globalLvl} lvl={recursionCounter} record={child} />
          })}
      </>
    )
  },
  Body: function Body(props: IFullReportBodyProps) {
    const { data, globalLvl, maxDeep } = props
    return (
      <TableBody>
        <Report.ReportRow maxDeep={maxDeep} globalLvl={globalLvl} lvl={0} record={data} />
      </TableBody>
    )
  },
  Saldo: function Saldo(props: ISaldoProps) {
    const history = useHistory()

    const { saldo } = props

    const reportType = getQueryParam(history, 'reportType') as TReportType

    const [hoveredCellIndex, setHoveredCellIndex] = useState<number | null>(null)

    const checkCellValueForOverflow = (index: number) => (e: any) => {
      isElementOverflow(e) && setHoveredCellIndex(index)
    }

    const closeTooltip = () => setHoveredCellIndex(null)

    return saldo && saldo.length > 0 ? (
      <TableFooter sx={{ ...table.footer.sticky, bottom: -24 }}>
        <TableRow>
          <TableCell colSpan={1} sx={getReportSaldoCellStyle('FIRST')}>
            <span style={{ textTransform: 'uppercase' }}>Сальдо</span>, руб
          </TableCell>
          {getAllUnitsWithZeroSum(
            Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IReportQuery,
            saldo,
          ).map((unit, index) => {
            const isLastCell = index === saldo.length - 1
            return (
              <TableCell key={index} sx={getReportSaldoCellStyle(isLastCell ? 'LAST' : 'DEFAULT')}>
                <Stack>
                  <Tooltip
                    open={hoveredCellIndex === index}
                    title={formatter.format(Number(unit.sum))}
                    placement={'top-start'}
                  >
                    <Stack justifyContent="center" alignItems="center">
                      <EENumber
                        align="center"
                        name={'totalSum'}
                        mode="view"
                        value={unit.sum}
                        viewModeStyle={{
                          ...tableCellInputSmall_ViewMode,
                          ...truncatedTextStyles,
                          maxWidth: CELL_MAX_WIDTH_BY_REPORT_TYPE[reportType],
                          textAlign: 'center',
                        }}
                        NumberFormatProps={{
                          format: (value) => formatter.format(Number(value)),
                          onMouseEnter: checkCellValueForOverflow(index),
                          onMouseLeave: closeTooltip,
                        }}
                      />
                    </Stack>
                  </Tooltip>
                </Stack>
              </TableCell>
            )
          })}
        </TableRow>
      </TableFooter>
    ) : null
  },
  Stats: function Stats(props: IReportStatsProps) {
    const history = useHistory()
    const bdrParameters = useTypedSelector(bdrParametersSelector)
    const { result, isResultShown, isPercentageShown, periods } = bdrParameters || {}

    const { stats } = props

    console.log('stats', stats)

    const { t } = useTranslation('ENUMS')

    const getStatsData = (stat: IReportStat) => {
      return getAllUnitsWithZeroSum(
        Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IReportQuery,
        stat.units,
      )
    }

    return stats ? (
      <TableFooter
        sx={{
          ...table.footer.sticky,
          '.MuiTableCell-root': {
            background: '#D1DFF5',
            boxShadow: 'inset 0px -1px 0px rgba(255, 255, 255, 0.3)',
            color: theme.palette.primary.main,
            fontWeight: 500,
            minHeight: 'auto',
            lineHeight: '14px !important',
            // padding: theme.spacing(0.75, 2)
          },
          '.MuiTableCell-root:first-of-type': {
            padding: theme.spacing(0.75, 2),
          },
        }}
      >
        {stats.length > 0 &&
          stats.map((stat) => {
            return (
              <TableRow>
                <TableCell colSpan={1}>{t('BDR_STATS.' + stat.title).toUpperCase()}</TableCell>
                {getStatsData(stat).map((unit, index) => {
                  const lastCell = getStatsData(stat).length === index + 1
                  const { plan, fact, deviation } = result || {}

                  let shownCellColumns = { plan: true, fact: true, deviation: true }
                  if (lastCell && !isResultShown) return null

                  if (!lastCell || !isResultShown) {
                    let matchingPeriod: IBdrPeriod | null = findMatchingPeriod(periods, index)

                    if (matchingPeriod) {
                      if (!matchingPeriod.plan) shownCellColumns.plan = false
                      if (!matchingPeriod.fact) shownCellColumns.fact = false
                      if (!matchingPeriod.deviation) shownCellColumns.deviation = false
                    }
                  }

                  return (
                    <Fragment key={index}>
                      {(!lastCell || plan) && shownCellColumns.plan && (
                        <TableCell>
                          <EENumber
                            align="right"
                            NumberFormatProps={{
                              format: (value) =>
                                formatter.format(Number(value)) +
                                (stat.title == 'rentability' || stat.title == 'marja' ? ' %' : ''),
                              // suffix: stat.title == "rentability" || stat.title == "marja" ? " %" : "",
                              allowNegative: stat.title == 'profit_before_tax' || stat.title == 'net_profit',
                            }}
                            name={'totalSum'}
                            mode="view"
                            value={unit.plannedAmount}
                            viewModeStyle={tableCellInputSmall_ViewMode}
                            // NumberFormatProps={{format: (value) => formatter.format(Number(value))}}
                          />
                        </TableCell>
                      )}
                      {(!lastCell || fact) && shownCellColumns.fact && (
                        <TableCell>
                          <EENumber
                            align="right"
                            NumberFormatProps={{
                              format: (value) =>
                                formatter.format(Number(value)) +
                                (stat.title == 'rentability' || stat.title == 'marja' ? ' %' : ''),
                              // suffix: stat.title == "rentability" || stat.title == "marja" ? " %" : "",
                              allowNegative: stat.title == 'profit_before_tax' || stat.title == 'net_profit',
                            }}
                            name={'totalSum'}
                            mode="view"
                            value={unit.actualAmount}
                            viewModeStyle={tableCellInputSmall_ViewMode}
                            // NumberFormatProps={{format: (value) => formatter.format(Number(value))}}
                          />
                        </TableCell>
                      )}
                      {(!lastCell || deviation) && shownCellColumns.deviation && (
                        <TableCell
                          style={{
                            color: getDeviationCellColor(unit.discrepancy),
                          }}
                        >
                          <Stack direction={'row'} alignItems={'center'} spacing={'2px'} justifyContent={'flex-end'}>
                            <EENumber
                              align="right"
                              NumberFormatProps={{
                                format: (value) =>
                                  formatter.format(Number(value)) +
                                  (stat.title == 'rentability' || stat.title == 'marja' ? ' %' : ''),
                                // suffix: stat.title == "rentability" || stat.title == "marja" ? " %" : "",
                                allowNegative: stat.title == 'profit_before_tax' || stat.title == 'net_profit',
                              }}
                              name={'totalSum'}
                              mode="view"
                              value={unit.discrepancy}
                              viewModeStyle={tableCellInputSmall_ViewMode}
                              // NumberFormatProps={{format: (value) => formatter.format(Number(value))}}
                            />
                            <PercentBadge
                              value={unit.growth}
                              isShow={
                                (stat.title == 'profit_before_tax' || stat.title == 'net_profit') && isPercentageShown
                              }
                            />
                          </Stack>
                        </TableCell>
                      )}
                    </Fragment>
                  )
                })}
              </TableRow>
            )
          })}
      </TableFooter>
    ) : null
  },
}

export function getAllUnitsWithZeroSum(query: IReportQuery, units: IReportRecord['value']['units']) {
  // let targetArr = [] as IOperationUnit[]
  // let zeroSumUnit = {} as IOperationUnit
  // let filteredRange = [] as any[]
  // let totalUnit = units.filter(unit => unit.type == "TOTAL")
  // switch (query.reportType) {
  //     case "YEARLY": filteredRange = getYearRange(query.yearStart, query.yearEnd); zeroSumUnit = new ZeroSumReportRecordUnit("YEAR"); break
  //     case "QUARTERLY": return units;
  //     case "MONTHLY": return units;
  // }
  //
  // filteredRange.forEach((year,i) => {
  //     if (units[i] && units[i].scale) {
  //         units[i]!.scale = {...units[i]!.scale,year};
  //     }else{
  //         units[i] = { ...units[i],scale: {
  //                 month: undefined,
  //                 quarter: undefined,
  //                 reportType: undefined,
  //                 year
  //             } }
  //     }
  // })
  // totalUnit[0] = {...totalUnit[0],scale:{}}
  // targetArr = filteredRange.map(rangeUnit => {
  //     let matchedUnit = units.filter(unit => unit.scale?.year == rangeUnit)
  //     return matchedUnit[0] || zeroSumUnit
  // })
  // return targetArr.concat(totalUnit)
  let targetArr = [] as IOperationUnit[]
  let zeroSumUnit = {} as IOperationUnit
  let filteredRange = [] as any[]
  let totalUnit = units.filter((unit) => unit.type == 'TOTAL')
  switch (query.reportType) {
    case 'YEARLY':
      filteredRange = getYearRange(query.yearStart, query.yearEnd)
      zeroSumUnit = new ZeroSumReportRecordUnit('YEAR')
      break
    case 'QUARTERLY':
      return units
    case 'MONTHLY':
      return units
  }

  targetArr = filteredRange.map((rangeUnit) => {
    let matchedUnit = units.filter((unit) => unit.scale?.year == rangeUnit)
    return matchedUnit[0]
  })

  return targetArr.concat(totalUnit).filter((item) => item)
}

// Если кварталы тоже не будут приходить

// function getAllUnitsWithZeroSum(query: IReportQuery, units: IReportRecord["value"]["units"]) {
//     let targetArr = [] as IOperationUnit[]
//     let zeroSumUnit = {} as IOperationUnit
//     let filteredRange = [] as any[]
//     let totalUnit = units.filter(unit => unit.type == "TOTAL")
//     switch (query.reportType) {
//         case "YEARLY": filteredRange = getYearRange(query.yearStart, query.yearEnd); zeroSumUnit = new ZeroSumReportRecordUnit("YEAR"); break
//         case "QUARTERLY": filteredRange = QUARTERS; zeroSumUnit = new ZeroSumReportRecordUnit("YEAR"); break;
//         case "MONTHLY": filteredRange = MONTHS; zeroSumUnit = new ZeroSumReportRecordUnit("YEAR"); break;;
//     }

//     targetArr = filteredRange.map(rangeUnit => {
//         let matchedUnit = {} as any
//         switch (query.reportType) {
//             case "YEARLY": matchedUnit = units.filter(unit => unit.scale?.year == rangeUnit); break
//             case "QUARTERLY": matchedUnit = units.filter(unit => unit.scale?.quarter == rangeUnit); break;
//             case "MONTHLY": matchedUnit = units.filter(unit => unit.scale?.month == rangeUnit); break;
//         }
//         // let matchedUnit = units.filter(unit => unit.scale?.year == rangeUnit)
//         return matchedUnit[0] || zeroSumUnit
//     })
//     return targetArr.concat(totalUnit)
// }
