import { Error } from '@mui/icons-material'
import { Box, TableRow, TableRowProps, Tooltip, Typography } from '@mui/material'
import React, { MutableRefObject, useRef } from 'react'
import { usePrevState } from '../../global/hooks'
import { theme } from '../../global/styles/theme'
import { TEEMode } from '../../global/types/commos-def'

export interface IValidationError {
  isValid: boolean
  message: string | undefined
}

export interface IValidationErrors {
  [key: string]: IValidationError
}

interface IEETableRowContext {
  validationErrors: IValidationErrors
  mode: TEEMode
}

interface IEETableRowContextValue {
  validationErrors: IValidationErrors
  mode: TEEMode
}

interface IEETableRowProvider {
  isEditDisabled?: boolean
  validation?: () => IValidationErrors
  children: React.ReactNode | React.ReactNode[]
  onSwitchMode: (prevMode: TEEMode, currentMode: TEEMode) => void
  onClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, contextValues: IEETableRowContextValue) => void
  onDoubleClick?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    switchMode: (mode: TEEMode) => void,
    value: IEETableRowContextValue,
  ) => void
  TableRowProps?: TableRowProps
  disableSwitchMode?: boolean
}

export const EETableRowContext = React.createContext({} as IEETableRowContext)
export function EETableRowProvider(props: IEETableRowProvider): JSX.Element {
  const {
    isEditDisabled,
    validation,
    children,
    onSwitchMode,
    onClick,
    onDoubleClick,
    TableRowProps,
    disableSwitchMode,
  } = props

  const [value, setValue] = React.useState({
    validationErrors: {},
    mode: 'view',
  } as IEETableRowContextValue)

  const prevMode = usePrevState(value.mode)

  const EETableRowRef = useRef() as MutableRefObject<HTMLTableRowElement>

  React.useEffect(() => {
    isRowValid() && value.mode == 'view' && onSwitchMode(prevMode as unknown as TEEMode, value.mode)

    if (value.mode == 'edit') {
      document.addEventListener('mousedown', switchModeOnMouseEventOutsideWrapper)
      document.addEventListener('keydown', switchModeOnMouseEventOutsideWrapper)
    }
    return () => {
      document.removeEventListener('mousedown', switchModeOnMouseEventOutsideWrapper)
      document.removeEventListener('keydown', switchModeOnMouseEventOutsideWrapper)
    }
  }, [value.mode, isRowValid()])

  React.useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      validationErrors: {
        ...prevValue.validationErrors,
        ...(validation && validation()),
      },
    }))
  }, [children])

  function switchMode(mode: TEEMode) {
    if (!isEditDisabled) {
      setValue((prevValue) => ({
        ...prevValue,
        mode: mode == 'view' ? 'edit' : 'view',
      }))
    }
  }

  function switchModeOnMouseEventOutsideWrapper(event: MouseEvent | KeyboardEvent) {
    let conditions = [
      EETableRowRef.current &&
        !EETableRowRef.current.contains(event.target as HTMLElement) &&
        event instanceof MouseEvent,
      event instanceof KeyboardEvent && event.key == 'Enter',
    ]

    if (conditions.includes(true)) {
      isRowValid() && switchMode(value.mode)
    }
  }

  function isRowValid() {
    let isValid = true
    for (let key in value.validationErrors) {
      if (value.validationErrors[key].isValid == false) {
        isValid = false
        break
      }
    }
    return isValid
  }

  function getErrorMessages() {
    let validationErrorsArr = [] as IValidationError['message'][]
    for (let key in value.validationErrors) {
      if (value.validationErrors[key].isValid == false) {
        validationErrorsArr = [...validationErrorsArr, value.validationErrors[key].message]
      }
    }
    return validationErrorsArr
  }

  return (
    <EETableRowContext.Provider
      value={{
        ...value,
      }}
    >
      <Tooltip
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              ml: 2,
              backgroundColor: theme.palette.error.main,
            },
            zIndex: 1,
          },
          anchorEl: EETableRowRef.current,
          disablePortal: true,
        }}
        title={
          isRowValid() ? (
            ''
          ) : (
            <Box display="flex" flexDirection="column" gap={1} p={0.5}>
              <Box display="flex" gap={1} alignItems="center">
                <Error fontSize="small" />
                <Typography>Ошибка</Typography>
              </Box>
              {getErrorMessages().map((message, index) => {
                return (
                  <Box>
                    {index + 1}. {message};
                  </Box>
                )
              })}
            </Box>
          )
        }
        placement="bottom-start"
      >
        <TableRow
          onDoubleClick={(e) => {
            onDoubleClick && onDoubleClick(e, switchMode, value)
            !onDoubleClick && !disableSwitchMode && switchMode(value.mode)
          }}
          ref={EETableRowRef}
          onClick={(e) => onClick && onClick(e, value)}
          {...TableRowProps}
          sx={{
            position: 'relative',
            '& .MuiTableCell-body': {
              // backgroundColor: value.mode == "edit" ? theme.palette.grey[200] : "white"
              backgroundColor: !isRowValid() ? '#fde1e1' : value.mode == 'edit' ? theme.palette.grey[200] : 'white',
            },
            ...TableRowProps?.sx,
          }}
        >
          {children}
        </TableRow>
      </Tooltip>
    </EETableRowContext.Provider>
  )
}
