import { Popper, PopperProps } from '@mui/material'

export function AutocompletePopper(props: PopperProps) {
  return (
    <Popper
      {...props}
      placement="bottom-start"
      sx={{
        '& .MuiAutocomplete-listbox': {
          maxHeight: '422px',
          minHeight: '390px',
        },
        zIndex: '3 !important',
        overflow: 'hidden',
        position: 'inherit !important',
        transform: 'none !important',
      }}
    >
      {props.children}
    </Popper>
  )
}
