import React from 'react'
import qs from 'qs'
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material'
import { IMappingPageQuerySchema, IMappingPageSubPageProps } from '../../MappingPage'
import { ReportAPI } from '../../../../global/api/APIMethods/ReportAPI'
import { useHistory, useLocation } from 'react-router-dom'
import { scrollableWrapper } from '../../../../global/styles/presets'
import {
  IBDDSArticle,
  IBDRArticle,
  IGostBill,
  IReportRelationshipItem,
  TBudgetType,
} from '../../../../global/types/commos-def'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../global/styles/theme'
import { HelpPlug } from '../../../../components/HelpPlug/HelpPlug'

interface ISearchLinksProps extends Partial<TableContainerProps>, IMappingPageSubPageProps {}

const defaultTableCellProps = {
  sx: {
    textAlign: 'left',
    cursor: 'pointer',
    ':hover': {
      background: theme.palette.bg.shades,
    },
  },
} as TableCellProps

interface ISearchLinksState {
  staticList: IReportRelationshipItem[] | null
  dynamicList: IReportRelationshipItem[] | null
  maxDeep: number[]
}

export function SearchArticles(props: ISearchLinksProps) {
  const { controlsState, setControlsState } = props

  const [state, setState] = React.useState({
    staticList: null,
    dynamicList: null,
    maxDeep: [],
  } as ISearchLinksState)

  const location = useLocation()

  const history = useHistory()

  const { t } = useTranslation('ENUMS')

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingPageQuerySchema

  let timeout: NodeJS.Timeout

  React.useEffect(() => {
    initiateData()
    return () => {
      resetData()
    }
  }, [queryParams.budgetType, location])

  React.useEffect(() => {
    state.staticList != null &&
      setControlsState((prevState) => ({
        ...prevState,
        search: {
          ...prevState.search,
          disabled: !state.staticList,
        },
      }))
  }, [state.staticList])

  React.useEffect(() => {
    timeout = setTimeout(() => {
      searchBySubBillName(controlsState.search.value)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [controlsState.search.value, queryParams.budgetType])

  React.useEffect(() => {
    console.log(state.dynamicList)
  }, [state.dynamicList])

  function searchBySubBillName(searchValue: string) {
    setState((prevState) => ({
      ...prevState,
      dynamicList:
        prevState.staticList?.filter((item) => item.subBill.name.toLowerCase().includes(searchValue.toLowerCase())) ||
        [],
    }))
  }

  function initiateData() {
    ReportAPI.getAllRelationships(queryParams.budgetType).then((data) => {
      setState((prevState) => ({
        ...prevState,
        staticList: data?.data,
        dynamicList: data?.data,
        maxDeep: Array.from(Array(data?.maxDeep).keys()),
      }))
    })
  }

  function resetData() {
    setControlsState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        value: '',
        disabled: true,
      },
    }))
    setState((prevState) => ({
      ...prevState,
      staticList: null,
      dynamicList: null,
      maxDeep: [],
    }))
  }

  function goToArticle(
    budgetType: TBudgetType,
    relationship: IReportRelationshipItem,
    chosenArticle?: IBDRArticle & IBDDSArticle,
    subBill?: boolean,
  ) {
    let targetQueryParams = {
      budgetType: budgetType,
      subPage: 'MappingArticles',
    } as IMappingPageQuerySchema

    let articleLvl = undefined

    relationship.articles.map((item, index) => {
      if (item.id == chosenArticle?.id) articleLvl = index
    })

    if (budgetType == 'bdr') {
      targetQueryParams.BDRArticleType = relationship.articles[0].type
    }

    if (articleLvl != undefined) {
      targetQueryParams.currentArticleID = String(relationship.articles[articleLvl].id)
    }

    if (subBill) {
      targetQueryParams.currentArticleID = String(relationship.articles[relationship.articles.length - 1].id)
    }

    history.push({
      pathname: `mappingNew`,
      search: qs.stringify(targetQueryParams),
    })
  }

  function goToGistBill(gostBillID: number) {
    history.push({
      pathname: `reference`,
      search: qs.stringify({
        subPage: 'Bills',
        gostBillID: gostBillID,
      }),
    })
  }

  return (
    <Box mr={3.5}>
      {!state.staticList && <CircularProgress sx={{ position: 'fixed', top: '50%', left: '50%' }} />}
      {state.staticList?.length == 0 ||
        (state.dynamicList?.length == 0 && controlsState.search.value != '' && (
          <HelpPlug.Wrapper height={'calc(100vh - 120px)'}>
            <HelpPlug.Title>Нет данных</HelpPlug.Title>
            <HelpPlug.Image />
          </HelpPlug.Wrapper>
        ))}
      {state.dynamicList && state.dynamicList.length > 0 && (
        <TableContainer sx={{ ...scrollableWrapper, ml: 2.5, pl: 0, height: 'calc(100vh - 140px)', pb: 0 }}>
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'left', minWidth: '530px' }}>Наименование субсчета</TableCell>
                <TableCell sx={{ textAlign: 'left', minWidth: '250px' }}>Наименование счета</TableCell>
                <TableCell sx={{ textAlign: 'left', minWidth: '250px' }}>Тип статьи</TableCell>
                <TableCell sx={{ textAlign: 'left', minWidth: '250px' }}>Наименование статьи</TableCell>
                {state.maxDeep.map((lvl) => {
                  if (lvl > (queryParams.budgetType == 'bdr' ? 0 : 1)) {
                    return (
                      <TableCell sx={{ textAlign: 'left', minWidth: '200px' }}>
                        Уровень {queryParams.budgetType == 'bdr' ? lvl : lvl - 1}
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.dynamicList?.map((relationShip) => {
                let emptyCells = []
                let emptyCell = <TableCell className="cell">---</TableCell>
                let emptyCellsCount = state.maxDeep.length - relationShip.deep
                for (let i = 0; i < emptyCellsCount; i++) {
                  emptyCells.push(emptyCell)
                }
                return (
                  <TableRow key={relationShip.subBill.id}>
                    <TableCell
                      {...defaultTableCellProps}
                      onClick={(e) => goToArticle(queryParams.budgetType, relationShip, undefined, true)}
                    >
                      {relationShip.subBill.name}
                    </TableCell>
                    <TableCell {...defaultTableCellProps} onClick={(e) => goToGistBill(relationShip.gostBill.id)}>
                      {relationShip.gostBill.number + '. ' + relationShip.gostBill.name}
                    </TableCell>
                    {queryParams.budgetType == 'bdr' && (
                      <TableCell
                        {...defaultTableCellProps}
                        onClick={(e) => goToArticle(queryParams.budgetType, relationShip)}
                      >
                        {t('BDR_ARTICLE_CATEGORY.' + relationShip.articles[0].type).toUpperCase()}
                      </TableCell>
                    )}
                    {relationShip.articles.map((article) => {
                      return (
                        <TableCell
                          {...defaultTableCellProps}
                          onClick={(e) => goToArticle(queryParams.budgetType, relationShip, article)}
                        >
                          {article.name}
                        </TableCell>
                      )
                    })}
                    {emptyCells}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}
