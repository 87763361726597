import React from 'react'
import { CircularProgress, Stack } from '@mui/material'

const Progress: React.FC = () => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  )
}

export default Progress
