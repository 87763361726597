import { Alert, AlertTitle, Box, Snackbar } from '@mui/material'
import { useContext } from 'react'
import { Header } from './components/Header/Header'
import { Navigation } from './components/Navigation/Navigation'
import { InterfaceContext } from './contexts/context.interface'
import { ActivePage } from './global/routing'
import { theme } from './global/styles/theme'

export function App() {
  const interfaceCTX = useContext(InterfaceContext)

  return (
    <Box
      className="app-wrapper"
      display={'flex'}
      alignItems={'flex-start'}
      sx={{
        backgroundColor: theme.palette.grey[100],
        width: '100vw',
        height: '100vh',
      }}
      // overflow={"auto"}
    >
      <Navigation></Navigation>
      <Box
        className="app-content"
        minWidth={1044}
        width="calc(100vw - 80px)"
        // minWidth="1200px"
        // overflow={"hidden"}
        // height="100%"
        // sx={{ minWidth: '1000px', display: 'flex', flexDirection: 'column' }}
        // ml="80px"
      >
        <Header></Header>
        {/* <Box> */}
        <ActivePage />
        {/* </Box> */}
      </Box>
      <Snackbar
        open={interfaceCTX.openSnackBar}
        autoHideDuration={3000}
        onClose={() => interfaceCTX.setOpenSnackBar(false, interfaceCTX.snackBarMessage)}
      >
        <Alert severity={interfaceCTX.snackBarMessage?.variant}>
          <AlertTitle>{interfaceCTX.snackBarMessage?.title}</AlertTitle>
          {interfaceCTX.snackBarMessage?.message}
        </Alert>
      </Snackbar>
      <Box id="snack"></Box>
    </Box>
  )
}
