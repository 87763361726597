import { EventNote } from '@mui/icons-material'
import { CircularProgress, MenuItem, Typography } from '@mui/material'
import { cloneDeep } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../../components/Form/Form'
import { SubBillAssign } from '../../../components/SubBillAssign/SubBillAssign'
import { AccountantAPI } from '../../../global/api/APIMethods/AccountantAPI'
import { ISubBillPair } from '../../../global/api/definitions'
import { STYLED, select } from '../../../global/styles/presets'
import {
  IBDDSArticle,
  IBDRArticle,
  IReportRelationshipItem,
  TControlRules,
  TFinanceCenterType,
} from '../../../global/types/commos-def'
import { BudgetTypeIcon } from '../../../img/customIcons/BudgetTypeIcon'
import { IBudgetFormData, IBudgetFormProps, IBudgetFormState, budgetFormDefaultValues } from './BudgetForm.types'
import { theme } from '../../../global/styles/theme'
import { ReportAPI } from '../../../global/api/APIMethods/ReportAPI'
import { useGetCompanyQuery } from '../../../api/profile'
import { IncomeBeforeTaxesIcon } from '../../../img/customIcons/IncomeBeforeTaxesIcon'
import { MappingArticlesAPI } from '../../../global/api/APIMethods/MappingArticlesAPI'

export function BudgetForm(props: IBudgetFormProps) {
  const { mode, project, values, isCapital, dirty, onSubmit, onDeleteClick, onCancel, setDirty } = props
  const [isFormLoading, setIsFormLoading] = useState(false)

  const { data: company } = useGetCompanyQuery()

  const { t } = useTranslation('ENUMS')

  const [billType, setBillType] = React.useState<IBDRArticle[] | IBDDSArticle[] | null>()

  const [state, setState] = React.useState<IBudgetFormState>({
    isProductionProgramChosen: false,
    formValues: null,
    subBills: null,
    subBillsFinance: null,
  })

  const methods = useForm({
    mode: 'all',
    defaultValues: cloneDeep(values) || (budgetFormDefaultValues as unknown as { [key: string]: string }),
  })

  // Below code simulate Asynchronous behavior while loading options, to await React rendering too many oprions. Solution is needed to fix bug with UI
  const [open, setOpen] = React.useState({
    genContract: false,
    subContract: false,
    genContractFinance: false,
    subContractFinance: false,
  })
  const [options, setOptions] = React.useState({
    genContract: [] as any[],
    subContract: [] as any[],
    genContractFinance: [] as any[],
    subContractFinance: [] as any[],
  })

  const loading = {
    genContract: open.genContract && options.genContract.length === 0,
    subContract: open.subContract && options.subContract.length === 0,
    genContractFinance: open.genContractFinance && options.genContractFinance.length === 0,
    subContractFinance: open.subContractFinance && options.subContractFinance.length === 0,
  }
  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay)
    })
  }

  React.useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      await sleep(1e3)

      if (active) {
        setOptions((prevState) => ({
          ...prevState,
          ...(loading.genContract && { genContract: [...getSubBillsOptions(state.subBills!)] }),
          ...(loading.subContract && { subContract: [...getSubBillsOptions(state.subBills!)] }),
          ...(loading.genContractFinance && { genContractFinance: [...getSubBillsOptions(state.subBillsFinance!)] }),
          ...(loading.subContractFinance && { subContractFinance: [...getSubBillsOptions(state.subBillsFinance!)] }),
        }))
      }
    })()

    return () => {
      active = false
    }
  }, [loading.genContract, loading.genContractFinance, loading.subContract, loading.subContractFinance])

  React.useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      genContract: open.genContract ? [] : prevState.genContract,
      subContract: open.subContract ? [] : prevState.subContract,
      genContractFinance: open.genContractFinance ? [] : prevState.genContractFinance,
      subContractFinance: open.subContractFinance ? [] : prevState.subContractFinance,
    }))
  }, [open.genContract, open.genContractFinance, open.subContract, open.subContractFinance])

  function openCloseAutocomplete(
    type: 'genContract' | 'subContract' | 'genContractFinance' | 'subContractFinance',
    open: boolean,
  ) {
    setOpen((prevState) => ({
      ...prevState,
      [type]: open,
    }))
  }
  // ==========================================

  React.useEffect(() => {
    state.formValues && changeIsProductionProgramChosen(state.formValues.type == 'PRODUCTION_PROGRAM')
  }, [state.formValues])

  React.useEffect(() => {
    Promise.all([
      AccountantAPI.getSubBillsPairs(isCapital ? 'INVEST' : 'GEN').then(({ data }) =>
        data.filter((item) => item.hasArticles),
      ),
      ReportAPI.getAllRelationships('bdds', project.type).then(({ data }) => data),
    ]).then((response) => {
      const [subBills, subBillsFinance] = response
      setState((prevState) => ({ ...prevState, subBills, subBillsFinance }))
    })
  }, [isCapital])

  React.useEffect(() => {
    const isEqual = JSON.stringify(values ?? budgetFormDefaultValues) === JSON.stringify(state.formValues)
    setDirty(!isEqual)
  }, [state.formValues])

  const validationRulesSchema = {
    name: {
      required: 'Обязательное поле',
      maxLength: {
        value: 80,
        message: 'Максимум 80 символов',
      },
      // minLength: {
      //     value: 5,
      //     message: 'Минимум 5 букв',
      // },
      validate: {
        test: (value: string) => {
          const reg = /^(?=(?:[^a-z^A-Z^а-я^А-Я^ёЁ^0-9^~]*[a-zA-Zа-яА-ЯёЁ0-9^~]){5,}[^a-z^A-Z^а-я^А-Я^ёЁ^~`]*$).{5,80}$/

          return reg.test(value) || 'Минимум 5 букв или цифр'
        },
      },
    },
    type: {
      required: 'Обязательное поле',
    },
    genContractSubBill: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          const localType: TFinanceCenterType | '' = localFormValues.type

          if (localType === 'PRODUCTION_PROGRAM' && !value) return 'Обязательное поле'
          else return true
        },
      },
    },
    subContractSubBill: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          const localType: TFinanceCenterType | '' = localFormValues.type

          if (localType === 'PRODUCTION_PROGRAM' && !value) return 'Обязательное поле'
          else return true
        },
      },
    },
    ecIncomeSubBillID: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          if (
            !value &&
            !localFormValues.ecExpenseSubBillID &&
            !localFormValues.finExpenseSubBillID &&
            !localFormValues.finIncomeSubBillID
          )
            return 'Заполните минимум один субсчет'

          methods.clearErrors('ecExpenseSubBillID')
          methods.clearErrors('finExpenseSubBillID')
          methods.clearErrors('finIncomeSubBillID')
          return true
        },
      },
    },
    ecExpenseSubBillID: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          if (
            !value &&
            !localFormValues.ecIncomeSubBillID &&
            !localFormValues.finExpenseSubBillID &&
            !localFormValues.finIncomeSubBillID
          )
            return 'Заполните минимум один субсчет'

          methods.clearErrors('ecIncomeSubBillID')
          methods.clearErrors('finExpenseSubBillID')
          methods.clearErrors('finIncomeSubBillID')
          return true
        },
      },
    },
    finIncomeSubBillID: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          if (
            (!value && !localFormValues.ecExpenseSubBillID && !localFormValues.ecIncomeSubBillID) ||
            !localFormValues.finExpenseSubBillID
            // !localFormValues.fiIncomeSubBillID
          )
            return 'Заполните минимум один субсчет'

          methods.clearErrors('ecExpenseSubBillID')
          methods.clearErrors('ecIncomeSubBillID')
          methods.clearErrors('finExpenseSubBillID')
          return true
        },
      },
    },
    finExpenseSubBillID: {
      validate: {
        test: (value: string, localFormValues: FieldValues) => {
          if (
            (!value && !localFormValues.ecExpenseSubBillID) ||
            !localFormValues.ecIncomeSubBillID ||
            !localFormValues.finIncomeSubBillID
          )
            return 'Заполните минимум один субсчет'

          methods.clearErrors('ecExpenseSubBillID')
          methods.clearErrors('ecIncomeSubBillID')
          methods.clearErrors('finIncomeSubBillID')
          return true
        },
      },
    },
    budgetYearStart: {
      required: 'Обязательное поле',
    },
    budgetYearEnd: {
      required: 'Обязательное поле',
    },
    notRequiredField: {
      validate: {
        test: () => true,
      },
    },
  } as { [key: string]: TControlRules }

  function onSubmitForm(data: any) {
    setIsFormLoading(true)
    onSubmit(data)
  }

  function getFinanceCenterTypeOptions(mode: 'add' | 'edit', budgetType?: TFinanceCenterType) {
    let types: TFinanceCenterType[] = []

    if (mode == 'add') {
      types = ['DEPARTMENT', 'SUBDIVISION', 'PRODUCTION_PROGRAM']
    } else {
      if (budgetType != 'PRODUCTION_PROGRAM') {
        types = ['DEPARTMENT', 'SUBDIVISION']
      } else {
        types = ['PRODUCTION_PROGRAM']
      }
    }

    return types.map((type: TFinanceCenterType) => {
      return (
        <MenuItem key={type} value={type}>
          {t('FINANCE_CENTER_TYPES.' + type)}
        </MenuItem>
      )
    })
  }

  function getYearOptions() {
    const currentYear = Number(project.dateStart.split('.')[2])
    const yearEnd = Number(project.dateEnd.split('.')[2])
    const yearList = []

    for (let i = 0; i <= yearEnd - currentYear; i++) {
      yearList.push(currentYear + i)
    }

    return yearList.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ))
  }

  function getFormValues(values: IBudgetFormData) {
    setState((prevState) => ({
      ...prevState,
      formValues: { ...values },
    }))
  }

  useEffect(() => {
    methods.setValue('budgetYearStart', project.dateStart.split('.')[2])
    methods.setValue('budgetYearEnd', project.dateEnd.split('.')[2])
  }, [])

  function changeIsProductionProgramChosen(newValue: boolean) {
    setState((prevState) => ({
      ...prevState,
      isProductionProgramChosen: newValue,
    }))
  }

  function getSubBillsOptions(subBillsFromServer: ISubBillPair[] | IReportRelationshipItem[]) {
    return (
      subBillsFromServer
        ?.map((pair) => {
          return {
            label: pair.subBill.name + '',
            value: pair.subBill.id,
            ...pair,
          }
        })
        .sort((a, b) => {
          if (a.gostBill.number > b.gostBill.number) {
            return 1
          } else if (a.gostBill.number == b.gostBill.number) {
            return 0
          } else {
            return -1
          }
        }) || []
    )
  }

  // React.useEffect(() => {
  //     if (!state.formValues) return

  //     const { type, incomeSubBillID, expenseSubBillID } = state.formValues

  //     if (type !== 'PRODUCTION_PROGRAM' && incomeSubBillID && expenseSubBillID) {
  //         methods.setValue('incomeSubBillID', undefined)
  //         methods.setValue('expenseSubBillID', undefined)
  //     }
  // }, [state.formValues])

  // function onBudgetTypeChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  //     const { type, incomeSubBillID, expenseSubBillID } = state.formValues!
  //     const currentBudgetType = type
  //     const nextBudgetType = event.target.value as TFinanceCenterType

  //     methods.setValue('type', nextBudgetType)

  //     if (nextBudgetType !== 'PRODUCTION_PROGRAM' && incomeSubBillID && expenseSubBillID) {
  //         methods.setValue('incomeSubBillID', undefined)
  //         methods.setValue('expenseSubBillID', undefined)
  //     }
  // }

  function onCancelClick(methods: UseFormReturn<FieldValues, any>) {
    onCancel && onCancel()
  }
  const isOnOfSelected = React.useMemo(() => {
    return (
      !!methods.getValues('ecIncomeSubBillID') ||
      !!methods.getValues('ecExpenseSubBillID') ||
      !!methods.getValues('finIncomeSubBillID') ||
      !!methods.getValues('finExpenseSubBillID')
    )
  }, [
    methods.getValues('ecIncomeSubBillID'),
    methods.getValues('ecExpenseSubBillID'),
    methods.getValues('finIncomeSubBillID'),
    methods.getValues('finExpenseSubBillID'),
  ])

  const isDisabledSubmitButton = useMemo(
    () =>
      isFormLoading ||
      !dirty ||
      (state.formValues?.type === 'PRODUCTION_PROGRAM' ? !methods.formState.isValid : !isOnOfSelected),
    [isFormLoading, dirty, methods.formState.isValid, isOnOfSelected, state.formValues?.type],
  )

  React.useEffect(() => {
    if (isCapital) {
      MappingArticlesAPI.getRootLvl('bdr', { budgetType: 'INVEST' }).then((response) => setBillType(response.data))
    }
  }, [props.isCapital])

  return (
    <Form.Wrapper
      methods={methods}
      onSubmit={onSubmitForm}
      defaultValues={values || (budgetFormDefaultValues as unknown as { [key: string]: string })}
      getValues={getFormValues}>
      <Form.ControlableTextField
        name="name"
        rules={validationRulesSchema.name}
        placeholder="Укажите краткое название бюджета"
        multiline={true}
        maxRows={2}
        sx={STYLED.TEXTFIELD.DRAWER_FORM}
      />
      <Form.FieldLabel title="Тип бюджета" icon={<BudgetTypeIcon />}>
        {/* {mode == "add" &&
                    <Form.ControlableSelect
                        name="type"
                        rules={validationRulesSchema.type}
                        sx={select.drawerForm}
                    >
                        {getFinanceCenterTypeOptions("add")}
                    </Form.ControlableSelect>
                } */}

        {/* {mode == "edit" && values?.type as TFinanceCenterType != "PRODUCTION_PROGRAM" && */}
        <Form.ControlableSelect
          name="type"
          rules={validationRulesSchema.type}
          selectPlaceholder={state?.formValues?.type ? '' : 'Тип бюджета'}
          sx={select.drawerForm}>
          {getFinanceCenterTypeOptions(mode, values?.type as TFinanceCenterType)}
        </Form.ControlableSelect>
        {/* } */}
      </Form.FieldLabel>

      {mode == 'add' && (
        <>
          <Form.FieldLabel title="Год начала" icon={<EventNote />}>
            <Form.ControlableSelect
              name="budgetYearStart"
              // rules={validationRulesSchema.budgetYearStart}
              // selectPlaceholder={state.formValues?.budgetYearStart ? '' : 'гггг'}
              sx={{ ...select.drawerForm, width: '100px' }}
              disabled={true}>
              {getYearOptions()}
            </Form.ControlableSelect>
          </Form.FieldLabel>
          <Form.FieldLabel title="Год окончания" icon={<EventNote />}>
            <Form.ControlableSelect
              name="budgetYearEnd"
              // rules={validationRulesSchema.budgetYearEnd}
              // selectPlaceholder={state.formValues?.budgetYearEnd ? '' : 'гггг'}
              sx={{ ...select.drawerForm, width: '100px' }}
              disabled={true}>
              {getYearOptions()}
            </Form.ControlableSelect>
          </Form.FieldLabel>
        </>
      )}
      {state.subBills ? (
        <>
          {isCapital && state.formValues?.type !== 'PRODUCTION_PROGRAM' && mode === 'add' && (
            <>
              <Form.FieldLabel title="Тип счета" icon={<IncomeBeforeTaxesIcon />}>
                <Form.ControlableSelect
                  name="billType"
                  selectPlaceholder={state?.formValues?.billType ? '' : 'Тип счета'}
                  rules={validationRulesSchema.type}
                  sx={select.drawerForm}>
                  {billType?.map((option) => {
                    return (
                      <MenuItem value={option.name} key={option.name}>
                        {option.name}
                      </MenuItem>
                    )
                  })}
                </Form.ControlableSelect>
                {/* } */}
              </Form.FieldLabel>
            </>
          )}

          {state.formValues?.type !== 'PRODUCTION_PROGRAM' && (
            <Typography variant={'subtitle2'} color={theme.palette.secondary.main}>
              Экономическая деятельность
            </Typography>
          )}
          {project.type === 'INVEST' && state?.formValues?.type !== 'PRODUCTION_PROGRAM'
            ? null
            : (company?.userCompanyName !== 'Черное море' || state?.formValues?.type === 'PRODUCTION_PROGRAM') &&
              project.type !== 'INVEST' && (
                <Form.ControlableAutocomplete
                  className="Form.ControlableAutocomplete"
                  name={state.formValues?.type === 'PRODUCTION_PROGRAM' ? 'genContractSubBill' : 'ecIncomeSubBillID'}
                  rules={
                    state.formValues?.type === 'PRODUCTION_PROGRAM'
                      ? validationRulesSchema.genContractSubBill
                      : validationRulesSchema.notRequiredField
                  }
                  options={options.genContract}
                  TextFieldProps={{
                    placeholder:
                      state.formValues?.type === 'PRODUCTION_PROGRAM'
                        ? 'Введите или выберите субсчет генподряда'
                        : 'Введите или выберите субсчет доходов',
                    sx: STYLED.AUTOCOMPLETE.DRAWER_FORM,
                  }}
                  AutocompleteProps={{
                    onChange: (event, value) => {
                      methods.setValue(
                        state.formValues?.type === 'PRODUCTION_PROGRAM' ? 'genContractSubBill' : 'ecIncomeSubBillID',
                        value,
                        { shouldValidate: true, shouldDirty: true },
                      )
                    },
                    renderOption: (props, option) => (
                      <SubBillAssign.AutocompleteOptionWrapper optionProps={props} key={option.subBill.id}>
                        <SubBillAssign.AutocompleteOptionItem width="100%">
                          {option.label}
                        </SubBillAssign.AutocompleteOptionItem>
                      </SubBillAssign.AutocompleteOptionWrapper>
                    ),
                    groupBy: (option) => option.gostBill.number,
                    PopperComponent: (props) => <SubBillAssign.AutocompletePopper {...props} sx={{ zIndex: 9 }} />,
                    PaperComponent: (props) => {
                      return (
                        <SubBillAssign.AutocompletePaper>
                          <SubBillAssign.AutocompleteOptionHeadings>
                            <SubBillAssign.AutocompleteOptionHeadingItem width="100%">
                              Наименование субсчета
                            </SubBillAssign.AutocompleteOptionHeadingItem>
                          </SubBillAssign.AutocompleteOptionHeadings>
                          {props.children}
                        </SubBillAssign.AutocompletePaper>
                      )
                    },
                    // The part of bugfix solution
                    loading: loading.genContract,
                    open: open.genContract,
                    onOpen: () => {
                      openCloseAutocomplete('genContract', true)
                    },
                    onClose: () => {
                      openCloseAutocomplete('genContract', false)
                    },
                    disabled:
                      isCapital && state.formValues?.type !== 'PRODUCTION_PROGRAM'
                        ? Boolean(!state.formValues?.type) || !state.formValues?.billType
                        : Boolean(!state.formValues?.type),
                    // ===========================
                  }}
                />
              )}

          <Form.ControlableAutocomplete
            // types = ['DEPARTMENT', 'SUBDIVISION', 'PRODUCTION_PROGRAM']
            name={state.formValues?.type === 'PRODUCTION_PROGRAM' ? 'subContractSubBill' : 'ecExpenseSubBillID'}
            rules={
              state.formValues?.type === 'PRODUCTION_PROGRAM'
                ? validationRulesSchema.subContractSubBill
                : validationRulesSchema.notRequiredField
            }
            options={options.subContract}
            TextFieldProps={{
              placeholder:
                state.formValues?.type === 'PRODUCTION_PROGRAM'
                  ? 'Введите или выберите субсчет затрат'
                  : 'Введите или выберите субсчет расходов',
              sx: { ...STYLED.AUTOCOMPLETE.DRAWER_FORM, zIndex: 1 },
            }}
            AutocompleteProps={{
              onChange: (event, value) => {
                methods.setValue(
                  state.formValues?.type === 'PRODUCTION_PROGRAM' ? 'subContractSubBill' : 'ecExpenseSubBillID',
                  value,
                  { shouldValidate: true, shouldDirty: true },
                )
              },
              noOptionsText: 'Нет данных',
              renderOption: (props, option) => (
                <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                  <SubBillAssign.AutocompleteOptionItem width="100%">
                    {option.label}
                  </SubBillAssign.AutocompleteOptionItem>
                </SubBillAssign.AutocompleteOptionWrapper>
              ),
              groupBy: (option) => option.gostBill.number,
              PopperComponent: (props) => <SubBillAssign.AutocompletePopper {...props} sx={{ zIndex: 9 }} />,
              PaperComponent: (props) => {
                return (
                  <SubBillAssign.AutocompletePaper>
                    <SubBillAssign.AutocompleteOptionHeadings>
                      <SubBillAssign.AutocompleteOptionHeadingItem width="100%">
                        Наименование субсчета
                      </SubBillAssign.AutocompleteOptionHeadingItem>
                    </SubBillAssign.AutocompleteOptionHeadings>
                    {props.children}
                  </SubBillAssign.AutocompletePaper>
                )
              },
              // The part of bugfix solution
              loading: loading.subContract,
              open: open.subContract,
              onOpen: () => {
                openCloseAutocomplete('subContract', true)
              },
              onClose: () => {
                openCloseAutocomplete('subContract', false)
              },
              disabled:
                isCapital && state.formValues?.type !== 'PRODUCTION_PROGRAM'
                  ? Boolean(!state.formValues?.type) || !state.formValues?.billType
                  : Boolean(!state.formValues?.type),
              // ===========================
            }}
          />

          {state.formValues?.type !== 'PRODUCTION_PROGRAM' && (
            <>
              <Typography variant={'subtitle2'} color={theme.palette.secondary.main}>
                Финансовая деятельность
              </Typography>
              <Form.ControlableAutocomplete
                className="Form.ControlableAutocomplete"
                name={'finIncomeSubBillID'}
                rules={validationRulesSchema.notRequiredField}
                // rules={
                //       validationRulesSchema.finIncomeSubBillID
                // }
                options={options.genContractFinance}
                TextFieldProps={{
                  placeholder: 'Введите или выберите субсчет доходов',
                  sx: STYLED.AUTOCOMPLETE.DRAWER_FORM,
                }}
                AutocompleteProps={{
                  onChange: (event, value) => {
                    methods.setValue('finIncomeSubBillID', value, { shouldValidate: true, shouldDirty: true })
                  },
                  renderOption: (props, option) => (
                    <SubBillAssign.AutocompleteOptionWrapper optionProps={props} key={option.subBill.id}>
                      <SubBillAssign.AutocompleteOptionItem width="100%">
                        {option.label}
                      </SubBillAssign.AutocompleteOptionItem>
                    </SubBillAssign.AutocompleteOptionWrapper>
                  ),
                  groupBy: (option) => option.gostBill.number,
                  PopperComponent: (props) => <SubBillAssign.AutocompletePopper {...props} sx={{ zIndex: 9 }} />,
                  PaperComponent: (props) => {
                    return (
                      <SubBillAssign.AutocompletePaper>
                        <SubBillAssign.AutocompleteOptionHeadings>
                          <SubBillAssign.AutocompleteOptionHeadingItem width="100%">
                            Наименование субсчета
                          </SubBillAssign.AutocompleteOptionHeadingItem>
                        </SubBillAssign.AutocompleteOptionHeadings>
                        {props.children}
                      </SubBillAssign.AutocompletePaper>
                    )
                  },
                  // The part of bugfix solution
                  loading: loading.genContractFinance,
                  open: open.genContractFinance,
                  // open: true,
                  onOpen: () => {
                    openCloseAutocomplete('genContractFinance', true)
                  },
                  onClose: () => {
                    openCloseAutocomplete('genContractFinance', false)
                  },
                  disabled: isCapital
                    ? Boolean(!state.formValues?.type) || !state.formValues?.billType
                    : Boolean(!state.formValues?.type),
                  // ===========================
                }}
              />
              <Form.ControlableAutocomplete
                // types = ['DEPARTMENT', 'SUBDIVISION', 'PRODUCTION_PROGRAM']
                name={'finExpenseSubBillID'}
                rules={validationRulesSchema.notRequiredField}
                // rules={
                //     validationRulesSchema.finExpenseSubBillID
                // }
                options={options.subContractFinance}
                TextFieldProps={{
                  placeholder: 'Введите или выберите субсчет расходов',
                  sx: { ...STYLED.AUTOCOMPLETE.DRAWER_FORM, zIndex: 1 },
                }}
                AutocompleteProps={{
                  onChange: (event, value) => {
                    methods.setValue('finExpenseSubBillID', value, { shouldValidate: true, shouldDirty: true })
                  },
                  noOptionsText: 'Нет данных',
                  renderOption: (props, option) => (
                    <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                      <SubBillAssign.AutocompleteOptionItem width="100%">
                        {option.label}
                      </SubBillAssign.AutocompleteOptionItem>
                    </SubBillAssign.AutocompleteOptionWrapper>
                  ),
                  groupBy: (option) => option.gostBill.number,
                  PopperComponent: (props) => <SubBillAssign.AutocompletePopper {...props} sx={{ zIndex: 9 }} />,
                  PaperComponent: (props) => {
                    return (
                      <SubBillAssign.AutocompletePaper>
                        <SubBillAssign.AutocompleteOptionHeadings>
                          <SubBillAssign.AutocompleteOptionHeadingItem width="100%">
                            Наименование субсчета
                          </SubBillAssign.AutocompleteOptionHeadingItem>
                        </SubBillAssign.AutocompleteOptionHeadings>
                        {props.children}
                      </SubBillAssign.AutocompletePaper>
                    )
                  },
                  // The part of bugfix solution
                  loading: loading.subContractFinance,
                  open: open.subContractFinance,
                  onOpen: () => {
                    openCloseAutocomplete('subContractFinance', true)
                  },
                  onClose: () => {
                    openCloseAutocomplete('subContractFinance', false)
                  },
                  disabled: isCapital
                    ? Boolean(!state.formValues?.type) || !state.formValues?.billType
                    : Boolean(!state.formValues?.type),
                  // ===========================
                }}
              />
            </>
          )}
        </>
      ) : (
        <CircularProgress />
      )}

      <Form.BtnGroup>
        <Form.SubmitButton BtnProps={{ disabled: isDisabledSubmitButton }} />
        <Form.CancelBtn onClick={onCancelClick} />
        {/* <Form.CancelBtn onClick={(methods, e) => onCancel && onCancel(methods)} /> */}
      </Form.BtnGroup>
      {/* <Form.Alert
                severity="warning"
                title="Удалить"
                message={<Box>Вы действительно хотите удалить бюджет? Это действие нельзя отменить.</Box>}
            /> */}
      {mode == 'edit' && (
        <Form.BtnGroup>
          <Form.DeleteBtn onClick={(methods) => onDeleteClick()} />
        </Form.BtnGroup>
      )}
    </Form.Wrapper>
  )
}
