import qs from 'qs'
import React, { UIEvent, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import {
  IPrPrEstimateOperation,
  IPrPrEstimateOperationWithLvl,
  IPrPrEstimateTotal,
  ProjectType,
} from '../../../global/types/commos-def'
import { useAgGridLastRowInView } from '../useGridScroll'

import { estimateWorkloadGenerateTable } from './../ProductionProgram.generateTable.Estimate'
import { StyledAgGrid } from '../ProdctionProgram.styles'
import { IProductionProgramState } from '../ProductionProgram-def'
import { EstimateTotal } from 'global/api/constructors'
import { GridApi, RowClassParams } from 'ag-grid-community'
import { ProductionProgramAPI } from 'global/api/APIMethods/ProductionProgramAPI'
import { getDeepCopy } from 'global/functions'
import { DeleteConfirmDialog } from 'components/Dialogs/DeleteConfirmDialog'
import { isDeepStrictEqual } from 'util'

interface IEstimateTableGridProps {
  estimateTableContext: any
  operationsLength: number
  isFetching: boolean
  isLoading: boolean
  hasNextPage: boolean
  fetchNextPage: () => void
  filteredRows: IPrPrEstimateOperationWithLvl[]
  total: IProductionProgramState['total']
  projectType: ProjectType
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
}

export const EstimateTableGrid = ({
  estimateTableContext,
  operationsLength,
  isFetching,
  hasNextPage,
  fetchNextPage,
  filteredRows,
  total,
  isLoading,
  projectType,
  setState,
}: IEstimateTableGridProps) => {
  const gridApiRef = useRef<GridApi | null>(null)

  const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(
    gridApiRef.current,
    filteredRows.length,
    '[grid-id="estimate-workload"] .ag-body-viewport',
  )
  const [rendering, setRendering] = useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [deletedOperation, setDeletedOperation] = useState<IPrPrEstimateOperationWithLvl | null>(null)
  const [outerLoading, setOuterLoading] = useState(false)
  // const [delta, setDelta] = useState<Partial<IPrPrEstimateTotal>>({
  //   genContractTotal: 0,
  //   subContractTotal: 0,
  //   income: 0,
  //   rentability: 0,
  // })

  useEffect(() => {
    if (!isFetching) {
      resetInView() // Сбросить только после того, как данные были успешно загружены
    }
  }, [isFetching])

  const shouldFetchMoreData = !isFetching && isLastRowInView && hasNextPage && !rendering

  useEffect(() => {
    if (!shouldFetchMoreData) return
    fetchNextPage()
  }, [shouldFetchMoreData])

  const getRowId = useCallback(({ data }) => {
    return data?.id.toString()
  }, [])

  useLayoutEffect(() => {
    if (isFetching || isLoading) {
      setRendering(true)
      if (gridApiRef.current) {
        gridApiRef.current.showLoadingOverlay()
      } else {
        setOuterLoading(true)
      }
    } else {
      if (filteredRows?.length) {
        setTimeout(() => {
          setRendering(false)
          gridApiRef.current?.hideOverlay()
        }, 500)
      } else {
        gridApiRef.current?.showNoRowsOverlay()
      }
    }
  }, [gridApiRef.current, isFetching, isLoading, filteredRows])

  const rowData = useMemo(() => {
    return filteredRows
  }, [filteredRows])

  const switchDeleteConfirm = (operation?: IPrPrEstimateOperationWithLvl | null) => {
    setDeletedOperation(operation || null)
    setIsDeleteConfirmOpen((prevState) => !prevState)
  }

  function deleteOperation() {
    if (deletedOperation) {
      if (gridApiRef.current) {
        const transaction = {
          remove: [deletedOperation],
        }
        gridApiRef.current.applyTransaction(transaction)
      }
      ProductionProgramAPI.deleteOperation(deletedOperation as IPrPrEstimateOperation).then(() => {
        setState((prevState) => {
          const operationIdToRemove = deletedOperation.id
          const newOperations = prevState.operations!.filter((operation) => operation.id !== operationIdToRemove)

          // Обновление hasChild для родительских операций
          const parentIds: number[] = deletedOperation.parentIds || []

          parentIds.forEach((parentId) => {
            // остались ли еще элементы у которых parentId как у удаленной строки
            const isStillHasChild = newOperations.some((op) =>
              (op as IPrPrEstimateOperationWithLvl).parentIds?.includes(parentId),
            )
            // находим род строку
            const parentIndex = newOperations.findIndex((op) => op.id === parentId)
            // находим оне есть и у нее изменилось свойство hasChild, меняем ее в массиве
            if (
              parentIndex !== -1 &&
              (newOperations[parentIndex] as IPrPrEstimateOperationWithLvl).hasChild !== isStillHasChild
            ) {
              newOperations[parentIndex] = {
                ...newOperations[parentIndex],
                hasChild: isStillHasChild,
              }
            }
          })

          return {
            ...prevState,
            operations: newOperations as IPrPrEstimateOperationWithLvl[],
          }
        })
      })
    }

    switchDeleteConfirm()
  }

  return (
    <>
      <DeleteConfirmDialog
        open={isDeleteConfirmOpen}
        onClose={() => switchDeleteConfirm()}
        onYes={() => deleteOperation()}
        onNo={() => switchDeleteConfirm()}
      />
      {outerLoading && <CircularProgress />}
      <StyledAgGrid
        gridId="estimate-workload"
        rowData={rowData}
        getRowId={getRowId}
        columnDefs={estimateWorkloadGenerateTable.columnDefs({
          total: total,
          projectType,
          switchDeleteConfirm,
        })}
        rowHeight={56}
        context={estimateTableContext}
        pinnedBottomRowData={[
          {
            name: 'ВСЕГО',
            id: 'totalRow',
            genContractUnitPrice: null,
            genContractPricePerHour: null,
            genContractTotal: (total as EstimateTotal)?.genContractTotal,
            subContractUnitPrice: null,
            subContractPricePerHour: (total as EstimateTotal)?.subContractPricePerHour,
            subContractContractor: null,
            subContractTotal: (total as EstimateTotal)?.subContractTotal,
            humanHoursQuantity: (total as EstimateTotal)?.humanHoursQuantity,
            machineryHoursQuantity: (total as EstimateTotal)?.machineryHoursQuantity,
            income: (total as EstimateTotal)?.income,
            incomePerHour: (total as EstimateTotal)?.incomePerHour,
            quantity: null,
            rentability: (total as EstimateTotal)?.rentability,
          },
        ]}
        suppressScrollOnNewData={true}
        suppressColumnMoveAnimation={true}
        loadingOverlayComponent={CircularProgress}
        noRowsOverlayComponent={() => <div></div>}
        onGridReady={(params) => {
          setOuterLoading(false)
          gridApiRef.current = params.api // Сохраняем ссылку на GridApi
          // Теперь вы можете использовать gridApiRef.current для доступа к API сетки
        }}
        rowClassRules={{
          'row-error': (params) =>
            (params.data as unknown as IPrPrEstimateOperationWithLvl).hasChild === false &&
            (params.api.getValue('name', params.node) === null ||
              params.api.getValue('name', params.node) === '' ||
              params.api.getValue('unitType', params.node) === null ||
              params.api.getValue('unitType', params.node) === ''),
        }}
        singleClickEdit
        onCellEditingStopped={(event) => {
          const operation = event.data as IPrPrEstimateOperationWithLvl
          const oldOperation = filteredRows.find((el) => el.id === operation.id)
          if (
            (!operation.hasChild && (!operation.name || !operation.unitType)) ||
            JSON.stringify(operation) === JSON.stringify(oldOperation)
          ) {
            // if (event.rowIndex !== null) {
            //   if (!operation.name) {
            //     event.api.startEditingCell({ rowIndex: event.rowIndex, colKey: 'name' })
            //   } else {
            //     event.api.startEditingCell({ rowIndex: event.rowIndex, colKey: 'unitType' })
            //   }
            // }
            return
          }
          ProductionProgramAPI.updateOperation(operation as IPrPrEstimateOperation).then((data) => {
            setState((prevState) => {
              let operationsCopy: IPrPrEstimateOperationWithLvl[] =
                prevState.operations && getDeepCopy(prevState.operations)
              let operationIndex = operationsCopy.findIndex((el) => el.id === operation.id)
              operationsCopy[operationIndex] = { ...operation }
              return {
                ...prevState,
                operations: operationsCopy,
                total: data.newTotal,
              }
            })
          })
        }}
      />
    </>
  )
}
