import { useCallback, useLayoutEffect, useRef } from 'react'
import { useImmer } from 'use-immer'

export const useLatest = <Value>(value: Value) => {
  const valueRef = useRef(value)

  useLayoutEffect(() => {
    valueRef.current = value
  })

  return valueRef
}

export const useHiddenRows = () => {
  const [hiddenRows, setHiddenRows] = useImmer<number[]>([])

  const actualHiddenRows = useLatest(hiddenRows)
  const isRowHidden = (id: number) => {
    return Boolean(actualHiddenRows.current.includes(id))
  }
  const update = useCallback(
    (id: number) => () => {
      setHiddenRows((draft) => {
        const index = draft.indexOf(id)
        if (index >= 0) {
          draft.splice(index, 1)
          return
        }
        draft.push(id)
      })
    },
    [setHiddenRows],
  )

  const setNewData = (data: number[]) => {
    setHiddenRows(data)
  }

  const reset = () => {
    setHiddenRows([])
  }

  return {
    hiddenRows,
    reset,
    isRowHidden,
    update,
    setNewData,
  }
}
