import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Fragment, useCallback, useEffect } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import style from './EmptyProjectStart.module.scss'

import {
  DragWrapper,
  StyledErrorBox,
  StyledReloadButton,
  StyledShowErrorButton,
  UploadText,
  UploadTitle,
  UploadWrapper,
} from './style'

import { theme } from '../../../global/styles/theme'
import { FieldHookConfig, useField } from 'formik'
import { useDropzone } from 'react-dropzone'
import Progress from '../../Progress'
import DoneIcon from '@mui/icons-material/Done'
import { Box, CircularProgress, Typography } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { ExcelParseError, UploadType } from '../../../global/types/commos-def'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Params } from '../../../global/types/params-def'
import { errorHandler, errorUploadHandler } from '../../../global/api/api'

export interface UploadFileProps {
  fieldProps?: Partial<FieldHookConfig<File[] | null>>
  isLoading?: boolean
  errorList?: ExcelParseError[] | null
  onErrorsListDrawerOpen?: (errorList: ExcelParseError[]) => void
  setFile?: (value: File) => void
  setUploaded: (value: UploadType) => void
  isUploaded: UploadType
}

const UploadFile: React.FC<UploadFileProps> = ({
  setFile,
  fieldProps,
  errorList,
  onErrorsListDrawerOpen,
  isUploaded,
  setUploaded,
}) => {
  // console.log('errorList-UploadFile: ', errorList);
  // console.log('isUploaded-UploadFile: ', isUploaded);

  const { t } = useTranslation('files')
  const { enqueueSnackbar } = useSnackbar()
  const { projectID, financeCenterID } = useParams() as Params

  const [{ value: formValue }, { touched, error }, { setValue, setTouched, setError }] = useField({
    name: 'files',
    ...fieldProps,
  })

  const onDrop = useCallback(
    (files: File[]) => {
      setTouched(true)
      let formData = new FormData()
      formData.append('file', files[0])
      setFile!(files[0])
      if (files?.length) {
        setValue(files)
      }
      // setUploaded!('start');
    },
    [setValue, setFile],
  )

  function onDropRejected() {
    const error = {
      message: 'Тип файла не является .xls или .xlsx ',
    }
    errorUploadHandler(error, 'upload')
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  })

  const onReloadFile = () => {
    setValue(null)
    setUploaded!('start')
    setTouched(false)
    // setFile!({} as File);
  }

  useEffect(() => {
    if (isUploaded === 'start') {
      setValue(null)
      setTouched(false)
    }
  }, [isUploaded])

  return (
    <main className={style.main_awd}>
      <Fragment>
        <div className={style.awd_container}>
          <div className={style.awd_loader}>
            {isUploaded === 'error' ? (
              <StyledErrorBox>
                <WarningAmberOutlinedIcon
                  sx={{
                    width: '51px',
                    height: '48px',
                    mt: '30px',
                    marginTop: errorList ? '30px' : '80px',
                    marginBottom: errorList ? '' : '75px',
                  }}
                />
                <Typography variant="body2" sx={{ mt: '30px' }}>
                  Произошла ошибка при загрузке файла!
                </Typography>
                {errorList && errorList!.length > 0 && (
                  <>
                    <Typography variant="subtitle2">Всего строк с ошибками {errorList!.length}</Typography>
                    <StyledShowErrorButton
                      onClick={() => {
                        if (onErrorsListDrawerOpen) onErrorsListDrawerOpen(errorList!)
                      }}
                      disableRipple
                    >
                      Посмотреть ошибки
                    </StyledShowErrorButton>
                    <StyledReloadButton startIcon={<SyncIcon />} onClick={onReloadFile} disableRipple>
                      {' '}
                      загрузить еще раз
                    </StyledReloadButton>
                  </>
                )}
              </StyledErrorBox>
            ) : (
              <DragWrapper drag={isDragActive} {...getRootProps()}>
                <input {...getInputProps()} style={{ display: 'none' }} />
                <UploadWrapper
                  className="UploadWrapper"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  isPointer
                  sx={{ position: 'relative' }}
                >
                  {!formValue && isUploaded === 'start' && (
                    <>
                      <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.secondary.gray }} />
                      <UploadTitle variant="body2">загрузить xls(х)</UploadTitle>
                      <UploadText variant="body2">
                        Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту
                        область.
                      </UploadText>
                    </>
                  )}
                  {formValue && isUploaded !== 'loading' && (
                    <>
                      <UploadTitle variant="body2">Файл добавлен!</UploadTitle>
                      <UploadText variant="body2">{formValue[0].name}</UploadText>
                    </>
                  )}
                  {isUploaded === 'loading' && (
                    <>
                      <UploadTitle variant="body2">Файл загружается...</UploadTitle>
                      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <CircularProgress sx={{ position: 'static' }} />
                      </Box>
                    </>
                  )}
                  {isUploaded === 'success' && (
                    <>
                      <DoneIcon sx={{ color: '#6FCCBC', fontSize: '47px' }} />
                      <UploadTitle variant="body2">Файл загружен!</UploadTitle>
                    </>
                  )}
                </UploadWrapper>
              </DragWrapper>
            )}
          </div>
        </div>
      </Fragment>
    </main>
  )
}
export default UploadFile
