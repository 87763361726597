import { Typography } from '@mui/material'
import { useMemo } from 'react'
import { ICondition } from '../../../../../../api/template/types'
import { StyledBox, StyledBoxPercent, StyledCondition } from './styles'
import { theme } from '../../../../../../global/styles/theme'

interface IConditionProps {
  condition: ICondition
}

export const Condition: React.FC<IConditionProps> = ({ condition }) => {
  const { percent, offset } = condition

  const month = useMemo(() => {
    switch (true) {
      case offset == 0:
        return 'месяцев'
      case offset == 1 || offset == -1:
        return `месяц`
      case (offset > 1 && offset <= 4) || (offset < -1 && offset >= -4):
        return `месяца`
      case offset >= 5 || offset <= -5:
        return `месяцев`
      default:
        break
    }
  }, [offset])

  return (
    <StyledCondition sx={{}}>
      <StyledBox>
        <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
          {percent}%
        </Typography>
      </StyledBox>
      <StyledBox sx={{}}>
        {offset > 0 && '+'} {offset} {month}
      </StyledBox>
    </StyledCondition>
  )
}
