import styled from "styled-components"
import { Box, Drawer, Stack } from "@mui/material"

export const StyledUploadDrawer = styled(Drawer)``

export const UploadDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`

export const UploadDrawerTopBar = styled(Box)`
  padding: 20px 20px 18px;
  display: flex;
  justify-content: center;
`
