import React, { useCallback, useEffect, useMemo } from 'react'
import {
  StyledUploadDrawerFormWrapper,
  StyledDownloadTemplate,
  StyledUploadStatusContainer,
  StyledUploadStatusCard,
  StyledUploadLabelCard,
} from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import { useForm } from '../../../../hooks/useForm'
import Button from '../../../../components/Button'
import { UploadDrawerFormProps } from './UploadDrawerForm.types'
import { theme } from '../../../../global/styles/theme'
import { ProductionProgramAPI } from '../../../../global/api/APIMethods/ProductionProgramAPI'
import { useHistory, useParams } from 'react-router-dom'
import { Params } from '../../../../global/types/params-def'
import { svgDownloadSecond } from '../../../../img/customIcons/svgDownloadSecond'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { changeQueryParams, getBase64 } from '../../../../global/functions'
import { socketChanel } from '../../../../global/api/socket'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UploadFile from '../../../../components/_NEW/UploadFile'
import { CircularProgressPercent } from '../../../../components/_NEW/CircularProgressPercent/CircularProgressPercent'

//let socketChanel: WebSocket;

const UploadDrawerForm: React.FC<UploadDrawerFormProps> = ({
  onClose,
  onFormChange,
  financeCenter,
  handleRefresh,
  onErrorsListDrawerOpen,
  setFile,
  file,
  setUploaded,
  isUploaded,
  setProgressTime,
  progressTime,
  progressPercent,
  setProgressPercent,
  uploadRecords,
  setUploadRecords,
  errorList,
  setErrorList,
}) => {
  const history = useHistory()

  const { name: financeCenterName } = financeCenter
  const { projectID, financeCenterID } = useParams() as Params

  const auth = JSON.parse(localStorage.getItem('auth') || '{}')

  const onUploadFile = useCallback(() => {
    if (socketChanel.readyState === 3) {
      console.log('socketChanel.readyState: ', socketChanel.readyState)
      //socketChanel.close();
      // connectWS(setIsLoginSocket);
      handleRefresh()
    }

    if (socketChanel.readyState === 1) {
      getBase64(file).then((result) => {
        // @ts-ignore
        const base64String = result.replace('data:', '').replace(/^.+,/, '')
        const fileWS = {
          type: 'upload_excel_program',
          id: financeCenterID,
          bodyJson: JSON.stringify({
            param: {
              projectID: projectID,
              financeCenterID: financeCenterID,
            },
            fileBase64: base64String,
          }),
        }
        socketChanel!.send(JSON.stringify(fileWS))
        setUploaded('loading')
      })
    }
    socketChanel!.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, код=${event.code} причина=${event.reason}`)
      } else {
        console.log('onUploadFile-[socketChanel-close] Соединение прервано')
        setUploaded('start')
      }
    }
    socketChanel!.onerror = function (error) {
      console.log(`[socketChanel-error]`, error)
    }
  }, [socketChanel, file])

  socketChanel.onmessage = function (event) {
    setUploaded('loading')
    let status = JSON.parse(event.data).type
    let bodyJson = JSON.parse(event.data).bodyJson
    let syncId = JSON.parse(event.data).id

    if (status === 'upload_progress' && Number(financeCenterID) === syncId) {
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress
      setProgressPercent!(Number(progress))
      let totalRecords = Number(JSON.parse(bodyJson).total)
      setUploadRecords!(totalRecords)
    }

    if (status === 'status_ok') {
      setUploaded('success')
      handleRefresh()
      changeQueryParams(history, {
        offset: 0,
        limit: 100,
      })
    }
    if (status === 'canceled') {
      setUploaded('canceled')
      setProgressTime!('')
      setProgressPercent!(0)
    }
    if (status === 'canceling') {
      setUploaded('canceling')
    }
    if (status === 'error') {
      let msg = JSON.parse(bodyJson).msg
      let formData = new FormData()
      formData.append('file', file)
      ProductionProgramAPI.uploadTemplateProductionProgram(financeCenterID, projectID, formData!).then(
        ({ success, error, errorList }) => {
          setErrorList(errorList!)
          setUploaded('error')
        },
      )
    }
  }

  socketChanel.onclose = function (event) {
    if (event.wasClean) {
      console.log(`[socketChanel-close] Соединение закрыто, код=${event.code} причина=${event.reason}`)
    } else {
      console.log('[socketChanel-close] Соединение прервано')
    }
  }

  socketChanel.onerror = function (error) {
    console.log(`[socketChanel-error]`, error)
  }

  const initialValues: {} = useMemo(() => ({}), [])

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      //onSubmit(values);
    },
  })

  const { values, setFieldValue, dirty, isValid, touched } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const handleDownloadTemplate = () => {
    ProductionProgramAPI.downloadTemplateProductionProgram(financeCenterName)
  }

  const onDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      switch (isUploaded) {
        case 'loading':
        case 'error':
          onClose(true, false)
          break
        case 'start':
          onClose(dirty, !dirty)
          break
        case 'canceled':
          onClose(false, true)
          setProgressPercent!(0)
          break
        case 'success':
          onClose(false, true)
          setUploaded('start')
          setProgressPercent!(0)
          break
        default:
          break
      }
    },
    [isUploaded, dirty],
  )

  return (
    <StyledUploadDrawerFormWrapper sx={{ height: '100%' }}>
      <FormikProvider value={formik}>
        <Stack
          sx={{ height: '100%', justifyContent: 'space-between' }}
          component={Form}
          onSubmit={formik.handleSubmit}
          name="upload"
        >
          <StyledUploadStatusContainer>
            {isUploaded === 'start' || isUploaded === 'error' ? (
              <UploadFile
                isUploaded={isUploaded}
                setUploaded={setUploaded}
                setFile={setFile}
                onErrorsListDrawerOpen={onErrorsListDrawerOpen}
                errorList={errorList}
              />
            ) : (
              <StyledUploadStatusCard>
                {isUploaded === 'loading' && (
                  <CircularProgressPercent thickness={3.6} value={progressPercent!} time={progressTime!} />
                )}
                {isUploaded !== 'loading' && (
                  <StyledUploadLabelCard>
                    {isUploaded === 'canceled' && (
                      <Box sx={{ mt: '80px', mb: '75px' }}>
                        <WarningAmberOutlinedIcon
                          sx={{ fontSize: '48px', mt: '31px', color: theme.palette.error.main }}
                        />
                      </Box>
                    )}
                    {isUploaded === 'canceling' && <Box sx={{ mt: '80px', mb: '75px', height: '48px' }}></Box>}
                    <Box>
                      {isUploaded === 'success' && (
                        <CheckCircleIcon sx={{ mt: '74px', color: '#6FCCBC', fontSize: '48px' }} />
                      )}
                    </Box>
                    {isUploaded === 'canceled' && (
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: '10px',
                          mt: '30px',
                          justifyContent: 'center',
                          color: theme.palette.error.main,
                        }}
                      >
                        Загрузка отменена
                      </Typography>
                    )}
                    {isUploaded === 'canceling' && (
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: '10px',
                          mt: '70px',
                          justifyContent: 'center',
                          color: theme.palette.secondary.gray,
                        }}
                      >
                        Идет процесс отмены загрузки...
                      </Typography>
                    )}
                    {isUploaded === 'success' && (
                      <Typography
                        mt={'20px'}
                        variant="body2"
                        align="center"
                        sx={{ color: theme.palette.secondary.main }}
                      >
                        Успешно обработано! <br />
                        Всего добавлено строк: {uploadRecords}
                      </Typography>
                    )}
                  </StyledUploadLabelCard>
                )}
              </StyledUploadStatusCard>
            )}
            <StyledDownloadTemplate className={'style.awd_download'} onClick={handleDownloadTemplate}>
              {svgDownloadSecond}
              <Typography
                variant="h4"
                sx={{ marginLeft: '10px', textTransform: 'uppercase', color: theme.palette.primary.main }}
              >
                скачать форму отчета
              </Typography>
            </StyledDownloadTemplate>
          </StyledUploadStatusContainer>
          <Stack direction="row" spacing={2} style={{ width: '100%', marginTop: '20px' }}>
            {isUploaded !== 'success' ? (
              <>
                <Button
                  onClick={onUploadFile}
                  type="submit"
                  color="success"
                  size="medium"
                  fullWidth
                  disabled={
                    !dirty ||
                    isUploaded === 'canceling' ||
                    isUploaded == 'error' ||
                    isUploaded == 'loading' ||
                    isUploaded === 'canceled'
                  }
                >
                  Обработать
                </Button>
                {isUploaded === 'loading' && (
                  <Button
                    size="medium"
                    fullWidth
                    onClick={() => {
                      onClose(true, false)
                    }}
                  >
                    Отменить
                  </Button>
                )}
                {isUploaded !== 'loading' && (
                  <Button
                    disabled={isUploaded === 'canceling'}
                    size="medium"
                    fullWidth
                    onClick={() => {
                      onDrawerClose(dirty)
                    }}
                  >
                    Закрыть
                  </Button>
                )}
              </>
            ) : (
              <Button
                style={{ maxWidth: '100%' }}
                size="medium"
                color="success"
                fullWidth
                onClick={() => {
                  onDrawerClose(false, true)
                }}
              >
                Готово
              </Button>
            )}
          </Stack>
        </Stack>
      </FormikProvider>
    </StyledUploadDrawerFormWrapper>
  )
}

export default UploadDrawerForm
