import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { AccountFormProps } from './AccountForm.types'
import { useTranslation } from 'react-i18next'
import AvatarFieldForm from '../../../../../../components/AvatarFieldForm/AvatarFieldForm'
import FieldForm from '../../../../../../components/FieldForm/FieldForm'

const AccountForm: React.FC<AccountFormProps> = ({ name, isEditUser }) => {
  const { t } = useTranslation('profile')

  return (
    <Box>
      <Stack direction="row" spacing={2.5} mb={2.5} minHeight="auto">
        <AvatarFieldForm
          name="avatar"
          needCrop
          isEditUser={isEditUser}
          placeholder={
            <Typography alignItems="center" textAlign="center" px={2} variant="body2">
              Нажмите чтобы загрузить Ваше фото
            </Typography>
          }
        />
        <Stack flex={1} justifyContent="space-between">
          <FieldForm
            version="profile"
            name="lastName"
            title={t('lastName')}
            placeholder={t('placeholders.lastName')}
            disabled={isEditUser}
          />
          <FieldForm
            version="profile"
            name="firstName"
            title={t('firstName')}
            placeholder={t('placeholders.firstName')}
            disabled={isEditUser}
          />
          <FieldForm
            version="profile"
            name="middleName"
            title={t('middleName')}
            placeholder={t('placeholders.notRequired')}
            disabled={isEditUser}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <FieldForm
          version="profile"
          name="companyName"
          title={t('companyName')}
          placeholder={(name && `${name} `) + `${t('placeholders.notRequired')}`}
          disabled={isEditUser}
        />
        <FieldForm
          version="profile"
          name="position"
          title={t('position')}
          placeholder={t('placeholders.position')}
          disabled={isEditUser}
        />
      </Stack>
    </Box>
  )
}

export default AccountForm
