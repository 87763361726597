import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { changeQueryParams, getQueryParam } from '../../../../../global/functions'
import { DATA } from './BudgetTypeSelect.constants'
import { styles } from './BudgetTypeSelect.styles'
import { IBudgetTypeSelectProps } from './BudgetTypeSelect.types'
import { useGetCompanyQuery } from '../../../../../api/profile'

export const BudgetTypeSelect: React.FC<IBudgetTypeSelectProps> = ({ isSubBillAssignMode, financeCenter, project }) => {
  const history = useHistory()

  const { data: company } = useGetCompanyQuery()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let category: any = 'INCOME'
    if (e.target.value === 'bdr') {
      if (financeCenter?.incomeSubBill) {
        category = 'INCOME'
      } else if (financeCenter?.expenseSubBill) {
        category = 'WAGE_FUND'
      } else {
        category = 'TOTAL'
      }
      if (company?.userCompanyName === 'Черное море' || project?.type === 'INVEST') {
        if (financeCenter?.expenseSubBill) {
          category = 'OPERATING_EXPENSES'
        } else {
          category = 'TOTAL'
        }
      }
    } else if (e.target.value === 'bdds') {
      if (financeCenter?.finIncomeSubBill) {
        category = 'INCOME'
      } else if (financeCenter?.finExpenseSubBill) {
        category = 'PAYMENTS'
      } else {
        category = 'TOTAL'
      }
    }
    changeQueryParams(history, {
      budgetType: e.target.value,
      category,
      reportType: 'MONTHLY',
      monthStart:
        getQueryParam(history, 'reportType') === 'QUARTERLY'
          ? 'JANUARY'
          : getQueryParam(history, 'monthStart') || 'JANUARY',
      monthEnd:
        getQueryParam(history, 'reportType') === 'QUARTERLY'
          ? 'DECEMBER'
          : getQueryParam(history, 'monthEnd') || 'DECEMBER',
    })
  }

  const data = isSubBillAssignMode ? DATA.slice(0, 2) : DATA

  return (
    <TextField
      select
      defaultValue={getQueryParam(history, 'budgetType')}
      onChange={handleChange}
      sx={styles}
      // disabled={state.isSubBillAssignMode}
    >
      {data.map((v) => (
        <MenuItem key={v.value} value={v.value}>
          {v.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
