import styled from "styled-components"
import { theme } from "../../../../../../global/styles/theme"
import { PhoneFieldForm } from "../PhoneFieldForm"

export const StyledPhoneFieldForm = styled(PhoneFieldForm)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiFilledInput-input {
    padding: 10px 16px;
    color: ${(props) => props.theme.palette.secondary.main};
    text-overflow: ellipsis;
    border-radius: 8px;
  }

  & .MuiFilledInput-input::placeholder {
    color: ${theme.palette.disabled!.main};
  }
`
