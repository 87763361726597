import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
import { theme } from '../../../../../../global/styles/theme'
import { Add, CheckBox } from '@mui/icons-material'
import React from 'react'
import { useDidMountEffect } from '../../../../../../hooks/useDidMountEffect'

export interface ICheckBoxListItem {
  label: string
  id: number | string | undefined | null
}

export interface ICheckBoxListItemProps {
  isGroupSelected: boolean
  CheckBoxProps?: CheckboxProps
  listItem: ICheckBoxListItem
  onSwitchCheckedState: (checked: boolean, listItem: ICheckBoxListItem) => void
}

export function CheckBoxListItem(props: ICheckBoxListItemProps) {
  const { CheckBoxProps, listItem, isGroupSelected, onSwitchCheckedState } = props

  const [state, setState] = React.useState({
    checked: isGroupSelected,
  })

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      checked: isGroupSelected,
    }))
  }, [isGroupSelected])

  useDidMountEffect(() => {
    onSwitchCheckedState(state.checked, listItem)
  }, [state.checked])

  return (
    <FormControlLabel
      key={listItem.id}
      checked={state.checked}
      control={
        <Checkbox
          icon={<Add fontSize="small" />}
          checked={state.checked}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              checked: e.target.checked,
            }))
          }}
          {...CheckBoxProps}
        />
      }
      label={listItem.label || ''}
      sx={{
        marginBottom: 1,
        color: theme.palette.primary.main,
        svg: { color: theme.palette.primary.main, mr: 0 },
        '.MuiTypography-root': {
          textAlign: 'left',
          ...theme.typography.subtitle2,
          color: theme.palette.text.primary,
          fontWeight: 400,
        },
        '.MuiButtonBase-root': {},
      }}
    />
  )
}
