import { Download, List } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ControlPanel } from '../../../../../components/ControlPanel/ControlPanel'
import { BudgetAPI } from '../../../../../global/api/APIMethods/BudgetAPI'
import { IFinanceCenter, IProjectInfo } from '../../../../../global/types/commos-def'
import { IncomeBeforeTaxesIcon } from '../../../../../img/customIcons/IncomeBeforeTaxesIcon'
import { UploadExcelBtn } from '../../../BudgetPageNew.styles'
import { IBudgetButtonsProps } from './BudgetButtons.types'

export const BudgetButtons: React.FC<IBudgetButtonsProps> = ({
  category,
  budgetType,
  project,
  financeCenter,
  isSubBillAssignMode,
  isDownloadDisabled,
  isCopyButtonDisabled,
  onUploadDrawerOpen,
  // createOperation,
  switchSubBillAssignMode,
  openProlongationForm,
  onCopyDialogOpen,
}) => {
  const history = useHistory()

  const isProlongationAvailable = (project: IProjectInfo, financeCenter: IFinanceCenter) => {
    if (!financeCenter.budgetYears) return false
    let projectStartYear = Number(project.dateStart.split('.')[2])
    let projectEndYear = Number(project.dateEnd.split('.')[2])
    let financeCenterLastYear = financeCenter.budgetYears[financeCenter.budgetYears.length - 1]
    // console.log(projectEndYear, financeCenterLastYear)
    if (financeCenterLastYear > projectEndYear) {
      return false
    }
    if (projectStartYear !== projectEndYear && financeCenterLastYear !== Number(projectEndYear)) {
      return true
    }
    return false
    // return  (projectStartYear != projectEndYear && financeCenterLastYear != Number(projectEndYear)) || projectEndYear < financeCenterLastYear
  }

  return (
    <ControlPanel.InnerContainer
      align="left"
      sx={{
        gap: '12px',
        mr: 0,
        pb: '10px',
        '@media (max-width: 1860px)': {
          flexWrap: 'wrap', // Пример изменения свойства
          rowGap: '7px',
          justifyContent: 'flex-end',
        },
      }}>
      {category !== 'TOTAL' && budgetType === 'bdr' && (
        <ControlPanel.Btn
          startIcon={<ContentCopyIcon />}
          onClick={onCopyDialogOpen}
          sx={{
            borderRadius: '6px',
          }}
          disabled={isCopyButtonDisabled}>
          Скопировать бюджет
        </ControlPanel.Btn>
      )}

      {category !== 'TOTAL' && budgetType === 'bdr' && (
        <UploadExcelBtn onClick={onUploadDrawerOpen}>Загрузить .XLS</UploadExcelBtn>
      )}
      {/* {category !== 'TOTAL' && (
                <ControlPanel.IconBtn
                    onClick={createOperation}
                    // disabled={!isRangeDefault(state.period)}
                >
                    <AddIcon />
                </ControlPanel.IconBtn>
            )} */}
      <ControlPanel.Btn
        startIcon={<Download />}
        onClick={(e) => {
          financeCenter &&
            BudgetAPI.getExcelReport(Object.fromEntries(new URLSearchParams(history.location.search)), financeCenter)
        }}
        sx={{
          borderRadius: '6px',
        }}
        disabled={isDownloadDisabled}>
        Скачать
      </ControlPanel.Btn>
      {category !== 'TOTAL' && (
        <ControlPanel.Btn startIcon={<List />} onClick={switchSubBillAssignMode}>
          Разнести статьи
        </ControlPanel.Btn>
      )}
      {project && financeCenter && (
        <ControlPanel.Btn
          startIcon={<IncomeBeforeTaxesIcon />}
          disabled={isProlongationAvailable(project, financeCenter) === false}
          onClick={(e) => openProlongationForm(true)}
          sx={{
            textTransform: 'none',
            fontWeight: 400,
            fontSize: 16,
            borderRadius: '6px',
          }}>
          Продлить бюджет
        </ControlPanel.Btn>
      )}
    </ControlPanel.InnerContainer>
  )
}
