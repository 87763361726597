import { Alert, AlertProps, AlertTitle, Snackbar, SnackbarCloseReason, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ISnackProps extends Partial<AlertProps> {
  randomKey: number
  error?: AxiosError | any
  message?: string
  type?: string
}

export function Snack(props: ISnackProps) {
  const { error, severity, title, message, randomKey, type } = props

  const [state, setState] = React.useState({
    open: false,
    message: message,
  })

  const { t } = useTranslation('common')

  React.useEffect(() => {
    openSnack()
  }, [randomKey])

  function getErrorMessage(error: AxiosError | any) {
    console.log('getErrorMessage: ', error)
    if (error && error?.status) {
      switch (error?.status) {
        case 404:
          return 'Нет такого url'
        case 401:
          return 'Вы не авторизованы'
        case 500:
          return error?.data || 'Внутренняя ошибка сервера'
      }
    } else {
      switch (error?.message || error?.status) {
        case error?.message:
          return `${error?.message}`
        case error?.status:
          return `${error}`
      }

      return 'Ошибка запроса к серверу. Обратитесь к администратору'
    }
    return error?.message
  }

  function getErrorUploadMessage(error: AxiosError | any) {
    console.log('error-getErrorUploadMessage:', error)
    if (error && error?.status) {
      switch (error?.status) {
        case 404:
          return 'Нет такого url'
        case 401:
          return 'Вы не авторизованы'
        case 500:
          return 'Внутренняя ошибка сервера'
      }
    } else {
      switch (error?.message || error?.status) {
        case error?.message:
          return `${error?.message}`
        case error?.status:
          return `${error}`
      }

      return 'Ошибка запроса к серверу. Обратитесь к администратору'
    }
    return error.message
  }

  function closeSnack(event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) {
    if (reason == 'clickaway') {
      // if (error) {
      //     return
      // } else {
      //     setState((prevState) => ({
      //         ...prevState,
      //         open: false,
      //     }))
      // }
    } else {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }))
    }
  }

  function openSnack() {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }))
  }

  return state.open ? (
    <Snackbar open={state.open} autoHideDuration={1500} onClose={closeSnack}>
      <Alert sx={{ display: 'flex', alignItems: 'center' }} severity={error ? 'error' : severity}>
        <AlertTitle>
          {error ? 'Ошибка' : title}
          {console.log(error)}
        </AlertTitle>
        <Typography variant="body2" maxWidth={'400px'}>
          {type === 'upload'
            ? severity == 'error'
              ? getErrorUploadMessage(error)
              : message
            : severity == 'error'
            ? getErrorMessage(error)
            : message}
        </Typography>
      </Alert>
    </Snackbar>
  ) : null
}
