import { Add as AddIcon } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { HelpPlug } from 'components/HelpPlug/HelpPlug'
import { FC, useState } from 'react'
import { FormatSelectionDialog } from '../FormatSelectionDialog'
import { IBudgetStartPageProps, TBudgetDialog } from './BudgetStartPage.types'
import { getQueryParam } from '../../../../global/functions'
import { useHistory } from 'react-router-dom'
import { TBudgetType } from '../../../../global/types/commos-def'

export const BudgetStartPage: FC<IBudgetStartPageProps> = ({
  availableYears,
  createOperation,
  fillingMode,
  onCopyDialogOpen,
}) => {
  const [openedDialog, setOpenedDialog] = useState<TBudgetDialog | null>(null)

  const history = useHistory()

  const budgetType = getQueryParam(history, 'budgetType') as TBudgetType

  const onAddClick = () => {
    if (fillingMode === 'UNDEFINED') return openDialog('formatSelection')
    else createOperation(fillingMode)
  }

  const openDialog = (type: TBudgetDialog) => {
    setOpenedDialog(type)
  }

  const closeDialog = () => {
    setOpenedDialog(null)
  }

  const isCopyButtonDisabled = () => {
    if (fillingMode === 'UNDEFINED') return true
    if ((availableYears?.length ?? 0) <= 1) return true
    return false
  }

  return (
    <>
      <HelpPlug.Wrapper
        sx={{
          height: 'calc(100vh - 195px)',
        }}
      >
        <HelpPlug.Title sx={{ mb: 2.5 }}>Нет данных</HelpPlug.Title>
        <HelpPlug.Btn sx={{ mb: 2.5 }} onClick={onAddClick} startIcon={<AddIcon />}>
          Добавить
        </HelpPlug.Btn>
        {budgetType !== 'bdds' && (
          <HelpPlug.Btn
            sx={{ mb: 2.5 }}
            onClick={onCopyDialogOpen}
            startIcon={<ContentCopyIcon />}
            disabled={isCopyButtonDisabled()}
          >
            Скопировать бюджет
          </HelpPlug.Btn>
        )}
        <HelpPlug.Image />
      </HelpPlug.Wrapper>
      <FormatSelectionDialog
        isOpen={openedDialog === 'formatSelection'}
        onSubmit={createOperation}
        onClose={closeDialog}
      />
    </>
  )
}
