import { useFormContext } from 'react-hook-form'
import { Fragment } from 'react'
import { UploadText, UploadTitle } from 'components/_NEW/UploadFile/style'

export function FileChosen() {
  const methods = useFormContext()

  return (
    <Fragment>
      <UploadTitle variant="body2">Файл добавлен</UploadTitle>
      <UploadText variant="body2">{methods && methods!.getValues('file')?.name}</UploadText>
    </Fragment>
  )
}
