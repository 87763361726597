import { api } from '../api'
import {
  CreatePaymentTemplateRequest,
  CreatePaymentTemplateResponse,
  GetPaymentTemplateRequest,
  TGetListPaymentTemplateResponse,
  GetPaymentTemplateByIdResponse,
  EditPaymentTemplateResponse,
  EditPaymentTemplateRequest,
} from './types'

export const paymentTemplatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentTemplates: build.query<TGetListPaymentTemplateResponse, void>({
      query: () => ({
        url: `/payment-template/get-list`,
        method: 'GET',
      }),
      // providesTags: ['PaymentTemplates'],
      providesTags: ['PaymentTemplates', { type: 'PaymentTemplates', id: 'DELETE' }],
    }),
    createPaymentTemplate: build.mutation<CreatePaymentTemplateResponse, CreatePaymentTemplateRequest>({
      query: ({ ...body }) => {
        return {
          url: `/payment-template/create`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['PaymentTemplates'],
    }),
    // /payment-template/{templateID}
    getPaymentTemplateById: build.query<GetPaymentTemplateByIdResponse, GetPaymentTemplateRequest>({
      query: ({ id }) => ({
        url: `/payment-template/${id}`,
        method: 'GET',
      }),
      providesTags: ['PaymentTemplate'],
    }),
    editPaymentTemplate: build.mutation<EditPaymentTemplateResponse, EditPaymentTemplateRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `/payment-template/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['PaymentTemplates'],
    }),
    deletePaymentTemplate: build.mutation<unknown, GetPaymentTemplateRequest>({
      query: ({ id }) => ({
        url: `/payment-template/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: deletePaymentTemplate } = await queryFulfilled
          dispatch(
            paymentTemplatesApi.util.updateQueryData('getPaymentTemplates', undefined, (draft) => {
              const findId = draft.findIndex((paymentTemplate) => paymentTemplate.id === id)
              draft.splice(findId, 1)
            }),
          )
        } catch {}
      },
      // invalidatesTags: ['PaymentTemplates'],
    }),
    // payment-template/1
  }),
  overrideExisting: false,
})

export const {
  useGetPaymentTemplatesQuery,
  useCreatePaymentTemplateMutation,
  useGetPaymentTemplateByIdQuery,
  useEditPaymentTemplateMutation,
  useDeletePaymentTemplateMutation,
} = paymentTemplatesApi
