import React, { useCallback, useEffect, useState } from 'react'
import { PaymentTemplateDrawerProps } from './PaymentTemplateDrawer.types'
import { StyledTopBar, StyledDrawerWrapper, StyledPaymentTemplateDrawer } from './styles'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { theme } from '../../../../../../global/styles/theme'
import { Divider } from '../../../../../../components/Divider'
import PaymentTemplateDrawerForm from '../PaymentTemplateDrawerForm'
// import { useDeleteProjectMutation, useGetProjectByIdQuery } from '../../../../api/projects';
import DeleteIcon from '@mui/icons-material/Delete'
// import DeleteConfirm from '../../../../components/DeleteConfirm';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import Progress from '../../../../../../components/Progress'
import Button from '../../../../../../components/Button'
import { useDeletePaymentTemplateMutation, useGetPaymentTemplateByIdQuery } from '../../../../../../api/template'
import { AlertDialog } from '../../../../../../components/AlertCustom/AlertDialog'
import { MyModal } from '../../../../../../components/MyModal/MyModal'
import { ButtonGroupCustom } from '../../../../../../components/ButtonGroupCustom/ButtonGroupCustom'
import { IPaymentTemplate } from '../../../../../../api/template/types'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
export interface IConfirmState {
  isConfirmOpen: boolean
}

const PaymentTemplateDrawer: React.FC<PaymentTemplateDrawerProps> = ({
  paymentTemplateId,
  openUserGuide,
  open,
  onClose,
}) => {
  const [isConfirmOpen, setConfirmOpen] = React.useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('template')

  const [dirty, setDirty] = useState<boolean>(false)

  const { data, isLoading, isFetching } = useGetPaymentTemplateByIdQuery(
    {
      id: paymentTemplateId!,
    },
    {
      skip: !paymentTemplateId,
      refetchOnMountOrArgChange: true,
    },
  )

  const isDataLoading = isFetching || isLoading

  const paymentTemplate = data ? data : ({} as IPaymentTemplate)

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const [deletePaymentTemplate, deletePaymentTemplateResponse] = useDeletePaymentTemplateMutation()

  /* ************************** Confirm Message ********************** */

  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)

  const onDeleteSubmit = useCallback(() => {
    if (paymentTemplateId) {
      deletePaymentTemplate({ id: paymentTemplateId })
    }
  }, [paymentTemplateId])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  /* ************************** Confirm Message ********************** */

  const onDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      onClose(dirty, immediately)
    },
    [dirty],
  )

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useMutationHandlers(deletePaymentTemplateResponse, (data) => {
    enqueueSnackbar(t('success.templateDelete'), {
      variant: 'success',
    })
    onClose(false, true)
  })

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <StyledPaymentTemplateDrawer
      className={'className-StyledPaymentTemplateDrawer'}
      anchor="right"
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <StyledDrawerWrapper justifyContent="flex-start">
        <StyledTopBar className={'className-StyledTopBar'}>
          <Typography
            justifyContent="center"
            variant="h2"
            sx={{ color: theme.palette.primary.main, width: '100%', marginLeft: '25px' }}
          >
            {paymentTemplateId ? 'Редактирование шаблона' : 'Добавление шаблона'}
          </Typography>
          {!paymentTemplateId && (
            <HelpOutlineIcon onClick={openUserGuide} sx={{ color: theme.palette.primary.light, cursor: 'pointer' }} />
          )}
        </StyledTopBar>
        <Divider color="#D6D1FA" />
        {paymentTemplateId ? (
          !isDataLoading ? (
            <>
              <PaymentTemplateDrawerForm
                onClose={onDrawerClose}
                onFormChange={onFormChange}
                paymentTemplate={data!}
                isDeleteClicked={isDeleteClicked}
                onDeleteCancel={onDeleteCancel}
                onDeleteSubmit={onDeleteSubmit}
              />
              <Stack
                justifyContent="space-between"
                alignItems="center"
                flex={1}
                sx={{ width: '100%', paddingBottom: '10px' }}
              >
                {!paymentTemplate.isDefault && (
                  <Box style={{ width: 'fit-content' }}>
                    <Button
                      sx={{ color: '#F46B6B' }}
                      onClick={onDeleteButtonClick}
                      startIcon={<DeleteIcon />}
                      variant="text"
                      color="error"
                    >
                      Удалить шаблон
                    </Button>
                  </Box>
                )}
              </Stack>
            </>
          ) : (
            <Progress />
          )
        ) : (
          <PaymentTemplateDrawerForm onClose={onDrawerClose} onFormChange={onFormChange} />
        )}
      </StyledDrawerWrapper>
    </StyledPaymentTemplateDrawer>
  )
}

export default PaymentTemplateDrawer
