import { Box, Button, IconButton, Pagination, Stack, Tab, Tabs } from '@mui/material'
import { UserRoleTabLabels } from '../../Users.types'
import { UsersLegendProps } from './UsersLegend.types'
import React, { FC } from 'react'
import { theme } from '../../../../global/styles/theme'
import AddIcon from '@mui/icons-material/Add'
import { FilterSelect } from '../../../../components/FilterSelect'
import { textTab, textTabs } from '../../../../global/styles/presets'
import { ControlPanel } from '../../../../components/ControlPanel/ControlPanel'

export const UsersLegend: FC<UsersLegendProps> = ({
  currentTab,
  tabsData,
  onTabChange,
  onAddClick,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit,
}) => {
  return (
    <Box>
      <ControlPanel.Wrapper>
        <ControlPanel.InnerContainer align="left">
          <Tabs value={currentTab} onChange={onTabChange} sx={textTabs}>
            {tabsData.map((tab) => (
              <Tab label={tab.label} value={tab.value} key={tab.value} sx={{ ...textTab }} />
            ))}
          </Tabs>
        </ControlPanel.InnerContainer>

        <ControlPanel.InnerContainer
          align="right"
          className="UsersLegend"
          sx={{ minWidth: '500px', justifyContent: 'flex-end' }}
        >
          <FilterSelect items={numberRows!} value={Number(limit!)} onChange={onChangeLimit!} startIcon="Строк" />
          <Pagination
            onChange={(e, v) => onChangePage!(v)}
            count={countPagination}
            shape="rounded"
            page={page}
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: 'rgba(109, 154, 220, 0.2)',
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '20px !important',
                },
              },
            }}
          />
          <Button onClick={onAddClick} size="small" variant="contained">
            <AddIcon />
          </Button>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>
    </Box>
  )
}
