import { typesProjectOptions, validExcelFormats } from 'global/types/commos-def'
import { mixed, object, string } from 'yup'

const isValidExcelFile = (file: File) => {
  return validExcelFormats.includes(file.type)
}

export const uploadBdrValidationSchema = object({
  budgetType: string().oneOf(typesProjectOptions).required(),
  file: mixed()
    .required('Файл обязателен')
    .test('fileType', 'Неверный тип файла', (value) => value && isValidExcelFile(value)),
})
