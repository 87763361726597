import { Box, Tab, Tabs } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetPaymentTemplatesQuery } from '../../api/template'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { InterfaceContext } from '../../contexts/context.interface'
import { changeQueryParams, getQueryParam } from '../../global/functions'
import { scrollableWrapper } from '../../global/styles/presets'
import { PaymentTemplatesPage } from './subPages/PaymentTemplatesPage/PaymentTemplatesPage'
import Add from '@mui/icons-material/Add'
import { IPaymentTemplate } from '../../api/template/types'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { UserGuide } from '../../components/UserGuide/UserGuide'
import { PAYMENT_TEMPLATES_GUIDE_STEPS } from './subPages/PaymentTemplatesPage/components/PaymentTemplatesUserGuide'
import { Bills } from './subPages/Bills/Bills'
import { TControlBtn } from '../MappingPage/MappingPage'

export type TControlBtns = 'addNewSubBillBtn'

export interface IControlBtnState {
  clicked: boolean
  hidden: boolean
  disabled: boolean
}

export type TControlsState = {
  [key in TControlBtns]: IControlBtnState
}

export function ReferencePage() {
  const [controls, setControls] = React.useState({
    addNewSubBillBtn: {
      hidden: true,
      disabled: false,
      clicked: false,
    },
  } as TControlsState)

  const { data, isLoading, isFetching } = useGetPaymentTemplatesQuery()

  const isDataLoading = isFetching || isLoading

  const paymentTemplates = data ? data : ([] as IPaymentTemplate[]) //

  const history = useHistory()

  const location = useLocation()

  const interfaceCTX = useContext(InterfaceContext)

  /****************************************** Drawer  ********************************/

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [chosenPaymentTemplateId, setPaymentTemplateId] = useState<number | null>(null)

  /****************************************** ConfirmDialog ********************************/

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  /****************************************** Drawer ********************************************/

  const onEditPaymentTemplateClick = (paymentTemplateId: number) => {
    setIsDrawerOpen(true)
    setPaymentTemplateId(paymentTemplateId)
  }

  const addPaymentTemplate = useCallback((open) => {
    setIsDrawerOpen(open)
    setPaymentTemplateId(null)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm()
    //  addPaymentTemplate(false)
  }, [])

  /****************************************** User Guide ********************************************/

  const [isUserGuideOpen, setUserGuideOpen] = useState(false)

  function openUserGuide() {
    setUserGuideOpen(true)
  }

  function switchisUserGuideOpenOpen() {
    setUserGuideOpen(!isUserGuideOpen)
  }

  /******************************************  ********************************************/

  const subPages = [
    {
      title: 'Счета БУ',
      queryParamValue: 'Bills',
      component: <Bills controls={controls} setControls={setControls} />,
    },
    {
      title: 'Условия оплаты',
      queryParamValue: 'PaymentTerms', // PaymentTerms - Условия оплаты
      component: !isDataLoading && (
        <PaymentTemplatesPage
          paymentTemplates={paymentTemplates}
          addPaymentTemplate={addPaymentTemplate}
          onEditPaymentTemplateClick={onEditPaymentTemplateClick}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          paymentTemplateId={chosenPaymentTemplateId}
          openUserGuide={openUserGuide}
        />
      ),
    },
  ]

  React.useEffect(() => {
    initiateBreadcrumbs()
  }, [])

  React.useEffect(() => {
    if (getQueryParam(history, 'subPage') == null) {
      changeQueryParams(history, {
        subPage: 'Bills',
      })
    }
  }, [getQueryParam(history, 'subPage'), location.search])

  function initiateBreadcrumbs() {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'title',
        title: `Cправочник`,
      },
    ])
  }

  function changeBthState(btn: TControlBtns, stateName: keyof IControlBtnState, stateNameValue: boolean) {
    setControls((prevState) => ({
      ...prevState,
      [btn]: {
        ...prevState[btn],
        [stateName]: stateNameValue,
      },
    }))
  }

  return (
    <Box>
      <ControlPanel.Wrapper>
        <ControlPanel.InnerContainer align="left" className="ControlPanel.InnerContainer">
          <Tabs
            value={getQueryParam(history, 'subPage')}
            onChange={(e, value) =>
              changeQueryParams(history, {
                subPage: value,
              })
            }
          >
            {subPages.map((subPage, index) => {
              return <Tab label={subPage.title} value={subPage.queryParamValue} key={index} />
            })}
          </Tabs>
        </ControlPanel.InnerContainer>
        {getQueryParam(history, 'subPage') == 'Bills' && !controls.addNewSubBillBtn.hidden && (
          <ControlPanel.Btn onClick={() => changeBthState('addNewSubBillBtn', 'clicked', true)} startIcon={<Add />}>
            Добавить субсчет
          </ControlPanel.Btn>
        )}
        {getQueryParam(history, 'subPage') == 'PaymentTerms' && (
          <ControlPanel.Btn onClick={() => addPaymentTemplate(true)} startIcon={<Add />}>
            добавить шаблон
          </ControlPanel.Btn>
        )}
      </ControlPanel.Wrapper>

      <ConfirmDialog />
      <UserGuide open={isUserGuideOpen} steps={PAYMENT_TEMPLATES_GUIDE_STEPS} onClose={switchisUserGuideOpenOpen} />

      <Box className="subPage" sx={{ height: 'fit-content', maxHeight: '85vh' }}>
        <Box>
          {getQueryParam(history, 'subPage') &&
            subPages.filter((subPage) => subPage.queryParamValue == getQueryParam(history, 'subPage'))[0].component}
        </Box>
      </Box>
    </Box>
  )
}
