import qs from 'qs'
import React, { useCallback } from 'react'
import { Remove } from '@mui/icons-material'
import { Button, TableBody, TableCell, TableRow } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { IMappingArticlesState } from '../MappingArticles'
import { IMappingPageSubPageProps } from '../../../MappingPage'
import { SubBillAssignDrawer } from './SubBillASsignDrawer/SubBillAssignDrawer'
import { useDidMountEffect } from '../../../../../hooks/useDidMountEffect'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../hooks/useConfirmDialog'
import { ISubBillPair } from '../../../../../global/api/definitions'
import { changeQueryParams } from '../../../../../global/functions'

interface ISubBillListProps extends IMappingPageSubPageProps {
  parentState: IMappingArticlesState
  setParentState: React.Dispatch<React.SetStateAction<IMappingArticlesState>>
}

export interface ISubBillListState {
  subBillList: IMappingArticlesState['subBillList']
  isSubBillAssignDrawerOpen: boolean
  queryOnRemoveSubBill: ISubBillPair
  clientWantToCloseForm: boolean
  mappingArticlesState: IMappingArticlesState
  setMappingArticlesState: React.Dispatch<React.SetStateAction<IMappingArticlesState>>
}

export function SubBillList(props: ISubBillListProps) {
  const { parentState, setParentState, controlsState, setControlsState } = props

  const [state, setState] = React.useState({
    subBillList: parentState.subBillList,
    isSubBillAssignDrawerOpen: false,
    queryOnRemoveSubBill: {},
    clientWantToCloseForm: false,
    mappingArticlesState: parentState,
    setMappingArticlesState: setParentState,
  } as ISubBillListState)

  const history = useHistory()

  const location = useLocation()

  useDidMountEffect(() => {
    openDrawer()
    setControlsState((prevState) => ({
      ...prevState,
      subBillAssignBtn: {
        ...prevState.subBillAssignBtn,
        clicked: false,
      },
    }))
  }, [controlsState.subBillAssignBtn.clicked])

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      subBillList: parentState.subBillList,
    }))
  }, [parentState.subBillList])

  React.useEffect(() => {
    setControlsState((prevState) => ({
      ...prevState,
      addArticleBtn: {
        ...prevState.addArticleBtn,
        hidden: state.subBillList?.length == 0 ? state.isSubBillAssignDrawerOpen : true,
      },
    }))
  }, [state.isSubBillAssignDrawerOpen])

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isSubBillAssignDrawerOpen: false,
    }))
  }, [location])

  function openDrawer() {
    setState((prevState) => ({
      ...prevState,
      isSubBillAssignDrawerOpen: true,
    }))
  }

  function checkIsConfirmNeeded() {
    setState((prevState) => ({
      ...prevState,
      clientWantToCloseForm: true,
    }))
  }

  function onRemoveSubBillClick(pair: ISubBillPair) {
    setState((prevState) => ({
      ...prevState,
      queryOnRemoveSubBill: pair,
    }))
  }

  return (
    parentState.subBillList && (
      <TableBody>
        <SubBillAssignDrawer
          billType={props.budgetType}
          parentState={state}
          setParentState={setState}
          DrawerProps={{
            open: state.isSubBillAssignDrawerOpen,
            onClose: checkIsConfirmNeeded,
          }}
          mappingArticlesState={state.mappingArticlesState}
          setMappingArticlesState={state.setMappingArticlesState}
        />
        {state?.subBillList?.map((pair) => {
          return (
            <TableRow key={pair.subBill.id} sx={{ height: '44px' }}>
              <TableCell>
                {
                  <Button onClick={(e) => onRemoveSubBillClick(pair)} size="small" sx={{ marginX: 'auto', p: 0.5 }}>
                    {state.isSubBillAssignDrawerOpen && <Remove color={'primary'} fontSize="small" />}
                  </Button>
                }
              </TableCell>
              <TableCell>{pair.subBill.name}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  )
}
