import React from 'react'
import { Close, Search } from '@mui/icons-material'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { MyDrawer } from '../../../components/MyDrawer/MyDrawer'
import { FinanceCenterAPI } from '../../../global/api/APIMethods/FinCenterAPI'
import { input, scrollableWrapper, STYLED } from '../../../global/styles/presets'
import { theme } from '../../../global/styles/theme'
import { IFinanceCenter, TBudgetType, TFinanceCenterType } from '../../../global/types/commos-def'
import { Params } from '../../../global/types/params-def'
import { HelpPlug } from '../../../components/HelpPlug/HelpPlug'
import { FinCenterList } from './FinCenterList'
import { getFinCentersByType, sortFinCentersByName } from '../../../global/functions'
import { MyModal } from '../../../components/MyModal/MyModal'
import { ButtonGroupCustom } from '../../../components/ButtonGroupCustom/ButtonGroupCustom'
import qs from 'qs'

export interface ISelectFinCentersState {
  selectType: 'byAllBudgets' | 'byType'
  allFinCenters: IFinanceCenter[]
  availableFinCenters: IFinanceCenter[]
  selectedFinCenters: IFinanceCenter[]
  filteredFinCenters: IFinanceCenter[]
  search: string
  isAllGroupsSelected?: boolean
  isConfirmOpen: boolean
}

interface ISelectFinCenterProps {
  allFinCenters?: IFinanceCenter[]
  availableFinCenters?: IFinanceCenter[]
  selectedFinCenters?: IFinanceCenter[]
  filteredFinCenters?: IFinanceCenter[]
  budgetType: TBudgetType
  isOpen: boolean
  onClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined
  onCancel: () => void
  onCreateReport: (finCenterIDArr: string[]) => void
  isCapital: boolean
}

export function SelectFinCenter(props: ISelectFinCenterProps) {
  const {
    budgetType,
    isOpen,
    onClose,
    isCapital,
    onCancel,
    onCreateReport,
    allFinCenters,
    availableFinCenters,
    selectedFinCenters,
    filteredFinCenters,
  } = props

  const { projectID } = useParams() as Params

  const { t } = useTranslation('ENUMS')

  const history = useHistory()

  const [state, setState] = React.useState({
    selectType: 'byAllBudgets',
    allFinCenters: allFinCenters || [],
    availableFinCenters: availableFinCenters || [],
    selectedFinCenters: selectedFinCenters || [],
    filteredFinCenters: filteredFinCenters || [],
    search: '',
    isAllGroupsSelected: false,
    isConfirmOpen: false,
  } as ISelectFinCentersState)

  const isOpenConfirm = React.useMemo(() => {
    const parseFinCenters = qs.parse(history.location.search).financeCenters as string | string[]
    const selectedFinCentersIDs: string[] = Array.isArray(parseFinCenters) ? parseFinCenters : [parseFinCenters]
    return state.selectedFinCenters.length !== selectedFinCentersIDs.length
  }, [state.selectedFinCenters, history])

  React.useEffect(() => {
    !allFinCenters &&
      FinanceCenterAPI.getBudgets(Number(projectID)).then((data) => {
        setState((prevState) => ({
          ...prevState,
          allFinCenters: data?.data,
          availableFinCenters: data?.data,
          filteredFinCenters: data?.data,
        }))
      })
  }, [])

  React.useEffect(() => {
    if (!isOpen) {
      // allFinCenters ?
      //     resetToSelectedFinCenters()
      //     :
      clearSelectedFinCenters()
    }
  }, [isOpen])

  React.useEffect(() => {
    !isOpen ? clearSelectedFinCenters() : resetToSelectedFinCenters()
  }, [selectedFinCenters])

  React.useEffect(() => {
    filterAvailableFinCentersBySearch(state.search)
  }, [state.search])

  React.useEffect(() => {
    state.filteredFinCenters?.length == 0
      ? setState((prevState) => ({
          ...prevState,
          isAllGroupsSelected: true,
        }))
      : setState((prevState) => ({
          ...prevState,
          isAllGroupsSelected: false,
        }))
    // console.log(state.filteredFinCenters)
  }, [state.filteredFinCenters])

  function getFinCenterById(finCenters: ISelectFinCentersState['allFinCenters'], finCenterID: number | string) {
    return finCenters!.find((finCenter) => finCenter.id == finCenterID) || ({} as IFinanceCenter)
  }

  function addFinCenterToSelectedList(finCenterID: number | string) {
    setState((prevState) => ({
      ...prevState,
      availableFinCenters: sortFinCentersByName(
        prevState.availableFinCenters!.filter((finCenter) => finCenter.id != finCenterID),
      ),
      filteredFinCenters: sortFinCentersByName(
        prevState.filteredFinCenters!.filter((finCenter) => finCenter.id != finCenterID),
      ),
      selectedFinCenters: sortFinCentersByName([
        getFinCenterById(prevState.availableFinCenters, finCenterID),
        ...prevState.selectedFinCenters,
      ]),
    }))
  }

  function removeSelectedFinCenter(finCenterID: number | string, search: string) {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: sortFinCentersByName(
        prevState.selectedFinCenters!.filter((finCenter) => finCenter.id != finCenterID),
      ),
      availableFinCenters: sortFinCentersByName([
        getFinCenterById(prevState.selectedFinCenters, finCenterID),
        ...prevState.availableFinCenters,
      ]),
      filteredFinCenters: sortFinCentersByName([
        getFinCenterById(prevState.selectedFinCenters, finCenterID),
        ...prevState.availableFinCenters,
      ]),
    }))
    filterAvailableFinCentersBySearch(search)
  }

  function clearSelectedFinCenters() {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: [],
      availableFinCenters: prevState.allFinCenters,
      filteredFinCenters: prevState.allFinCenters,
      isAllGroupsSelected: false,
      search: '',
    }))
  }

  function resetToSelectedFinCenters() {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: selectedFinCenters || [],
      availableFinCenters: availableFinCenters || prevState.allFinCenters,
      filteredFinCenters: filteredFinCenters || prevState.allFinCenters,
      isAllGroupsSelected: false,
      search: '',
    }))
  }

  function filterAvailableFinCentersBySearch(search: string) {
    setState((prevState) => ({
      ...prevState,
      filteredFinCenters: sortFinCentersByName(
        prevState.availableFinCenters.filter((finCenter) =>
          finCenter.name.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
    }))
  }

  function resetSearch() {
    setState((prevState) => ({
      ...prevState,
      search: '',
    }))
  }

  function selectAllFinCenters() {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: prevState.allFinCenters,
      availableFinCenters: [],
      filteredFinCenters: [],
      isAllGroupsSelected: true,
    }))
  }

  function selectGroup(finCenterType: TFinanceCenterType) {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: sortFinCentersByName(
        prevState.selectedFinCenters.concat(getFinCentersByType(finCenterType, prevState.availableFinCenters)),
      ),
      availableFinCenters: sortFinCentersByName(
        prevState.availableFinCenters.filter((finCenter) => finCenter.type != finCenterType),
      ),
      filteredFinCenters: sortFinCentersByName(
        prevState.availableFinCenters.filter((finCenter) => finCenter.type != finCenterType),
      ),
    }))
  }

  function unSelectGroup(finCenterType: TFinanceCenterType) {
    setState((prevState) => ({
      ...prevState,
      selectedFinCenters: sortFinCentersByName(
        prevState.selectedFinCenters.filter((finCenter) => finCenter.type != finCenterType),
      ),
      availableFinCenters: sortFinCentersByName(
        prevState.availableFinCenters.concat(getFinCentersByType(finCenterType, prevState.allFinCenters)),
      ),
      filteredFinCenters: sortFinCentersByName(
        prevState.availableFinCenters.concat(getFinCentersByType(finCenterType, prevState.allFinCenters)),
      ),
    }))
  }

  function closeConfirm() {
    setState((prevState) => ({
      ...prevState,
      isConfirmOpen: false,
    }))
  }

  function openConfirm() {
    setState((prevState) => ({
      ...prevState,
      isConfirmOpen: true,
    }))
  }

  return (
    <>
      <MyDrawer.Wrapper
        open={isOpen}
        onClose={(event, reason) => (isOpenConfirm ? openConfirm() : onClose && onClose(event, reason))}
        sx={{
          '& .MuiPaper-root': {
            width: '882px',
          },
        }}
      >
        <MyDrawer.Title>
          Сформировать {isCapital ? 'отчет капвложений' : t('BUDGET_TYPE.' + budgetType)}: Бюджеты проекта
        </MyDrawer.Title>

        <MyDrawer.Content
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box p={2.25} width="100%" display="flex" gap={2.25} height="calc(100vh - 125px)">
            <Box
              height="100%"
              width="411px"
              border={`1px solid ${theme.palette.legends.gray}`}
              borderRadius={theme.spacing(2)}
            >
              <TextField
                disabled={state.allFinCenters?.length == 0}
                InputProps={{
                  startAdornment: <Search sx={{ color: theme.palette.secondary.dark, marginX: 1 }} />,
                  endAdornment: state.search && (
                    <Close
                      fontSize="small"
                      sx={{ color: theme.palette.secondary.dark, marginX: 1, cursor: 'pointer' }}
                      onClick={resetSearch}
                    />
                  ),
                }}
                fullWidth
                sx={{
                  p: 2.25,
                  input: {
                    padding: 0.5,
                  },
                  '.MuiInputBase-root': {
                    paddingX: 0.5,
                    background: theme.palette.bg.shades,
                  },
                }}
                placeholder="Поиск"
                value={state.search}
                onChange={(e) => setState((prevState) => ({ ...prevState, search: e.target.value }))}
              ></TextField>
              <Box height="calc(100% - 70px)" sx={{ ...scrollableWrapper, pb: 0 }}>
                {!state.search ? (
                  state.allFinCenters?.length > 0 ? (
                    <>
                      <FormControlLabel
                        checked={state.isAllGroupsSelected}
                        onClick={(e) => {
                          ;(e.target as HTMLInputElement).checked ? selectAllFinCenters() : clearSelectedFinCenters()
                        }}
                        control={<Checkbox sx={{ svg: { color: theme.palette.primary.main } }} />}
                        label="Выбрать все"
                        sx={{
                          mb: 2.25,
                          color: theme.palette.primary.main,
                          '.MuiTypography-root': { fontWeight: 500 },
                        }}
                      />
                      {getFinCentersByType('DEPARTMENT', state.allFinCenters).length > 0 && (
                        <FinCenterList
                          variant="select"
                          addFinCenterToSelectedList={addFinCenterToSelectedList}
                          finCenterType="DEPARTMENT"
                          finCenters={state.filteredFinCenters}
                          selectGroup={() => selectGroup('DEPARTMENT')}
                          unSelectGroup={() => unSelectGroup('DEPARTMENT')}
                          isAllGroupsSelected={state.isAllGroupsSelected}
                          setParentState={setState}
                        />
                      )}
                      {getFinCentersByType('SUBDIVISION', state.allFinCenters).length > 0 && (
                        <FinCenterList
                          variant="select"
                          addFinCenterToSelectedList={addFinCenterToSelectedList}
                          finCenterType="SUBDIVISION"
                          finCenters={state.filteredFinCenters}
                          selectGroup={() => selectGroup('SUBDIVISION')}
                          unSelectGroup={() => unSelectGroup('SUBDIVISION')}
                          isAllGroupsSelected={state.isAllGroupsSelected}
                          setParentState={setState}
                        />
                      )}
                      {getFinCentersByType('PRODUCTION_PROGRAM', state.allFinCenters).length > 0 && (
                        <FinCenterList
                          variant="select"
                          addFinCenterToSelectedList={addFinCenterToSelectedList}
                          finCenterType="PRODUCTION_PROGRAM"
                          finCenters={state.filteredFinCenters}
                          selectGroup={() => selectGroup('PRODUCTION_PROGRAM')}
                          unSelectGroup={() => unSelectGroup('PRODUCTION_PROGRAM')}
                          isAllGroupsSelected={state.isAllGroupsSelected}
                          setParentState={setState}
                        />
                      )}
                    </>
                  ) : (
                    <HelpPlug.Wrapper sx={{ height: '100%' }}>
                      <HelpPlug.Title>Нет доступных бюджетов</HelpPlug.Title>
                      <HelpPlug.Image
                        sx={{
                          img: {
                            width: '100%',
                          },
                        }}
                      />
                    </HelpPlug.Wrapper>
                  )
                ) : (
                  <FinCenterList
                    variant="select"
                    addFinCenterToSelectedList={addFinCenterToSelectedList}
                    finCenters={state.filteredFinCenters}
                    setParentState={setState}
                  />
                )}
              </Box>
            </Box>
            <Box
              height="100%"
              width="411px"
              border={`1px solid ${theme.palette.legends.gray}`}
              borderRadius={theme.spacing(2)}
            >
              <Box p={2.25} display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
                <Typography variant="subtitle1" lineHeight="auto" color="primary">
                  Выбранные бюджеты
                </Typography>
                <Typography
                  fontSize={12}
                  variant="body2"
                  color="error"
                  sx={{ color: theme.palette.error.main, cursor: 'pointer' }}
                  onClick={clearSelectedFinCenters}
                >
                  ОЧИСТИТЬ СПИСОК
                </Typography>
              </Box>
              <Box height="calc(100% - 70px)" sx={{ ...scrollableWrapper, pb: 0 }}>
                <FinCenterList
                  variant="selected"
                  removeSelectedFinCenter={(finCenterID) => removeSelectedFinCenter(finCenterID, state.search)}
                  clearSelectedFinCenters={clearSelectedFinCenters}
                  finCenters={state.selectedFinCenters}
                  setParentState={setState}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap={2} sx={{ mt: 'auto', justifyContent: 'flex-end' }} p={2} paddingTop={0}>
            <Button
              disabled={state.allFinCenters?.length == 0 || state.selectedFinCenters?.length == 0}
              variant="contained"
              color="success"
              onClick={(e) => onCreateReport(state.selectedFinCenters.map((finCenter) => String(finCenter.id)))}
            >
              Сформировать {isCapital ? 'отчет капвложений' : t('BUDGET_TYPE.' + budgetType)}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => (isOpenConfirm ? openConfirm() : onCancel && onCancel())}
            >
              Отменить и закрыть
            </Button>
          </Box>
        </MyDrawer.Content>
      </MyDrawer.Wrapper>

      <MyModal.Wrapper open={state.isConfirmOpen} onClose={closeConfirm}>
        <MyModal.Inner>
          <MyModal.Title>Вы уверены, что хотите закрыть форму?</MyModal.Title>
          <MyModal.Guide>Все несохраненные данные будут удалены</MyModal.Guide>
          <ButtonGroupCustom sx={{ mt: 1.5 }}>
            <Button
              variant="contained"
              color="success"
              size="medium"
              onClick={(e) => {
                closeConfirm()
                onCancel()
              }}
            >
              Да
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={closeConfirm}>
              Нет
            </Button>
          </ButtonGroupCustom>
        </MyModal.Inner>
      </MyModal.Wrapper>
    </>
  )
}
