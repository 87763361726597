import { Tab, Tabs } from '@mui/material'
import { changeQueryParams, getQueryParam } from 'global/functions'
import { STYLED } from 'global/styles/presets'
import { SyntheticEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { ISubcategoryData, ISubcategoryTabsProps } from './SubcategoryTabs.types'

export const enum Subcategory {
  INCOME = 'INCOME',
  OTHER_INCOME = 'OTHER_INCOME',
  WAGE_FUND = 'WAGE_FUND',
  STAFF_MAINTENANCE = 'STAFF_MAINTENANCE',
  OPERATING_EXPENSES = 'OPERATING_EXPENSES',
}

const INCOME_DATA: ISubcategoryData[] = [
  { id: 1, label: 'Основные', value: Subcategory.INCOME },
  { id: 2, label: 'Прочие', value: Subcategory.OTHER_INCOME },
]

const OPERATING_EXPENSES_DATA: ISubcategoryData[] = [
  { id: 1, label: 'Фот', value: Subcategory.WAGE_FUND },
  { id: 2, label: 'Содержание персонала', value: Subcategory.STAFF_MAINTENANCE },
  { id: 3, label: 'Прочие расходы', value: Subcategory.OPERATING_EXPENSES },
]

const DATA_BY_CATEGORY: Record<string, ISubcategoryData[]> = {
  INCOME: INCOME_DATA,
  OTHER_INCOME: INCOME_DATA,
  OPERATING_EXPENSES: OPERATING_EXPENSES_DATA,
  WAGE_FUND: OPERATING_EXPENSES_DATA,
  STAFF_MAINTENANCE: OPERATING_EXPENSES_DATA,
  DEFAULT: [],
}

const getData = (category: string | null): ISubcategoryData[] => {
  if (!category) return DATA_BY_CATEGORY.DEFAULT
  return DATA_BY_CATEGORY[category] ?? DATA_BY_CATEGORY.DEFAULT
}

export const SubcategoryTabs: React.FC<ISubcategoryTabsProps> = ({ category, setState }) => {
  const history = useHistory()
  const subcategory = getQueryParam(history, 'subcategory') || false
  const data = getData(category)

  const changeSubcategory = (_: SyntheticEvent<Element, Event>, subcategory: string) => {
    setState((prev) => ({ ...prev, operations: null }))
    setTimeout(() => {
      changeQueryParams(history, {
        category: subcategory,
        subcategory,
      })
    }, 0)
  }

  return (
    <Tabs value={subcategory} onChange={changeSubcategory} sx={{ ...STYLED.TABS.OUTLINED }}>
      {data.map(({ id, label, value, disabled }) => (
        <Tab key={id} label={label} value={value} disabled={disabled} />
      ))}
    </Tabs>
  )
}
