import { Box } from '@mui/material'
import { IUploadStatus, TUploadStatus } from './UploadFileDrawer.def'
import styled from 'styled-components'
import { StyledBtn } from './UploadFileDrawer.styles'

interface IBtnGroupWrapperStatus {
  uploadStatus: IUploadStatus
  DISABLE_SUBMIT_BTN_STATUSES: TUploadStatus[]
  triggerConfirmDialog(): void
  closeDrawer(): void
  sendFile(): void
  cancelSendFile(): void
  repeatFile(): void
}

const BtnGroupWrapperStyled = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: auto;
`

export function BtnGroupWrapper({
  uploadStatus: { type = 'initial' },
  DISABLE_SUBMIT_BTN_STATUSES,
  triggerConfirmDialog,
  closeDrawer,
  sendFile,
  cancelSendFile,
  repeatFile,
}: IBtnGroupWrapperStatus) {
  switch (type) {
    case 'error_upload_success':
    case 'error':
      return (
        <BtnGroupWrapperStyled>
          <StyledBtn color="success" onClick={repeatFile}>
            Повторить
          </StyledBtn>
        </BtnGroupWrapperStyled>
      )
    case 'canceled':
    case 'canceling':
      return (
        <BtnGroupWrapperStyled>
          <StyledBtn color="success" disabled={DISABLE_SUBMIT_BTN_STATUSES.includes(type)} onClick={repeatFile}>
            Повторить
          </StyledBtn>
        </BtnGroupWrapperStyled>
      )
    case 'upload_progress':
      return (
        <BtnGroupWrapperStyled>
          <StyledBtn color="success" onClick={cancelSendFile}>
            Отменить
          </StyledBtn>
        </BtnGroupWrapperStyled>
      )
    case 'upload_success':
      return (
        <BtnGroupWrapperStyled>
          <StyledBtn color="success" onClick={closeDrawer}>
            Готово
          </StyledBtn>
        </BtnGroupWrapperStyled>
      )

    default:
      return (
        <BtnGroupWrapperStyled>
          <StyledBtn color="success" disabled={DISABLE_SUBMIT_BTN_STATUSES.includes(type)} onClick={sendFile}>
            Обработать
          </StyledBtn>
          <StyledBtn color="primary" onClick={triggerConfirmDialog}>
            Отменить
          </StyledBtn>
        </BtnGroupWrapperStyled>
      )
  }
}
