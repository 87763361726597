import { Stack, SxProps, TextField, TextFieldProps } from '@mui/material'
import { getFlexJustifyContent } from 'components/editableElements/utils'
import React from 'react'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'

export interface IEENumberProps {
  align?: 'left' | 'center' | 'right'
  name: string
  mode: 'edit' | 'view'
  value?: any
  onNumberChange?: (values: NumberFormatValues) => void
  TextFieldProps?: Partial<TextFieldProps>
  NumberFormatProps?: NumberFormatProps
  viewModeStyle?: SxProps
  editModeStyle?: SxProps
  allowNegative?: boolean
  customInput?: React.ReactNode
  viewModeProps?: any
}

export const NumberFormatPropsSchema = {
  default: {
    decimalSeparator: ',',
    thousandSeparator: ' ',
    decimalScale: 2,
    getInputRef: (el: HTMLElement) => {
      let input = el && el.getElementsByTagName('input')[0]
      if (input) {
        input.addEventListener('focus', () => {
          input.select()
        })
      }
    },
    isAllowed: (v: NumberFormatValues) => v.value.length <= 15,
  },
  nominal: {
    isAllowed: (v: NumberFormatValues) => v.value.length <= 15,
  },
}

export function EENumberGrid(props: IEENumberProps) {
  const {
    align,
    name,
    mode,
    value,
    onNumberChange: onChange,
    TextFieldProps,
    NumberFormatProps,
    viewModeStyle,
    editModeStyle,
    allowNegative,
    customInput,
    viewModeProps,
  } = props

  const [localValue, setLocalValue] = React.useState(value)
  const NumberFormatRef = React.useRef(null)
  const wrapperRef = React.useRef(null)
  const customInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    return () => {
      if (mode == 'edit') {
        //@ts-ignore
        NumberFormatRef?.current?.onBlur(NumberFormatRef?.current)
      }
    }
  }, [mode])

  React.useEffect(() => {
    if (mode === 'edit') {
      //@ts-ignore
      wrapperRef.current?.children[0].focus()
      //@ts-ignore
      wrapperRef.current?.children[0].select()
    }
  }, [mode, NumberFormatRef])

  function replaceLeadingZeros(formattedValue: NumberFormatValues['formattedValue']) {
    let targetValue = clearSuffix(formattedValue)
    if (Number(targetValue) > 1) {
      targetValue = targetValue.replace(/^0+/, '')
    }
    return addSuffix(targetValue)
  }

  function clearSuffix(formattedValue: NumberFormatValues['formattedValue']) {
    let suffix = NumberFormatProps?.suffix || ''
    return formattedValue.replace(suffix, '')
  }

  function addSuffix(formattedValue: NumberFormatValues['formattedValue']) {
    let suffix = NumberFormatProps?.suffix || ''
    return formattedValue + suffix
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return (
    // @ts-ignore
    <Stack ref={wrapperRef} justifyContent={'center'}>
      <NumberFormat
        ref={NumberFormatRef}
        className="table-input"
        onDoubleClick={(e: any) => mode === 'edit' && e.stopPropagation()}
        displayType={mode === 'edit' ? 'input' : 'text'}
        allowNegative={allowNegative || false}
        allowLeadingZeros={false}
        key={name}
        customInput={customInput || TextField}
        // customInput={(inputProps) => (customInput ? customInput(inputProps) : <TextField ref={customInputRef} {...inputProps} />)}
        fixedDecimalScale
        value={isNaN(value) ? 0 : parseFloat(Number(value)?.toFixed(2)) || 0}
        onBlur={() => {}}
        onValueChange={(values, sourceInfo) => {
          // Кусок, отвечающий за замену 0 на значение при вводе
          let valuesCopy
          if (value == 0) {
            valuesCopy = { ...values }
            //@ts-ignore
            if (sourceInfo.event?.nativeEvent?.inputType != 'insertFromPaste') {
              let suffix = NumberFormatProps?.suffix || ''

              // Предыдущая стабильная версия
              // valuesCopy.value = values.value.replace("0" + suffix, "")
              // valuesCopy.formattedValue = values.formattedValue.replace("0" + suffix, "")
              // valuesCopy.floatValue = Number(values.formattedValue.replace("0" + suffix, "").trim()) || undefined

              // WEB. Не ставит символ, на экране "Производственная программа", при выделении символов внутри окна. Багфикс
              valuesCopy.value = values.formattedValue.replace('0' + suffix, '')
              valuesCopy.formattedValue = values.formattedValue.replace('0' + suffix, '')
              valuesCopy.floatValue = Number(values.value.replace('0', '').replace(suffix, '').trim()) || 0
            }
          }
          // setLocalValue(valuesCopy || values.floatValue)
          onChange && onChange(valuesCopy || values)
        }}
        {...TextFieldProps}
        {...NumberFormatPropsSchema.default}
        {...NumberFormatProps}
        {...(mode === 'view' ? viewModeProps : {})}
        sx={{
          '.MuiFormHelperText-root': {
            margin: 0,
          },
          span: {
            textAlign: align || 'center',
          },
          ...TextFieldProps?.sx,
          input: {
            textAlign: align || 'center',
            //@ts-ignore
            ...TextFieldProps?.sx['input'],
          },
        }}
        style={{
          whiteSpace: 'nowrap',
          ...(mode === 'view' ? viewModeStyle : editModeStyle),
          justifyContent: (align && getFlexJustifyContent(align)) || 'center',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    </Stack>
  )
}
