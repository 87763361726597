import Box from '@mui/material/Box'
import { AppBar, Avatar, Button, Typography } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { theme } from '../../global/styles/theme'
import { useTypedSelector } from '../../store/store'
import { profileSelector } from '../../store/slices/profile'
import { MyBreadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useCallback, useContext } from 'react'
import { InterfaceContext } from '../../contexts/context.interface'
import img from './../../img/avatar.jpg'
import { LINK_TO_CORE } from '../../global/variables'
import UserMenu from '../_NEW/UserMenu'
import { useGetCompanyQuery } from '../../api/profile'
import Search from './Search'
import { config, currentCompanyName } from '../../global/config'

export function Header() {
  const { firstName, middleName, lastName } = useTypedSelector(profileSelector)
  const profile = useTypedSelector(profileSelector)
  const params = useParams()
  const history = useHistory()

  const {
    data: company,
    error: companyError,
    isLoading,
    isFetching,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery()

  const isDataLoading = isFetching || isLoading

  const location = useLocation()

  const fullName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  )
  const partedName = (
    <>
      {lastName} {firstName}
    </>
  )
  const firstLetter = lastName?.[0] || ''

  const interfaceCTX = useContext(InterfaceContext)

  const onChangeModule = useCallback(() => {
    window.location.replace(`${config.coreURL}${currentCompanyName ? `?company=${currentCompanyName}` : ''}`)
  }, [currentCompanyName])

  return (
    <Box className="Box-header" paddingX={2.5}>
      <AppBar
        className={'AppBar-header'}
        elevation={0}
        position={'relative'}
        sx={{
          width: '100% !important',
          padding: '11 0',
          backgroundColor: theme.palette.grey[100],
          borderBottom: `2px solid var(--DividerMain)`,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <MyBreadcrumbs
          backPath={interfaceCTX.breadcrumbsProps.backPath}
          breadcrumbs={interfaceCTX.breadcrumbsProps.breadcrumbs}
        />
        {/*  <Search /> */}
        {history.location.pathname === '/users' ? <Search /> : null}
        {!isDataLoading && <UserMenu onChangeModule={onChangeModule} company={company!} />}

        {/*  <Box className='inner' display="flex" justifyContent={"flex-end"}>
                    <Box mr={1}>
                        <Avatar
                            src={img}
                        >
                        </Avatar>
                    </Box>
                    <Button
                        variant={"text"}
                        endIcon={<KeyboardArrowDown></KeyboardArrowDown>}
                        sx={{ color: `${theme.palette.text.primary}` }}
                    >
                        <Typography variant={"subtitle1"}>
                            {partedName}
                        </Typography>
                    </Button>
                </Box> */}
      </AppBar>
    </Box>
  )
}
