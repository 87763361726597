import styled from 'styled-components'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import Progress from '../../Progress'

interface DragWrapperProps {
  drag: boolean
}

export const DragWrapper = styled(Stack)<DragWrapperProps>`
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  border: ${(props) => (props.drag ? `2px solid ${props.theme.palette.secondary.gray}` : '')};
  & > div {
    box-shadow: ${(props) => (props.drag ? 'inset 0px 0px 250px rgba(0, 26, 77, 0.08)' : '')};
  }
`
interface UploadWrapperProps {
  isPointer: boolean
}

export const UploadWrapper = styled(Stack)<UploadWrapperProps>`
  width: 100%;
  height: 300px;
  padding: 16px;
  text-align: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  cursor: ${(props) => (props.isPointer ? 'pointer' : 'initial')};
`

export const UploadTitle = styled(Typography)`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
`

export const UploadText = styled(Typography)`
  max-width: 285px;
  word-break: break-word;
`

export const StyledErrorBox = styled(Box)`
  color: ${(props) => props.theme.palette.error.main};
  width: 100%;
  height: 300px;
  padding: 16px;
  text-align: center;
  background-color: #f6f7fb;
  border-radius: 8px;
  /*  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */
`

export const StyledShowErrorButton = styled(Button)`
  padding: 7px 8px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.error.main};
  background-color: rgba(255, 177, 177, 0.15);
  border-radius: 6px;
  margin-top: 14px;

  &:hover {
    background-color: rgba(255, 177, 177, 0.3);
  }
`

export const StyledReloadButton = styled(Button)`
  padding: 7px 16px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: #ffffff;
  border-radius: 6px;
  margin-top: 12px;
  /*  &:hover {
        background-color: rgba(255, 177, 177, 0.15);
    } */
`
