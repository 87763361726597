import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material'
import styled from 'styled-components'
import { theme } from '../../../../../global/styles/theme'
import { TESNParamsVariant } from '../../../../../global/types/commos-def'
import { ESN_DESCRIPTION_ESN_VARIANT_VALUES, ESN_DESCRIPTION_TERMS } from '../../../../../global/variables'

interface Descriptions {
  optionName: 'cpIncomingTax' | 'cpOutgoingTax' | 'ESN' | 'FSS' | 'taxSystem'
}

export const StyledTableHeadRow = styled(TableRow)`
  background: white !important;
  vertical-align: bottom;
  border: none !important;
  .MuiTableCell-root {
    padding: ${theme.spacing(1.5, 1)};
    border-bottom: none;
    color: ${theme.palette.primary.main};
  }
  :last-of-type {
    border-bottom: 1px solid #d1d8fa !important;
  }
`

export const StyledTableFirstCell = styled(TableCell)`
  color: ${theme.palette.primary.main};
  font-weight: 500;
  text-align: left !important;
`

export const StyledTableBodyRow = styled(TableRow)`
  padding: ${theme.spacing(1, 0)};
  .MuiTableCell-root {
    box-shadow: none !important;
    text-align: center;
  }
`

export function Descriptions(props: Descriptions) {
  const { optionName } = props
  switch (optionName) {
    case 'ESN':
      return (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableHeadRow>
                  <TableCell width="153px"></TableCell>
                  <TableCell width="153px">ОПС Предельная база</TableCell>
                  <TableCell width="153px">Ставка ОПС до достижения предельной базы</TableCell>
                  <TableCell width="153px">Ставка ОПС свыше достижения предельной базы</TableCell>
                  <TableCell width="153px">ВНиМ(ОСС) Предельная база</TableCell>
                  <TableCell width="153px">Ставка ВНиМ(ОСС) до достижения предельной базы</TableCell>
                  <TableCell width="153px">Ставка ВНиМ(ОСС) свыше достижения предельной базы</TableCell>
                  <TableCell width="153px">Ставка ОМС</TableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {ESN_DESCRIPTION_ESN_VARIANT_VALUES.map((variant) => {
                  const {
                    ESNVariant,
                    OPSLimit,
                    OPSInLimitRate,
                    OPSOutLimitRate,
                    VNIMLimit,
                    VNIMInLimitRate,
                    VNIMOutLimitRate,
                    OMSRate,
                  } = variant

                  return (
                    <StyledTableBodyRow>
                      <StyledTableFirstCell>{ESNVariant}</StyledTableFirstCell>
                      <TableCell>{OPSLimit}</TableCell>
                      <TableCell>{OPSInLimitRate}</TableCell>
                      <TableCell>{OPSOutLimitRate}</TableCell>
                      <TableCell>{VNIMLimit}</TableCell>
                      <TableCell>{VNIMInLimitRate}</TableCell>
                      <TableCell>{VNIMOutLimitRate}</TableCell>
                      <TableCell>{OMSRate}</TableCell>
                    </StyledTableBodyRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" flexDirection="column" p={1} gap={1}>
            {ESN_DESCRIPTION_TERMS.map((term) => {
              const { termName, text } = term

              return (
                <Box display="inline-block">
                  <Typography variant="subtitle2" color="primary" display="inline">
                    {termName}{' '}
                  </Typography>
                  -
                  <Typography variant="body2" display="inline" sx={{ color: theme.palette.secondary.dark }}>
                    {' '}
                    {text}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </>
      )
  }
  return null
}
