import {
  Box,
  BoxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Modal,
  ModalProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { theme } from '../../global/styles/theme'

interface IRadioGroupProps extends RadioGroupProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

export const MyModal = {
  Wrapper: function Wrapper(props: ModalProps) {
    const { children } = props

    return (
      <Modal
        sx={{
          display: 'flex',
          borderRadius: theme.spacing(1),
          '::focused': { bgcolor: 'red' },
          m: 10,
        }}
        {...props}
      >
        {children}
      </Modal>
    )
  },
  Inner: function Inner(props: BoxProps) {
    const { children } = props

    return (
      <Box
        margin="auto"
        minWidth={400}
        maxWidth={1300}
        borderRadius={theme.spacing(1)}
        bgcolor="white"
        padding={theme.spacing(3.75)}
        display="flex"
        flexDirection="column"
        {...props}
      >
        {children}
      </Box>
    )
  },
  ModalRadioGroup: function ModalRadioGroup(props: IRadioGroupProps) {
    const { children, value, onChange } = props

    return (
      <FormControl sx={{ paddingY: 1 }}>
        <RadioGroup value={value} onChange={onChange}>
          {children}
        </RadioGroup>
      </FormControl>
    )
  },
  ModalRadio: function ModalRadio(props: Partial<FormControlLabelProps>) {
    const { label, value } = props

    return <FormControlLabel sx={{ p: 1 }} value={value} control={<Radio sx={{ mr: 4, p: 0 }} />} label={label} />
  },
  Title: function Title(props: TypographyProps) {
    const { children } = props

    return (
      <Typography variant="subtitle1" component="h2" color={'primary'} textAlign="center" mb={2}>
        {children}
      </Typography>
    )
  },
  Guide: function Guide(props: TypographyProps) {
    const { children } = props

    return (
      <Typography variant="body2" component="p" textAlign="center" mb={2}>
        {children}
      </Typography>
    )
  },
  Description: function Description(props: TypographyProps) {
    console.log('props: ', props)

    const { children, style } = props

    return (
      <Typography style={style} variant="body2" component="p" color={theme.palette.secondary.main} mb={4}>
        {children}
      </Typography>
    )
  },
}
