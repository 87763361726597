import { SvgIcon } from '@mui/material'

export function IncomeBeforeTaxesIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00002 15.3337C3.95183 15.3337 0.666687 12.0485 0.666687 8.00033C0.666687 3.95214 3.95183 0.666992 8.00002 0.666992C12.0482 0.666992 15.3334 3.95214 15.3334 8.00033C15.3334 12.0485 12.0482 15.3337 8.00002 15.3337ZM2.00002 8.00033C2.00002 11.3121 4.68821 14.0003 8.00002 14.0003C11.3118 14.0003 14 11.3121 14 8.00033C14 4.68852 11.3118 2.00033 8.00002 2.00033C4.68821 2.00033 2.00002 4.68852 2.00002 8.00033ZM10.0923 8.26688C10.2527 8.54632 10.3334 8.87224 10.3334 9.2457C10.3334 9.81876 10.1676 10.2954 9.83626 10.6777C9.50451 11.0601 9.02673 11.2857 8.40047 11.3533V12.3337H7.6034V11.3576C6.56115 11.2473 5.91598 10.6224 5.66669 9.48554L6.89921 9.15429C7.0129 9.8695 7.39109 10.2276 8.03337 10.2276C8.33335 10.2276 8.55526 10.1516 8.69648 9.99752C8.8377 9.84411 8.90893 9.66049 8.90893 9.44329C8.90893 9.21875 8.8377 9.04828 8.69648 8.93402C8.61402 8.86656 8.47252 8.78859 8.27212 8.70004C8.12898 8.63681 7.95585 8.56818 7.75271 8.49413C7.5593 8.42504 7.38451 8.35641 7.22836 8.28796C7.0307 8.20139 6.86304 8.11513 6.72524 8.02863C6.47882 7.87617 6.27884 7.66314 6.12556 7.388C5.9705 7.11162 5.89393 6.78997 5.89393 6.42448C5.89393 5.94481 6.03228 5.51196 6.30609 5.12806C6.58156 4.74455 7.0129 4.51046 7.60327 4.42449V3.66699H8.40047V4.42449C9.29096 4.53455 9.869 5.05362 10.1321 5.98166L9.03194 6.44557C8.81839 5.80956 8.48814 5.4908 8.03885 5.4908C7.81421 5.4908 7.63354 5.56126 7.49766 5.7038C7.36082 5.84627 7.29247 6.01837 7.29247 6.22153C7.29247 6.42759 7.35808 6.58543 7.48958 6.69684C7.62121 6.80686 7.90188 6.9438 8.33335 7.10584C8.80606 7.28368 9.17864 7.45285 9.44698 7.61094C9.71682 7.76914 9.9305 7.98899 10.0923 8.26688Z"
        />
      </svg>
    </SvgIcon>
  )
}
