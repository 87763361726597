import { Box, Button, Divider, Typography } from '@mui/material'
import { Status } from './components/Status'
import { ProjectDetail } from './components/ProjectDetail'
import { EventAvailable } from '@mui/icons-material'
import { IncomeIcon } from '../../img/customIcons/IncomeIcon'
import { OutcomeIcon } from '../../img/customIcons/OutcomeIcon'
import { IncomeBeforeTaxesIcon } from '../../img/customIcons/IncomeBeforeTaxesIcon'
import EventNote from '@mui/icons-material/EventNote'
import { IProjectInfo } from '../../global/types/commos-def'
import Edit from '@mui/icons-material/Edit'
import { StyledTypography } from './styles'
import { useTranslation } from 'react-i18next'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
interface IProjectCardProps {
  project: IProjectInfo
  onEdit: () => void
  onClick: (e: any) => void
}

export function ProjectCard(props: IProjectCardProps) {
  const { t: translate } = useTranslation('ENUMS')
  const { project, onEdit, onClick } = props

  return (
    <Box
      onClick={(e) => onClick(e)}
      p={2}
      bgcolor={'white'}
      borderRadius={2}
      sx={{
        zIndex: 0,
        transition: 'box-shadow 0.3s ease-in-out',
        ':hover': {
          boxShadow:
            '0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
        <Status title={`Рентабельность ${project.rentability} %`} value={project.rentability} />
        <Button
          size="small"
          onClick={(e) => {
            onEdit()
            e.stopPropagation()
          }}
        >
          <Edit fontSize="small" color="primary" />
        </Button>
      </Box>
      <StyledTypography variant="subtitle1" color="primary" pb={0.5} style={{ WebkitLineClamp: 1 }}>
        {project.name}
      </StyledTypography>
      <StyledTypography variant="body2" style={{ WebkitLineClamp: 2 }}>
        {project.address}
      </StyledTypography>
      <Divider sx={{ pt: 2, borderColor: 'var(--LightPrimaryShades8p)' }}></Divider>
      <Box display="flex" flexDirection="column" pt={1} gap={0.5}>
        <ProjectDetail title="Дата начала:" icon={<EventNote />} value={project.dateStart} detailType={'string'} />
        <ProjectDetail
          title="Дата окончания:"
          icon={<EventAvailable />}
          value={project.dateEnd}
          detailType={'string'}
        />
        {project.type === 'GEN' && (
          <ProjectDetail title="Доходы:" icon={<IncomeIcon />} value={project.incomePlan} detailType={'number'} />
        )}
        <ProjectDetail
          title={`${project.type === 'GEN' ? 'Расходы' : 'Итого'}:`}
          icon={<OutcomeIcon />}
          value={project.expensesPlan}
          detailType={'number'}
        />
        {project.type === 'GEN' && (
          <ProjectDetail
            title="Прибыль до налога:"
            icon={<IncomeBeforeTaxesIcon />}
            value={project.netProfit}
            detailType={'number'}
          />
        )}
        <ProjectDetail
          title="Тип проекта:"
          icon={<BusinessCenterIcon fontSize={'small'} />}
          value={translate('PROJECT_TYPE.' + project.type)}
          detailType={'string'}
        />
      </Box>
    </Box>
  )
}
