import React from 'react'
import qs from 'qs'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { scrollableWrapper } from '../../../../../global/styles/presets'
import { ISubBill } from '../../../../../global/types/commos-def'
import { AccountantAPI } from '../../../../../global/api/APIMethods/AccountantAPI'
import { IBillQuerySchema } from '../Bills'
import { useHistory, useLocation } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { SubBillListItem } from './SubBillListItem'
import { HelpPlug } from '../../../../../components/HelpPlug/HelpPlug'
import { profileSelector } from '../../../../../store/slices/profile'
import { useTypedSelector } from '../../../../../store/store'
import { TControlBtns, TControlsState } from '../../../ReferencePage'
import { useDidMountEffect } from '../../../../../hooks/useDidMountEffect'
import { IControlBtnState } from '../../../../MappingPage/MappingPage'

export interface ISubBillListState {
  subBillList: ISubBill[] | null
  gostBillName: string | undefined
}

interface ISubBillListProps {
  controls: TControlsState
  setControls: React.Dispatch<React.SetStateAction<TControlsState>>
}

export function SubBillList(props: ISubBillListProps) {
  const { controls, setControls } = props

  const [state, setState] = React.useState({
    subBillList: null,
    gostBillName: undefined,
  } as ISubBillListState)

  const history = useHistory()

  const location = useLocation()

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IBillQuerySchema

  const profile = useTypedSelector(profileSelector)

  const { firstName } = profile

  React.useEffect(() => {
    initiateGostBillData()
    initiateSubBillList()
  }, [])

  React.useEffect(() => {
    state.subBillList && state.subBillList.length > 0 && changeBthState('addNewSubBillBtn', 'hidden', false)
    return () => {
      changeBthState('addNewSubBillBtn', 'hidden', true)
    }
  }, [state.subBillList])

  useDidMountEffect(() => {
    controls.addNewSubBillBtn.clicked && createSubBill()
    changeBthState('addNewSubBillBtn', 'clicked', false)
  }, [controls.addNewSubBillBtn.clicked])

  function changeBthState(btn: TControlBtns, stateName: keyof IControlBtnState, stateNameValue: boolean) {
    setControls((prevState) => ({
      ...prevState,
      [btn]: {
        ...prevState[btn],
        [stateName]: stateNameValue,
      },
    }))
  }

  function initiateGostBillData() {
    AccountantAPI.getGostBills().then(({ data }) => {
      let targetGostBill = data.filter((item) => item.id == Number(queryParams.gostBillID))[0]
      setState((prevState) => ({
        ...prevState,
        gostBillName: targetGostBill.number + '. ' + targetGostBill.name,
      }))
    })
  }

  function initiateSubBillList() {
    AccountantAPI.getSubBillsListByGostBill(Number(queryParams.gostBillID)).then(({ data }) => {
      setState((prevState) => ({
        ...prevState,
        subBillList: data,
      }))
    })
  }

  function goToBillsSubPage() {
    history.push({
      pathname: '/reference',
      search: qs.stringify({
        subPage: 'Bills',
        gostBillID: undefined,
      }),
    })
  }

  function createSubBill() {
    AccountantAPI.createSubBill({
      gostBillID: Number(queryParams.gostBillID),
      name: 'Новый субсчет',
    }).then((data) => {
      setState((prevState) => ({
        ...prevState,
        subBillList: [...[{ ...data, name: 'Новый субсчет' }], ...(prevState.subBillList || [])],
      }))
    })
  }

  return (
    <Box mr={1}>
      {!state.subBillList ||
        (state.subBillList.length == 0 && state.gostBillName && (
          <>
            <HelpPlug.Wrapper>
              <HelpPlug.Title>
                Здравствуйте {firstName}, у вас еще нет субсчетов в счете {state.gostBillName.toUpperCase()}.
                <br />
                Давайте добавим Ваш первый субсчет.
              </HelpPlug.Title>
              <HelpPlug.Btn onClick={createSubBill} startIcon={<Add />}>
                Добавить субсчет
              </HelpPlug.Btn>
              <HelpPlug.Image />
            </HelpPlug.Wrapper>
          </>
        ))}
      {state.subBillList != null && state.subBillList.length != 0 && (
        <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)', pb: 0 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: 40 }}>
                <TableCell width={'44px'}>
                  <ArrowBack
                    fontSize="small"
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={goToBillsSubPage}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>{state.gostBillName}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.subBillList?.map((subBill) => {
                const { id, name } = subBill
                return (
                  <SubBillListItem key={subBill.id} parentState={state} setParentState={setState} subBill={subBill} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}
