import React, { useCallback, useContext } from 'react'
import {
  StyledIntegrtaionLabelCard,
  StyledStatusIntegrtaionCard,
  StyledProjectName,
  StyledDrawerFormWrapper,
  StyledLabelModule,
  StyledListItem,
  StyledLogo,
  StyledLogoContainer,
  StyledLogoTypography,
  StyledModuleCard,
  StyledStatus,
  StyledTypography,
} from './styles'
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Button from '../../../../components/Button'
import { theme } from '../../../../global/styles/theme'
import { useHistory, useParams } from 'react-router-dom'
import { Params } from '../../../../global/types/params-def'
import { IntegrationDrawerFormProps } from './IntegrationDrawerForm.types'
import logoRM from '../../../../assets/logoRM.svg'
import { AutocompleteTextfield } from './components/AutocompleteTextfield'
import { IProjectRM } from '../../../../global/api/definitions'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DoneIcon from '@mui/icons-material/Done'
import { AutocompletePopper } from './components/AutocompletePopper'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import { changeQueryParams } from '../../../../global/functions'
import { InterfaceContext } from '../../../../contexts/context.interface'
import { CircularProgressPercent } from '../../../../components/_NEW/CircularProgressPercent/CircularProgressPercent'
import { socketChanel } from '../../../../global/api/socket'

import { successHandler } from '../../../../global/api/api'

import { useTranslation } from 'react-i18next'
/* **************************************************************************** */

const IntegrationDrawerForm: React.FC<IntegrationDrawerFormProps> = ({
  onClose,
  projectRMList,
  financeCenter,
  handleRefresh,
  handleRefreshRMList,
  isIntegrateRM,
  setIsIntegrateRM,
  isSettings,
  setSettings,
  setProgressTime,
  setProgressPercent: setProgressPercent,
  progressPercent,
  progressTime,
  uploadRecords,
  setUploadRecords,
  //socketChanel,
  setCloseUpload,
  errorMsg,
  setErrorMsg,
}) => {
  const { projectID, financeCenterID } = useParams() as Params
  const history = useHistory()
  const auth = JSON.parse(localStorage.getItem('auth') || '{}')

  const interfaceCTX = useContext(InterfaceContext)
  const { t } = useTranslation('production_program')
  const [projectRM, setProjectRM] = React.useState<IProjectRM | null>(null)

  const [syncDate, setSyncDate] = React.useState<string | null>(null)
  const [syncTime, setSyncTime] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (isSettings) setProjectRM(null)
  }, [isSettings])

  React.useEffect(() => {
    const indProject = projectRMList.findIndex((item) => item.id === financeCenter.rmId)
    setProjectRM(projectRMList[indProject])
  }, [financeCenter.rmId])

  React.useEffect(() => {
    setSyncTime(new Date(financeCenter.syncDate).toLocaleTimeString())
    setSyncDate(new Date(financeCenter.syncDate).toLocaleDateString())
  }, [financeCenter.syncDate])

  const onBindRmProject = useCallback(() => {
    if (socketChanel.readyState === 3) {
      console.log('socketChanel.readyState: ', socketChanel.readyState)
      // socketChanel.close();
      handleRefresh()
    }

    const msgWS = {
      type: 'rm_bind',
      id: financeCenterID,
      bodyJson: JSON.stringify({
        rmParam: {
          id: projectRM!.id,
          name: projectRM!.title,
        },
        financeCenterID: financeCenterID,
      }),
    }
    socketChanel!.send(JSON.stringify(msgWS))

    socketChanel!.onmessage = function (event) {
      let status = JSON.parse(event.data).type

      if (status === 'status_ok') {
        handleRefreshRMList()
        setSettings(false)
      }
      if (status === 'error') {
      }
    }
    socketChanel!.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, код=${event.code} причина=${event.reason}`)
      }
    }
    socketChanel!.onerror = function (error) {
      console.log(`[socketChanel-error]`, error)
    }
  }, [socketChanel, projectRM])

  const onRmImportProject = useCallback(() => {
    if (socketChanel.readyState === 3) {
      console.log('onRmImportProject-readyState: ', socketChanel.readyState)
      handleRefresh()
    }
    const msgWS = {
      type: 'rm_sync_start',
      id: financeCenterID,
      bodyJson: JSON.stringify({
        projectID: projectID,
        financeCenterID: financeCenterID,
      }),
    }
    socketChanel.send(JSON.stringify(msgWS))
    setIsIntegrateRM('loading')
  }, [projectRM])

  socketChanel.onmessage = function (event) {
    let status = JSON.parse(event.data).type
    let syncId = JSON.parse(event.data).id

    if (status === 'sync_progress' && Number(financeCenterID) === syncId) {
      setIsIntegrateRM('loading')
      let bodyJson = JSON.parse(event.data).bodyJson
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress // %
      setProgressPercent!(Number(progress))
    }

    if (status === 'rm_sync_result') {
      let bodyJson = JSON.parse(event.data).bodyJson
      let value = JSON.parse(bodyJson).value
      setUploadRecords!(value.importedRecords)

      setSyncTime(new Date(value.syncDate).toLocaleTimeString())
      setSyncDate(new Date(value.syncDate).toLocaleDateString())
      successHandler('Файл успешно загружен')

      handleRefresh()
      handleRefreshRMList()

      setIsIntegrateRM('success')
      setProgressPercent!(0)
      setProgressTime!('')

      changeQueryParams(history, {
        offset: 0,
        limit: 100,
      })
    }

    if (status === 'canceled') {
      setIsIntegrateRM('canceled')
      setProgressTime!('')
      setProgressPercent!(0)
    }
    if (status === 'canceling') {
      setIsIntegrateRM('canceling')
    }

    if (status === 'error') {
      setIsIntegrateRM('error')
      setProgressPercent!(0)
      setProgressTime!('')

      let bodyJson = JSON.parse(event.data).bodyJson
      let msg = JSON.parse(bodyJson).msg.split('.')[1]
      setErrorMsg(t(`${msg}`))
    }
  }

  const onDrawerClose = useCallback(() => {
    if (isIntegrateRM === 'loading') {
      onClose(true, false)
      setCloseUpload(true)
    }
    if (isIntegrateRM !== 'loading') {
      onClose(false, true)
    }
    if (isIntegrateRM === 'success') {
      onClose(false, true)
    }
  }, [isIntegrateRM])

  return (
    <Stack sx={{ height: '100%', overflow: 'auto', justifyContent: !isSettings ? '' : 'space-between' }}>
      <StyledDrawerFormWrapper>
        <Stack sx={{ height: '100%', justifyContent: 'flex-start' }}>
          <Stack spacing={5} sx={{ height: '100%' }}>
            {financeCenter.rmId && !isSettings ? (
              <Stack>
                <Stack spacing={2.5}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" sx={{ color: theme.palette.legends.orange }}>
                      ВНИМАНИЕ!
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
                      При загрузке отчета, он будет полностью перезаписан данными из нового файла.
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                      Последняя синхронизация:{' '}
                      {syncDate === 'Invalid Date' ? 'Не было синхронизации' : `${syncDate} в ${syncTime}`}
                    </Typography>
                  </Box>
                  <StyledLabelModule>
                    <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
                      Pragma.RM
                    </Typography>
                  </StyledLabelModule>
                </Stack>
                <StyledProjectName display="flex" mt={4} mb={'13px'}>
                  <Box display="flex" alignItems="center">
                    <DescriptionOutlinedIcon sx={{ color: '#5C6E8C', mr: 1 }} fontSize="small" />
                    <Typography variant="body2" sx={{ color: '#5C6E8C', whiteSpace: 'nowrap' }}>
                      Проект
                    </Typography>
                  </Box>
                  <StyledTypography textAlign="right" variant="subtitle2" style={{ lineHeight: '20px' }}>
                    {financeCenter.rmName}
                  </StyledTypography>
                </StyledProjectName>
                {isIntegrateRM === 'start' && <Box mb={2.5}></Box>}
                {isIntegrateRM !== 'start' && (
                  <StyledStatusIntegrtaionCard className="StatusIntegrtaionCard">
                    {isIntegrateRM === 'loading' && (
                      <CircularProgressPercent thickness={3.6} value={progressPercent} time={progressTime!} />
                    )}
                    {isIntegrateRM !== 'loading' && (
                      <StyledIntegrtaionLabelCard>
                        <Box sx={{ mt: '80px', mb: '75px' }}>
                          {isIntegrateRM === 'success' && <DoneIcon sx={{ color: '#6FCCBC', fontSize: '47px' }} />}
                          {(isIntegrateRM === 'error' || isIntegrateRM === 'canceled') && (
                            <WarningAmberOutlinedIcon sx={{ color: ' #F46B6B', width: '48px', height: '48px' }} />
                          )}
                        </Box>
                        {isIntegrateRM === 'success' && (
                          <Typography
                            variant="body2"
                            align="center"
                            mb={'26px'}
                            sx={{ color: theme.palette.secondary.main }}
                          >
                            Синхронизация проекта прошла успешно! Количество загруженных строк: {uploadRecords}
                          </Typography>
                        )}
                        {isIntegrateRM === 'error' && (
                          <Box mb={'26px'}>
                            <StyledTypography
                              variant="body2"
                              align="center"
                              sx={{ color: theme.palette.secondary.main, WebkitLineClamp: 2 }}
                            >
                              Ошибка!
                            </StyledTypography>
                            <StyledTypography
                              variant="body2"
                              align="center"
                              style={{ color: theme.palette.secondary.main, WebkitLineClamp: 2 }}
                            >
                              {errorMsg}
                            </StyledTypography>
                          </Box>
                        )}
                        {isIntegrateRM === 'canceling' && (
                          <Typography
                            variant="body2"
                            sx={{
                              marginLeft: '10px',
                              mt: '70px',
                              justifyContent: 'center',
                              color: theme.palette.secondary.gray,
                            }}
                          >
                            Идет процесс отмены загрузки...
                          </Typography>
                        )}
                        {isIntegrateRM === 'canceled' && (
                          <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                            Загрузка отменена
                          </Typography>
                        )}
                      </StyledIntegrtaionLabelCard>
                    )}
                  </StyledStatusIntegrtaionCard>
                )}
              </Stack>
            ) : (
              <Stack>
                <StyledModuleCard>
                  <StyledLogoContainer>
                    <StyledStatus>
                      <Typography sx={{ fontSize: '12px', lineHeight: '14px', color: '#FFFFFF' }}>ДОСТУПНО</Typography>
                    </StyledStatus>
                    <StyledLogo>
                      <img src={logoRM}></img>
                      <Box height="30px" sx={{ alignItems: 'center', display: 'flex' }}>
                        <StyledLogoTypography variant="body2" sx={{ color: theme.palette.text.dark }}>
                          Pragma.RM
                        </StyledLogoTypography>
                      </Box>
                    </StyledLogo>
                  </StyledLogoContainer>
                </StyledModuleCard>
                <Box sx={{ textAlign: 'center', mt: 5, minHeight: '543px' }}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
                    Выберите проект, из которого станет доступен запрос актуальных данных:
                  </Typography>
                  <Autocomplete
                    open={isSettings || !projectRM}
                    disablePortal
                    clearIcon={<ClearIcon fontSize="small" />}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={projectRM}
                    onChange={(event, value) => setProjectRM(value)}
                    options={projectRMList}
                    filterOptions={(p, s) => {
                      return p.filter((elem) => elem.title.toLowerCase().includes(s.inputValue.toLowerCase()))
                    }}
                    getOptionLabel={(option) => option.title}
                    PopperComponent={(props) => <AutocompletePopper {...props} />}
                    PaperComponent={(props) => (
                      <Paper
                        {...props}
                        sx={{
                          boxShadow: 'inset -1px 0px 0px rgb(0 36 95 / 10%)',
                          borderRadius: 'inherit',
                        }}
                      />
                    )}
                    renderOption={(props, option) => <StyledListItem {...props}>{option.title}</StyledListItem>}
                    renderInput={(params) => <AutocompleteTextfield {...params} />}
                    noOptionsText={'Нет данных'}
                    style={{ background: '#F6F7FB', marginBottom: '20px', marginTop: '28px' }}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      </StyledDrawerFormWrapper>
      <Stack sx={{ padding: '20px', pt: 0 }}>
        {financeCenter.rmId && !isSettings ? (
          <Stack className="className-Button" direction="row" spacing={2}>
            {!isSettings && isIntegrateRM !== 'success' ? (
              <>
                <Button
                  disabled={
                    isIntegrateRM === 'loading' ||
                    isIntegrateRM === 'canceled' ||
                    isIntegrateRM === 'canceling' ||
                    isIntegrateRM === 'error'
                  }
                  color="success"
                  size="medium"
                  fullWidth
                  onClick={onRmImportProject}
                >
                  Синхронизировать
                </Button>
                {isIntegrateRM === 'loading' && (
                  <Button size="medium" disabled={!progressTime} fullWidth onClick={onDrawerClose}>
                    Отменить
                  </Button>
                )}
                {isIntegrateRM !== 'loading' && (
                  <Button disabled={isIntegrateRM === 'canceling'} size="medium" fullWidth onClick={onDrawerClose}>
                    Закрыть
                  </Button>
                )}
              </>
            ) : (
              !isSettings && (
                <Button style={{ maxWidth: 'none' }} color="success" size="medium" fullWidth onClick={onDrawerClose}>
                  Готово
                </Button>
              )
            )}
          </Stack>
        ) : (
          <Stack direction="row" spacing={2}>
            <Button color="success" size="medium" fullWidth onClick={onBindRmProject} disabled={!projectRM}>
              Сохранить
            </Button>
            <Button size="medium" fullWidth onClick={() => onClose(true, false)}>
              Отменить
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default IntegrationDrawerForm
