import { theme } from './theme'

export const BUTTON_STYLES = {
  common: {
    minWidth: 'auto',
  },
  iconButton: {
    '.MuiButton-startIcon': {
      mr: 0,
      display: 'block',
      margin: 'auto',
    },
  },
  outlined: {
    secondary: {
      backgroundColor: 'var(--LightPrimaryShades8p)',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  primary: {},
  size: {
    small: {
      paddingX: '7px',
      paddingY: '7px',
      m: 0,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '22px',
    },
  },
}

export const ICON_STYLES = {
  size: {
    small: {
      // width: "20px",
      fontSize: '16px !important',
    },
    medium: {
      // width: "20px",
      fontSize: '20px !important',
    },
  },
}
