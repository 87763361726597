import { TUploadStatus } from './UploadFileDrawer.def'

export const DISABLE_SUBMIT_BTN_STATUSES: TUploadStatus[] = ['initial', 'canceling']

export const DISABLE_FILE_INPUT_STATUSES: TUploadStatus[] = [
  'upload_progress',
  'upload_success',
  'canceling',
  'canceled',
  'upload_another_user',
  'error',
]

export const LEAVE_WITH_CONFIRM_DIALOG_STATUSES: TUploadStatus[] = ['file_chosen', 'upload_progress']
