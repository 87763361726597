import { Box, Button, IconButton, TableCell, TextField, Tooltip, Typography } from '@mui/material'
import { CellRendererWrapper } from './CellRenderer.styles'
import { ICellEditorParams, ICellRendererParams, ITooltipParams } from 'ag-grid-community'
import { Delete, Error, Edit as EditIcon } from '@mui/icons-material'
import { EENumber, IEENumberProps } from '../../editableElements/EENumber'
import { EEString, EEStringWithRef, IEEStringProps } from '../../editableElements/EEString'
import { floatToJustifyContent } from '../functions'
import {
  ICellButton,
  ICellMultipleNumber,
  ICellMultipleNumberEditor,
  ICellMultipleString,
  ICellNumber,
  ICellNumberEditor,
  ICellString,
  ICellStringEditor,
} from './CellRenderer.types'
import { IValidationError } from '../../editableElements/EETableRow'
import { theme } from '../../../global/styles/theme'
import { getLvlStyles } from 'pages/ProductionProgram/components/EstimateTable'
import { IAgGridEstimateContext } from 'pages/ProductionProgram/ProductionProgram-def'
import { IPrPrEstimateOperationWithLvl } from 'global/types/commos-def'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useImperativeHandle, useRef } from 'react'

export const CellRenderer = {
  Tooltip: function (props: ITooltipParams) {
    const valueToDisplay = props.value.value ? props.value.value : undefined
    function getErrorMessages(value: any) {
      let validationErrorsArr = [] as IValidationError['message'][]
      for (let key in value) {
        if (value[key].isValid == false) {
          validationErrorsArr = [...validationErrorsArr, value[key].message]
        }
      }
      return validationErrorsArr
    }
    function checkValue(value: any) {
      for (let key in value) {
        if (value[key].isValid == false) {
          return true
        }
      }
    }
    return checkValue(valueToDisplay) ? (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        p={0.5}
        style={{
          backgroundColor: theme.palette.error.main,
        }}>
        <Box display="flex" gap={1} alignItems="center">
          <Error fontSize="small" />
          <Typography>Ошибка</Typography>
        </Box>
        {getErrorMessages(valueToDisplay).map((message, index) => {
          return (
            <Box>
              {index + 1}. {message};
            </Box>
          )
        })}
      </Box>
    ) : (
      <></>
    )
  },
  Multiple: {
    String: function (props: ICellMultipleString & ICellRendererParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEEStringProps, id: number) => (
              <EEString {...props} name={String(e)} value={String(e)} mode={'view'} key={id} />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
    Number: function (props: ICellMultipleNumber & ICellRendererParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell align={props.align} sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEENumberProps, id: number) => (
              <EENumber {...e} value={e?.value || 0} mode={'view'} key={id} />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
    NumberEditor: function (props: ICellMultipleNumberEditor & ICellEditorParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell align={props.align} sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEENumberProps, id: number) => (
              <EENumber
                {...e}
                value={e?.value || 0}
                NumberFormatProps={{
                  ...e?.NumberFormatProps,
                  onValueChange: (value) => props.onMultipleChangeValue && props.onMultipleChangeValue(props, e, value),
                }}
                mode={props.editableIndexes ? (props.editableIndexes.includes(id) ? 'edit' : 'view') : 'view'}
                key={id}
              />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
  },
  Button: {
    Edit: function (props: ICellButton & ICellRendererParams) {
      return (
        <CellRendererWrapper
          hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
          justifyContent={floatToJustifyContent(props?.align)}>
          <Button
            size="small"
            variant="text"
            onClick={() => {
              props?.onCellClicked && props.onCellClicked(props?.value)
            }}>
            <EditIcon fontSize="small" />
          </Button>
        </CellRendererWrapper>
      )
    },
    Delete: function (props: ICellButton & ICellRendererParams) {
      return (
        <CellRendererWrapper
          hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
          justifyContent={floatToJustifyContent(props?.align)}>
          <Button
            color={'error'}
            size="small"
            onClick={() => {
              props?.onCellClicked && props?.onCellClicked(props?.value)
            }}>
            <Delete color={'error'} fontSize="small" />
          </Button>
        </CellRendererWrapper>
      )
    },
    EstimateDelete: function (props: ICellButton & ICellRendererParams) {
      return (
        <CellRendererWrapper
          hideTotal={(Boolean(props?.node?.rowPinned) && props?.hideTotal) || !props?.node.data.unitType}
          justifyContent={floatToJustifyContent(props?.align)}>
          <Button
            color={'error'}
            size="small"
            onClick={() => {
              props?.onCellClicked && props?.onCellClicked(props?.value)
            }}>
            <Delete color={'error'} fontSize="small" />
          </Button>
        </CellRendererWrapper>
      )
    },
  },
  Number: function (props: ICellNumber & ICellRendererParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
        justifyContent={floatToJustifyContent(props.EENumberProps.align)}>
        <EENumber {...props?.EENumberProps} value={props?.value} />
      </CellRendererWrapper>
    )
  },
  EstimateNumber: function (props: ICellNumber & ICellRendererParams) {
    return props?.data?.unitType || props?.data?.name === 'ВСЕГО' ? (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
        justifyContent={floatToJustifyContent(props.EENumberProps.align)}>
        <EENumber {...props?.EENumberProps} value={props?.value} />
      </CellRendererWrapper>
    ) : null
  },
  NumberEditor: function (props: ICellNumberEditor & ICellEditorParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
        justifyContent={floatToJustifyContent(props.EENumberProps.align)}>
        <EENumber
          {...props?.EENumberProps}
          value={props?.value || 0}
          NumberFormatProps={{
            ...props?.EENumberProps.NumberFormatProps,
            onValueChange: (value) => props?.onChangeValue && props?.onChangeValue(props, value),
          }}
        />
      </CellRendererWrapper>
    )
  },
  String: function (props: ICellString & ICellRendererParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
        justifyContent={floatToJustifyContent(props?.EEStringProps?.align)}>
        <EEString {...props?.EEStringProps} value={props.value} />
      </CellRendererWrapper>
    )
  },
  StringEditor: (props: ICellStringEditor & ICellEditorParams) => {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
        justifyContent={floatToJustifyContent(props?.EEStringProps?.align)}>
        <EEStringWithRef
          {...props?.EEStringProps}
          value={props?.value}
          onChange={(e: any) => props?.onChangeValue && props?.onChangeValue(props, e.target.value)}
          isDefaultValue
        />
      </CellRendererWrapper>
    )
  },
  Lvl: function (props: ICellRendererParams<unknown, number>) {
    return props.value !== null && props.value !== undefined ? (
      <Typography
        sx={{
          width: '31px',
          height: '20px',
          lineHeight: '20px',
          fontSize: '10px',
          textAlign: 'center',
          borderRadius: '4px',
          ...getLvlStyles(props.value),
        }}>
        УР {props.value}
      </Typography>
    ) : (
      ''
    )
  },
  Arrow: function (props: ICellRendererParams<IPrPrEstimateOperationWithLvl, any, IAgGridEstimateContext>) {
    return props.data && props.data.id && props.data.hasChild ? (
      <IconButton
        sx={{
          width: '20px',
          height: '20px',
          padding: 0,
        }}
        color="inherit"
        component="button"
        onClick={() => {
          if (props.data && props.data.id) {
            props.context.updateHiddenRows(props.data.id)()
          }
        }}>
        {props.data.id && props.context.isRowHidden(props.data.id) ? (
          <KeyboardArrowUpIcon
            sx={{
              fontSize: 20,
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{
              fontSize: 20,
            }}
          />
        )}
      </IconButton>
    ) : (
      ''
    )
  },
}

export function getFlexJustifyContent(align: 'left' | 'center' | 'right') {
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
  }
}

interface ICellEditorReactComp {
  getValue: () => string[]
}
