import { Box, Typography } from '@mui/material'
import { theme } from 'global/styles/theme'

export function DefaultAlertText() {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body2" sx={{ color: theme.palette.legends.orange }}>
        ВНИМАНИЕ!
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.dark }}>
        При загрузке файла, данные будут полностью перезаписаны.
      </Typography>
    </Box>
  )
}
