import { FC } from 'react'
import { IPercentBadgeProps } from './PercentBadge.types'
import { Badge } from '@mui/material'
import { PercentBadgeWrapper } from './PercentBadge.styles'
import { formatter } from '../Report/Report'

export const PercentBadge: FC<IPercentBadgeProps> = ({ value, isShow = true }) => {
  if (!value || !isShow) return null

  let formattedValue = formatter.format(value)

  if (value > 0) formattedValue = `+${formattedValue}`

  return <PercentBadgeWrapper isPositive={value > 0}>{formattedValue}%</PercentBadgeWrapper>
}
