import { Box, BoxProps, Divider, Drawer, DrawerProps, Typography, TypographyProps } from '@mui/material'

export const MyDrawer = {
  Wrapper: function Wrapper(props: DrawerProps) {
    const { open, onClose, children } = props

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={(event, reason) => onClose && onClose(event, reason)}
        {...props}
        sx={{
          '& .MuiPaper-root': {
            width: '400px',
          },
          ...props.sx,
        }}
      >
        <Box height="100%" display="flex" flexDirection="column">
          {children}
        </Box>
      </Drawer>
    )
  },
  Title: function Title(props: TypographyProps) {
    const { children } = props

    return (
      <>
        <Typography
          color="primary"
          variant="h2"
          pt={2.6}
          pb={2}
          paddingX={2.5}
          textAlign={'center'}
          width="100%"
          justifyContent="center"
          {...props}
        >
          {children}
        </Typography>
        <Divider sx={{ borderColor: '#D6D1FA' }} />
      </>
    )
  },
  Content: function Content(props: BoxProps) {
    const { children } = props

    return (
      <Box sx={{ height: 'calc(100% - 66px)' }} {...props}>
        {children}
      </Box>
    )
  },
}
