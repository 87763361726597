import styled from "styled-components"
import { Box, Drawer, Stack, TextField } from "@mui/material"

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: 0 9px;
  }
  & .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 500;
  }
  & .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator {
    display: none;
  }
`
