import React from 'react'
import { Add, ExpandLess, ExpandMore, Remove } from '@mui/icons-material'
import { Box, FormControlLabel, Checkbox, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getFinCentersByType, sortFinCentersByName } from '../../../global/functions'
import { theme } from '../../../global/styles/theme'
import { IFinanceCenter, TFinanceCenterType } from '../../../global/types/commos-def'
import { ISelectFinCentersState } from './SelectFinCenter'

interface IFinCenterListProps {
  variant: 'select' | 'selected'
  finCenters: IFinanceCenter[]
  isAllGroupsSelected?: boolean
  finCenterType?: TFinanceCenterType
  selectGroup?: () => void
  unSelectGroup?: () => void
  addFinCenterToSelectedList?: (id: IFinanceCenter['id']) => void
  removeSelectedFinCenter?: (id: IFinanceCenter['id']) => void
  setParentState: React.Dispatch<React.SetStateAction<ISelectFinCentersState>>
  clearSelectedFinCenters?: () => void
}

export function FinCenterList(props: IFinCenterListProps) {
  const {
    variant,
    finCenterType,
    finCenters,
    addFinCenterToSelectedList,
    removeSelectedFinCenter,
    selectGroup,
    unSelectGroup,
    isAllGroupsSelected,
    setParentState,
    clearSelectedFinCenters,
  } = props

  const { t } = useTranslation('ENUMS')

  const [state, setState] = React.useState({
    isOpen: true,
    checked: false,
  })

  function switchIsOpen() {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }))
  }

  React.useEffect(() => {
    getFinCentersByType(finCenterType, finCenters).length > 0 &&
      setState((prevState) => ({
        ...prevState,
        checked: false,
      }))

    getFinCentersByType(finCenterType, finCenters).length == 0 &&
      setState((prevState) => ({
        ...prevState,
        checked: true,
      }))
    // console.log(finCenters)
  }, [finCenters])

  React.useEffect(() => {
    isAllGroupsSelected &&
      setState((prevState) => ({
        ...prevState,
        checked: true,
      }))
  }, [isAllGroupsSelected])

  return (
    <Box mb={2.25}>
      {variant == 'select' && finCenterType && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={switchIsOpen}
        >
          <FormControlLabel
            checked={state.checked}
            control={
              <Checkbox
                sx={{ svg: { color: theme.palette.primary.main } }}
                onClick={(e) => {
                  e.stopPropagation()
                  setState((prevState) => ({
                    ...prevState,
                    checked: (e.target as HTMLInputElement).checked,
                  }))
                  ;(e.target as HTMLInputElement).checked
                    ? selectGroup && selectGroup()
                    : unSelectGroup && unSelectGroup()
                }}
              />
            }
            label={t('FINANCE_CENTER_TYPES.' + finCenterType)}
            sx={{
              color: theme.palette.primary.main,
              '.MuiTypography-root': {
                fontWeight: 500,
              },
            }}
          />
          {state.isOpen ? (
            <ExpandLess sx={{ fontSize: '16px', m: 1 }} />
          ) : (
            <ExpandMore sx={{ fontSize: '16px', m: 1 }} />
          )}
        </Box>
      )}
      {finCenters &&
        ((finCenterType && state.isOpen) || !finCenterType) &&
        sortFinCentersByName(getFinCentersByType(finCenterType, finCenters)).map((finCenter) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="space-between" paddingY={1.25}>
              <Box display="flex" alignItems="center">
                {variant == 'select' ? (
                  <Add
                    color="primary"
                    fontSize="small"
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={(e) => addFinCenterToSelectedList && addFinCenterToSelectedList(finCenter.id)}
                  />
                ) : (
                  <Remove
                    color="primary"
                    fontSize="small"
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={(e) => removeSelectedFinCenter && removeSelectedFinCenter(finCenter.id)}
                  />
                )}
                <Typography
                  variant="body2"
                  textAlign="left"
                  sx={{ maxWidth: '170px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {finCenter.name}
                </Typography>
              </Box>
              <Typography
                variant="tooltip"
                textAlign="right"
                sx={{
                  maxHeight: '30px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  color: theme.palette.secondary.main,
                }}
              >
                {t('FINANCE_CENTER_TYPES.' + finCenter.type).replace('Производственная', 'Произв.')}
              </Typography>
            </Box>
          )
        })}
    </Box>
  )
}
