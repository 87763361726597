import { createSlice } from '@reduxjs/toolkit'
import { IOrganization } from '../../global/types/commos-def'
import { RootState } from '../store'
import { PublicUserProfile } from '../../api/users/types'
import { companyParam, config } from '../../global/config'

export type ProfileState = {
  profile: PublicUserProfile
  currentCompanyId: IOrganization['id']
  isLogout: boolean
}

let initialState: ProfileState = {
  profile: {} as ProfileState['profile'],
  currentCompanyId: 0,
  isLogout: false,
}
// TODO: добавить проверку на Черное море, в стор, убрать запросы за компанией в других местах
const updateState = (prevState: Partial<ProfileState>, payload: Partial<ProfileState>) => {
  const { profile, currentCompanyId, isLogout } = payload
  prevState.profile = profile
  prevState.currentCompanyId = currentCompanyId
  prevState.isLogout = isLogout
}

const profileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateProfileState: (state, { payload }) => {
      updateState(state, payload)
    },
    onLogout: (state) => {
      updateState(state, { isLogout: true })
      window.location.replace(`${config.coreURL}${companyParam.replace('&', '?')}`)
    },
  },
})

export default profileSlice.reducer

export const { updateProfileState, onLogout } = profileSlice.actions

export const profileSelector = (state: RootState) => state.profile.profile
export const currentCompanyIdSelector = (state: RootState) => state.profile.currentCompanyId
export const isLogoutSelector = (state: RootState) => state.profile.isLogout
