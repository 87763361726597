import ReactDOM from 'react-dom'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { InterfaceContextProvider } from './contexts/context.interface'
// import { localization } from './translations/localization'
import { App } from './App'
import { theme } from './global/styles/theme'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './global/styles/index.sass'
import { Layout } from './layouts/Layout'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { StyledEngineProvider } from '@mui/material'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import { i18n } from './translations/localization'
import React, { RefObject } from 'react'
import { ModalManagerLayout } from 'layouts/ModalManagerLayout'
import NiceModal from '@ebay/nice-modal-react'

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const notistackRef = React.createRef() as RefObject<SnackbarProvider>

ReactDOM.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <InterfaceContextProvider>
                <SnackbarProvider
                  ref={notistackRef}
                  style={{ maxWidth: 320 }}
                  anchorOrigin={snackbarAnchorOrigin}
                  maxSnack={1}
                  autoHideDuration={1500}
                  onClose={(event, reason, key) => {
                    if (reason === 'clickaway') {
                      notistackRef?.current!.closeSnackbar(key)
                    }
                  }}>
                  <div className="App">
                    <Layout>
                      <NiceModal.Provider>
                        <ModalManagerLayout>
                          <App />
                        </ModalManagerLayout>
                      </NiceModal.Provider>
                    </Layout>
                  </div>
                </SnackbarProvider>
              </InterfaceContextProvider>
            </I18nextProvider>
          </Provider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
