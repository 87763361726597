import { ModalProps } from '@mui/material'
//import _delete from '../../../img/userGuides/delete.svg'
import { MyModal } from '../MyModal/MyModal'
import { MyStepper } from '../Stepper/Stepper'
import { UserGuideStep } from '../UserGuideSteps/UserGuideSteps'

export interface IUserGuideStep {
  title: React.ReactNode
  content: React.ReactNode
  img: string
}

interface IUserGuideProps extends Partial<ModalProps> {
  steps: IUserGuideStep[]
}

export function UserGuide(props: IUserGuideProps) {
  const { steps, open } = props

  return (
    <MyModal.Wrapper open={open as boolean} {...props}>
      <MyModal.Inner width={600} height={320}>
        <MyStepper.Wrapper>
          {steps?.map((step: IUserGuideStep, index: number) => {
            return (
              <MyStepper.Step key={index} stepNumber={index}>
                <UserGuideStep.Wrapper>
                  <UserGuideStep.Image src={step.img} />
                  <UserGuideStep.Content>
                    <UserGuideStep.Title>{step.title}</UserGuideStep.Title>
                    <UserGuideStep.Content>{step.content}</UserGuideStep.Content>
                  </UserGuideStep.Content>
                </UserGuideStep.Wrapper>
              </MyStepper.Step>
            )
          })}
          <MyStepper.Pagination
            steps={steps.length}
            backButton={<MyStepper.BackBtn />}
            nextButton={<MyStepper.NextBtn />}
          />
        </MyStepper.Wrapper>
      </MyModal.Inner>
    </MyModal.Wrapper>
  )
}
