import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { theme } from '../../../global/styles/theme'
import { UploadTitle, UploadText } from '../../_NEW/UploadFile/style'
import { Fragment } from 'react'

export function Initial() {
  return (
    <Fragment>
      <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.secondary.gray }} />
      <UploadTitle variant="body2">загрузить xls(х)</UploadTitle>
      <UploadText variant="body2">
        Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.
      </UploadText>
    </Fragment>
  )
}
