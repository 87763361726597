import { SxProps, Theme } from "@mui/material"
import { theme } from "../../../../../global/styles/theme"

export const STYLES_FOR_OPERATION_NAME_BY_CATEGORY: Record<string, SxProps<Theme>> = {
  TOTAL: {
    minWidth: 200,
    height: 80,
    textAlign: "center",
    padding: theme.spacing(1),
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    letterSpacing: 0,
  },
  DEFAULT: {
    minWidth: "300px",
    textAlign: "left",
    padding: theme.spacing(2),
  },
}
