import { IBdrReportRequestBody, IBdrReportRequestBodyPeriod } from '../../../../global/api/definitions'
import {
  TMonthByQuarter,
  TMonthName,
  TQuarterName,
  monthByQuarterEnd,
  monthByQuarterStart,
} from '../../../../global/types/commos-def'
import { IBdrParametersFormData } from '../../../../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { RootState } from '../../../store'

export const bdrParametersSelector = (state: RootState): IBdrParametersFormData => state.bdrParameters

export const bdrReportRequestBodySelector = (state: RootState): IBdrReportRequestBody => {
  let periods: IBdrReportRequestBodyPeriod[] | null = null

  const getMonthByQuarter = (
    monthByQuarter: TMonthByQuarter,
    quarter: Omit<TQuarterName, 'ALL'> | null,
  ): TMonthName | null => {
    if (!quarter) return null

    // @ts-ignore
    return monthByQuarter[quarter]
  }

  periods = state.bdrParameters.periods.map((period): IBdrReportRequestBodyPeriod => {
    const { start, end } = period

    return {
      start: {
        month: start.month || getMonthByQuarter(monthByQuarterStart, start.quarter) || 'JANUARY',
        year: start.year,
      },
      end: {
        month: end.month || getMonthByQuarter(monthByQuarterEnd, end.quarter) || 'DECEMBER',
        year: end.year,
      },
    }
  })

  return { periods: periods.length ? periods : null }
}
