import { Stack } from "@mui/material"
import styled from "styled-components"

export const FormatSelectionItem = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`
