import React, { useContext } from 'react'
import { InputAdornment } from '@mui/material'
import { StyledSearch, StyledSearchBox, StyledSearchIcon } from './styles'
import { InterfaceContext } from '../../../contexts/context.interface'

const Search: React.FC = () => {
  const interfaceCTX = useContext(InterfaceContext)

  return (
    <StyledSearchBox>
      <StyledSearch
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Поиск"
        ref={interfaceCTX.searchRef}
        onChange={(e) => interfaceCTX.setSearchValue(e.target.value, 'userInput')}
      />
    </StyledSearchBox>
  )
}

export default Search
