import { Close, Done } from '@mui/icons-material'
import { Alert, AlertColor, AlertProps, AlertTitle, Box, Button, ButtonGroup, SxProps } from '@mui/material'
import React from 'react'
import { StyledButton, StyledButtonGroup } from './style'

export interface IAlertDialog extends AlertProps {
  title?: string
  open?: boolean
  message: React.ReactNode | React.ReactNode[]
  onYes?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onNo?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  sx?: SxProps
}

export function AlertDialog(props: IAlertDialog) {
  const { open, title, message, severity, sx, onYes, onNo } = props

  return open ? (
    <Alert
      severity={severity}
      sx={{
        justifyContent: 'flex-start',
        boxShadow:
          '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);',
        ...sx,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Box>
      <StyledButtonGroup className={'ButtonGroup'} variant="text" size="small">
        <StyledButton style={{ minWidth: '24px' }} onClick={(e) => onYes && onYes(e)}>
          <Done />
        </StyledButton>
        <StyledButton style={{ minWidth: '24px' }} onClick={(e) => onNo && onNo(e)}>
          <Close />
        </StyledButton>
      </StyledButtonGroup>
    </Alert>
  ) : (
    <></>
  )
}
