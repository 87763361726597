import { Box, Typography } from '@mui/material'

type TStatusType = 'succsess' | 'warning' | 'danger'

interface IBreakPoint {
  breakpoint: number
  color: string
}

interface IStatusProps {
  title: string
  value: number
  options?: {
    limit: number
    breakpoints: IBreakPoint[]
  }
}

const defaultOptions = {
  limit: 100,
  breakpoints: [
    {
      breakpoint: 80,
      color: 'var(--GreenLight)',
    },
    {
      breakpoint: 50,
      color: 'var(--OrangePrimary)',
    },
    {
      breakpoint: 30,
      color: 'var(--ErrorPrimary)',
    },
  ],
}

export function Status(props: IStatusProps) {
  const { title, value, options } = props

  function getTargetColorByValue(value: number, options: IStatusProps['options']) {
    let targetOptions = options || defaultOptions
    let targetColor = ''
    targetOptions.breakpoints.map((breakpoint: IBreakPoint, index: number) => {
      if (index == 0 && value > breakpoint.breakpoint) {
        targetColor = breakpoint.color
      } else if (index == targetOptions.breakpoints.length - 1 && value < breakpoint.breakpoint) {
        targetColor = breakpoint.color
      } else if (
        value >= breakpoint.breakpoint &&
        index > 0 &&
        value <= targetOptions.breakpoints[index - 1].breakpoint
      ) {
        targetColor = targetOptions.breakpoints[index - 1].color
      }
    })
    return targetColor
  }

  return (
    <Box
      paddingY={0.5}
      paddingX={1}
      bgcolor={getTargetColorByValue(value, options)}
      borderRadius={0.5}
      display="flex"
      alignItems="center"
    >
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '110%',
          textTransform: 'uppercase',
          color: 'white',
          textAlign: 'left',
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}
