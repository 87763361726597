import { api } from 'api/api'
import {
  IProductionProgramEstimateGetListQuery,
  IProductionProgramEstimateResponseV2,
  IProductionProgramEstimateTransformResponse,
} from 'global/api/definitions'
import { IPrPrEstimateOperation, IPrPrEstimateOperationWithLvl } from 'global/types/commos-def'

const baseURL: string = '/production-program'

let parentHash: Record<number, number[]> = {}

export const prPrApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEstimateV2: build.query<IProductionProgramEstimateTransformResponse, IProductionProgramEstimateGetListQuery>({
      query: (params) => ({
        url: baseURL + '/estimate/get-list/V2',
        method: 'GET',
        params,
      }),
      transformResponse: (res: IProductionProgramEstimateResponseV2): IProductionProgramEstimateTransformResponse => {
        let operations: IPrPrEstimateOperationWithLvl[] = []

        res.estimateItems.forEach((group) => {
          const currentLevel = group.lvl ?? 0

          if (group.parentId && group.id) {
            parentHash[group.id] = [...(parentHash[group.parentId] || []), group.parentId]
          }

          if (group.estimateItem != null) {
            // Проверяем, существует ли уже группа как отдельный элемент
            if (group.id && !operations.some((op) => op.id === group.id)) {
              // Группа с estimateItem, которая еще не добавлена
              operations.push({
                id: group.id,
                name: group.name ?? '',
                lvl: currentLevel,
                parentIds: parentHash[group.id] || [], // Копируем текущий стек
                hasChild: true,
                relationships: {
                  genContract: null,
                  subContract: null,
                },
              })
            }
            // Операция внутри группы
            operations.push({
              ...group.estimateItem,
              lvl: group.lvl !== null ? currentLevel + 1 : 0,
              parentIds: group.id ? [...parentHash[group.id], group.id] : [],
              hasChild: false,
            })
          } else {
            // Обычная группа без estimateItem
            operations.push({
              id: group.id,
              name: group.name ?? '',
              lvl: currentLevel,
              parentIds: group.id ? parentHash[group.id] || [] : [],
              hasChild: true,
              relationships: {
                genContract: null,
                subContract: null,
              },
            })
          }
        })

        return {
          total: res.total,
          operations: operations,
          currentPage: res.currentPage,
          maxPage: res.maxPage,
        }
      },
      providesTags: (result, error, arg) => ['ProductionProgramEstimate'],
    }),
    createOperation: build.mutation<
      number,
      { body: IPrPrEstimateOperation; params: IProductionProgramEstimateGetListQuery }
    >({
      query: ({ body, params }) => {
        return {
          url: baseURL + `/estimate/create`,
          method: 'POST',
          body,
          params,
        }
      },
      invalidatesTags: ['ProductionProgramEstimate'],
    }),
    updateOperationV2: build.mutation<void, { body: IPrPrEstimateOperation }>({
      query: ({ body }) => {
        return {
          url: baseURL + `/estimate/${body.id}/v2`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['ProductionProgramEstimate'],
    }),
    updateOperation: build.mutation<void, { body: IPrPrEstimateOperation }>({
      query: ({ body }) => {
        return {
          url: baseURL + `/estimate/${body.id}`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['ProductionProgramEstimate'],
    }),
    deleteOperation: build.mutation<void, { body: IPrPrEstimateOperation }>({
      query: ({ body }) => {
        return {
          url: baseURL + `/estimate/${body.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['ProductionProgramEstimate'],
    }),
  }),
})

export const {
  useLazyGetEstimateV2Query,
  useGetEstimateV2Query,
  useCreateOperationMutation,
  useUpdateOperationMutation,
  useUpdateOperationV2Mutation,
  useDeleteOperationMutation,
} = prPrApi
