import { ICON_STYLES } from "global/styles/buttonStyles"
import { paginationSelect } from "global/styles/presets"
import { theme } from "global/styles/theme"

export const styles = {
  ...paginationSelect,
  mr: "20px",
  ".MuiSelect-select": {
    p: theme.spacing(0.75, 2),
    fontSize: "13px !important",
    backgroundColor: "rgba(25, 118, 210, 0.08);",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    borderRadius: theme.spacing(0.75),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  fieldset: {
    // border: '1px solid rgba(92, 110, 140, 0.1)',
  },
  "label:not(.MuiInputLabel-shrink)": {
    // top: "0 !important",
    fontSize: "12px",
  },
  svg: {
    ...ICON_STYLES.size.medium,
    color: theme.palette.primary.main,
  },
}
