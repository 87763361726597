import { Box, BoxProps, TableCell, TableHead, TableProps, TableRow, TableRowProps, Typography } from '@mui/material'
import { CalendarHeadings } from '../../../components/CalendarHeadings/CalendarHeadings'
import { EENumber } from '../../../components/editableElements/EENumber'
import { tableCellInputSmall, tableCellInputSmall_ViewMode } from '../../../global/styles/presets'
import {
  IEstimatePlanningItem,
  IOperationUnitScale,
  IPrPrEstimateOperation,
  IPrPrOperationUnit,
  IPrPrPlanningAndAnaliticsOperation,
} from '../../../global/types/commos-def'
import { EstimateTable } from './EstimateTable'

interface IPrPrAnaliticRowProps {
  operationName?: string
  withDetails: boolean
  operation: IPrPrPlanningAndAnaliticsOperation
}

interface IOperationUnitProps {
  withDetails: boolean
  unit: IPrPrOperationUnit | IEstimatePlanningItem
}

interface IHeadingProps extends TableRowProps {
  budgetYears: string[]
}

export const AnaliticsTable = {
  Wrapper: (props: TableProps) => (
    <EstimateTable.Wrapper {...props} sx={{ ...props.sx, height: 'calc(100vh - 185px)' }}>
      {props.children}
    </EstimateTable.Wrapper>
  ),
  Headings: function Headings(props: IHeadingProps) {
    const { budgetYears } = props

    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: 'left', pl: 2 }}>Наименование работ</TableCell>
          <TableCell>Показатели</TableCell>
          <TableCell>План</TableCell>
          <TableCell>Реализовано всего</TableCell>
          <CalendarHeadings budgetYears={budgetYears} />
        </TableRow>
      </TableHead>
    )
  },
  Row: function Row(props: IPrPrAnaliticRowProps) {
    const { operationName, operation, withDetails } = props
    const { name, realizedTotal, estimate, units } = operation
    return (
      <TableRow>
        <TableCell align="left" sx={{ pl: 2 }}>
          {operationName || name}
        </TableCell>
        <TableCell align="left">
          {withDetails && (
            <>
              <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Генподряд</Box>
              <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Субподряд</Box>
            </>
          )}
          <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Прибыль</Box>
          <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Рентабельность</Box>
        </TableCell>
        <AnaliticsTable.OperationUnit withDetails={withDetails} unit={new EstimateToUnit(estimate)} />
        <AnaliticsTable.OperationUnit withDetails={withDetails} unit={realizedTotal} />
        {units?.map((unit) => {
          return <AnaliticsTable.OperationUnit withDetails={withDetails} unit={unit} />
        })}
      </TableRow>
    )
  },
  OperationUnit: function OperationUnit(props: IOperationUnitProps) {
    const { unit, withDetails } = props

    const { genContractValue, subContractValue, income, rentability } = unit || {}

    return (
      <TableCell align="left">
        <Box display="flex" flexDirection="column">
          {withDetails && (
            <>
              <EENumber
                mode="view"
                name={'genContractValue'}
                value={genContractValue}
                viewModeStyle={tableCellInputSmall_ViewMode}
                TextFieldProps={{
                  sx: tableCellInputSmall,
                }}
              />
              <EENumber
                mode="view"
                name={'subContractValue'}
                value={subContractValue}
                viewModeStyle={tableCellInputSmall_ViewMode}
                TextFieldProps={{
                  sx: tableCellInputSmall,
                }}
              />
            </>
          )}
          <EENumber
            mode="view"
            name={'income'}
            value={income}
            viewModeStyle={tableCellInputSmall_ViewMode}
            TextFieldProps={{
              sx: tableCellInputSmall,
            }}
            NumberFormatProps={{
              allowNegative: true,
            }}
          />
          <EENumber
            mode="view"
            name={'rentability'}
            value={rentability}
            viewModeStyle={{ ...tableCellInputSmall_ViewMode, marginLeft: '14px' }}
            TextFieldProps={{
              sx: tableCellInputSmall,
            }}
            NumberFormatProps={{
              suffix: ' %',
            }}
          />
        </Box>
      </TableCell>
    )
  },
}

export class EstimateToUnit implements IPrPrOperationUnit {
  nominal?: number | undefined
  reportType?: string | undefined
  scale?: IOperationUnitScale | undefined
  sum: number
  type: 'MONTH' | 'QUARTER' | 'YEAR' | 'TOTAL' | 'UNKNOWN'
  genContractValue: number
  income: number
  percent: number
  rentability: number
  subContractValue: number
  plannedAmount: number
  actualAmount: number
  discrepancy: number
  growth: number

  constructor(data: IPrPrEstimateOperation) {
    this.genContractValue = data?.genContractTotal || 0
    this.subContractValue = data?.subContractTotal || 0
    this.income = data?.income || 0
    this.rentability = data?.rentability || 0
    this.sum = 0
    this.type = 'UNKNOWN'
    this.percent = 0
    this.plannedAmount = 0
    this.actualAmount = 0
    this.discrepancy = 0
    this.growth = 0
  }
}
