import styled from "styled-components"
import { Box, Drawer, Stack, Typography } from "@mui/material"

export const StyledGridContainer = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "ProjectMenuGrid";
  width: 100%;
  height: calc(100vh - 67px);
  padding: 20px;
  overflow: auto;
`
export const StyledProjectMenuGrid = styled(Box)`
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(218px, 423px) minmax(218px, 423px); //  400px; repeat(2, 423px);
  max-height: 866px;
  min-height: 825px;
  gap: 20px 20px;
  grid-template-areas:
    "First Sec"
    "Third Fourth";
  grid-area: ProjectMenuGrid;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr); //  400px; repeat(2, 423px);
    max-height: none;
    min-height: 825px;
    gap: 20px 20px;
    grid-template-areas:
      "First"
      "Sec"
      "Third"
      "Fourth";
  }
`

export const StyledFirst = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-template-rows: minmax(173px, 1fr) minmax(173px, 1fr);
  gap: 20px 20px;
  grid-template-areas:
    "Head Head Head"
    "Body Body Body";
  grid-area: First;
  background: #fff;
  padding: 20px;
  // width: 845px;
  // height: 423px;
`
export const StyledHead = styled(Box)`
  grid-area: Head;
  display: flex;
  justify-content: flex-start;
  // gap: 20px;
  color: ${(props) => props.theme.palette.primary.main};
`

export const StyledDesc = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`
export const StyledTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  -webkit-line-clamp: 1;
`

export const StyledBody = styled(Box)`
  grid-area: Body;
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-template-rows: minmax(173px, 1fr);
  gap: 20px 20px;
  grid-template-areas: "NetProfit ExpensesPlan Rentability";
`
export const StyledNetProfit = styled(Box)`
  grid-area: NetProfit;
  border: 1px solid #edeff3;
  padding: 20px;
`

export const StyledExpensesPlan = styled(Box)`
  grid-area: ExpensesPlan;
  border: 1px solid #edeff3;
  padding: 20px;
`

export const StyledRentability = styled(Box)`
  grid-area: Rentability;
  border: 1px solid #edeff3;
  padding: 20px;
`

export const StyledSec = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-template-rows: repeat(2, minmax(173px, 1fr));
  gap: 20px 20px;
  grid-template-areas:
    "Budgets BDR BDDS"
    "Timetable Analysis Capital";
  grid-area: Sec;
`
export const StyledBudgets = styled(Box)`
  grid-area: Budgets;
  background: #fff;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
`
export const StyledBDR = styled(Box)`
  grid-area: BDR;
  background: #fff;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
`
export const StyledBDDS = styled(Box)`
  grid-area: BDDS;
  background: #fff;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
`

export const StyledInfo = styled(Stack)`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin: auto 0;
  padding-top: 8px;
`

export const StyledInfoItem = styled(Stack)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 8px 0px;
`

export const StyledInfoType = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #5c6e8c;
  text-align: left;
`

export const StyledInfoSum = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #2b3648;
`

export const StyledTimetable = styled(Box)`
  grid-area: Timetable;
  background: #fff;
  padding: 20px;
`
export const StyledAnalysis = styled(Box)`
  grid-area: Analysis;
  background: #fff;
  padding: 20px;
`
export const StyledCapital = styled(Box)`
  grid-area: Capital;
  background: #fff;
  padding: 20px;
`
export const StyledThird = styled(Box)`
  // display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 88px repeat(2, 147.5px);
  gap: 20px 20px;
  grid-template-areas:
    "IncomeAndExpenses IncomeAndExpenses IncomeAndExpenses"
    "Diagram Diagram Income"
    "Diagram Diagram Expenses";
  grid-area: Third;
  background: #fff;
  height: 100%;
`

export const StyledIncomeAndExpenses = styled(Box)`
  grid-area: IncomeAndExpenses;
  height: 88px;
  padding: 20px;
  border: 1px solid #edeff3;
`
export const StyledIncome = styled(Box)`
  grid-area: Income;
  border: 1px solid #edeff3;
`
export const StyledExpenses = styled(Box)`
  grid-area: Expenses;
  border: 1px solid #edeff3;
`
export const StyledDiagram = styled(Box)`
  grid-area: Diagram;
  border: 1px solid #edeff3;
`
export const StyledFourth = styled(Box)`
  grid-area: Fourth;
  background: #fff;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
`
