import { api } from '../api'
import { LoginByTokenRequest, LoginByTokenResponse } from './types'

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginByToken: build.mutation<LoginByTokenResponse, LoginByTokenRequest>({
      query: ({ token }) => ({
        url: `/auth/from-core`,
        method: 'POST',
        body: { token },
        // params: { token }
      }),
      invalidatesTags: () => ['Profile'],
    }),
  }),
  overrideExisting: false,
})

export const { useLoginByTokenMutation } = authApi
