import { Button, Stack } from '@mui/material'
import React from 'react'
import UserRoleSelection from '../../components/UserRoleSelection'
import { UserAccessesProps } from './UserAccesses.types'
import { MAX_WIDTH_USER_MANAGEMENT_BLOCK, MIN_WIDTH_USER_MANAGEMENT_BLOCK } from '../../../../../../global/variables'

const UserAccesses: React.FC<UserAccessesProps> = ({ isEditUser }) => {
  return (
    <Stack
      maxWidth={MAX_WIDTH_USER_MANAGEMENT_BLOCK}
      minWidth={MIN_WIDTH_USER_MANAGEMENT_BLOCK}
      flex={1}
      spacing={4.375}
    >
      <UserRoleSelection />
      <Button
        type="submit"
        color="success"
        variant="contained"
        fullWidth
        style={{
          alignSelf: 'end',
          maxWidth: 230,
        }}
      >
        {isEditUser ? 'Сохранить' : 'Создать'}
      </Button>
    </Stack>
  )
}

export default UserAccesses
