import Add from '@mui/icons-material/Add'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { parse } from 'date-fns'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from '../../api/projects'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { HelpPlug } from '../../components/HelpPlug/HelpPlug'
import { MyDrawer } from '../../components/MyDrawer/MyDrawer'
import { ProjectCard } from '../../components/ProjectCard/ProjectCard'
import { UserGuide } from '../../components/UserGuide/UserGuide'
import { InterfaceContext } from '../../contexts/context.interface'
import { ProjectForServer } from '../../global/api/constructors'
import { SWWithShaddowedInnerItems, scrollableWrapper, STYLED } from '../../global/styles/presets'
import { IProjectInfo, ProjectType } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { profileSelector } from '../../store/slices/profile'
import { useTypedSelector } from '../../store/store'
import { ProjectForm } from './components/ProjectForm'
import { PROJECT_USER_GUIDE_STEPS } from './components/ProjectUserGuide'
import { StyledTabs } from '../Fact/styles'

class ProjectForForm {
  name: string
  address: string
  dateStart: Date
  dateEnd: Date
  type?: ProjectType

  constructor(data: any) {
    this.name = data.name
    this.address = data.address
    this.dateStart = parse(data.dateStart, 'dd.MM.yyyy', new Date())
    this.dateEnd = parse(data.dateEnd, 'dd.MM.yyyy', new Date())
    this.type = data.type
  }
}
type TABS = null | 'GEN' | 'INVEST'
export function ProjectsPage() {
  const [state, setState] = React.useState({
    isUserGuideOpen: false,
    projects: null as IProjectInfo[] | null,
    openForm: false,
    formMode: 'add' as 'add' | 'edit',
    projectToEdit: null as IProjectInfo | null,
    refresh: true,
  })

  const [activeTab, setActiveTab] = React.useState<TABS>(null)

  const changeTab = (tab: TABS) => setActiveTab(tab)

  const { enqueueSnackbar } = useSnackbar()

  const { projectID } = useParams() as Params

  const history = useHistory()

  const interfaceCTX = useContext(InterfaceContext)

  const dispatch = useDispatch()

  const {
    data: projects,
    error: projectsError,
    isLoading: projectsIsLoading,
    isSuccess: projectsSuccess,
    // refetch: refresh,
  } = useGetProjectsQuery()

  const [updateProjectReq] = useUpdateProjectMutation()
  const [createProjectReq] = useCreateProjectMutation()
  const [deleteProjectReq] = useDeleteProjectMutation()

  React.useEffect(() => {
    initiateBreadcrumbs()
  }, [])

  React.useEffect(() => {
    projects &&
      setState((prevState) => ({
        ...prevState,
        projects: projects,
      }))
  }, [projects, state.refresh])

  // =============================================================================================

  function initiateBreadcrumbs() {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'title',
        title: 'Проекты',
      },
    ])
  }

  function openForm() {
    setState((v) => ({
      ...v,
      formMode: 'add',
      openForm: true,
      projectToEdit: null,
    }))
  }

  function openEditForm(project: IProjectInfo) {
    setState((v) => ({
      ...v,
      formMode: 'edit',
      openForm: true,
      projectToEdit: project,
    }))
  }

  function closeForm(reason?: 'create' | 'update' | 'delete') {
    setState((v) => ({
      ...v,
      openForm: false,
      projectToEdit: null,
    }))
    switch (reason) {
      case 'create':
        enqueueSnackbar('Проект успешно создан', { variant: 'success' })
        break
      case 'update':
        enqueueSnackbar('Проект успешно обновлен', { variant: 'success' })
        break
      case 'delete':
        enqueueSnackbar('Проект успешно удален', { variant: 'success' })
        break
    }
  }

  function createNewProject(data: any) {
    createProjectReq({ body: { ...new ProjectForServer(data) } }).then(() => closeForm('create'))
  }

  function updateProject(projectID: number, data: any) {
    updateProjectReq({ projectID: projectID, body: { ...new ProjectForServer(data) } }).then(() => closeForm('update'))
  }

  function deleteProject(projectID: number) {
    deleteProjectReq({ projectID: projectID }).then(() => closeForm('delete'))
  }

  function openUserGuide() {
    setState((prevState) => ({
      ...prevState,
      isUserGuideOpen: !prevState.isUserGuideOpen,
    }))
  }

  const profile = useTypedSelector(profileSelector)

  const { firstName } = profile

  const tabs = React.useMemo(() => {
    if (!state.projects) return null
    return state.projects
      .filter((project) => (activeTab ? project.type === activeTab : true))
      .map((project: IProjectInfo, index: number) => {
        return (
          <ProjectCard
            key={project.name + index}
            onClick={(e) => {
              history.push(`/projects/${project.id}/menu`)
            }}
            onEdit={() => openEditForm(project)}
            project={project}
          />
        )
      })
  }, [state.projects, activeTab])

  return (
    <Box>
      {state.projects && state.projects.length === 0 && (
        <>
          <HelpPlug.Wrapper>
            <HelpPlug.Title>
              Здравствуйте {firstName}, у вас еще нет проектов для отображения.
              <br />
              Давайте создадим ваш первый проект.
            </HelpPlug.Title>
            <HelpPlug.Btn onClick={openForm} startIcon={<Add />}>
              Добавить проект
            </HelpPlug.Btn>
            <HelpPlug.Image />
            <HelpPlug.HelpCenterLink openUserGuide={openUserGuide} />
          </HelpPlug.Wrapper>
          <UserGuide open={state.isUserGuideOpen} onClose={openUserGuide} steps={PROJECT_USER_GUIDE_STEPS} />
        </>
      )}

      {projectsIsLoading && <CircularProgress />}
      {state.projects && state.projects.length > 0 && (
        <>
          <ControlPanel.Wrapper justifyContent={'space-between'}>
            <Tabs onChange={(ev, value) => changeTab(value)} value={activeTab}>
              <Tab
                label={'Все проекты'}
                value={null}
                sx={{ letterSpacing: '0.46px !important', lineHeight: '22px !important' }}
              />
              <Tab
                label={'Инвестиционные'}
                value={'INVEST'}
                sx={{
                  letterSpacing: '0.46px !important',
                  lineHeight: '22px !important',
                }}
              />
              <Tab
                label={'Генподрядные'}
                value={'GEN'}
                sx={{
                  letterSpacing: '0.46px !important',
                  lineHeight: '22px !important',
                }}
              />
            </Tabs>
            <ControlPanel.Btn onClick={openForm} startIcon={<Add />}>
              Добавить проект
            </ControlPanel.Btn>
          </ControlPanel.Wrapper>
          <Box
            display="grid"
            sx={{
              ...scrollableWrapper,
              ...SWWithShaddowedInnerItems,
              height: 'calc(100vh - 100px)',
              // pb: "30px",
              gridGap: 20,
              gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))',
              gridTemplateRows: 'repeat(auto-fit, 350px)',
            }}
          >
            {tabs}
          </Box>
        </>
      )}

      <MyDrawer.Wrapper open={state.openForm} onClose={(e) => closeForm()}>
        <MyDrawer.Title>{state.formMode == 'add' ? 'Добавить проект' : 'Редактировать проект'}</MyDrawer.Title>

        <MyDrawer.Content>
          <ProjectForm
            mode={state.formMode}
            // @ts-ignore
            values={
              state.formMode == 'edit' && state.projectToEdit ? new ProjectForForm(state.projectToEdit) : undefined
            }
            onSubmit={(data) =>
              state.formMode == 'add' ? createNewProject(data) : updateProject(state.projectToEdit!.id, data)
            }
            onDelete={(e) => deleteProject(state.projectToEdit!.id)}
            onCancel={(methods, e) => {
              closeForm()
              methods.reset()
            }}
          />
        </MyDrawer.Content>
      </MyDrawer.Wrapper>
    </Box>
  )
}
