import {
  Button,
  ButtonPropsColorOverrides,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { CancelTokenSource } from 'axios'
import React, { ReactElement, useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react'
import { ButtonGroupCustom } from '../components/ButtonGroupCustom/ButtonGroupCustom'
import { MyModal } from '../components/MyModal/MyModal'
import { theme } from '../global/styles/theme'
import { MUIColor } from '../global/types/commos-def'

export interface UseExitConfirmProps {
  handleConfirm: (confirm: boolean, ...params: any) => void
  closeDeps?: any[]
  title?: string
  body?: string | ReactElement<{ children: string }>
  confirmButtonText?: string
  confirmButtonColor?: MUIColor
  denyButtonText?: string
}

export interface UseExitConfirmData {
  ConfirmDialog: React.FC
  openConfirm: (...params: any) => void
  onClose: (...params: any) => void
  isConfirmDialogOpened: boolean
}

const useConfirmDialog = (data: UseExitConfirmProps): UseExitConfirmData => {
  const {
    handleConfirm,
    closeDeps,
    confirmButtonText,
    confirmButtonColor = 'success',
    denyButtonText,
    title,
    body,
  } = data /* || {} as UseExitConfirmProps */
  const [open, setOpen] = useState(false)
  const [params, setParams] = useState<any[]>([])

  const openConfirm = useCallback((...params: any) => {
    setOpen(true)
    setParams(params)
  }, [])

  const onClose = useCallback(
    (confirm: boolean) => {
      setOpen(false)
      handleConfirm(confirm, ...params)
    },
    [params, ...(closeDeps || [])],
  )

  useEffect(() => {
    return () => {
      if (open) {
        setParams([])
      }
    }
  }, [open])

  const submitRef = useRef(null)

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', onKeyUp)
    }

    return () => document.removeEventListener('keyup', onKeyUp)
  }, [open])

  const onKeyUp = (e: any) => {
    switch (e.key) {
      case 'Enter':
        onClose(true)
        break

      case 'Escape':
        onClose(false)
        break

      default:
        break
    }
  }

  const ConfirmDialog: React.FC = () => (
    <>
      <MyModal.Wrapper open={open} /*  isConfirmOpen */ /*  onClose={closeConfirm} */>
        <MyModal.Inner>
          <MyModal.Title>{title || 'Вы уверены, что хотите закрыть форму?'}</MyModal.Title>
          <MyModal.Guide>{body || 'Форма будет закрыта, а все несохраненные данные будут удалены.'}</MyModal.Guide>
          <ButtonGroupCustom sx={{ mt: 1.5 }}>
            <Button
              variant="contained"
              color={confirmButtonColor}
              ref={submitRef}
              size="medium"
              type="submit"
              onClick={() => onClose(true)}
            >
              <Typography fontSize={14} fontWeight={500} color={theme.palette.text.light}>
                {confirmButtonText || 'Да'}
              </Typography>
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              // onClick={() => setConfirmOpen(false)}
              onClick={() => onClose(false)}
            >
              <Typography fontSize={14} fontWeight={500} color={theme.palette.text.light}>
                {denyButtonText || 'Отмена'}
              </Typography>
            </Button>
          </ButtonGroupCustom>
        </MyModal.Inner>
      </MyModal.Wrapper>
      {/* <Dialog
        open={open}
        onClose={() => onClose(false)}
        PaperProps={{
          style: {
            width: 400,
            textAlign: 'center',
          },
        }}
      >
        <DialogTitle style={{ justifyContent: 'center', paddingTop: 30, paddingBottom: 12 }}>
          <Typography variant="subtitle1">{title || 'Подтвердить закрытие формы?'}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" color={theme.palette.text.dark}>
              {body || 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.'}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
          <Button
            onClick={() => onClose(true)}
            autoFocus
            color="success"
            fullWidth
            style={{ maxWidth: 165 }}
          >
            {confirmButtonText || 'Да'}
          </Button>
          <Button onClick={() => onClose(false)} fullWidth style={{ maxWidth: 165 }}>
            {denyButtonText || 'Отмена'}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
  return {
    ConfirmDialog,
    openConfirm,
    onClose,
    isConfirmDialogOpened: open,
  }
}

export default useConfirmDialog
