import { SvgIcon, SvgIconProps } from '@mui/material'
import { theme } from '../../global/styles/theme'

export function BudgetTypeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="-1 -1 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6667 1.99984H9.88C9.6 1.2265 8.86667 0.666504 8 0.666504C7.13333 0.666504 6.4 1.2265 6.12 1.99984H3.33333C2.6 1.99984 2 2.59984 2 3.33317V12.6665C2 13.3998 2.6 13.9998 3.33333 13.9998H12.6667C13.4 13.9998 14 13.3998 14 12.6665V3.33317C14 2.59984 13.4 1.99984 12.6667 1.99984ZM8 1.99984C8.36667 1.99984 8.66667 2.29984 8.66667 2.6665C8.66667 3.03317 8.36667 3.33317 8 3.33317C7.63333 3.33317 7.33333 3.03317 7.33333 2.6665C7.33333 2.29984 7.63333 1.99984 8 1.99984ZM4.66667 11.3332H9.33333V9.99984H4.66667V11.3332ZM11.3333 8.6665H4.66667V7.33317H11.3333V8.6665ZM4.66667 5.99984H11.3333V4.6665H4.66667V5.99984Z"
        />
      </svg>
    </SvgIcon>
  )
}
