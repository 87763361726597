import { TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getMonthRange, getQuarterRange, getQueryParam, getYearRange } from '../../global/functions'
import { TReportType, TMonthName, TQuarterName } from '../../global/types/commos-def'
import { MONTHS, QUARTERS } from '../../global/variables'
import { useTypedSelector } from '../../store/store'
import { bdrParametersSelector } from '../../store/slices/bdrParameters/selectors'
import { IBdrPeriod } from '../../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { findMatchingPeriod } from '../../utils/findMatchingPeriod'

const styles = {
  MONTHLY: {
    width: '80px',
  },
  QUARTERLY: {
    width: '150px',
  },
  YEARLY: {
    width: '50px',
  },
}

interface ICalendarHeadingsProps {
  budgetYears?: string[]
  inner?: boolean
}

export function BdrCalendarHeadings(props: ICalendarHeadingsProps) {
  const { budgetYears, inner } = props
  const bdrParameters = useTypedSelector(bdrParametersSelector)
  const { result, isResultShown, periods, periodType } = bdrParameters || {}
  const activedResultColumns: number = Object.values(result).filter((column) => column).length

  const { t } = useTranslation('ENUMS')

  const history = useHistory()
  const headerData = [...getTargetRangeArr(getQueryParam(history, 'reportType') as TReportType)]

  headerData.push('')

  function getTargetRangeArr(reportType: TReportType) {
    let targetArr: any[] = []
    const yearStart = getQueryParam(history, 'yearStart')
    const yearEnd = getQueryParam(history, 'yearEnd')

    if (yearStart === yearEnd && periods.length === 1 && periodType === 'MONTHLY') {
      periods.forEach((period) => {
        targetArr = [
          ...targetArr,
          ...period.columnsHeader.map((item) => {
            return item.split(',').length === 2 ? item : `${item},\u00A0${yearStart}`
          }),
        ]
      })
    } else {
      periods.forEach((period) => {
        targetArr = [...targetArr, ...period.columnsHeader]
      })
    }

    return targetArr
  }

  if (inner)
    return (
      <TableRow>
        {headerData.map((v, index) => {
          const lastCell = headerData.length === index + 1
          const { plan, fact, deviation } = result || {}
          let shownCellColumns = { plan: true, fact: true, deviation: true }
          if (lastCell && !isResultShown) return null

          if (!lastCell || !isResultShown) {
            let matchingPeriod: IBdrPeriod | null = findMatchingPeriod(periods, index)

            if (matchingPeriod) {
              if (!matchingPeriod.plan) shownCellColumns.plan = false
              if (!matchingPeriod.fact) shownCellColumns.fact = false
              if (!matchingPeriod.deviation) shownCellColumns.deviation = false
            }
          }

          return (
            <>
              <TableCell style={{ display: 'none' }}></TableCell>
              {(!lastCell || plan) && shownCellColumns.plan && <TableCell style={{ top: '46px' }}>План</TableCell>}
              {(!lastCell || fact) && shownCellColumns.fact && <TableCell style={{ top: '46px' }}>Факт</TableCell>}
              {(!lastCell || deviation) && shownCellColumns.deviation && (
                <TableCell style={{ top: '46px' }}>Отклонение</TableCell>
              )}
              <TableCell style={{ display: 'none' }}></TableCell>
            </>
          )
        })}
      </TableRow>
    )

  return (
    <>
      {getTargetRangeArr(getQueryParam(history, 'reportType') as TReportType).map((value, index) => {
        const lastCell = headerData.length === index + 1
        const { plan, fact, deviation } = result || {}
        let shownCellColumns = { plan: true, fact: true, deviation: true }
        if (lastCell && !isResultShown) return null

        if (!lastCell || !isResultShown) {
          let matchingPeriod: IBdrPeriod | null = findMatchingPeriod(periods, index)

          if (matchingPeriod) {
            if (!matchingPeriod.plan) shownCellColumns.plan = false
            if (!matchingPeriod.fact) shownCellColumns.fact = false
            if (!matchingPeriod.deviation) shownCellColumns.deviation = false
          }
        }

        return (
          <TableCell
            colSpan={Object.values(shownCellColumns).filter((item) => item).length}
            key={value}
            style={{ borderBottom: 'none' }}
            // sx={{ ...styles[getQueryParam(history, 'reportType') as TReportType] }}
          >
            {value}
          </TableCell>
        )
      })}
      {isResultShown && (
        <TableCell
          style={{ borderBottom: 'none' }}
          colSpan={activedResultColumns}
          sx={{ ...styles[getQueryParam(history, 'reportType') as TReportType] }}
        >
          Итого
        </TableCell>
      )}
    </>
  )
}
