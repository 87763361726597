import { Dialog, Radio, Stack, Typography } from '@mui/material'
import Button from 'components/Button'
import { theme } from 'global/styles/theme'
import { FC, useState } from 'react'
import { FormatSelectionItem } from './FormatSelectionDialog.styles'
import { IFormatSelectionData, IFormatSelectionDialogProps, TFormatSelectionValue } from './FormatSelectionDialog.types'

const formatSelectionData: IFormatSelectionData[] = [
  {
    label: 'По количеству',
    value: 'QUANTITY',
  },
  {
    label: 'По сумме',
    value: 'AMOUNT',
  },
]

const SUBTITLE_BY_VALUE = {
  QUANTITY: 'Заполнение количественных показателей, расчет стоимостных',
  AMOUNT: 'Заполнение стоимостных показателей, расчет количественных',
  UNDEFINED: '',
}

export const FormatSelectionDialog: FC<IFormatSelectionDialogProps> = ({ isOpen, onSubmit, onClose }) => {
  const [selectedFormat, setSelectedFormat] = useState<TFormatSelectionValue>('QUANTITY')

  const onChangeFormat = (value: TFormatSelectionValue) => {
    setSelectedFormat(value)
  }

  const onSubmitClick = () => {
    onSubmit(selectedFormat)
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Stack padding={'30px'} width={'400px'} alignItems={'center'} spacing={3}>
        <Typography variant="subtitle1" color={theme.palette.primary.main}>
          Выберите формат заполнения бюджета
        </Typography>
        <Stack width={'100%'} spacing={1}>
          <Stack width={'100%'}>
            {formatSelectionData.map((item) => (
              <FormatSelectionItem onClick={() => onChangeFormat(item.value)}>
                <Radio checked={selectedFormat === item.value} />
                <Typography variant="subtitle2" fontSize={13}>
                  {item.label.toUpperCase()}
                </Typography>
              </FormatSelectionItem>
            ))}
          </Stack>
          <Typography
            fontSize={14}
            lineHeight={'20px'}
            letterSpacing={0.17}
            textAlign={'center'}
            color="#5C6E8C"
            maxWidth={300}
            alignSelf={'center'}
          >
            {SUBTITLE_BY_VALUE[selectedFormat]}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1.25} width={'100%'}>
          <Button color="success" width={'100%'} onClick={onSubmitClick}>
            Выбрать
          </Button>
          <Button width={'100%'} onClick={onClose}>
            отменить
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
