import { Box, Paper } from "@mui/material"
import styled from "styled-components"

export const DialogPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3.75)};
  width: 400px;
  border-radius: 8px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2.5)};
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const BtnGroup = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`
