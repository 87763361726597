import styled from "styled-components"
import { Stack, Typography } from "@mui/material"

export const ErrorsItemWrapper = styled(Stack)`
  padding: 16px 20px;
`

export const ErrorRowText = styled(Typography)`
  text-transform: initial;
  color: ${(props) => props.theme.palette.text.dark};
  line-height: 24px;
`
export const StyledTypography = styled(Typography)`
  width: 280px;
  text-align: left;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
`
