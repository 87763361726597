import { SxProps, Theme } from "@mui/material"
import { theme } from "../../global/styles/theme"

export const STYLES_BY_REPORT_TYPE = {
  MONTHLY: {
    width: "80px",
  },
  QUARTERLY: {
    width: "150px",
  },
  YEARLY: {
    width: "50px",
  },
}

export const STYLES_BY_CATEGORY: Record<string, SxProps<Theme>> = {
  TOTAL: {
    minWidth: 100,
    height: 80,
    textAlign: "center",
    padding: theme.spacing(1),
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    letterSpacing: 0,
  },
  DEFAULT: {},
}
