import React from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { theme } from '../../../../../../global/styles/theme'
import { useDidMountEffect } from '../../../../../../hooks/useDidMountEffect'
import { CheckBoxListItem, ICheckBoxListItem, ICheckBoxListItemProps } from './CheckBoxGroupItem'
import { IMappingArticlesState } from '../../MappingArticles'
import { ISubBillListState } from '../SubBillList'

export interface ICheckBoxGroupProps {
  onSelectGroup: () => void
  onUnselectGroup: () => void
  groupLabel: string
  checkBoxList: ICheckBoxListItem[]
  CheckBoxListItemProps?: Partial<ICheckBoxListItemProps>
  parentState: ISubBillListState
  setParentState: React.Dispatch<React.SetStateAction<ISubBillListState>>
}

export function CheckBoxGroup(props: ICheckBoxGroupProps) {
  const { groupLabel, checkBoxList, CheckBoxListItemProps, onSelectGroup, onUnselectGroup } = props

  const [state, setState] = React.useState({
    isGroupSelected: false,
    isOpen: false,
  })

  function switchIsOpen() {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }))
  }

  useDidMountEffect(() => {
    state.isGroupSelected && onSelectGroup()
    state.isGroupSelected &&
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
      }))
    // !state.isGroupSelected && onUnselectGroup()
  }, [state.isGroupSelected])

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isGroupSelected: checkBoxList.length == 0,
    }))
  }, [checkBoxList])

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ cursor: 'pointer' }}
        onClick={() => !state.isGroupSelected && switchIsOpen()}
      >
        <FormControlLabel
          checked={state.isGroupSelected}
          onClick={(e) => {
            !(e.target as HTMLElement).classList.contains('MuiButtonBase-root') && e.preventDefault()
          }}
          control={
            <Checkbox
              sx={{ svg: { color: theme.palette.primary.main } }}
              onClick={(e) => {
                e.stopPropagation()
                setState((prevState) => ({
                  ...prevState,
                  isGroupSelected: (e.target as HTMLInputElement).checked,
                }))
                !(e.target as HTMLInputElement).checked && onUnselectGroup()
              }}
            />
          }
          label={groupLabel.replace('_', '. ')}
          sx={{
            color: theme.palette.primary.main,

            '.MuiTypography-root': {
              fontWeight: 500,
              textAlign: 'left',
            },
          }}
        />
        {state.isOpen ? (
          <ExpandLess sx={{ fontSize: '16px', m: 1, mr: 0 }} />
        ) : (
          <ExpandMore sx={{ fontSize: '16px', m: 1, mr: 0 }} />
        )}
      </Box>
      <Box display={'flex'} flexDirection="column" ml={2}>
        {state.isOpen &&
          checkBoxList?.map((listItem) => {
            return (
              <CheckBoxListItem
                key={listItem.id}
                listItem={CheckBoxListItemProps?.listItem || listItem}
                isGroupSelected={CheckBoxListItemProps?.isGroupSelected || state.isGroupSelected}
                CheckBoxProps={CheckBoxListItemProps?.CheckBoxProps || {}}
                onSwitchCheckedState={(checked, listItem) => {
                  CheckBoxListItemProps?.onSwitchCheckedState &&
                    CheckBoxListItemProps.onSwitchCheckedState(checked, listItem)
                }}
              />
            )
          })}
      </Box>
    </Box>
  )
}
