import React, { useCallback, useEffect, useRef } from 'react'

export function usePrevState(value: any) {
  const ref = useRef()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}
