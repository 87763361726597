import styled from "styled-components"
import { Box, Button, FormControlLabel, FormLabel } from "@mui/material"
import FieldForm from "../../../../components/FieldForm/FieldForm"

export const StyledUploadDrawerFormWrapper = styled(Box)`
  padding: 20px;
`
export const StyledFieldFormTitle = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 33px;
    color: #f46b6b;
  }
`
export const StyledFieldFormDesc = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 62px;
    color: #f46b6b;
  }
`
export const StyledFieldFormClient = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 35px;
    color: #f46b6b;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  border: 1px solid rgba(25, 118, 210, 0.04);
  border-radius: 8px;
  margin: 0;
  margin-bottom: 12px;
  padding: 10px 8px 10px 13px;

  border: 1px solid ${(props) => (props.checked === true ? props.theme.palette.primary.light : "#EDEFF3")};
  background: ${(props) => (props.checked === true ? "rgba(25, 118, 210, 0.04)" : "#FFFFFF")};

  & .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #2b3648;
  }

  & .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0;
    margin-right: 13px;
  }
`
export const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 12px;
  color: #0044b4;
  font-weight: 500;
  font-size: 14px;
`

export const StyledDownloadTemplate = styled(Box)`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: #0044b4;
  }
`
export const StyledUploadStatusContainer = styled(Box)`
  width: 100%;
  text-align: center;
`
export const StyledUploadStatusCard = styled(Box)`
  // margin-top: 40px;
  width: 100%;
  height: 300px;
  padding: 16px;
  text-align: center;
  background-color: #f6f7fb;
  border-radius: 8px;
`
export const StyledUploadLabelCard = styled(Box)`
  width: 100%;
  text-align: center;
`

export const ShowErrorButton = styled(Button)`
  padding: 7px 8px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.error.main};
  background-color: rgba(255, 177, 177, 0.15);
  border-radius: 6px;
  margin-top: 14px;

  &:hover {
    background-color: rgba(255, 177, 177, 0.3);
  }
`
export const UploadButton = styled(Button)`
  padding: 7px 16px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: #ffffff;
  border-radius: 6px;
  margin-top: 12px;

  /*  &:hover {
        background-color: rgba(255, 177, 177, 0.15);
    } */
`
