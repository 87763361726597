import styled from "styled-components"
import { MenuItem, Select, TableCell, TableRow, Tabs, Typography } from "@mui/material"

export const StyledSelectMenuItem = styled(MenuItem)`
  width: 130px;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 13px;
  line-height: 22px;
  text-align: left;
  font-weight: 500;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }
  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }
`
export const StyledSelect = styled(Select)`
  width: 130px;
  background: rgba(25, 118, 210, 0.08);
  height: 36px;
  & .MuiSelect-select {
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 32px !important;
    height: 36px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

export const DisabledSelectMenuItem = styled(StyledSelectMenuItem)`
  &.Mui-disabled {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`
export const StyledTabs = styled(Tabs)`
  & .MuiButtonBase-root {
    padding: 7px 16px !important;
    line-height: 22px;
    height: 36px;
  }
`
export const StyledTypography = styled(Typography)`
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
