import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material'
import defaultImage from '../../img/helpPlugDefaultImg.svg'

interface IHelpPlugImageProps extends BoxProps {
  imgSource?: string
}
interface IHelpCenterLinkProps {
  children?: React.ReactNode | React.ReactNode[]
  openUserGuide: () => void
}

interface IHelpPlugProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[]
}

export const HelpPlug = {
  Wrapper: function Wrapper(props: IHelpPlugProps) {
    const { children } = props

    return (
      <Box display="flex" flexDirection="column" alignItems="center" height="calc(100vh - 120px)" {...props}>
        <Box display="flex" flexDirection="column" alignItems="center" marginY="auto">
          {children}
        </Box>
      </Box>
    )
  },
  Title: function Title(props: TypographyProps) {
    const { children } = props

    return (
      <Typography textAlign={'center'} mb={3.75} variant="h2" color={'primary'} {...props}>
        {children}
      </Typography>
    )
  },
  Btn: function Btn(props: ButtonProps) {
    const { children } = props

    return (
      <Button size="medium" variant="contained" {...props} sx={{ mb: 3.75, ...props.sx }}>
        {children}
      </Button>
    )
  },
  Image: function Image(props: IHelpPlugImageProps) {
    const { imgSource, sx } = props

    return (
      <Box marginY={3.75} sx={sx}>
        <img height={400} src={imgSource || defaultImage} />
      </Box>
    )
  },
  HelpCenterLink: function HelpCenterLink(props: IHelpCenterLinkProps) {
    const { children, openUserGuide } = props

    return (
      <Box mt={3.75} display="flex" flexDirection="column" alignItems="center">
        {children || (
          <>
            <Typography textAlign="center" variant="h2" color="primary">
              Нужна помощь?
            </Typography>
            <Typography
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              variant="h3"
              color="primary"
              fontWeight={400}
              onClick={(e) => openUserGuide()}
            >
              Перейти в справочный центр
            </Typography>
          </>
        )}
      </Box>
    )
  },
}
