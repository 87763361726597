import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getMonthRange, getQuarterRange, getQueryParam, getYearRange } from '../../global/functions'
import { TMonthName, TOperationCategory, TQuarterName, TReportType } from '../../global/types/commos-def'
import { MONTHS, QUARTERS } from '../../global/variables'
import { STYLES_BY_CATEGORY, STYLES_BY_REPORT_TYPE } from './CalendarHeadings.styles'
import { getTargetRangeArr } from './CalendarHeadings.utils'

interface ICalendarHeadingsProps {
  budgetYears?: string[]
  category?: TOperationCategory
}

export function CalendarHeadings(props: ICalendarHeadingsProps) {
  const { budgetYears, category } = props
  const history = useHistory()
  const reportType = getQueryParam(history, 'reportType') as TReportType

  const { t: translate } = useTranslation('ENUMS')

  const getCellStylesByCategory = () => {
    if (!category) return STYLES_BY_CATEGORY.DEFAULT
    return STYLES_BY_CATEGORY[category] ?? STYLES_BY_CATEGORY.DEFAULT
  }

  return (
    <>
      {getTargetRangeArr(history, reportType, translate, budgetYears).map((v) => {
        return (
          <TableCell
            key={v}
            sx={{
              ...STYLES_BY_REPORT_TYPE[getQueryParam(history, 'reportType') as TReportType],
              ...getCellStylesByCategory(),
            }}
          >
            {v}
          </TableCell>
        )
      })}
      <TableCell
        sx={{
          ...STYLES_BY_REPORT_TYPE[getQueryParam(history, 'reportType') as TReportType],
          ...getCellStylesByCategory(),
        }}
      >
        Итого
      </TableCell>
    </>
  )
}
