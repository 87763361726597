import { getMonthRange, getQuarterRange, getQueryParam, getYearRange } from 'global/functions'
import { TMonthName, TQuarterName, TRangeValue, TReportType } from 'global/types/commos-def'
import { MONTHS, QUARTERS } from 'global/variables'
import { History } from 'history'

export function getTargetRangeArr(
  history: History,
  reportType: TReportType,
  translate: (value: string) => void,
  budgetYears?: string[],
  shouldTranslate: boolean = true,
) {
  let targetArr = []
  switch (reportType) {
    case 'MONTHLY':
      targetArr = getMonthRange(
        getQueryParam(history, 'monthStart') as TMonthName,
        getQueryParam(history, 'monthEnd') as TMonthName,
        MONTHS,
      ).map((month: TMonthName) => (shouldTranslate ? translate('MONTHS_ABBR.' + month) : month))
      break
    case 'QUARTERLY':
      targetArr = getQuarterRange('FIRST', 'FOURTH', QUARTERS).map((quarter: TQuarterName) =>
        shouldTranslate ? translate('QUARTERS.' + quarter) : quarter,
      )
      break
    case 'YEARLY':
      if (budgetYears) {
        targetArr = getTargetYearsFromBudgetYears(history, budgetYears)
      } else {
        targetArr = getYearRange(
          getQueryParam(history, 'yearStart') as string,
          getQueryParam(history, 'yearEnd') as string,
        )
      }
      break
  }

  return targetArr as TRangeValue[]
}

function getTargetYearsFromBudgetYears(history: History, budgetYears: string[]) {
  const yearStart = Number(getQueryParam(history, 'yearStart'))
  const yearEnd = Number(getQueryParam(history, 'yearEnd'))
  return budgetYears.filter((year) => isYearInTargetRange(Number(year), yearStart, yearEnd))
}

function isYearInTargetRange(currentYear: number, yearStart: number, yearEnd: number) {
  return currentYear >= yearStart && currentYear <= yearEnd
}
