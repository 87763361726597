import React, { useCallback, useEffect, useState } from 'react'
import { DrawerTopBar, DrawerWrapper } from './styles'
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { theme } from '../../../../global/styles/theme'
import { Divider } from '../../../../components/Divider'
import IntegrationDrawerForm from '../IntegrationDrawerForm'
import { IntegrationDrawerProps } from './IntegrationDrawer.types'
import SettingsIcon from '@mui/icons-material/Settings'

const IntegrationDrawer: React.FC<IntegrationDrawerProps> = ({
  open,
  onClose,
  projectRMList,
  financeCenter,
  handleRefresh,
  handleRefreshRMList,
  isIntegrateRM,
  setIsIntegrateRM,
  setCloseUpload,
  errorMsg,
  setErrorMsg,
}) => {
  const onDrawerClose = useCallback(() => {
    switch (isIntegrateRM) {
      case 'loading':
        onClose(true, false)
        break
      case 'success':
        onClose(false, true)
        setIsIntegrateRM('start')
        break
      case 'canceled':
      case 'canceling':
      case 'start':
        onClose(false, true)
        break
      case 'error':
        onClose(true, false)
        break
      default:
        break
    }
  }, [isIntegrateRM, onClose])

  const [isSettings, setSettings] = useState<boolean>(false)
  const [progressTime, setProgressTime] = React.useState<string>('')
  const [progressPercent, setProgressPercent] = React.useState<number>(0)
  const [uploadRecords, setUploadRecords] = useState<number>(0)

  const onSettingsClick = useCallback((value) => {
    setSettings(value)
    setIsIntegrateRM('start')
  }, [])

  useEffect(() => {
    setSettings(false)
    setCloseUpload(false)
    if (isIntegrateRM === 'canceled') {
      setProgressTime('')
      setProgressPercent(0)
      setCloseUpload(false)
      setIsIntegrateRM('start')
    }
    /* if (isIntegrateRM === 'error') {
    setIsIntegrateRM('start')
    setProgressTime('')
    setProgressPercent(0)
   } */
  }, [open])

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onDrawerClose}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'hidden',
        },
      }}
    >
      <DrawerWrapper justifyContent="flex-start">
        <DrawerTopBar>
          <Typography variant="h1" color={theme.palette.primary.main}>
            {financeCenter.rmId && !isSettings ? ' Синхронизировать данные' : 'Доступные для интеграции по api:'}
          </Typography>
          {financeCenter.rmId && !isSettings && (
            <IconButton
              size="medium"
              color="primary"
              onClick={() => onSettingsClick(!isSettings)}
              sx={{ width: '35px', height: '35px', position: 'absolute', right: '20px', padding: 0 }}
              disabled={isIntegrateRM === 'loading'}
            >
              <SettingsIcon
                sx={{ color: isIntegrateRM === 'loading' ? theme.palette.primary.gray : theme.palette.primary.light }}
              />
            </IconButton>
          )}
        </DrawerTopBar>
        <Divider />
        <IntegrationDrawerForm
          onClose={onDrawerClose}
          projectRMList={projectRMList}
          financeCenter={financeCenter}
          handleRefresh={handleRefresh}
          handleRefreshRMList={handleRefreshRMList}
          isIntegrateRM={isIntegrateRM}
          setIsIntegrateRM={setIsIntegrateRM}
          isSettings={isSettings}
          setSettings={setSettings}
          setCloseUpload={setCloseUpload}
          setProgressTime={setProgressTime}
          progressTime={progressTime}
          uploadRecords={uploadRecords}
          setUploadRecords={setUploadRecords}
          progressPercent={progressPercent}
          setProgressPercent={setProgressPercent}
          // socketChanel={socketChanel}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        />
      </DrawerWrapper>
    </Drawer>
  )
}

export default IntegrationDrawer
