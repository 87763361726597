import { FC, useRef, useState, KeyboardEvent, useEffect, MouseEvent } from 'react'
import { IEditableCellNumberProps } from './EditableCellNumber.types'
import { EditableCellValue, EditableCellWrapper } from '../EditableCell/EditableCell.styles'
import { tableCellInput } from 'global/styles/presets'
import { NumberFormatValues } from 'react-number-format'
import { Stack, Tooltip } from '@mui/material'
import { EENumberGrid } from '../EditableCell/components/EENumberGrid'
import { TooltipBySumStyles } from '../../components/TooltipBySum/TooltipBySum.styles'
import { isElementOverflow } from 'utils/isElementOverflow'

export const EditableCellNumber: FC<IEditableCellNumberProps> = ({
  value = 0,
  onChangeValue,
  maxLines = 1,
  isTooltipEnable = true,
  isRequired = false,
  maxLength = Infinity,
  placeholder,
  viewCellProps = {},
  tooltip,
  isForciblyBlur,
  handleFocus,
  handleBlur,
  forceFocus,
  handleTab,
  shouldUpdate,
}) => {
  const [localValue, setLocalValue] = useState<number>(value)
  const [tooltipTitle, setTooltipTitle] = useState<string | null>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isTooltipOpened, setIsTooltipOpened] = useState<boolean>(false)
  const [isRootTooltipOpened, setIsRootTooltipOpened] = useState<boolean>(false)

  useEffect(() => {
    if (isForciblyBlur) onBlur()
  }, [isForciblyBlur])

  useEffect(() => {
    if (forceFocus) onCellValueClick()
  }, [forceFocus])

  const handleClick = () => {
    isFocused && onBlur()
  }

  useEffect(() => {
    if (!isFocused) return

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [isFocused, localValue, shouldUpdate])

  const checkCellValueForOverflow = (e: any) => {
    if (!isTooltipEnable || isFocused) return

    isElementOverflow(e) && openTooltip(e.target.innerText)
  }

  const openTooltip = (value: string) => {
    setTooltipTitle(value)
    setIsTooltipOpened(true)
  }

  const closeTooltip = () => {
    // setTooltipTitle(null)
    setIsTooltipOpened(false)
  }

  const onCellValueClick = () => {
    if (!onChangeValue || isEditMode) return

    setIsEditMode(true)
    setIsTooltipOpened(false)
    setIsRootTooltipOpened(true)
    !forceFocus && handleFocus && handleFocus()
  }

  const onLocalChangeValue = (newValue: number) => {
    setLocalValue(newValue)

    if (!newValue && isRequired) {
      setError('Обязательное поле')
      return
    }

    setError(null)
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = (shouldHandleBlur: boolean = true) => {
    if (error) return

    if ((localValue !== value || isForciblyBlur || shouldUpdate) && onChangeValue) onChangeValue(localValue)

    onLocalChangeValue(value)
    setIsEditMode(false)
    setIsFocused(false)
    shouldHandleBlur && handleBlur && handleBlur()
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        onBlur()
        break

      case 'Escape':
        setIsEditMode(false)
        onLocalChangeValue(value)
        handleBlur && handleBlur()
        break

      case 'Tab':
        onBlur(false)
        handleTab && handleTab()
        break

      default:
        break
    }
  }

  return (
    <EditableCellWrapper isEditMode={isEditMode} onClick={onCellValueClick} isError={!!error}>
      <Stack
        onMouseEnter={() => isFocused && tooltip && setIsRootTooltipOpened(true)}
        onMouseLeave={() => isFocused && tooltip && setIsRootTooltipOpened(false)}
        onClick={(e: any) => isFocused && e.stopPropagation()}
      >
        <Tooltip
          open={Boolean((!!tooltip && isRootTooltipOpened && isFocused) || isTooltipOpened)}
          title={tooltip || tooltipTitle}
          placement={!!tooltip ? 'bottom-start' : 'top-start'}
          PopperProps={!!tooltip ? TooltipBySumStyles : {}}
        >
          <Stack justifyContent={'center'}>
            <EENumberGrid
              name="unitPriceWithoutVAT"
              mode={isEditMode ? 'edit' : 'view'}
              value={localValue}
              onNumberChange={(values: NumberFormatValues) => onLocalChangeValue(values.floatValue || 0)}
              TextFieldProps={{
                sx: tableCellInput,
                onKeyUp: handleKeyUp,
                onFocus: onFocus,
                // onBlur: onBlur,
                ref: inputRef,
                onMouseEnter: checkCellValueForOverflow,
                onMouseLeave: closeTooltip,
              }}
              customInput={EditableCellValue}
              viewModeStyle={viewCellProps?.style || {}}
              viewModeProps={viewCellProps}
            />
          </Stack>
        </Tooltip>
      </Stack>
    </EditableCellWrapper>
  )
}
