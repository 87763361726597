import styled from 'styled-components'
import { Button, Stack, Alert, ButtonGroup } from '@mui/material'

export const StyledButton = styled(Button)`
  padding: 0;
`
export const StyledButtonGroup = styled(ButtonGroup)`
  min-width: 60px;
  justify-content: space-between;
`
