import React, { useCallback, useState } from 'react'
import UserForm from './UserForm'
import { InnerContainer, UserManagementWrapper } from './styles'
import { useHistory } from 'react-router-dom'
import { UserManagementProps } from './UserManagement.types'
import UserTopBar from './UserTopBar'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { PublicUserProfile } from '../../../../api/users/types'
import { useBanUserInModuleMutation } from '../../../../api/users'

export const UserManagement: React.FC<UserManagementProps> = ({ profile = {} as PublicUserProfile }) => {
  const { t } = useTranslation('user')
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()
  const isEditUser = !!profile?.company
  const userId = profile.id

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit')

  const onFormChange = (dirty: boolean) => {
    setIsFormDirty(dirty)
  }

  const [banUserInModule, banUserInModuleResponse] = useBanUserInModuleMutation()

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      history.push('/users')
    }
  }, [])

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      banUserInModule({ userId })
    }
  }, [])

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить пользователя?',
      body: (
        <>
          Пользователь будет полностью удален <br /> из модуля Pragma.Finance
        </>
      ),
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit')

    isFormDirty ? openConfirm() : history.push('/users')
  }, [isFormDirty])

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  useMutationHandlers(banUserInModuleResponse, () => {
    history.push('/users')
    enqueueSnackbar(t('success.profileDelete'), {
      variant: 'success',
    })
  })

  return (
    <InnerContainer className="InnerContainer" sx={{ height: '100%' }}>
      <UserManagementWrapper className="UserManagementWrapper" flex={1} sx={{ maxHeight: '91vh' }}>
        <UserTopBar onReturnClick={onReturnClick} onDeleteClick={onDeleteClick} isEditUser={isEditUser} />
        <UserForm userProfileToChange={profile} isEditUser={isEditUser} onFormChange={onFormChange} />
      </UserManagementWrapper>
      <ConfirmDialog />
    </InnerContainer>
  )
}
