import * as yup from 'yup'
import { MIN_PASSWORD_LENGTH_VALIDATION } from '../../../../global/variables'
import { userRolesArrayEn } from '../../Users.types'
import 'yup-phone-lite'

export const validationProfile = yup.object({
  lastName: yup.string().trim().required('Поле обязательно к заполнению'),
  firstName: yup.string().trim().required('Поле обязательно к заполнению'),
  middleName: yup.string().trim(),
  companyName: yup.string().trim(),
  position: yup.string().trim().required('Поле обязательно к заполнению'),
  phone: yup
    .string()
    .phone('RU', 'Введите телефон в формате +7 (9##) ###-##-##')
    .required('Поле обязательно к заполнению'),
  email: yup.string().email('Вы ввели неверный адрес электронной почты').required('Поле обязательно к заполнению'),
  login: yup.string().trim(),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH_VALIDATION, `Длина пароля не может быть менее ${MIN_PASSWORD_LENGTH_VALIDATION} символов`)
    .required('Поле обязательно к заполнению'),
  role: yup.string().oneOf(userRolesArrayEn),
})
