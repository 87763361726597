import ErrorsListDrawer from '../_NEW/ErrorsListDrawer'
import useWebSocket from '../../global/api/socketV3'
import { BtnGroupWrapper } from './BtnGroupWrapper'
import { ConfirmDialog } from './ConfirmDialog/ConfirmDialog'
import { DDFileInput } from './DDFileInput'
import { DDFileInputWrapper } from './DDFileInputWrapper'
import { DefaultAlertText } from './DefaultAlertText'
import { DefaultDownloadTemplate } from './DefaultDownloadTemplate'
import { DragWrapper } from './UploadFileDrawer.styles'
import { errorUploadHandler } from '../../global/api/api'
import { ExcelParseError } from '../../global/types/commos-def'
import { FormProvider, useForm } from 'react-hook-form'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { IUploadDrawerProps, IUploadStatus, TUploadStatus } from './UploadFileDrawer.def'
import { MyDrawer } from '../MyDrawer/MyDrawer'
import { useDropzone } from 'react-dropzone'
import {
  DISABLE_FILE_INPUT_STATUSES,
  DISABLE_SUBMIT_BTN_STATUSES,
  LEAVE_WITH_CONFIRM_DIALOG_STATUSES,
} from './UploadFileDrawer.service'

export function UploadFileDrawer({
  changeBlocking,
  refresh,
  ApiProps,
  DrawerProps,
  AlertTextComponent,
  DownloadTemplateComponent,
  DropzoneOptions,
}: IUploadDrawerProps) {
  const { connectSocketChannel, closeSocketChannel, sendFile, cancelSendFile } = useWebSocket(handleMessage, ApiProps)
  const methods = useForm()
  const UPLOAD_STATUS_DEFAULT: IUploadStatus = {
    type: 'initial',
  }
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>(UPLOAD_STATUS_DEFAULT)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [isErrorsListDrawerOpen, setIsErrorsListDrawerOpen] = useState<boolean>(false)
  const [errorList, setErrorList] = useState<ExcelParseError[]>([])

  const onDrop = useCallback((files: File[]) => {
    if (files?.length > 0) {
      methods.setValue('file', files[0])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    disabled: DISABLE_FILE_INPUT_STATUSES.includes(uploadStatus.type),
    ...DropzoneOptions,
  })

  function onErrorsListDrawerOpen() {
    setIsErrorsListDrawerOpen(true)
  }

  function onDropRejected() {
    const error = {
      message: 'Тип файла не является .xls или .xlsx ',
    }
    errorUploadHandler(error, 'upload')
  }

  function closeDrawer() {
    DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick')
    setIsConfirmDialogOpen(() => false)
    methods.reset()
    setUploadStatus(UPLOAD_STATUS_DEFAULT)
  }

  function continueDownloading() {
    DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick')
    setIsConfirmDialogOpen(() => false)
  }

  function triggerConfirmDialog() {
    if (LEAVE_WITH_CONFIRM_DIALOG_STATUSES.includes(uploadStatus.type)) {
      setIsConfirmDialogOpen(() => true)
    } else {
      closeDrawer()
    }
  }

  function repeatFile() {
    methods.reset()
    setUploadStatus(UPLOAD_STATUS_DEFAULT)
  }

  function checkType(type: TUploadStatus, pastType?: TUploadStatus): TUploadStatus {
    if (type === 'upload_progress' && pastType === 'canceling') return 'canceling'
    if (type === 'canceling' && pastType === 'upload_success') return 'error_upload_success'
    if (type === 'canceling' && pastType === 'canceled') return 'canceled'
    return type
  }

  function localCancelSendFile() {
    setIsConfirmDialogOpen(() => false)
    setUploadStatus((prevState) => ({
      ...prevState,
      type: 'canceling',
    }))
    cancelSendFile()
  }

  function handleMessage(event: any) {
    const data = JSON.parse(event.data)
    const bodyJson = JSON.parse(data.bodyJson) || {}
    setUploadStatus((prevState) => ({
      ...prevState,
      remainTime: bodyJson?.remainTime,
      progressPercent: bodyJson?.progress,
      type: checkType(data.type, prevState.type),
    }))
    if (data.type === 'upload_success') {
      setIsConfirmDialogOpen(() => false)
      refresh()
    } else if (data.type === 'error') {
      setIsConfirmDialogOpen(() => false)
      const errorList = JSON.parse(bodyJson?.msg)
      setErrorList(Array.isArray(errorList) ? errorList : [errorList])
    }
  }

  useEffect(() => {
    connectSocketChannel()
    return () => {
      closeSocketChannel()
    }
  }, [ApiProps.id])

  useEffect(() => {
    methods.getValues('file') &&
      setUploadStatus((prevState) => ({
        ...prevState,
        type: 'file_chosen',
      }))
  }, [methods.watch('file')])
  return (
    <>
      {errorList.length > 0 && (
        <ErrorsListDrawer
          open={isErrorsListDrawerOpen}
          onClose={() => setIsErrorsListDrawerOpen(false)}
          errorList={errorList}
        />
      )}
      <Fragment>
        <MyDrawer.Wrapper {...DrawerProps} onClose={triggerConfirmDialog}>
          <FormProvider {...methods}>
            <MyDrawer.Title>Загрузить Excel</MyDrawer.Title>
            <MyDrawer.Content p={2.5} gap={2.5} display={'flex'} flexDirection={'column'}>
              {!AlertTextComponent ? <DefaultAlertText /> : AlertTextComponent()}
              <DragWrapper drag={isDragActive} {...getRootProps()}>
                <DDFileInputWrapper
                  errorList={errorList}
                  repeatFile={repeatFile}
                  onErrorsListDrawerOpen={onErrorsListDrawerOpen}
                  uploadStatus={uploadStatus}
                />
                <DDFileInput inputProps={{ ...getInputProps() }} />
              </DragWrapper>
              {!DownloadTemplateComponent ? <DefaultDownloadTemplate /> : DownloadTemplateComponent()}
              <BtnGroupWrapper
                repeatFile={repeatFile}
                cancelSendFile={localCancelSendFile}
                uploadStatus={uploadStatus}
                DISABLE_SUBMIT_BTN_STATUSES={DISABLE_SUBMIT_BTN_STATUSES}
                triggerConfirmDialog={triggerConfirmDialog}
                closeDrawer={closeDrawer}
                sendFile={() => sendFile(methods.getValues('file'))}
              />
            </MyDrawer.Content>
          </FormProvider>
        </MyDrawer.Wrapper>
        <ConfirmDialog
          open={isConfirmDialogOpen}
          title={'Вы уверены, что хотите закрыть форму?'}
          message={'Форма будет закрыта, а все несохраненные данные будут потеряны'}
          onConfirm={{
            action: () => {
              uploadStatus.type === 'upload_progress' ? continueDownloading() : closeDrawer()
            },
            btnText: uploadStatus.type === 'upload_progress' ? 'Продолжить загрузку' : 'Да',
          }}
          onCancel={{
            action: () => {
              uploadStatus.type === 'upload_progress' ? localCancelSendFile() : setIsConfirmDialogOpen(() => false)
            },
            btnText: uploadStatus.type === 'upload_progress' ? 'Отменить' : 'Нет',
          }}
        />
      </Fragment>
    </>
  )
}
