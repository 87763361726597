import { ISubBillPair } from '../../../global/api/definitions'
import { IProjectInfo, IReportRelationshipItem, TFinanceCenterType } from '../../../global/types/commos-def'

export interface IBudgetFormProps {
  values?: IBudgetFormData
  mode: 'add' | 'edit'
  project: IProjectInfo
  dirty: boolean
  onSubmit: (data: any) => void
  onDeleteClick: () => void
  onCancel: (immediately?: boolean) => void
  setDirty: (dirty: boolean) => void
  isCapital: boolean
}

export interface IBudgetFormData {
  name: string
  type: TFinanceCenterType | ''
  billType?: string
  budgetYearStart: string
  budgetYearEnd: string
  subContractSubBill: string | undefined
  genContractSubBill: string | undefined
  ecExpenseSubBillID: string | undefined
  ecIncomeSubBillID: string | undefined
  finExpenseSubBillID: string | undefined
  finIncomeSubBillID: string | undefined
}

export const budgetFormDefaultValues = {
  name: '',
  type: '',
  subContractSubBill: undefined,
  genContractSubBill: undefined,
  budgetYearStart: '',
  budgetYearEnd: '',
  ecExpenseSubBillID: undefined,
  ecIncomeSubBillID: undefined,
  finExpenseSubBillID: undefined,
  finIncomeSubBillID: undefined,
} as IBudgetFormData

export interface IBudgetFormState {
  isProductionProgramChosen: boolean
  formValues: IBudgetFormData | null
  subBills: ISubBillPair[] | null
  subBillsFinance: IReportRelationshipItem[] | null
}
