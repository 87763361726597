import { Add } from '@mui/icons-material'
import { Box, CircularProgress, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalSwitcher } from '../../../../components/_NEW/ModalSwitcher/ModalSwitcher'
import { OrganizationAPI } from '../../../../global/api/APIMethods/OrganizationAPI'
import { theme } from '../../../../global/styles/theme'
import { TESNParamsVariant, TTaxSystem } from '../../../../global/types/commos-def'
import {
  CP_TAX,
  ESN_DESCRIPTION_ESN_VARIANT_VALUES,
  ESN_OPTIONS,
  TAX_SYSTEM,
  USN_OPTIONS,
} from '../../../../global/variables'
import { SettingCard } from '../../components/SettingCard/SettingCard'
import { StyledGridSettingsItemContainer } from '../../styles'
import { Descriptions } from './descriptions/Descriptions'
import ESN from '../../../../assets/taxes_img_1.svg'
import FSS from '../../../../assets/taxes_img_2.svg'
import taxSystem from '../../../../assets/taxes_img_4.svg'
import settlement from '../../../../assets/taxes_img_5.svg'
import { DescriptionTitle } from './style'

interface ITaxesState {
  cpIncomingTax: number | null
  cpOutgoingTax: number | null
  taxSystem: TTaxSystem | null
  ensType: TESNParamsVariant | null
  esnFSSPercent: number | null | undefined
  isLoaded: boolean
}

export function Taxes() {
  const [state, setState] = React.useState({
    cpIncomingTax: null,
    cpOutgoingTax: null,
    taxSystem: null,
    ensType: null,
    esnFSSPercent: undefined,
    isLoaded: false,
  } as ITaxesState)

  const { t } = useTranslation('settings')

  React.useEffect(() => {
    OrganizationAPI.getUserPreferences().then((data) =>
      setState((prevState) => ({
        ...prevState,
        cpIncomingTax: data?.cpIncomingTax,
        cpOutgoingTax: data?.cpOutgoingTax,
        taxSystem: data?.taxSystem,
        ensType: data?.ensType,
        esnFSSPercent: data?.esnFSSPercent,
        isLoaded: !!data,
      })),
    )
  }, [])

  return (
    <StyledGridSettingsItemContainer className="className-Taxes">
      {!state.isLoaded && <CircularProgress></CircularProgress>}
      {state.isLoaded && (
        <>
          <SettingCard.Wrapper
          // style={state.taxSystem === "УСН" ? {pointerEvents: 'none', opacity: '0.7'}: {}}
          >
            <SettingCard.Title>Налог при взаиморасчетах с контрагентами</SettingCard.Title>
            <SettingCard.Content>
              <SettingCard.OptionList>
                <SettingCard.Option.Wrapper>
                  <SettingCard.Option.Title>Входящий НДС</SettingCard.Option.Title>
                  <SettingCard.Option.Input
                    optionName="cpOutgoingTax"
                    TextFieldProps={{
                      defaultValue: state.cpOutgoingTax,
                      select: true,
                    }}
                  >
                    {CP_TAX.map((tax) => {
                      return <MenuItem value={tax}>{tax + ' %'}</MenuItem>
                    })}
                  </SettingCard.Option.Input>
                </SettingCard.Option.Wrapper>
                <SettingCard.Option.Wrapper>
                  <SettingCard.Option.Title>Исходящий НДС</SettingCard.Option.Title>
                  <SettingCard.Option.Input
                    optionName="cpIncomingTax"
                    TextFieldProps={{
                      defaultValue: state.cpIncomingTax,
                      select: true,
                    }}
                  >
                    {CP_TAX.map((tax) => {
                      return <MenuItem value={tax}>{tax + ' %'}</MenuItem>
                    })}
                  </SettingCard.Option.Input>
                </SettingCard.Option.Wrapper>

                <SettingCard.Image src={settlement} />
              </SettingCard.OptionList>
              <SettingCard.Option.Info>
                <Typography variant={'tooltip'}>
                  Используется при расчете показателя "На оплату НДС" строки "Распределяемые платежи" Бюджета Движения
                  Денежных Средств (БДДС)
                  <br />
                  <br />
                  Привязываются к субсчетам на уровне Бюджета Отдела/Подразделения на экране "Условия оплаты и налоги".
                  <br />
                  <br />
                </Typography>
              </SettingCard.Option.Info>
            </SettingCard.Content>
          </SettingCard.Wrapper>

          <SettingCard.Wrapper>
            <SettingCard.Title>Система налогообложения предприятия</SettingCard.Title>
            <SettingCard.Content>
              <SettingCard.OptionList>
                <SettingCard.Option.Wrapper>
                  <SettingCard.Option.Input
                    optionName="taxSystem"
                    TextFieldProps={{
                      defaultValue: state.taxSystem,
                      select: true,
                    }}
                  >
                    {TAX_SYSTEM.map((system) => {
                      return (
                        <MenuItem
                          value={system}
                          onClick={() => {
                            // setState(prevState => ({
                            // 	...prevState,
                            // 	taxSystem: system
                            // }))
                          }}
                        >
                          {system}
                        </MenuItem>
                      )
                    })}
                  </SettingCard.Option.Input>
                </SettingCard.Option.Wrapper>
                {/*{state.taxSystem === "УСН" &&*/}
                {/*	<SettingCard.Option.Wrapper>*/}
                {/*		<SettingCard.Option.Input*/}
                {/*			optionName="taxSystem"*/}
                {/*			TextFieldProps={{*/}
                {/*				defaultValue: "6% на Доходы",*/}
                {/*				select: true*/}
                {/*			}}*/}
                {/*		>*/}
                {/*			{USN_OPTIONS.map(system => {*/}
                {/*				return (*/}
                {/*					<MenuItem value={system}>{system}</MenuItem>*/}
                {/*				);*/}
                {/*			})}*/}
                {/*		</SettingCard.Option.Input>*/}
                {/*	</SettingCard.Option.Wrapper>*/}
                {/*}*/}
                <SettingCard.Image src={taxSystem} />
              </SettingCard.OptionList>

              <SettingCard.Option.Info>
                <Typography variant={'tooltip'}>
                  Используется при расчете показателя "На оплату НДС" строки "Распределяемые платежи" Бюджета Движения
                  Денежных Средств (БДДС)
                  <br />
                  При изменении параметра будет меняться алгоритм расчета.
                  <br />
                  <br />
                  <DescriptionTitle>ОСНО:</DescriptionTitle> <br />
                  "На оплату НДС" будет разницей значений Исходящего и Входящего НДС
                  <br />
                  <br />
                  <DescriptionTitle>УСН (Доходы):</DescriptionTitle> <br />
                  Налоговая база - сумма всех доходных операций по Бюджету. <br />
                  Налоговая ставка - 6%
                  <br />
                  <br />
                  <DescriptionTitle>УСН (Доходы минус расходы):</DescriptionTitle> <br />
                  Налоговая база - сумма всех доходных операций после вычета расходных операций. <br />
                  Налоговая ставка - 15%
                  <br />
                </Typography>
              </SettingCard.Option.Info>
            </SettingCard.Content>
          </SettingCard.Wrapper>

          <SettingCard.Wrapper>
            <SettingCard.Title>
              Вариант единого социального налога (ЕСН)
              <ModalSwitcher ModalContent={(props) => <Descriptions optionName={'ESN'} />} />
            </SettingCard.Title>
            <SettingCard.Content>
              <SettingCard.OptionList>
                <SettingCard.Option.Wrapper>
                  <SettingCard.Option.Input
                    optionName="ensType"
                    onOptionChange={(v) =>
                      setState((prevState) => ({
                        ...prevState,
                        ensType: v as TESNParamsVariant,
                      }))
                    }
                    TextFieldProps={{
                      defaultValue: state.ensType,
                      select: true,
                    }}
                  >
                    {ESN_OPTIONS.map((option) => {
                      return <MenuItem value={option.value}>{option.label}</MenuItem>
                    })}
                  </SettingCard.Option.Input>
                </SettingCard.Option.Wrapper>
                <SettingCard.Image src={ESN} />
              </SettingCard.OptionList>
              <SettingCard.Option.Info>
                <Box display="flex" flexDirection="column">
                  {ESN_DESCRIPTION_ESN_VARIANT_VALUES.map((value: { [index: string]: string }) => {
                    if (value.ESNVariant == t('ESNVariants.' + state.ensType)) {
                      let valuesArr = []
                      for (let key in value) {
                        if (key != 'ESNVariant')
                          valuesArr.push(
                            <Box display="flex" minHeight=" 40px" alignItems="center">
                              <Typography
                                variant={'tooltip'}
                                fontWeight={700}
                                sx={{
                                  color: theme.palette.secondary.dark,
                                  mr: 2.5,
                                  minWidth: '60px',
                                }}
                              >
                                {value[key]}
                              </Typography>
                              <Typography variant={'tooltip'}>{t('ESNTerms.' + key)}</Typography>
                            </Box>,
                          )
                      }
                      return valuesArr
                    }
                  })}
                </Box>
              </SettingCard.Option.Info>
            </SettingCard.Content>
          </SettingCard.Wrapper>

          <SettingCard.Wrapper>
            <SettingCard.Title>
              <Typography>Ставка Фонда социального страхования (ФСС)</Typography>
            </SettingCard.Title>
            <SettingCard.Content>
              <SettingCard.OptionList>
                <SettingCard.Option.Wrapper>
                  <SettingCard.Option.Input
                    number
                    optionName="esnFSSPercent"
                    rules={{
                      required: {
                        value: true,
                        message: 'Обязательное поле',
                      },
                      validate: {
                        isInRange: (v) => {
                          console.log(v)
                          return (v && v.replace(' %', '').replace(',', '.') <= 8.5) || 'От 0% до 8.5%'
                        },
                      },
                    }}
                    numberFormatProps={{
                      allowNegative: false,
                      decimalScale: 1,
                      defaultValue: (state.esnFSSPercent && state.esnFSSPercent) || 0,
                      suffix: ' %',
                      decimalSeparator: ',',
                    }}
                  />
                </SettingCard.Option.Wrapper>
                <SettingCard.Image src={FSS} />
              </SettingCard.OptionList>
              <SettingCard.Option.Info>
                <Typography variant={'tooltip'}>
                  Тариф страхового взноса на травматизм.
                  <br />
                  <br />
                  Рассчитанная на основе тарифа сумма добавляется к строке "ЕСН" операций бюджетов типа
                  Отдел/Подразделение в категории расходов "Фонд оплаты труда" (ФОТ).
                  <br />
                  <br />
                  Значение может быть установлено в диапазоне от 0 % до 8,5 %.
                </Typography>
              </SettingCard.Option.Info>
            </SettingCard.Content>
          </SettingCard.Wrapper>
        </>
      )}
    </StyledGridSettingsItemContainer>
  )
}
