import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { InterfaceContext } from '../../contexts/context.interface'
import { IPage, PAGES } from '../../global/routing'
import { PragmaLogo } from '../../img/customIcons/PragmaLogo'
import { NavigationBtn } from '../NavigationBtn/NavigationBtn'
import { BottomBox, BottomItemButton, StyledDrawer } from './styles'
import { useGetProjectsQuery } from '../../api/projects'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { IProjectInfo } from '../../global/types/commos-def'

export function Navigation() {
  const [state, setState] = React.useState({
    menuSize: 'small' as 'small' | 'large',
    projectID: null,
  })

  const [project, setProject] = React.useState<IProjectInfo | null>(null)

  const _navbarIsOpen = localStorage.getItem('navBarIsOpen') === 'true'

  const [open, setOpen] = useState(_navbarIsOpen)
  const [overrideOpen, setOverrideOpen] = useState(_navbarIsOpen)
  const interfaceCTX = React.useContext(InterfaceContext)
  const location = useLocation()

  const isCapital = React.useMemo(() => {
    return location.pathname.endsWith('capital/')
  }, [location])

  React.useEffect(() => {
    const match: any = matchPath(location.pathname, {
      path: '/projects/:projectID',
      exact: false,
      strict: true,
    })
    if (match) {
      interfaceCTX.setProject(match.params.projectID)
    } else {
      interfaceCTX.setProject('')
    }
  }, [location.pathname])

  React.useEffect(() => {
    if (interfaceCTX.activeProjectID) {
      ProjectsAPI.getProjectById(interfaceCTX.activeProjectID).then((response) => {
        setProject(response)
      })
    }
  }, [interfaceCTX.activeProjectID])

  function changeMenuSize(e: React.MouseEvent<HTMLDivElement, MouseEvent>, size: 'large' | 'small') {
    setState((prevState) => {
      return { ...prevState, menuSize: size }
    })
  }
  function getSubPagesByMainPage(mainPage: IPage, project: IProjectInfo | null) {
    let subPages = PAGES.filter((page) => page.pageType == 'sub' && page.groupBy == mainPage.title)
    if (project) {
      if (project.type === 'INVEST') {
        subPages = subPages.filter((page) => page.title !== 'БДР')
      } else {
        subPages = subPages.filter((page) => page.title !== 'Капвложения')
      }
    }
    if (subPages.length == 0) {
      return undefined
    } else {
      return subPages
    }
  }

  const mouseHandler = (value: boolean) => {
    if (overrideOpen) return
    setOpen(value)
  }

  const onOverride = () => {
    setOverrideOpen((value) => {
      localStorage.setItem('navBarIsOpen', String(!value))
      return !value
    })
    setOpen(true)
  }

  const style = {
    width: overrideOpen ? '236px' : '80px',
    '> .MuiPaper-root': {
      width: open ? '236px' : '80px',
    },
  }

  return (
    <StyledDrawer
      variant="permanent"
      className="navbar"
      onMouseMove={() => mouseHandler(true)}
      onMouseEnter={() => mouseHandler(true)}
      onMouseLeave={() => mouseHandler(false)}
      sx={style}
    >
      <Link to="/projects" style={{ width: '100%', textDecoration: 'none', overflow: 'hidden' }}>
        <Button
          sx={{ width: '100%', justifyContent: 'flex-start', padding: '20px 16px' }}
          variant={'text'}
          startIcon={<PragmaLogo sx={{ marginLeft: '13px', marginRight: 2.3, minWidth: '30px', height: 24 }} />}
        >
          <Typography variant={'h1'} component={'span'}>
            FINANCE
          </Typography>
        </Button>
      </Link>
      <Stack sx={{ justifyContent: 'space-between', height: '95vh' }}>
        <List sx={{ padding: 0 }} className="className-ListMenu">
          {PAGES.map((page: IPage, index) => {
            return (
              <Fragment key={index}>
                {page.pageType == 'main' && (
                  <ListItem
                    key={page.title}
                    sx={{
                      padding: '0 8px',
                      overflow: 'hidden',
                      marginBottom: '2px',
                      width: 'auto',
                      marginLeft: '0px',
                    }}
                  >
                    <NavigationBtn page={page} type="main" />
                  </ListItem>
                )}
                {page.pageType == 'main' && interfaceCTX.activeProjectID && getSubPagesByMainPage(page, null) && (
                  <>
                    <Divider
                      sx={{
                        borderColor: '#D2DCFF',
                        borderWidth: '1px',
                        margin: '16px 10px',
                      }}
                    />
                    {/* #D2DCFF */}
                    {getSubPagesByMainPage(page, project)?.map((subPage: IPage) => {
                      if (subPage.pageType == 'sub' && subPage.groupBy == page.title) {
                        return (
                          <ListItem
                            key={subPage.title}
                            style={{
                              padding: 0,
                              overflow: 'hidden',
                              marginBottom: '2px',
                              marginLeft: '0px',
                            }}
                          >
                            <NavigationBtn page={subPage} type={'additional'} />
                          </ListItem>
                        )
                      }
                    })}
                    <Divider
                      sx={{
                        borderColor: '#D2DCFF',
                        margin: '16px 10px',
                        borderWidth: '1px',
                      }}
                    />
                  </>
                )}
              </Fragment>
            )
          })}
        </List>
        <BottomBox className="BottomBox">
          <List style={{ padding: 0 }}>
            <Divider sx={{ borderColor: '#D2DCFF', ml: 1, mr: 1 }} />
            <BottomItemButton onClick={onOverride} style={{ borderRadius: '0px', paddingLeft: '23px' }}>
              {overrideOpen ? (
                <>
                  <ListItemIcon>
                    <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '36px !important' }} />
                  </ListItemIcon>
                  <ListItemText primary="Открепить меню" />
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <KeyboardDoubleArrowRightIcon sx={{ fontSize: '36px !important' }} />
                  </ListItemIcon>
                  <ListItemText primary="Закрепить меню" />
                </>
              )}
            </BottomItemButton>
          </List>
        </BottomBox>
      </Stack>
    </StyledDrawer>
  )
}
