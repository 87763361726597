export function ReorderCheck(props: any) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666016 2.66667H11.3327V4H0.666016V2.66667ZM0.666016 6.66667H11.3327V5.33333H0.666016V6.66667ZM0.666016 9.33333H5.33268V8H0.666016V9.33333ZM0.666016 12H5.33268V10.6667H0.666016V12ZM8.27268 10.1133L7.33268 9.16667L6.39268 10.1067L8.27268 12L11.3327 8.94667L10.386 8L8.27268 10.1133ZM0.666016 0V1.33333H11.3327V0H0.666016Z"
        fill="#0044B4"
      />
    </svg>
  )
}
