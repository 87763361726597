import { IBDDSArticle, IBDRArticle, ProjectType, TBudgetType } from '../../types/commos-def'
import { req, errorHandler, successHandler } from '../api'
import {
  IMappingArticleQuery,
  IGetArticleByArticleIDResponse,
  IReportQuery,
  IReportResponse,
  IGetSubbillsByArticleIDResponse,
  ICreateArticleBody,
  INewArticleForServer,
  ISubBillPair,
  ILinkSubBillToArticleBodyItem,
  CreateArticleRequest,
} from '../definitions'

export class MappingArticlesAPI {
  static baseURL: string = '/finance-center'

  static getBaseURLByBudgetType(budgetType: TBudgetType) {
    return budgetType == 'bdr' ? '/bdr/financier' : '/bdds'
  }

  static async getRootLvl(
    budgetType: TBudgetType,
    query?: Partial<IMappingArticleQuery>,
  ): Promise<IGetArticleByArticleIDResponse> {
    return await req
      .get(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + '/articles/get/root', query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getInvestRootLvl(
    budgetType: TBudgetType,
    organizationID: string | number,
    query?: Partial<IMappingArticleQuery>,
  ): Promise<IGetArticleByArticleIDResponse> {
    return await req
      .get(`/mapping/${organizationID}/articles/root`, { budgetType: 'BDR' })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getArticleDataByArticleID(
    budgetType: TBudgetType,
    query?: Partial<IMappingArticleQuery>,
  ): Promise<IGetArticleByArticleIDResponse> {
    return await req
      .get(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + '/articles/get/by-article', query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSubBillsByArticleID(
    budgetType: TBudgetType,
    query?: Partial<IMappingArticleQuery>,
  ): Promise<IGetSubbillsByArticleIDResponse> {
    return await req
      .get(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + '/sub-bills/get/by-article', query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async createArticle(
    budgetType: TBudgetType,
    { body, params }: CreateArticleRequest,
  ): Promise<IBDRArticle | IBDDSArticle> {
    return await req
      .post(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + '/articles/create', body, params)
      .then(({ data }) => {
        successHandler('Статья успешно добавлена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updateArticle(
    budgetType: TBudgetType,
    body: { articles: IBDDSArticle[] | IBDRArticle[] },
  ): Promise<IGetSubbillsByArticleIDResponse> {
    return await req
      .put(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + '/articles/update-mass', body)
      .then(({ data }) => {
        successHandler('Статья успешно сохранена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteArticle(
    budgetType: TBudgetType,
    articleID: number | null,
  ): Promise<IGetSubbillsByArticleIDResponse> {
    return await req
      .delete(MappingArticlesAPI.getBaseURLByBudgetType(budgetType) + `/articles/${articleID}/delete`)
      .then(({ data }) => {
        successHandler('Статья успешно удалена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async resetTemplate(budgetType: TBudgetType): Promise<void> {
    return await req
      .get('/' + budgetType + '/template/reset')
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async applyTemplate(budgetType: TBudgetType): Promise<void> {
    return await req
      .get('/' + budgetType + '/template/apply')
      .then(({ data }) => {
        successHandler('Шаблон успешно применен')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSubBillPairs(budgetType: TBudgetType, billType: ProjectType): Promise<{ data: ISubBillPair[] }> {
    return await req
      .get(budgetType == 'bdr' ? '/accountant/sub-bills/get-list/pairs' : '/bdds/sub-bills/get/pairs', {
        budgetType: billType,
      })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async linkSubBillsToArticle(
    budgetType: TBudgetType,
    body: { data: ILinkSubBillToArticleBodyItem[] },
    billType: ProjectType,
  ): Promise<number> {
    return await req
      .post(
        budgetType == 'bdr' ? '/bdr/financier/articles/link-to-sub-bill-mass' : '/bdds/articles/link-to-sub-bill-mass',
        body,
        { budgetType: billType },
      )
      .then(({ data }) => {
        successHandler('Данные успешно сохранены')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async unlinkSubBillsFromArticle(
    budgetType: TBudgetType,
    body: { data: ILinkSubBillToArticleBodyItem[] },
  ): Promise<number> {
    return await req
      .post(
        budgetType == 'bdr'
          ? '/bdr/financier/articles/unlink-from-sub-bill-mass'
          : '/bdds/articles/unlink-from-sub-bill-mass',
        body,
      )
      .then(({ data }) => {
        successHandler('Данные успешно сохранены')
        return data
      })
      .catch((error) => errorHandler(error))
  }
}
