import { SvgIcon } from '@mui/material'

export function BudgetsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 19C14.419 19 16.4367 17.2822 16.9 15H21V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.11 3 19L3.01 15H7.10002C7.56329 17.2822 9.58104 19 12 19ZM20 14H17C17 11.2386 14.7614 9 12 9C9.23858 9 7 11.2386 7 14H4C2.89 14 2 13.11 2 12V9C2 7.9 2.9 7 4 7H7.99V5L9.99 3H13.99L15.99 5V7H20C21.1 7 22 7.9 22 9V12C22 13.1 21.1 14 20 14ZM14 7H10V5H14V7ZM8 14C8 11.7909 9.79085 10 12 10C14.2091 10 16 11.7909 16 14C16 16.2091 14.2091 18 12 18C9.79085 18 8 16.2091 8 14ZM13.3567 15.0548C13.3567 14.3066 12.748 13.6979 11.9999 13.6979C11.5849 13.6979 11.2473 13.3603 11.2473 12.9454C11.2473 12.5304 11.5849 12.1928 11.9999 12.1928C12.4151 12.1928 12.7527 12.5304 12.7527 12.9454H13.3567C13.3567 12.3016 12.9046 11.7634 12.3018 11.6254V11.1137H11.6979V11.6254C11.0953 11.7635 10.6433 12.3016 10.6433 12.9454C10.6433 13.6932 11.252 14.3019 11.9999 14.3019C12.4151 14.3019 12.7527 14.6395 12.7527 15.0548C12.7527 15.4697 12.4151 15.8074 11.9999 15.8074C11.5849 15.8074 11.2473 15.4697 11.2473 15.0548H10.6433C10.6433 15.6985 11.0953 16.2366 11.6979 16.3747V16.8861H12.3018V16.3747C12.9046 16.2367 13.3567 15.6986 13.3567 15.0548Z"
          fill="#6D9ADC"
        />
      </svg>
    </SvgIcon>
  )
}
