import { EventAvailable, EventNote } from '@mui/icons-material'
import { Box } from '@mui/system'
import { isAfter, isBefore, isValid } from 'date-fns'
import React from 'react'
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form'
import { Form } from '../../../components/Form/Form'
import { STYLED } from '../../../global/styles/presets'
import { ProjectType, TControlRules } from '../../../global/types/commos-def'
import { MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

interface IProjectForm {
  values?: {
    name: string
    address: string
    dateStart: string
    dateEnd: string
  }
  mode: 'add' | 'edit'
  onSubmit: (data: any) => void
  onDelete?: (methods: UseFormReturn<FieldValues, any>, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel?: (methods: UseFormReturn<FieldValues, any>, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const defaultValues = {
  name: '',
  address: '',
  dateStart: '',
  dateEnd: '',
  type: 'GEN',
}

const typesProject: ProjectType[] = ['GEN', 'INVEST']

export function ProjectForm(props: IProjectForm) {
  const { mode, values, onSubmit, onDelete, onCancel } = props
  const { t: translate } = useTranslation('ENUMS')
  const methods = useForm({
    mode: 'all',
    defaultValues: values || (defaultValues as unknown as { [key: string]: string }),
  })

  const validationRulesSchema = {
    name: {
      required: 'Обязательное поле',
      maxLength: {
        value: 80,
        message: 'Максимум 80 символов',
      },
    },
    address: {
      required: 'Обязательное поле',
      maxLength: {
        value: 160,
        message: 'Максимум 160 символов',
      },
    },
    dateStart: {
      required: 'Обязательное поле',
      validate: {
        isBeforeDateTo: (v: any) => {
          if (!isValid(v)) return

          const dateEnd = methods.getValues('dateEnd')

          if (!dateEnd) return
          if (isBefore(v, new Date(dateEnd))) {
            methods.clearErrors('dateEnd')
            return
          }
          return '>= даты окончания'
        },
      },
    },
    dateEnd: {
      required: 'Обязательное поле',
      validate: {
        isAfterDateFrom: (v: any) => {
          if (!isValid(v)) return

          const dateStart = methods.getValues('dateStart')
          if (!dateStart) return

          const errors = methods.formState.errors
          if (errors.dateStart && errors.dateStart.type !== 'isBeforeDateTo') return
          if (isAfter(v, new Date(dateStart))) {
            methods.clearErrors('dateStart')
            return
          }
          return '<= даты начала'
        },
      },
    },
    // }
  } as { [key: string]: TControlRules }

  return (
    <Form.Wrapper
      methods={methods}
      onSubmit={onSubmit}
      defaultValues={values || (defaultValues as unknown as { [key: string]: string })}
    >
      <Form.ControlableTextField
        name="name"
        rules={validationRulesSchema.name}
        placeholder="Укажите краткое название проекта"
        multiline
        maxRows={2}
        sx={STYLED.TEXTFIELD.DRAWER_FORM}
      />
      <Form.ControlableTextField
        name="address"
        rules={validationRulesSchema.address}
        placeholder="Укажите полное название проекта, его описание, или адрес"
        multiline
        maxRows={2}
        sx={STYLED.TEXTFIELD.DRAWER_FORM}
      />
      <Form.FieldLabel title="Дата начала" icon={<EventNote />}>
        <Form.ControllableDatePicker
          name="dateStart"
          rules={validationRulesSchema.dateStart}
          TextFieldProps={{
            sx: STYLED.DATE_PICKER.DRAWER_FORM,
          }}
          DatePickerProps={{
            maxDate: methods.getValues('dateEnd')
              ? new Date(methods.getValues('dateEnd') || new Date(2100, 11, 31))
              : new Date(2100, 11, 31),
          }}
        />
      </Form.FieldLabel>
      <Form.FieldLabel title="Дата окончания" icon={<EventAvailable />}>
        <Form.ControllableDatePicker
          name="dateEnd"
          rules={validationRulesSchema.dateEnd}
          TextFieldProps={{
            sx: STYLED.DATE_PICKER.DRAWER_FORM,
          }}
          DatePickerProps={{
            disabled: !isValid(methods.getValues('dateStart')),
            minDate: methods.getValues('dateStart')
              ? new Date(methods.getValues('dateStart') || new Date(2000, 0, 1))
              : new Date(2000, 0, 1),
          }}
        />
      </Form.FieldLabel>
      {mode === 'add' && (
        <Form.FieldLabel title="Тип проекта" icon={<BusinessCenterIcon fontSize={'small'} />}>
          <Form.ControlableSelect name="type" sx={STYLED.SELECT.DRAWER_FORM}>
            {typesProject.map((type) => (
              <MenuItem key={type} value={type}>
                {translate('PROJECT_TYPE.' + type)}
              </MenuItem>
            ))}
          </Form.ControlableSelect>
        </Form.FieldLabel>
      )}
      <Form.BtnGroup>
        <Form.SubmitButton />
        <Form.CancelBtn onClick={(methods, e) => onCancel && onCancel(methods)} />
      </Form.BtnGroup>
      <Form.Alert
        severity="warning"
        title="Удалить"
        message={<Box>Вы действительно хотите удалить проект? Это действие нельзя отменить.</Box>}
      />
      {mode == 'edit' && (
        <Form.BtnGroup>
          <Form.DeleteBtn withAlert onClick={(methods) => onDelete && onDelete(methods)} />
        </Form.BtnGroup>
      )}
    </Form.Wrapper>
  )
}
