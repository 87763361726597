import { api } from '../api'
import {
  BanUserInModuleRequest,
  CompanyUserAccessUpdateRequest,
  CreateFullUserRequest,
  CreateFullUserResponse,
  CreateUserRequest,
  CreateUserResponse,
  GetUserByIdRequest,
  GetUsersRequest,
  GetUsersResponse,
  InviteUserRequest,
  InviteUserResponse,
  PublicUserProfile,
  SetAvatarRequest,
  UserProfile,
} from './types'

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResponse, GetUsersRequest>({
      query: ({ limit, offset }) => ({
        url: `/company/user/list`,
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUserById: build.query<PublicUserProfile, GetUserByIdRequest>({
      query: ({ userId }) => ({
        url: `/company/user/${userId}/get`,
        method: 'GET',
      }),
    }),
    createUser: build.mutation<CreateUserResponse, CreateUserRequest>({
      query: (body) => ({
        url: `/company/user/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    inviteUser: build.mutation<InviteUserResponse, InviteUserRequest>({
      query: ({ userId, profile, employment }) => ({
        url: `/company/user/${userId}/invite`,
        method: 'PUT',
        body: { profile, employment },
      }),
    }),
    setUserAvatar: build.mutation<PublicUserProfile, SetAvatarRequest>({
      query: ({ body, userId }) => {
        const { file } = body || {}

        if (file instanceof Blob) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/company/user/${userId}/set-avatar`,
            method: 'PUT',
            body: formData,
          }
        }
      },
      invalidatesTags: ['Users'],
    }),
    updateUserAccess: build.mutation<UserProfile, CompanyUserAccessUpdateRequest>({
      query: ({ body, userId }) => ({
        url: `/company/user/${userId}/update-access`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),

    createFullUser: build.mutation<any, CreateFullUserRequest>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { dataForCreate, dataForSetAvatar, dataForSetAccess } = arg || {}

        const data = await baseQuery({
          url: `/company/user/create`,
          method: 'POST',
          body: dataForCreate,
        })

        if (data?.error) {
          return { error: data.error }
        }

        const { success: newUser } = data.data as CreateUserResponse

        if (!!newUser) {
          const { id: userId } = newUser || {}
          const { file } = dataForSetAvatar || {}

          if (file instanceof Blob) {
            const formData = new FormData()
            formData.append('file', file)
            await baseQuery({
              url: `/company/user/${userId}/set-avatar`,
              method: 'PUT',
              body: formData,
            })
          }

          if (dataForSetAccess.newRole) {
            await baseQuery({
              url: `/company/user/${userId}/update-access`,
              method: 'POST',
              body: dataForSetAccess,
            })
          }
        }
        return { data: data.data } as CreateFullUserResponse
      },
      invalidatesTags: ['Users'],
    }),
    banUserInModule: build.mutation<unknown, BanUserInModuleRequest>({
      query: ({ userId }) => ({
        url: `/company/user/${userId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useInviteUserMutation,
  useSetUserAvatarMutation,
  useUpdateUserAccessMutation,
  useCreateFullUserMutation,
  useBanUserInModuleMutation,
} = usersApi
