import { SxProps } from '@mui/material'
import { BUTTON_STYLES, ICON_STYLES } from './buttonStyles'
import { theme } from './theme'

//===================================NEW

export const STYLED = {
  TABS: {
    OUTLINED: {
      '.MuiTabs-flexContainer': {
        gap: '0 !important',
        // height: '100%'
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
      minHeight: 'auto',
      borderRadius: theme.spacing(0.75),
      '.MuiTab-root': {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        ...theme.typography.buttonSmall,
        '&:first-of-type': {
          borderRadius: theme.spacing(0.75, 0, 0, 0.75),
        },
        '&:last-of-type': {
          borderRight: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.spacing(0, 0.75, 0.75, 0),
        },
        '&.MuiButtonBase-root': {
          color: theme.palette.primary.main,
          padding: `${theme.spacing('7px', 2)} !important`,
          lineHeight: '20px',
        },
        '&.Mui-disabled': {
          color: theme.palette.disabled!.main,
          borderColor: theme.palette.disabled!.main,
        },
        "&[aria-selected='true']": {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.bg!.gray,
          },
          background: 'rgba(25, 118, 210, 0.08)',
        },
      },
    },
  },
  TEXTFIELD: {
    DRAWER_FORM: {
      width: '100%',
      root: {
        marginTop: '5px',
        alignItems: 'center',
      },
      '& textarea': {
        ...theme.typography.body2,
      },
      input: {
        ...theme.typography.body2,
        backgroundColor: theme.palette.bg.shades,
        borderRadius: 2,
        padding: `${theme.spacing(2)}`,
        zIndex: 99,
      },
      '& fieldset': {
        border: `1px solid rgba(92,110,140,0.1)`,
        bacgkround: theme.palette.bg.shades,
        // zIndex: -1
      },
      '& .MuiInputBase-multiline': {
        backgroundColor: theme.palette.bg.shades,
      },
    },
    USERS_FORM: {
      // width: "100%",
      root: {
        alignItems: 'center',
      },
      '& textarea': {
        ...theme.typography.button,
      },
      input: {
        ...theme.typography.button,
        textTransform: 'none',
        textAlign: 'left',
        backgroundColor: theme.palette.bg.shades,
        borderRadius: 1,
        padding: `${theme.spacing(1.25, 2)}`,
        zIndex: 99,
      },
      '& fieldset': {
        border: `1px solid rgba(92,110,140,0.1)`,
        // bacgkround: theme.palette.bg.shades,
        zIndex: -1,
      },
      '& .MuiInputBase-multiline': {
        backgroundColor: theme.palette.bg.shades,
      },
      '& .Mui-focused:not(.Mui-error)': {
        input: {
          backgroundColor: theme.palette.bg.gray,
          outline: `1px solid rgba(92, 110, 140, 0.2)`,
        },
      },
    },
  },
  DATE_PICKER: {
    DRAWER_FORM: {
      // wrapper: {
      width: '112px',
      root: {
        position: 'relative',
      },
      '& fieldset': {
        padding: 0,
        border: `1px solid rgba(92,110,140,0.1)`,
        backgroundColor: '#F6F7FB',
      },
      '& p': {
        position: 'white !important',
        whiteSpace: 'nowrap',
        marginLeft: 0,
      },
      '.MuiInputBase': {
        paddingRight: 0,
      },
      '& input': {
        zIndex: 1,
        minWidth: 72,
        padding: 1,
        paddingRight: 0,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
      },
      '& button': {
        zIndex: 1,
        paddingLeft: 0,
        '& svg': {
          fontSize: 16,
        },
      },
    },
  },
  AUTOCOMPLETE: {
    DRAWER_FORM: {
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
        ...theme.typography.buttonSmall,
      },
      p: 0,
      backgroundColor: 'white',
      '& .MuiInputBase-root': {
        paddingY: 1,
        // p: theme.spacing(0, 1),
        backgroundColor: theme.palette.bg.shades,
      },
      input: {
        ...theme.typography.body2,
        textTransform: 'none',
        textAlign: 'left',
        padding: `${theme.spacing(1.25, 2)}`,
        zIndex: 99,
      },
      '& fieldset': {
        border: `1px solid rgba(92,110,140,0.1)`,
      },
    },
  },
  SELECT: {
    DRAWER_FORM: {
      '& .MuiFormControl-root': {},
      '& .MuiSelect-select': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
        padding: theme.spacing(0.75, 1),
        backgroundColor: '#F6F7FB',
        textAlign: 'right',
      },
      '& fieldset': {
        padding: 0,
        border: `1px solid rgba(92,110,140,0.1)`,
      },
      svg: {
        color: theme.palette.text.primary,
      },
      '& input': {
        zIndex: 1,
        minWidth: 72,
        padding: 1,
        paddingRight: 0,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
      },
    },
  },
} as {
  [key: string]: {
    [key: string]: SxProps
  }
}

//===================================OLD

export const autocomplete = {
  small: {
    p: 0,
    backgroundColor: 'white',
    '.MuiInputBase-root': {
      p: theme.spacing(0, 1),
    },
  },
}

export const iconButton = {
  secondary: {
    small: {
      ...BUTTON_STYLES.common,
      ...BUTTON_STYLES.iconButton,
      ...BUTTON_STYLES.outlined.secondary,
      ...BUTTON_STYLES.size.small,
      span: {
        svg: {
          ...ICON_STYLES.size.medium,
        },
      },
    },
  },
}

export const button = {
  secondary: {
    small: {
      ...BUTTON_STYLES.common,
      ...BUTTON_STYLES.outlined.secondary,
      ...BUTTON_STYLES.size.small,
      span: {
        display: 'block',
        m: 'auto',
        mr: '7px',
        svg: {
          ...ICON_STYLES.size.small,
        },
      },
    },
  },
  primary: {
    small: {
      ...BUTTON_STYLES.common,
      ...BUTTON_STYLES.size.small,
      span: {
        display: 'block',
        m: 'auto',
        mr: '7px',
        svg: {
          ...ICON_STYLES.size.small,
        },
      },
    },
  },
}

export const textTabs = {
  // minHeight: "auto",
}

export const textTab = {
  // p: 1.25,
  // mr: 1,
  // fontStyle: "normal",
  // fontWeight: 500,
  // fontSize: "13px",
  // lineHeight: "auto",
  // letterSpacing: "0.46px",
  // textTransform: "uppercase",
  // minWidth: "auto",
  // minHeight: "auto",
  // "&.Mui-disabled": {
  //     color: theme.palette.disabled!.main,
  //     // borderColor: theme.palette.disabled!.main
  // },
  // "&[aria-selected='false']": {
  //     color: theme.palette.secondary.main
  // },
}

export const outlinedTabs = {
  '.MuiTabs-indicator': {
    opacity: '0',
  },
  minHeight: 'auto',
  borderRadius: theme.spacing(0.5),
}

export const outlinedTab = {
  ...textTab,
  mr: 0,
  lineHeight: 'auto',
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.primary.main}`,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  '&:first-of-type': {
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
  },
  '&:last-of-type': {
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
  },
  '&.MuiButtonBase-root': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.disabled!.main,
    borderColor: theme.palette.disabled!.main,
  },
  "&[aria-selected='true']": {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.bg!.gray,
    },
    background: 'rgba(25, 118, 210, 0.08)',
  },
}

export const periodSelect = {
  '.MuiSelect-select': {
    p: theme.spacing(0.65, 1),
    background: '#F6F7FB',
    fontSize: '14px !important',
  },
  fieldset: {
    border: '1px solid rgba(92, 110, 140, 0.1)',
  },
  'label:not(.MuiInputLabel-shrink)': {
    // top: "0 !important",
    fontSize: '12px',
  },
  svg: {
    ...ICON_STYLES.size.medium,
    color: theme.palette.text.primary,
  },
}

export const paginationSelect = {
  '.MuiSelect-select': {
    p: 1,
  },
  '.MuiPaper-root': {
    maxHeight: 100,
  },
  fieldset: {
    border: 'none',
  },
  svg: {
    ...ICON_STYLES.size.medium,
    color: theme.palette.text.primary,
  },
}

export const paginationItem = {
  '.Mui-selected': {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'rgba(25, 118, 210, 0.08)',
  },
}

export const input = {
  drawerForm: {
    width: '100%',
    root: {
      marginTop: '5px',
      alignItems: 'center',
    },
    '& textarea': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
    },
    '& input': {
      background: theme.palette.bg.gray,
      borderRadius: 2,
      padding: `${theme.spacing(2)}`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
      zIndex: 99,
    },
    '& fieldset': {
      border: `1px solid rgba(92,110,140,0.1)`,
      backgroundColor: '#F6F7FB',
      zIndex: -1,
    },
    '& .MuiInputBase-multiline': {},
  },
}

export const autoComplete = {
  drawerForm: {
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      ...theme.typography.buttonSmall,
    },
  },
}

export const datePicker = {
  drawerForm: {
    // wrapper: {
    width: '112px',
    root: {
      position: 'relative',
    },
    '& fieldset': {
      padding: 0,
      border: `1px solid rgba(92,110,140,0.1)`,
      backgroundColor: '#F6F7FB',
    },
    // "& .Mui-focused:not(.Mui-error)": {
    //     "& fieldset": {
    //         border: "2px solid rgba(92,110,140,1)"
    //     }
    // },
    '& p': {
      position: 'white !important',
      whiteSpace: 'nowrap',
      marginLeft: 0,
    },
    '.MuiInputBase': {
      paddingRight: 0,
    },
    '& input': {
      zIndex: 1,
      minWidth: 72,
      padding: 1,
      paddingRight: 0,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
    '& button': {
      zIndex: 1,
      paddingLeft: 0,
      '& svg': {
        fontSize: 16,
      },
    },
    // },
  },
}

export const select = {
  drawerForm: {
    width: '200px',
    '& .MuiFormControl-root': {},
    '& .MuiSelect-select': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
      padding: theme.spacing(0.75, 1),
      backgroundColor: '#F6F7FB',
      textAlign: 'right',
    },
    '& fieldset': {
      padding: 0,
      border: `1px solid rgba(92,110,140,0.1)`,
    },
    svg: {
      color: theme.palette.text.primary,
    },
    '& input': {
      zIndex: 1,
      minWidth: 72,
      padding: 1,
      paddingRight: 0,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
  },
}

export const tableCellInput = {
  width: '100%',
  p: '0 !important',
  '& fieldset': {
    borderRadius: theme.spacing(0.5),
    borderWidth: '1px !important',
    // border: `1px solid rgba(92,110,140,0.2)`,
  },
  '& .Mui-focused:not(.Mui-error)': {
    '& fieldset': {
      // border: "1px solid #6D9ADC"
    },
  },
  '& .Mui-focused.Mui-error': {
    '& fieldset': {
      // borderWidth: "1px"
    },
  },
  input: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: 'white',
    padding: theme.spacing(1, 1),
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
  },
}

export const searchInput = {
  ...tableCellInput,
  background: 'white',
  width: '300px',
  borderRadius: theme.spacing(0.5),
}

export const tableCellInput_ViewMode = {
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
}

export const tableCellInputSmall = {
  ...tableCellInput,
  input: {
    ...tableCellInput['input'],
    textAlign: 'center',
    padding: theme.spacing(0.5, 0.5),
  },
}

export const tableCellInputSmall_ViewMode = {
  // display: 'flex',
  textAlign: 'right',
  // width:'100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.5, 0.5),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const operationNominal = {
  ...tableCellInputSmall,
  input: {
    ...tableCellInputSmall['input'],
    background: 'white',
  },
  padding: '0 !important',
}

export const operationNominal_ViewMode = {
  ...tableCellInputSmall_ViewMode,
  background: '#F6F7FB',
  color: theme.palette.primary.main,
  fontWeight: 500,
}

export const table = {
  footer: {
    sticky: {
      position: 'sticky !important',
      bottom: 0,
    },
  },
}

export const slider = {
  horizontal: {
    marginX: 1,
    "& .MuiSlider-markLabel[data-index='0']": { left: '10px !important' },
    '& :nth-last-child(2)': { left: 'calc(100% - 12px) !important' },
    '& .MuiSlider-valueLabel': { background: theme.palette.secondary.main, borderRadius: theme.spacing(0.5) },
  },
}

export const scrollableWrapper = {
  overflow: 'auto',
  pl: 2.5,
  pr: 1,
  mr: 1,
}

export const SWWithShaddowedInnerItems = {
  my: -2.5,
  py: 2.5,
  '&::-webkit-scrollbar-thumb': {
    borderTop: `20px solid ${theme.palette.grey[100]} !important`,
    borderBottom: `20px solid ${theme.palette.grey[100]} !important`,
  },
}

export const scrollableInner = {
  filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
}

export const subBillAssignTableWrapper = {
  padding: '0px 20px 20px 20px',
  borderRadius: theme.spacing(0.5),
  fleDirection: 'column',
  position: 'absolute',
  // top: 60px
  bottom: '-515px',
  right: '15px',
  width: '886px',
  height: '500px',
  background: 'white',
  zIndex: '99',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  overflow: 'auto',
}

export const hoverableRow = {
  ':hover': {
    '& td': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
}

export const statusBar = {
  minHeight: 36,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
  // mr: 1.25,
  paddingY: 1.25,
  paddingX: 2,
  backgroundColor: 'white',
  color: theme.palette.primary.main,
}

export const statusBarSuccess = {
  ...statusBar,
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
}
