import React from 'react'
import { StyledButton } from './styles'
import { ButtonProps } from './Button.types'

const Button: React.FC<ButtonProps> = ({
  customSize = 'small',
  paddingSize = 'medium',
  color = 'primary',
  variant = 'contained',
  children,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      /* customSize={customSize} */ /* paddingSize={paddingSize} */ disableRipple
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default Button
