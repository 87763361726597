import styled from 'styled-components'
import { TextField } from '@mui/material'
import { theme } from '../../../../../../global/styles/theme'

export const StyledTextField = styled(TextField)`
  font-size: ${theme.typography.body2.fontSize};
  .MuiInputBase-input {
    border: 1px solid rgba(92, 110, 140, 0.1);
    background: ${theme.palette.bg.shades};
    font-size: ${theme.typography.body2.fontSize};
    font-weight: ${theme.typography.body2.fontWeight};
    line-height: ${theme.typography.body2.lineHeight};
    padding: ${(props) => props.size == 'small' && theme.spacing(0.5, 1)};
    border-radius: 4px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);
  }
`
