import React, { useCallback } from 'react'
import qs from 'qs'
import { Box, Button, CircularProgress, DrawerProps, TextField } from '@mui/material'
import { MyDrawer } from '../../../../../../components/MyDrawer/MyDrawer'
import { ButtonGroupCustom } from '../../../../../../components/ButtonGroupCustom/ButtonGroupCustom'
import { theme } from '../../../../../../global/styles/theme'
import { useDidMountEffect } from '../../../../../../hooks/useDidMountEffect'
import { MappingArticlesAPI } from '../../../../../../global/api/APIMethods/MappingArticlesAPI'
import { useLocation } from 'react-router-dom'
import { IMappingArticleQuerySchema, IMappingArticlesState, sortSubBillsByName } from '../../MappingArticles'
import { ISubBillPair } from '../../../../../../global/api/definitions'
import { CheckBoxGroup } from './CheckBoxGroup'
import { getDeepCopy } from '../../../../../../global/functions'
import { scrollableWrapper } from '../../../../../../global/styles/presets'
import { CheckBoxListItem, ICheckBoxListItem } from './CheckBoxGroupItem'
import { Search, Close } from '@mui/icons-material'
import { ISubBillListState } from '../SubBillList'
import { usePrevState } from '../../../../../../global/hooks'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../hooks/useConfirmDialog'
import { ProjectType } from '../../../../../../global/types/commos-def'

interface ISubBillAssignCheckBoxListItem extends ICheckBoxListItem, ISubBillPair {
  initial?: boolean
}

interface ISubBillAssignDrawerProps {
  parentState: ISubBillListState
  setParentState: React.Dispatch<React.SetStateAction<ISubBillListState>>
  DrawerProps: DrawerProps
  mappingArticlesState: IMappingArticlesState
  setMappingArticlesState: React.Dispatch<React.SetStateAction<IMappingArticlesState>>
  billType: ProjectType
}

interface ISubBillAssignDrawerState {
  subBillsPairsListFromServer: ISubBillPair[] | null
  groupedSubBillsForRender: { [key: string]: ISubBillAssignCheckBoxListItem[] } | null
  searchedSubBills: ISubBillAssignCheckBoxListItem[] | null
  search: string
  isSaveBtnDisabled: boolean
  isConfirmDialogOpen: boolean
  isAvailableSubBillsLoading: boolean
}

export function SubBillAssignDrawer(props: ISubBillAssignDrawerProps) {
  const { parentState, setParentState, DrawerProps, mappingArticlesState, setMappingArticlesState } = props

  const [state, setState] = React.useState({
    subBillsPairsListFromServer: null,
    groupedSubBillsForRender: null,
    searchedSubBills: null,
    search: '',
    isSaveBtnDisabled: true,
    isConfirmDialogOpen: false,
    isAvailableSubBillsLoading: true,
  } as ISubBillAssignDrawerState)

  const location = useLocation()

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingArticleQuerySchema

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      closeDrawer()
      refreshMappingArticlesPage()
    } else {
      setParentState((parentState) => ({
        ...parentState,
        clientWantToCloseForm: false,
      }))
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  React.useEffect(() => {
    const params = location.pathname.endsWith('Invest') ? 'INVEST' : 'GEN'
    DrawerProps.open &&
      MappingArticlesAPI.getSubBillPairs(queryParams.budgetType, params).then((data) => {
        setState((prevState) => ({
          ...prevState,
          subBillsPairsListFromServer: data?.data.filter((pair) => !pair.hasArticles) || [],
          groupedSubBillsForRender: groupSubBillPairsByGostBillNumber(data?.data),
          isAvailableSubBillsLoading: false,
          // isSaveBtnDisabled: true
        }))
      })

    if (!DrawerProps.open) {
      resetAvailableSubBills()
      setState((prevState) => ({
        ...prevState,
        isAvailableSubBillsLoading: true,
      }))
    }
  }, [DrawerProps.open])

  React.useEffect(() => {
    search()
  }, [state.search])

  React.useEffect(() => {
    parentState.clientWantToCloseForm ? (state.isSaveBtnDisabled ? closeDrawer() : openConfirm()) : console.log('asd')
  }, [parentState.clientWantToCloseForm])

  useDidMountEffect(() => {
    removeSubBillFromArticle(parentState.queryOnRemoveSubBill)
  }, [parentState.queryOnRemoveSubBill])

  React.useEffect(() => {
    setParentState((prevState) => ({
      ...prevState,
      subBillList: sortSubBillsByName(prevState.subBillList) || [],
    }))
  }, [state.groupedSubBillsForRender])

  function refreshMappingArticlesPage() {
    setMappingArticlesState((prevState) => ({
      ...prevState,
      refresh: !prevState.refresh,
    }))
  }

  function resetAvailableSubBills() {
    setState((prevState) => ({
      ...prevState,
      subBillsPairsListFromServer: null,
      groupedSubBillsForRender: null,
    }))
  }

  function closeDrawer() {
    setParentState((parentState) => ({
      ...parentState,
      isSubBillAssignDrawerOpen: false,
      clientWantToCloseForm: false,
    }))
    setState((prevState) => ({
      ...prevState,
      isSaveBtnDisabled: true,
    }))
  }

  function search() {
    let subBillPairs: ISubBillAssignCheckBoxListItem[] = []

    for (let key in state.groupedSubBillsForRender) {
      subBillPairs = [...subBillPairs, ...state.groupedSubBillsForRender[key]]
    }

    subBillPairs = [
      ...subBillPairs.filter((subBill) => subBill.subBill.name.toLowerCase().includes(state.search.toLowerCase())),
    ]

    setState((prevState) => ({
      ...prevState,
      searchedSubBills: subBillPairs,
    }))
  }

  function parseGroupIntoPureList(groupedList: ISubBillAssignDrawerState['groupedSubBillsForRender']) {
    let targetArray = [] as ISubBillAssignCheckBoxListItem[]

    for (let key in groupedList) {
      targetArray = targetArray!.concat(groupedList[key])
    }

    return targetArray
  }

  function parseSubBillPairIntoCheckBoxListItem(pair: ISubBillPair) {
    let pairCopy: ISubBillAssignCheckBoxListItem = getDeepCopy(pair)
    pairCopy.label = pairCopy.subBill.name
    pairCopy.id = pairCopy.subBill.id
    // pairCopy.hasArticles = false
    return pairCopy
  }

  function groupSubBillPairsByGostBillNumber(pairs: ISubBillPair[]) {
    let targetGroupedObject = {} as ISubBillAssignDrawerState['groupedSubBillsForRender']
    sortPairtByGostBillNumber(pairs)?.map((pair) => {
      let targetItem: ISubBillAssignCheckBoxListItem = parseSubBillPairIntoCheckBoxListItem(pair)
      let { gostBill, subBill, hasArticles, initial } = targetItem
      if (!hasArticles || initial) {
        let gostBillFullName = gostBill.number + '_' + gostBill.name
        if (!targetGroupedObject!.hasOwnProperty(gostBillFullName)) {
          targetGroupedObject![gostBillFullName] = []
        }
        targetGroupedObject![gostBillFullName].push(targetItem)
      }
    })
    return targetGroupedObject
  }

  function sortPairtByGostBillNumber(pairs: ISubBillPair[]) {
    return pairs?.sort((pairA, pairB) => {
      return pairA.gostBill.number - pairB.gostBill.number
    })
  }

  function getCheckboxGroupList(groupedSubBillsForRender: ISubBillAssignDrawerState['groupedSubBillsForRender']) {
    let groupsArr = []
    for (let key in groupedSubBillsForRender) {
      groupsArr.push(
        <CheckBoxGroup
          key={key}
          onSelectGroup={() => {
            addSubBillGroupToArticle(groupedSubBillsForRender[key], key)
          }}
          onUnselectGroup={() => {
            removeSubBillGroupFromArticle(groupedSubBillsForRender[key], key)
          }}
          CheckBoxListItemProps={{
            onSwitchCheckedState: (checked, listItem) => {
              checked
                ? addSubBillToArticle(listItem as ISubBillAssignCheckBoxListItem, key)
                : removeSubBillFromArticle(listItem as ISubBillAssignCheckBoxListItem)
            },
          }}
          groupLabel={key}
          checkBoxList={groupedSubBillsForRender[key]}
          parentState={parentState}
          setParentState={setParentState}
        />,
      )
    }
    return groupsArr
  }

  function addSubBillGroupToArticle(group: ISubBillAssignCheckBoxListItem[], key: string) {
    disableSaveBtn(false)
    setParentState((prevState) => ({
      ...prevState,
      subBillList:
        prevState.subBillList == null
          ? [...(group as unknown as ISubBillPair[])]
          : [...prevState.subBillList, ...(group as unknown as ISubBillPair[])],
    }))

    let copy = state.groupedSubBillsForRender && getDeepCopy(state.groupedSubBillsForRender)

    copy[key] = []

    setState((prevState) => ({
      ...prevState,
      groupedSubBillsForRender: copy,
      subBillsPairsListFromServer: prevState.subBillsPairsListFromServer!.filter(
        (pair) => group.filter((groupSb) => pair.subBill.id == groupSb.subBill.id).length == 0,
      ),
    }))
  }

  function removeSubBillGroupFromArticle(group: ISubBillAssignCheckBoxListItem[], key: string) {
    disableSaveBtn(false)
    let gostBillNumber = Number(key.split('_')[0])

    // console.log(gostBillNumber)

    setParentState((prevState) => ({
      ...prevState,
      subBillList: prevState.subBillList!.filter((subBill) => subBill?.gostBill?.number != gostBillNumber),
    }))

    let copy = state.groupedSubBillsForRender && getDeepCopy(state.groupedSubBillsForRender)

    copy[key] =
      sortSubBillsByName([
        ...parentState
          .subBillList!.filter((articleSubBill) => articleSubBill.gostBill.number == gostBillNumber)
          .map((subBill) => parseSubBillPairIntoCheckBoxListItem(subBill)),
      ]) || []

    setState((prevState) => ({
      ...prevState,
      groupedSubBillsForRender: copy,
      subBillsPairsListFromServer: parseGroupIntoPureList(copy),
    }))
  }

  function addSubBillToArticle(listItem: ISubBillAssignCheckBoxListItem, key: string) {
    disableSaveBtn(false)
    let gostBillNumber = Number(key.split('_')[0])

    setParentState((prevState) => ({
      ...prevState,
      subBillList:
        prevState.subBillList == null
          ? [listItem as unknown as ISubBillPair]
          : [...prevState.subBillList, listItem as unknown as ISubBillPair],
    }))

    let copy = state.groupedSubBillsForRender && getDeepCopy(state.groupedSubBillsForRender)

    copy![key] =
      sortSubBillsByName(
        copy![key].filter((subBill: { subBill: { id: number } }) => subBill.subBill.id != listItem.subBill.id),
      ) || []

    setState((prevState) => ({
      ...prevState,
      groupedSubBillsForRender: copy,
      subBillsPairsListFromServer: parseGroupIntoPureList(copy),
      searchedSubBills: prevState.searchedSubBills!.filter((searchSubBill) => searchSubBill.subBill.id != listItem.id),
    }))
  }

  function removeSubBillFromArticle(listItem: ISubBillPair) {
    disableSaveBtn(false)
    setParentState((prevState) => ({
      ...prevState,
      subBillList: (prevState.subBillList! || []).filter((pair) => pair.subBill.id != listItem.subBill.id),
    }))

    let listCopy = getDeepCopy(state.subBillsPairsListFromServer!) as ISubBillPair[]

    listCopy = listCopy ? listCopy.concat([listItem]) : []

    let groupKey = listItem.gostBill?.number + '_' + listItem.gostBill?.name
    let groupedList = getDeepCopy(state.groupedSubBillsForRender! || {})
    groupedList![groupKey] =
      sortSubBillsByName([
        ...(groupedList![groupKey] ? groupedList![groupKey] : []),
        ...[{ ...listItem, label: listItem.subBill?.name, id: listItem.subBill?.id }],
      ]) || []

    let orderedByGostBillNumber = Object.keys(groupedList)
      .sort()
      .reduce((obj, key) => {
        //@ts-ignore
        obj[key] = groupedList[key]
        return obj
      }, {})

    setState((prevState) => ({
      ...prevState,
      groupedSubBillsForRender: orderedByGostBillNumber,
      subBillsPairsListFromServer: listCopy,
      searchedSubBills:
        state.search != ''
          ? parseGroupIntoPureList(groupedList).filter((subBill) =>
              subBill.subBill.name.toLowerCase().includes(state.search.toLowerCase()),
            )
          : [],
    }))
  }

  function resetSearch() {
    setState((prevState) => ({
      ...prevState,
      search: '',
    }))
  }

  function saveUpdates() {
    MappingArticlesAPI.linkSubBillsToArticle(
      queryParams.budgetType,
      {
        data: parentState
          .subBillList!.filter((pair) => !pair.hasArticles && !pair.initial)
          .map((pair) => {
            return {
              articleID: Number(queryParams.currentArticleID),
              subBillID: pair.subBill.id,
            }
          }),
      },
      props.billType,
    )
      .then(() =>
        MappingArticlesAPI.unlinkSubBillsFromArticle(queryParams.budgetType, {
          data: state
            .subBillsPairsListFromServer!.filter((pair) => pair.initial || pair.hasArticles)
            .map((pair) => {
              return {
                articleID: Number(queryParams.currentArticleID),
                subBillID: pair.subBill.id,
              }
            }),
        }),
      )
      .then(() => {
        disableSaveBtn(true)
        refreshMappingArticlesPage()
      })
  }

  function disableSaveBtn(isDisabled: boolean) {
    setState((prevState) => ({
      ...prevState,
      isSaveBtnDisabled: isDisabled,
    }))
  }

  function addAllSearchedSubBIlls() {
    let searchedSubBillIDs = state.searchedSubBills?.map((subBill) => subBill.subBill.id)

    let searchedSubBills =
      state.subBillsPairsListFromServer?.filter((searchSb) => searchedSubBillIDs?.includes(searchSb.subBill.id)) || []

    let filteredAvailableSubBills =
      state.subBillsPairsListFromServer?.filter((searchSb) => !searchedSubBillIDs?.includes(searchSb.subBill.id)) || []

    // let availableSubBillsWithoutSearcheValues = {}

    let groupedCopy = getDeepCopy(
      state.groupedSubBillsForRender || {},
    ) as ISubBillAssignDrawerState['groupedSubBillsForRender']

    for (let key in groupedCopy) {
      groupedCopy[key] =
        groupedCopy[key]?.filter((searchSb) => !searchedSubBillIDs?.includes(searchSb.subBill.id)) || []
    }

    setParentState((prevState) => ({
      ...prevState,
      subBillList: prevState.subBillList?.concat(searchedSubBills) || [],
    }))

    setState((prevState) => ({
      ...prevState,
      searchedSubBills: [],
      groupedSubBillsForRender: groupedCopy,
      subBillsPairsListFromServer: filteredAvailableSubBills,
    }))

    disableSaveBtn(false)
  }

  return (
    <MyDrawer.Wrapper variant="persistent" {...DrawerProps}>
      <ConfirmDialog />
      <MyDrawer.Title>Назначение субсчетов</MyDrawer.Title>
      <MyDrawer.Content
        sx={{
          padding: theme.spacing(1, 2.5),
          height: 'calc(100vh - 145px) !important',
          pb: 0,
          mt: 2.5,
          pt: 0,
          // mb: 2,
          ...scrollableWrapper,
        }}>
        {state.isAvailableSubBillsLoading && <CircularProgress />}
        {!state.isAvailableSubBillsLoading && (
          <TextField
            // disabled={state.allFinCenters?.length == 0}
            InputProps={{
              startAdornment: <Search sx={{ color: theme.palette.secondary.dark, marginX: 1 }} />,
              endAdornment: state.search && (
                <Close
                  fontSize="small"
                  sx={{ color: theme.palette.secondary.dark, marginX: 1, cursor: 'pointer' }}
                  onClick={resetSearch}
                />
              ),
            }}
            fullWidth
            sx={{
              paddingBottom: 1.5,
              input: {
                padding: 0.5,
              },
              '.MuiInputBase-root': {
                paddingX: 0.5,
                background: theme.palette.bg.shades,
              },
            }}
            placeholder="Поиск"
            value={state.search}
            onChange={(e) => setState((prevState) => ({ ...prevState, search: e.target.value }))}
          />
        )}
        {state.groupedSubBillsForRender && !state.search && getCheckboxGroupList(state.groupedSubBillsForRender)}
        {state.search && (
          <Box display="flex" flexDirection="column">
            {state.searchedSubBills && state.searchedSubBills.length > 0 && (
              <Button sx={{ color: theme.palette.primary.main }} onClick={addAllSearchedSubBIlls}>
                Добавить все совпадения
              </Button>
            )}
            {state.searchedSubBills?.map((searchedSubBill) => {
              return (
                <CheckBoxListItem
                  key={searchedSubBill.subBill.id}
                  isGroupSelected={false}
                  // CheckBoxProps={undefined}
                  listItem={searchedSubBill}
                  onSwitchCheckedState={(checked, listItem) => {
                    let copyListItem = getDeepCopy(listItem) as ISubBillAssignCheckBoxListItem
                    addSubBillToArticle(copyListItem, copyListItem.gostBill.number + '_' + copyListItem.gostBill.name)
                  }}
                />
              )
            })}
          </Box>
        )}
        <ButtonGroupCustom
          sx={{
            mt: 'auto',
            position: 'absolute',
            bottom: theme.spacing(2),
            backgroundColor: 'white',
            paddingY: 2,
            pb: 0,
            width: 'calc(100% - 32px)',
          }}>
          <Button
            disabled={state.isSaveBtnDisabled}
            onClick={saveUpdates}
            variant="contained"
            color="success"
            size="small">
            Сохранить
          </Button>
          <Button
            //@ts-ignore
            onClick={(e) => DrawerProps.onClose && DrawerProps.onClose()}
            variant="contained"
            color="primary"
            size="small">
            Закрыть
          </Button>
        </ButtonGroupCustom>
      </MyDrawer.Content>
    </MyDrawer.Wrapper>
  )
}
