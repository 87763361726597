import { ColDef, ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { CellRenderer } from '../../components/TableAGGrid/CellRenderer/CellRenderer'
import { tableCellInput, tableCellInput_ViewMode } from '../../global/styles/presets'

export const factGenerateTable = {
  columnDefs: function () {
    const columns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      {
        field: 'operationDate',
        headerName: 'Дата',
        minWidth: 105,
        flex: 7,
        headerClass: 'column-center',
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      {
        field: 'closingDocument',
        headerName: 'Документ',
        minWidth: 156,
        flex: 15,
      },
      {
        field: 'paymentPurpose',
        headerName: 'Аналитика КТ',
        minWidth: 232,
        flex: 23,
      },
      {
        field: 'financeCenterName',
        headerName: 'ЦФО',
        minWidth: 118,
        flex: 12,
      },
      {
        field: 'gostBill.number',
        headerName: 'Счет',
        minWidth: 110,
        flex: 8,
        headerClass: 'column-center',
        cellRendererParams: {
          EEStringProps: {
            align: 'center',
          },
        },
      },
      {
        field: 'subBill.name',
        headerName: 'Субсчет',
        minWidth: 208,
        flex: 23,
      },
      {
        field: 'amount',
        headerName: 'Сумма',
        minWidth: 101,
        flex: 9,
        headerClass: 'column-center',
        cellRenderer: CellRenderer.Number,
        cellRendererParams: {
          EENumberProps: {
            align: 'center',
            viewModeStyle: { tableCellInput_ViewMode },
            TextFieldProps: {
              sx: tableCellInput,
            },
          },
        },
      },
    ]
    return columns
  },
}
