import React, { MutableRefObject, useRef, useEffect } from 'react'
import { IBreadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { ISnackBarMessage } from '../global/types/commos-def'

type SearchChangeAction = 'userInput' | 'setDefaultValue' | 'clear'
interface ContextProps {
  breadcrumbsProps: {
    backPath: string | undefined
    breadcrumbs: IBreadcrumb[]
  }
  activeProjectID: number | string | ''
  showSearch: boolean
  searchIsDirty: boolean
  searchIsDefault: boolean
  searchValue: string
  searchRef: MutableRefObject<HTMLDivElement> | null
  openSnackBar: boolean
  snackBarMessage: ISnackBarMessage
  setProject: (projectID: number | string) => void
  setOpenSnackBar: (state: boolean, snackbar: ISnackBarMessage) => void
  clearSnackBar: () => void
  setBreadcrumbsProps: (breadcrumbs: IBreadcrumb[], backPath?: string) => void
  setShowSearch: (value: boolean) => any
  setSearchValue: (value: string, action: SearchChangeAction, immediately?: boolean) => any
  clearSearchRefValue: () => any
}

let timeout: NodeJS.Timeout

export const InterfaceContext = React.createContext({} as ContextProps)

export function InterfaceContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [value, setValue] = React.useState({
    breadcrumbsProps: {
      backPath: undefined,
      breadcrumbs: null,
    },
    version: '0.1.0',
    activeProjectID: '',
    showSearch: false,
    searchIsDirty: false,
    searchIsDefault: false,
    searchValue: '',
    searchRef: useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement> | null,
  } as unknown as ContextProps)

  function setProject(projectID: number | string | '') {
    setValue((prevValue) => ({
      ...prevValue,
      activeProjectID: projectID,
    }))
  }

  function setOpenSnackBar(state: boolean, snackbar: ISnackBarMessage) {
    setValue((prevState) => ({
      ...prevState,
      openSnackBar: state,
      snackBarMessage: {
        ...prevState.snackBarMessage,
        variant: snackbar.variant,
        title: snackbar.title,
        message: snackbar.message,
      },
    }))
  }

  function setBreadcrumbsProps(breadcrumbs: IBreadcrumb[], backPath?: string) {
    setValue((prevState) => ({
      ...prevState,
      breadcrumbsProps: {
        ...prevState.breadcrumbsProps,
        breadcrumbs: breadcrumbs,
        backPath: backPath,
      },
    }))
  }

  function clearSnackBar() {
    setValue((prevState) => ({
      ...prevState,
      openSnackBar: false,
    }))
  }
  /**************** *********************************/
  function setShowSearch(value: boolean) {
    clearSearchRefValue()
    setValue((v) => ({ ...v, showSearch: value, searchValue: '' }))
  }
  function setSearchValue(value: string, action: SearchChangeAction, immediately?: boolean) {
    clearTimeout(timeout)

    let searchIsDirty = false
    let searchIsDefault = false

    switch (action) {
      case 'userInput':
        searchIsDirty = true
        break
      case 'setDefaultValue':
        searchIsDefault = true
        break
      case 'clear':
        break
    }

    if (value && !immediately) {
      timeout = setTimeout(() => {
        setValue((v) => ({
          ...v,
          searchValue: value,
          searchIsDirty,
          searchIsDefault,
        }))
      }, 200)
      return
    }
    setValue((v) => ({
      ...v,
      searchValue: value,
      searchIsDirty,
      searchIsDefault,
    }))
  }

  useEffect(() => {
    if (!value.searchIsDefault) return
    const input = _getSearchInputElement()
    if (input) input.value = value.searchValue
  }, [value.searchIsDefault])

  function _getSearchInputElement(): HTMLInputElement | null {
    const target = value.searchRef?.current
    const input = (target?.children[0]?.children[1] as HTMLInputElement) ?? null

    return input
  }

  function clearSearchRefValue() {
    setSearchValue('', 'clear')
    const input = _getSearchInputElement()
    if (input) input.value = ''
  }
  /**************** *********************************/

  return (
    <InterfaceContext.Provider
      value={{
        ...value,
        setProject: setProject,
        setOpenSnackBar: setOpenSnackBar,
        clearSnackBar: clearSnackBar,
        setBreadcrumbsProps: setBreadcrumbsProps,
        setShowSearch,
        setSearchValue,
        clearSearchRefValue,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
