import { SnackbarCloseReason as MUISnackbarCloseReason } from '@mui/material/Snackbar/Snackbar'
import { ReactNode } from 'react'
import { IGetArticleByArticleIDResponse } from './api/definitions'
import { config } from './config'
import { theme } from './styles/theme'
import {
  TCpTax,
  TFinanceOperationsCategory,
  TMonthName,
  TOperationalActivityCategory,
  TQuarterName,
  TReportType,
  TTaxSystem,
  TUSNOptions,
} from './types/commos-def'

export const LINK_TO_CORE = config.coreURL

export type LegendColors = keyof typeof theme.palette.legends
export const XXL_FOR_LAYOUT = '2300px'

export const MIN_PASSWORD_LENGTH_VALIDATION = 4
export const DEFAULT_DISPLAY_PASSWORD_VALUE = '* * * * * *'
export const MAX_WIDTH_USER_MANAGEMENT_BLOCK = '476px'
export const MIN_WIDTH_USER_MANAGEMENT_BLOCK = '396px'

export interface Limit {
  value: number
  title: string
}
export interface WithChildren<T = ReactNode> {
  children: T | T[]
}

export type SnackbarCloseReason = 'click' | MUISnackbarCloseReason

export const NUMBER_OF_ROWS_PER_USERS_PAGE = [
  { value: 100, title: '100' },
  { value: 50, title: '50' },
  { value: 25, title: '25' },
]

export const ERRORS_PHONE = ['company.user_creation.invalid_phone', 'profile.invalid_phone']

export const ERRORS_COINCIDENCE = {
  'company.user_creation.phone_taken': 'phone' as const,
  'company.user_creation.email_taken': 'email' as const,
  'company.user_creation.login_taken': 'login' as const,
  'profile.phone_taken': 'phone' as const,
  'profile.email_taken': 'email' as const,
  'profile.login_taken': 'login' as const,
}

export const MONTHS: TMonthName[] = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
]

export const QUARTERS: TQuarterName[] = ['FIRST', 'SECOND', 'THIRD', 'FOURTH']

export const BDR_OPERATION_CATEGORIES: TOperationalActivityCategory[] = [
  'INCOME',
  // 'WAGE_FUND',
  // 'STAFF_MAINTENANCE',
  'OPERATING_EXPENSES',
  'TOTAL',
]

export const BDDS_OPERATION_CATEGORIES: TFinanceOperationsCategory[] = ['INCOME', 'PAYMENTS', 'TOTAL']

export const REPORT_TYPES: TReportType[] = ['MONTHLY', 'QUARTERLY', 'YEARLY']

export const ROWS_PER_PAGE: number[] = [25, 50, 100]

export const calendar = {
  monthIndex: {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
  },
  quarters: [
    {
      label: 'I квартал',
      value: 'FIRST',
      calendarPosition: 1,
      monthsIncluded: [
        {
          label: 'Январь',
          value: 'JANUARY',
          abbr: 'ЯНВ',
          calendarPosition: 1,
        },
        {
          label: 'Февраль',
          value: 'FEBRUARY',
          abbr: 'ФЕВ',
          calendarPosition: 2,
        },
        {
          label: 'Март',
          value: 'MARCH',
          abbr: 'МАРТ',
          calendarPosition: 3,
        },
      ],
    },
    {
      label: 'II квартал',
      value: 'SECOND',
      calendarPosition: 2,
      monthsIncluded: [
        {
          label: 'Апрель',
          value: 'APRIL',
          abbr: 'АПР',
          calendarPosition: 4,
        },
        {
          label: 'Май',
          value: 'MAY',
          abbr: 'МАЙ',
          calendarPosition: 5,
        },
        {
          label: 'Июнь',
          value: 'JUNE',
          abbr: 'ИЮНЬ',
          calendarPosition: 6,
        },
      ],
    },
    {
      label: 'III квартал',
      value: 'THIRD',
      calendarPosition: 3,
      monthsIncluded: [
        {
          label: 'Июль',
          value: 'JULY',
          abbr: 'ИЮЛЬ',
          calendarPosition: 7,
        },
        {
          label: 'Август',
          value: 'AUGUST',
          abbr: 'АВГ',
          calendarPosition: 8,
        },
        {
          label: 'Сентябрь',
          value: 'SEPTEMBER',
          abbr: 'СЕН',
          calendarPosition: 9,
        },
      ],
    },
    {
      label: 'IV квартал',
      value: 'FOURTH',
      calendarPosition: 4,
      monthsIncluded: [
        {
          label: 'Октябрь',
          value: 'OCTOBER',
          abbr: 'ЯНВ',
          calendarPosition: 10,
        },
        {
          label: 'Ноябрь',
          value: 'NOVEMBER',
          abbr: 'НОЯ',
          calendarPosition: 11,
        },
        {
          label: 'Декабрь',
          value: 'DECEMBER',
          abbr: 'ДЕК',
          calendarPosition: 12,
        },
      ],
    },
  ],
  months: [
    {
      label: 'Январь',
      value: 'JANUARY',
      abbr: 'ЯНВ',
      calendarPosition: 1,
    },
    {
      label: 'Февраль',
      value: 'FEBRUARY',
      abbr: 'ФЕВ',
      calendarPosition: 2,
    },
    {
      label: 'Март',
      value: 'MARCH',
      abbr: 'МАРТ',
      calendarPosition: 3,
    },
    {
      label: 'Апрель',
      value: 'APRIL',
      abbr: 'АПР',
      calendarPosition: 4,
    },
    {
      label: 'Май',
      value: 'MAY',
      abbr: 'МАЙ',
      calendarPosition: 5,
    },
    {
      label: 'Июнь',
      value: 'JUNE',
      abbr: 'ИЮНЬ',
      calendarPosition: 6,
    },
    {
      label: 'Июль',
      value: 'JULY',
      abbr: 'ИЮЛЬ',
      calendarPosition: 7,
    },
    {
      label: 'Август',
      value: 'AUGUST',
      abbr: 'АВГ',
      calendarPosition: 8,
    },
    {
      label: 'Сентябрь',
      value: 'SEPTEMBER',
      abbr: 'СЕН',
      calendarPosition: 9,
    },
    {
      label: 'Октябрь',
      value: 'OCTOBER',
      abbr: 'ОКТ',
      calendarPosition: 10,
    },
    {
      label: 'Ноябрь',
      value: 'NOVEMBER',
      abbr: 'НОЯ',
      calendarPosition: 11,
    },
    {
      label: 'Декабрь',
      value: 'DECEMBER',
      abbr: 'ДЕК',
      calendarPosition: 12,
    },
  ],
}

export const CP_TAX: TCpTax[] = [20, 10, 0]
export const TAX_SYSTEM: TTaxSystem[] = ['ОСНО', 'УСН (Доходы)', 'УСН (Доходы минус расходы)']

export const USN_OPTIONS: TUSNOptions[] = ['6% на Доходы', '15% на Доходы - Расходы']

export const ESN_OPTIONS = [
  {
    label: 'Полный',
    value: 'FULL',
  },
  {
    label: 'Льготный (МСП)',
    value: 'BENEFITS_FOR_SMALL_AND_MEDIUM_BUSINESSES',
  },
  {
    label: 'Льготный (IT)',
    value: 'BENEFITS_FOR_INFORMATION_TECHNOLOGY_BUSINESSES',
  },
  {
    label: 'Нулевой',
    value: 'ZERO',
  },
]

export const ESN_DESCRIPTION_TERMS = [
  {
    termName: 'ЕСН',
    text: `совокупность обязательных страховых взносов (ОПС, ВНиМ, ОМС) во внебюджетные фонды (ПФР, ФСС (+ВНиМ), ОМС), уплачиваемых работодателем за работника, подлежащего обязательному соцстрахованию.  Сумма рассчитывается в процентах от вознаграждения (заработной платы) работника, по тарифам, установленным государством. Тарифы ЕСН могут быть полными, а могут быть льготными. Льготные тарифы применяются либо по достижению предельной базы, либо в связи с определенными льготами в пользу определенных организаций (к примеру, IT компаний или МСП - малые и средние предприятия).
        Предельная база по взносам — это такая сумма дохода физлица, после достижения которой страховые взносы уплачиваются по пониженным тарифам.С 2022 года сновные тарифы страховых взносов для большинства компаний установлены в размере 30 %`,
  },
  {
    termName: 'ПФР',
    text: `Пенсионный фонд России.`,
  },
  {
    termName: 'ФСС',
    text: ` Фонд социального страхования.`,
  },
  {
    termName: 'ФФОМС',
    text: ` Федеральный фонд обязательного медицинского страхования.`,
  },
  {
    termName: 'ОПС',
    text: `обязательное пенсионное страхование.`,
  },
  {
    termName: 'ВНиМ',
    text: `обязательное социальное страхование на случай временной нетрудоспособности и в связи с материнством.`,
  },
  {
    termName: 'ОМС',
    text: `обязательное медицинское страхование.`,
  },
]

export const ESN_DESCRIPTION_ESN_VARIANT_VALUES = [
  {
    ESNVariant: 'Полный',
    OPSLimit: '1 565 000',
    OPSInLimitRate: '22 %',
    OPSOutLimitRate: '10 %',
    VNIMLimit: '1 032 000',
    VNIMInLimitRate: '2,9 %',
    VNIMOutLimitRate: '0 %',
    OMSRate: '5,9 %',
  },
  {
    ESNVariant: 'Льготный (МСП)',
    OPSLimit: '0',
    OPSInLimitRate: '10 %',
    OPSOutLimitRate: '10 %',
    VNIMLimit: '0',
    VNIMInLimitRate: '0 %',
    VNIMOutLimitRate: '0 %',
    OMSRate: '5 %',
  },
  {
    ESNVariant: 'Льготный (IT)',
    OPSLimit: '0',
    OPSInLimitRate: '6 %',
    OPSOutLimitRate: '6 %',
    VNIMLimit: '0',
    VNIMInLimitRate: '1,5 %',
    VNIMOutLimitRate: '1,5 %',
    OMSRate: '0,1 %',
  },
  {
    ESNVariant: 'Нулевой',
    OPSLimit: '0',
    OPSInLimitRate: '0 %',
    OPSOutLimitRate: '0 %',
    VNIMLimit: '0',
    VNIMInLimitRate: '0 %',
    VNIMOutLimitRate: '0 %',
    OMSRate: '0 %',
  },
]

export const BDR_ROOT_LVL: IGetArticleByArticleIDResponse = {
  data: [
    {
      id: null,
      name: 'ДОХОДЫ',
      type: 'INCOME',
      editable: false,
    },
    {
      id: null,
      name: 'РАСХОДЫ',
      type: 'OUTCOME',
      editable: false,
    },
    {
      id: null,
      name: 'ПРОЧИЕ ДОХОДЫ И РАСХОДЫ',
      type: 'OTHER_INCOMES_OUTCOMES',
      editable: false,
    },
  ],
  article: null,
  parents: null,
  subBill: null,
}

export const alphabetRu = [
  'а',
  'б',
  'в',
  'г',
  'д',
  'е',
  'ё',
  'ж',
  'з',
  'и',
  'й',
  'к',
  'л',
  'м',
  'н',
  'о',
  'п',
  'р',
  'с',
  'т',
  'у',
  'ф',
  'х',
  'ц',
  'ч',
  'ш',
  'щ',
  'ъ',
  'ы',
  'ь',
  'э',
  'ю',
  'я',
]

export const alphabetEn = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]
