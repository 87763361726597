import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import UserData from './UserData'
import UserAccesses from './UserAccesses'
import { UserFormData, UserFormProps } from './UserForm.types'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useTypedSelector } from '../../../../../store/store'
import { currentCompanyIdSelector } from '../../../../../store/slices/profile'
import { DEFAULT_DISPLAY_PASSWORD_VALUE } from '../../../../../global/variables'
import { validationProfile } from '../validation'
import {
  CompanyUserAccessUpdateData,
  CreateUserRequest,
  CreateUserResponse,
  InviteUserResponse,
  PublicUserProfile,
  SetAvatarData,
  UserBindCandidate,
  UserBindFields,
} from '../../../../../api/users/types'
import { useCreateFullUserMutation, useInviteUserMutation, useUpdateUserAccessMutation } from '../../../../../api/users'
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers'
import { mapFieldErrorByError } from '../../../../../global/utils/mapFieldErrorByError'
import { FoundUserDialog } from './FoundUserDialog'
import { useForm } from '../../../../../hooks/useForm'
import { Form, FormikProvider } from 'formik'

const UserForm: React.FC<UserFormProps> = ({ userProfileToChange, isEditUser, onFormChange }) => {
  const { t } = useTranslation('user')
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const companyID = useTypedSelector(currentCompanyIdSelector)
  const [candidates, setCandidates] = useState<CreateUserResponse['candidates']>()

  const {
    avatar,
    firstName,
    lastName,
    middleName,
    id,
    email,
    role,
    login,
    phoneConfirmed,
    emailConfirmed,
    phone,
    company,
  } = userProfileToChange || {}

  const { companyName, userPosition, userCompanyName } = company || {}

  const initialValues: UserFormData = useMemo(() => {
    return {
      lastName: lastName || '',
      firstName: firstName || '',
      middleName: middleName || '',
      companyName: company?.userCompanyName || '',
      position: userPosition || '',
      phone: phone || '',
      email: email || '',
      login: login || '',
      password: DEFAULT_DISPLAY_PASSWORD_VALUE as string | undefined,
      avatar: avatar || '',
      role: role || 'none',
    }
  }, [lastName, firstName, middleName, userCompanyName, userPosition, phone, email, login, avatar, role])

  const [createFullUser, createFullUserResponse] = useCreateFullUserMutation()
  const [updateUserAccess, updateUserAccessResponse] = useUpdateUserAccessMutation()
  const [inviteUser, inviteUserResponse] = useInviteUserMutation()

  const onSubmit = (values: UserFormData) => {
    const { role } = values
    const dataForSetAccess: CompanyUserAccessUpdateData = {
      newRole: role,
    }

    if (isEditUser) {
      updateUserAccess({
        body: dataForSetAccess,
        userId: userProfileToChange?.id!,
      })
    } else {
      const { login, email, phone, password, avatar, companyName, position, firstName, lastName, middleName } = values

      const dataForCreate: CreateUserRequest = {
        profile: {
          email,
          login,
          password: password!,
          phone,
        },
        employment: {
          companyID,
          companyName,
          firstName,
          lastName,
          middleName,
          position,
        },
      }

      const dataForSetAvatar: SetAvatarData = {
        file: values.avatar as Blob,
      }

      createFullUser({
        dataForCreate,
        dataForSetAvatar,
        dataForSetAccess,
      })
    }
  }

  const { formik } = useForm({
    validationSchema: validationProfile,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  const { values, setFieldError, dirty } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(
    createFullUserResponse,
    (data: CreateUserResponse) => {
      if (!!data) {
        const { success: newUser, candidates } = data || {}

        if (!!newUser) {
          history.push('../users')
        }
        if (!!candidates?.length) {
          const notInvitedCandidates: UserBindCandidate[] = []
          candidates.forEach((candidate) => {
            const { alreadyInvited, bindFields } = candidate
            if (alreadyInvited) {
              bindFields?.forEach((field: UserBindFields) => setFieldError(field, t('status.coincidence')))
            } else {
              notInvitedCandidates.push(candidate)
            }
          })
          setCandidates(notInvitedCandidates)
        }
      }
    },
    (error) => {
      const errorData = mapFieldErrorByError(error)
      if (errorData) {
        const { field, text, type } = errorData
        if (type === 'phone') {
          setFieldError(field, t(text))
        }
      } else {
        enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
      }
    },
  )

  useMutationHandlers(
    updateUserAccessResponse,
    (data: PublicUserProfile) => {
      history.push('/users')
    },
    () => {
      enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
    },
  )

  const handleCloseFoundUserDialog = useCallback(() => {
    setCandidates(undefined)
  }, [])

  const handleInviteUser = useCallback(
    (candidateId: PublicUserProfile['id']) => {
      const selectedCandidate = candidates?.find((candidate) => candidate?.profile?.id === Number(candidateId))
      const getFieldBySelectedCandidate = (bindField: UserBindFields) => {
        if (selectedCandidate?.bindFields?.includes(bindField)) {
          return values[bindField] || ''
        }
        return ''
      }

      inviteUser({
        userId: candidateId,
        employment: {
          companyID,
          companyName: values.companyName,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          position: values.position,
        },
        profile: {
          email: getFieldBySelectedCandidate('email'),
          login: getFieldBySelectedCandidate('login'),
          phone: getFieldBySelectedCandidate('phone'),
          password: values.password!,
        },
      })
    },
    [inviteUser, values, candidates, companyID],
  )

  useMutationHandlers(
    inviteUserResponse,
    (data: InviteUserResponse) => {
      history.push('/users')
      enqueueSnackbar(t('success.acceptInvitation'), { variant: 'success' })
    },
    (error) => {
      enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
    },
  )

  return (
    <>
      <FormikProvider value={formik}>
        <Stack flex={1} component={Form} alignItems="center" px={7} py={4}>
          <Stack
            spacing={5}
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            style={{ maxWidth: '1072px', width: '100%' }}
          >
            <UserData
              isEditUser={isEditUser}
              initialValues={initialValues}
              phoneConfirmed={phoneConfirmed || false}
              emailConfirmed={emailConfirmed || false}
              name={userProfileToChange?.company?.userCompanyName}
            />
            <UserAccesses isEditUser={isEditUser} />
          </Stack>
        </Stack>
      </FormikProvider>
      <FoundUserDialog
        isOpen={!!candidates?.length}
        onCancel={handleCloseFoundUserDialog}
        candidates={candidates!}
        onSuccess={handleInviteUser}
      />
    </>
  )
}

export default UserForm
