export function floatToJustifyContent(align: 'center' | 'left' | 'right' | undefined) {
  switch (align) {
    case 'center':
      return 'center'
    case 'left':
      return 'start'
    case 'right':
      return 'end'
    default:
      return 'start'
  }
}

export function justifyContentTONormalJustifyContent(justifyContent: 'start' | 'center' | 'end') {
  switch (justifyContent) {
    case 'start':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'end':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

export function agGridSum(allLeafChildren: any[], field: string) {
  return allLeafChildren.reduce((acc, obj) => {
    const value = obj.data[field]
    if (typeof value === 'number') {
      acc += value
    }
    return acc
  }, 0)
}
