import React, { useCallback } from 'react'
import { FormFieldProps } from './FieldForm.types'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box, FilledTextFieldProps, FormControlLabel, TextField, TextFieldProps } from '@mui/material'
import {
  DatePickerRenderInput,
  StyledFormTextField,
  SmallFormTextField,
  StyledCheckbox,
  StyledSelectField,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTitle,
} from './FieldForm.styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const FieldForm: React.FC<FormFieldProps> = ({
  version,
  title,
  maxLength,
  fieldProps,
  onBlur,
  dataValue,
  checkboxData,
  onDataChange,
  children,
  name,
  ...props
}) => {
  const { t } = useTranslation()
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name,
    ...fieldProps,
  })

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur, onFieldBlur],
  )

  const isError = touched && !!error

  switch (version) {
    case 'profile':
      return (
        <label style={{ textAlign: 'left' }}>
          <StyledTextFieldTitle mb={0.75} variant="tooltip" pl={2}>
            {title}
          </StyledTextFieldTitle>
          <StyledTextField
            {...field}
            InputProps={{ disableUnderline: true }}
            fullWidth
            variant="filled"
            size="small"
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            onBlur={handleBlur}
            error={isError}
            {...props}
          />
        </label>
      )
    case 'project':
    case 'template':
    case 'rd':
    case 'objects':
    case 'doc':
      return (
        <StyledFormTextField
          {...field}
          InputProps={{ disableUnderline: true }}
          fullWidth
          variant={'filled'}
          size="small"
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          {...props}
        />
      )

    case 'date':
      return (
        <DesktopDatePicker
          mask="__.__.____"
          value={dataValue}
          onChange={onDataChange!}
          renderInput={(params) => (
            <DatePickerRenderInput
              helperText={isError ? t(error as string, { maxLength }) : undefined}
              {...props}
              {...params}
              error={isError}
              inputProps={{
                ...params.inputProps,
                placeholder: 'дд.мм.год',
              }}
            />
          )}
        />
      )

    case 'select':
      return (
        <StyledSelectField
          select
          {...field}
          InputProps={{ disableUnderline: true }}
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          {...props}
        >
          {children}
        </StyledSelectField>
      )

    case 'small':
      return (
        <SmallFormTextField
          {...field}
          InputProps={{ disableUnderline: true }}
          fullWidth
          variant="filled"
          size="small"
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          {...props}
        />
      )

    case 'checkbox':
      return (
        <FormControlLabel
          onClick={checkboxData?.onLabelClick}
          control={
            <StyledCheckbox
              {...field}
              checked={!!field.value}
              onChange={checkboxData?.onChange}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
              icon={checkboxData?.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            />
          }
          label={<Box>{checkboxData?.label}</Box>}
        />
      )

    case 'switch':
      return (
        <FormControlLabel
          control={
            <StyledSwitch
              {...field}
              checked={checkboxData?.checked}
              onChange={checkboxData?.onChange}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
            />
          }
          label={checkboxData?.label}
        />
      )

    default:
      return (
        <TextField
          {...field}
          onBlur={handleBlur}
          error={isError}
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          {...props}
        />
      )
  }
}

export default FieldForm
