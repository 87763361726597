import { Divider, Drawer, Stack, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { IBdrParametersDrawerProps } from './BdrParametersDrawer.types'
import { DrawerTopBar } from './BdrParametersDrawer.styles'
import { theme } from '../../../../global/styles/theme'
import { BdrParametersDrawerForm } from './components/BdrParametersDrawerForm'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'

export const BdrParametersDrawer: FC<IBdrParametersDrawerProps> = ({
  availableYears,
  availableYearsResponse,
  isOpen,
  onClose,
}) => {
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
    }
  }, [])

  const dataForConfirmDialog: Record<NonNullable<'exit'>, UseExitConfirmProps> = {
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog['exit'])

  const onFormChange = (dirty: boolean) => {
    setIsFormDirty(dirty)
  }

  const onDrawerClose = (immediately: boolean) => {
    if (immediately || !isFormDirty) {
      onClose()
    } else {
      // setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }

  return (
    <Drawer open={isOpen} anchor="right" onClose={() => onDrawerClose(false)}>
      <Stack width={'400px'} height={'100%'} spacing={1}>
        <Stack>
          <DrawerTopBar>
            <Typography variant="h1" color={theme.palette.primary.main}>
              Параметры отчета
            </Typography>
          </DrawerTopBar>

          <Divider />
        </Stack>

        <BdrParametersDrawerForm
          availableYears={availableYears}
          onFormChange={onFormChange}
          onDrawerClose={onDrawerClose}
        />

        <ConfirmDialog />
      </Stack>
    </Drawer>
  )
}
