import * as React from 'react'
import { useRef, useState } from 'react'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { UserMenuButton, StyledMenu, CompanyWrapper, UserInfo, UserData, UserName } from './styles'
import Button from '../../Button'
import { UserMenuProps } from './UserMenu.types'
import { useTypedSelector } from '../../../store/store'
import { onLogout, profileSelector } from '../../../store/slices/profile'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../store/slices/auth'

const UserMenu: React.FC<UserMenuProps> = ({ company, onChangeModule /* onLogout */ }) => {
  // const { firstName, middleName, lastName, avatarPreview, company } = profile;
  const { firstName, middleName, lastName, avatarPreview } = useTypedSelector(profileSelector)

  const dispatch = useDispatch()

  const { companyName, userPosition, userCompanyName } = company

  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleLogout = React.useCallback(() => {
    // dispatch(setCredentials({}));
    dispatch(onLogout()) // window.location.replace(config.coreURL)
  }, [dispatch])

  const fullName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  )
  const partedName = (
    <>
      {firstName} {lastName}
    </>
  )
  const firstLetter = lastName?.[0] || ''

  return (
    <Box sx={{ ml: 2.5 }}>
      <UserMenuButton
        startIcon={<Avatar src={avatarPreview}></Avatar>}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        disableRipple
        ref={anchorRef}
        onClick={handleToggle}
      >
        <Typography variant="subtitle1" component="span">
          {partedName}
        </Typography>
      </UserMenuButton>
      <StyledMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: 'none' }}
        // transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        //  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <CompanyWrapper>{userCompanyName}</CompanyWrapper>
        <UserInfo>
          <Avatar src={avatarPreview}>{firstLetter}</Avatar>
          <UserData>
            <UserName>{fullName}</UserName>
            <div>{companyName}</div>
            <div>{userPosition}</div>
          </UserData>
        </UserInfo>
        <Stack spacing={2.5}>
          <Button onClick={onChangeModule} width="100%" style={{ maxWidth: 'none' }}>
            К выбору модуля
          </Button>
          <Button onClick={handleLogout} width="100%" style={{ maxWidth: 'none' }}>
            Выйти из системы
          </Button>
        </Stack>
      </StyledMenu>
    </Box>
  )
}

export default UserMenu
