import { Crop } from 'react-image-crop'

export const getCroppedImg = (image: HTMLImageElement, crop: Crop): Promise<Blob> => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  let width = crop.width
  let height = crop.height
  if (crop.unit === '%') {
    width = image.naturalWidth * (crop.width / 100)
    height = image.naturalHeight * (crop.height / 100)
  }

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  ctx?.drawImage(image, crop.x * scaleX, crop.y * scaleY, width * scaleX, height * scaleY, 0, 0, width, height)

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const reader = new FileReader()
      reader.onload = (result) => {
        resolve(new Blob([result.target!.result as ArrayBuffer]))
      }
      if (blob) {
        reader.readAsArrayBuffer(blob)
      }
    })
  })
}
