import { Fragment } from 'react'
import { DropzoneInputProps } from 'react-dropzone'
import { UseFormReturn, FieldValues, Controller, useFormContext } from 'react-hook-form'

interface IDDFileInputProps {
  inputProps: DropzoneInputProps
}

export function DDFileInput({ inputProps }: IDDFileInputProps) {
  const methods = useFormContext()

  return (
    <Fragment>
      {methods && (
        <Controller
          control={methods!.control}
          name={'fileInput'}
          render={({ field }) => <input {...field} {...inputProps} />}
        />
      )}
    </Fragment>
  )
}
