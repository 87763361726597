import { Box, Stack } from "@mui/material"
import styled from "styled-components"

interface IBdrAnalysItemProps {
  isDisabled?: boolean
}

export const BdrAnalysItem = styled(Stack)<IBdrAnalysItemProps>`
  border-radius: 8px;
  padding: 16px;
  background-color: #1976d214;
  gap: 12px;
  position: relative;

  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`

export const BdrPeriodCloseWrapper = styled(Box)`
  position: absolute;
  right: 8px;
  top: 8px;

  &:hover {
    cursor: pointer;
  }
`
