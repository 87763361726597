import { TMonthName, TQuarterName } from '../../../../../../global/types/commos-def'

export interface IBdrParametersDrawerFormProps {
  availableYears: number[]
  onFormChange: (dirty: boolean) => void
  onDrawerClose: (immediately: boolean) => void
}

export interface IBdrParametersFormData {
  periodType: TBdrPeriodType
  periods: IBdrPeriod[]
  result: TBdrResult
  yearStart: number | null
  yearEnd: number | null
  isResultShown: boolean
  // isResultDisabled: boolean
  isPercentageShown: boolean
}

export type TBdrPeriodSwitch = 'plan' | 'fact' | 'deviation'

export interface IBdrPeriod {
  id: number
  start: IBdrPeriodRange
  end: IBdrPeriodRange
  plan: boolean
  fact: boolean
  deviation: boolean
  columnsHeader: string[]
}

export interface IBdrPeriodRange {
  month: TMonthName | null
  quarter: TQuarterName | null
  year: number
}

export type TBdrResult = Pick<IBdrPeriod, TBdrPeriodSwitch>

export type TBdrPeriodType = 'MONTHLY' | 'QUARTERLY' | 'YEARLY'
export type TBdrPeriodTypeRu = 'Месяцы' | 'Кварталы' | 'Годы'

export const bdrPeriodTypes: TBdrPeriodType[] = ['MONTHLY', 'QUARTERLY', 'YEARLY']

export const bdrPeriodTypeRuByEn: Record<TBdrPeriodType, TBdrPeriodTypeRu> = {
  MONTHLY: 'Месяцы',
  QUARTERLY: 'Кварталы',
  YEARLY: 'Годы',
}

// export const bdrPeriodTypeEnByRu: Record<TBdrPeriodTypeRu, TBdrPeriodType> = {
//     Месяцы: 'MONTHLY',
//     Кварталы: 'QUARTERLY',
//     Годы: 'YEARLY',
// }
