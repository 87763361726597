import { Stack } from "@mui/material"
import styled from "styled-components"

interface IPercentBadgeWrapperProps {
  isPositive: boolean
}

export const PercentBadgeWrapper = styled(Stack)<IPercentBadgeWrapperProps>`
  padding: 2px 6px;
  background-color: ${(props) => (props.isPositive ? "#8EDD9280" : "#FFB1B14D")};
  border-radius: 20px;
  color: ${(props) => (props.isPositive ? props.theme.palette.text.green : props.theme.palette.text.red)};
`
