import styled from "styled-components"
import { Box, Drawer, Stack } from "@mui/material"

export const StyledPaymentTemplateDrawer = styled(Drawer)``
export const StyledDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`

export const StyledTopBar = styled(Box)`
  padding: 20px 20px 15px;
  text-align: center;
  height: 64px;
  display: flex;
`
