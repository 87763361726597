import styled from "styled-components"
import { Box, FormControlLabel, FormLabel, ListItem, Stack, Typography } from "@mui/material"
import FieldForm from "../../../../components/FieldForm/FieldForm"

export const StyledDrawerFormWrapper = styled(Box)`
  padding: 20px;
  //padding-bottom: 46px;
`

export const StyledTypography = styled(Typography)`
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const StyledProjectName = styled(Box)`
  gap: 20px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
`
export const StyledLabelModule = styled(Box)`
  width: 360px;
  height: 32px;
  background: #f6f7fb;
  border-radius: 4px;
  border: 1px solid rgba(120, 144, 178, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledStatus = styled(Box)`
  width: 83px;
  height: 18px;
  background: #6d9adc;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledLogo = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const StyledLogoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -8px;
`

export const StyledLogoTypography = styled(Typography)`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #2b3648;
`

export const StyledModuleCard = styled(Stack)`
  margin-top: 30px;
  width: 172px;
  height: 160px;
  background: #f6f7fb;
  border-radius: 8px;
  align-items: center;
`

export const StyledListItem = styled(ListItem)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 16px;
  border-radius: 6px;
`
export const StyledStatusIntegrtaionCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  height: 240px;
`

export const StyledIntegrtaionLabelCard = styled(Box)`
  text-align: center;
`
