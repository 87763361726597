import { FC, ReactNode, useMemo, useState } from 'react'
import { IUnitPriceCellProps } from './UnitPriceCell.types'
import { EditableCellNumber } from '../EditableCellNumber'
import { TooltipBySum } from '../../components/TooltipBySum'
import { TFormatSelectionValue } from 'pages/BudgetPageNew/components/FormatSelectionDialog/FormatSelectionDialog.types'
import { TooltipByQuantity } from '../../components/TooltipByQuantity'
import { getQueryParam } from 'global/functions'
import { TMonthName } from 'global/types/commos-def'
import { useHistory } from 'react-router-dom'
import { BudgetCellWrapper } from '../../BudgetAgGrid.styles'

export const UnitPriceCell: FC<IUnitPriceCellProps> = ({
  params,
  fillingMode,
  handleFocus,
  handleBlur,
  forceFocus,
  onChangeValue,
  handleTab,
}) => {
  const { data } = params
  const history = useHistory()
  const [splitedQuantity, setSplitedQuantity] = useState<number>(0)
  const [isShouldSplit, setIsShouldSplit] = useState<boolean>(false)
  const [isForciblyBlur, setIsForciblyBlur] = useState<boolean>(false)
  const monthStart = getQueryParam(history, 'monthStart') as TMonthName
  const monthEnd = getQueryParam(history, 'monthEnd') as TMonthName
  const isFullYear = monthStart === 'JANUARY' && monthEnd === 'DECEMBER'

  const onChange = () => {
    setIsShouldSplit((prev) => !prev)
  }

  const tooltipByFillingMode = useMemo(() => {
    const tooltipData: Record<TFormatSelectionValue, ReactNode> = {
      QUANTITY: (
        <TooltipByQuantity
          splitedQuantity={splitedQuantity}
          setSplitedQuantity={setSplitedQuantity}
          onSubmit={() => setIsForciblyBlur(true)}
        />
      ),
      AMOUNT: <TooltipBySum isChecked={isShouldSplit} onChange={onChange} />,
      UNDEFINED: <></>,
    }

    return fillingMode ? tooltipData[fillingMode] : <></>
  }, [fillingMode, isShouldSplit, splitedQuantity])

  return (
    <BudgetCellWrapper>
      <EditableCellNumber
        value={data?.unitPriceWithoutVAT}
        onChangeValue={onChangeValue && ((value) => onChangeValue(value, splitedQuantity, isShouldSplit))}
        viewCellProps={{ style: { textAlign: 'end', height: 'fit-content', fontSize: '14px' } }}
        isForciblyBlur={isForciblyBlur}
        tooltip={isFullYear && tooltipByFillingMode}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        forceFocus={forceFocus}
        handleTab={handleTab}
        shouldUpdate={isShouldSplit}
      />
    </BudgetCellWrapper>
  )
}
