import NiceModal from '@ebay/nice-modal-react'
import { FC, ReactElement, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'

export const ModalManagerLayout: FC = ({ children }): ReactElement | null => {
  const location = useLocation()
  const niceModalContext = useContext(NiceModal.NiceModalContext)

  useEffect(() => {
    Object.keys(niceModalContext).forEach((key) => {
      NiceModal.hide(key)
    })
  }, [location.pathname, location.search])

  return <>{children}</>
}
