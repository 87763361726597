import { errorHandler, req, successHandler } from '../api'
import { INewUserPreferences, IUserPreferences } from '../definitions'

export class OrganizationAPI {
  static baseURL: string = '/organisation'

  static async updateUserPreferences(body: Partial<INewUserPreferences>) {
    return await req
      .put(this.baseURL + '/preferences/update', body)
      .then(({ data }) => {
        successHandler('Настройки успешно сохранены')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getUserPreferences(): Promise<INewUserPreferences> {
    return await req
      .get(this.baseURL + '/preferences/get')
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
}
