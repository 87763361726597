import React, { useEffect, useState } from 'react'
import { Close, Help } from '@mui/icons-material'
import { Autocomplete, AutocompleteProps, Box, Button, SxProps, Tab, TableCell, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ControlPanel } from '../ControlPanel/ControlPanel'
import { SubBillAssign, SUBBILL_ASSIGN_USER_GUIDE_STEPS } from '../SubBillAssign/SubBillAssign'
import { AccountantAPI } from '../../global/api/APIMethods/AccountantAPI'
import { changeQueryParams, getDeepCopy, getQueryParam } from '../../global/functions'
import { autocomplete, outlinedTab, outlinedTabs, scrollableWrapper, STYLED } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IFinCenterOperation,
  IPrPrEstimateOperation,
  IPrPrEstimateOperationWithLvl,
  IReportRelationshipItem,
  ProjectType,
  TBudgetType,
  TEstimateContractType,
} from '../../global/types/commos-def'
import { useHistory, useLocation } from 'react-router-dom'
import { UserGuide } from '../UserGuide/UserGuide'
import { useHiddenRows } from 'hooks/useHiddenRows'

export type TSubBillAssignOperation = IFinCenterOperation | IPrPrEstimateOperationWithLvl

interface isSubBillAssignModeProps {
  operationType: 'finCenter' | 'productionProgram'
  subBillAssignOperationRowCells?: (operation: TSubBillAssignOperation) => React.ReactNode | React.ReactNode[]
  budgetType: TBudgetType
  operations: TSubBillAssignOperation[]
  operationsTableSxProps?: SxProps
  additionalControls?: React.ReactNode | React.ReactNode[]
  autocompleteOptions: IReportRelationshipItem[]
  AutocompleteProps?: Partial<AutocompleteProps<IReportRelationshipItem, undefined, undefined, undefined>>
  open: boolean
  onClose: () => void
  projectType: ProjectType
}

interface isSubBillAssignModeState {
  operations: TSubBillAssignOperation[]
  activeRelationshipItem: IReportRelationshipItem | null
  isUserGuideOpen: boolean
}

export function SubBillAssignMode(props: isSubBillAssignModeProps) {
  const {
    additionalControls,
    operationType,
    subBillAssignOperationRowCells,
    operations,
    operationsTableSxProps,
    open,
    onClose,
    budgetType,
    autocompleteOptions,
    AutocompleteProps,
    projectType,
  } = props

  // console.log('autocompleteOptions: ', autocompleteOptions);
  const [state, setState] = React.useState({
    operations: operations,
    activeRelationshipItem: null,
    isUserGuideOpen: false,
  } as isSubBillAssignModeState)

  const history = useHistory()

  const location = useLocation()

  const { t } = useTranslation('ENUMS')

  React.useEffect(() => {
    // console.log('asdasdasda');
    setState((prevState) => ({
      ...prevState,
      activeRelationshipItem: null,
    }))
  }, [location.search])

  /*******  скрывание строк в таблице    ****/

  const { hiddenRows, isRowHidden, update: updateHiddenRows, reset: resetHiddenRows } = useHiddenRows()

  const [filteredRows, setFilteredRows] = useState<TSubBillAssignOperation[] | null>(null)

  useEffect(() => {
    setFilteredRows(() => {
      if (state.operations && operationType === 'productionProgram' && 'parentId' in state.operations[0]) {
        const filtArr = state.operations.filter((operation) => {
          // Проверяем, содержится ли любой из parentId в списке скрытых строк
          if ((operation as IPrPrEstimateOperationWithLvl).parentIds.some((pid) => hiddenRows.includes(pid))) {
            return false
          }
          // Если parentId не содержатся в hiddenRows, включаем операцию в результат
          return true
        })

        return filtArr.length
          ? (filtArr as IPrPrEstimateOperationWithLvl[])
          : ([...state.operations] as IPrPrEstimateOperationWithLvl[])
      } else {
        return state.operations ? [...state.operations] : null
      }
    })
  }, [hiddenRows, state.operations])

  function switchisUserGuideOpenOpen() {
    setState((prevState) => ({
      ...prevState,
      isUserGuideOpen: !prevState.isUserGuideOpen,
    }))
    resetHiddenRows()
  }

  function setActiveSubBill(value: IReportRelationshipItem | null) {
    // console.log('value: ', value);

    setState((prevState) => ({
      ...prevState,
      activeRelationshipItem: value,
    }))
  }

  function subBillToOperation(
    operationIndex: number,
    type: 'link' | 'unlink',
    budgetType: TBudgetType,
    operationType: 'finCenter' | 'productionProgram',
  ) {
    if (!operationType || operationType == 'finCenter') {
      if (state.operations) {
        AccountantAPI.subBillToOperation(budgetType, type, {
          data: [
            {
              budgetRecordID: state.operations![operationIndex].id as number,
              subBillID: state.activeRelationshipItem?.subBill.id as number,
            },
          ],
        })
        let copy = getDeepCopy(state.operations)
        copy[operationIndex].relationships = type == 'link' ? state.activeRelationshipItem : null
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      }
    } else {
      let copy = getDeepCopy(state.operations) as IPrPrEstimateOperationWithLvl[]
      if (type == 'link') {
        copy[operationIndex].relationships[getQueryParam(history, 'contractType') as TEstimateContractType] =
          state.activeRelationshipItem && state.activeRelationshipItem
      } else {
        copy[operationIndex].relationships[getQueryParam(history, 'contractType') as TEstimateContractType] = null
      }

      let requestBody = {
        estimateID: state.operations[operationIndex].id as number,
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }

      AccountantAPI.subBillToPrPrOperation(type, requestBody, () => false)

      setState((prevState) => ({
        ...prevState,
        operations: copy,
      }))
    }
  }

  function subBillToOperationForPrPr(value: IReportRelationshipItem | null, operation: IPrPrEstimateOperationWithLvl) {
    let copy = getDeepCopy(state.operations) as IPrPrEstimateOperationWithLvl[]
    const operationIndex = copy.findIndex((el) => el.id === operation.id)
    const contractType = getQueryParam(history, 'contractType') as TEstimateContractType

    copy[operationIndex].relationships[contractType] = value

    if (operation.hasChild) {
      const financeCenterID = getQueryParam(history, 'financeCenterID') || 0
      let requestBody = {
        groupId: operation.id as number,
        financeCenterId: Number(financeCenterID),
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }
      AccountantAPI.subBillToPrPrOperationGroup(value === null ? 'unlink' : 'link', requestBody, () => {
        const children = copy.filter((op) => op.parentIds.includes(operation.id as number))
        children.forEach((op) => {
          const operationIndex = copy.findIndex((el) => el.id === op.id)
          copy[operationIndex].relationships[contractType] = value
        })
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      })
    } else {
      let requestBody = {
        estimateID: operation.id as number,
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }
      AccountantAPI.subBillToPrPrOperation(value === null ? 'unlink' : 'link', requestBody, () => {
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      })
    }
  }

  return (
    <Box>
      <UserGuide
        open={state.isUserGuideOpen}
        onClose={switchisUserGuideOpenOpen}
        steps={SUBBILL_ASSIGN_USER_GUIDE_STEPS}
      />
      <ControlPanel.Wrapper>
        {additionalControls}
        {operationType === 'productionProgram' && projectType === 'GEN' && (
          <ControlPanel.InnerContainer align="left" sx={{ mr: 2 }}>
            <Tabs
              sx={STYLED.TABS.OUTLINED}
              value={getQueryParam(history, 'contractType')}
              onChange={(e, value: string) =>
                changeQueryParams(history, {
                  contractType: value,
                })
              }>
              <Tab label="ГЕНПОДРЯД" value="genContract"></Tab>
              <Tab label="СУБПОДРЯД" value="subContract"></Tab>
            </Tabs>
          </ControlPanel.InnerContainer>
        )}
        {operationType === 'finCenter' && (
          <ControlPanel.InnerContainer align="left" sx={{ width: '50%', position: 'relative' }}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              // disablePortal
              fullWidth
              options={autocompleteOptions}
              sx={autocomplete.small}
              value={state.activeRelationshipItem}
              onChange={(event, value) => setActiveSubBill(value)}
              noOptionsText={'Нет данных'}
              // groupBy={(option) => getQueryParam(history, "budgetType") == "bdr" ? t(`BDR_ARTICLE_CATEGORY.` + String(option.articles[0].type)) : option.articles[0].name}
              renderOption={(props, option) => (
                <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                  <SubBillAssign.AutocompleteOptionItem width="50%">
                    {option.label}
                  </SubBillAssign.AutocompleteOptionItem>
                  <SubBillAssign.AutocompleteOptionItem width="25%">
                    {option.gostBill.number + ' ' + option.gostBill.name}
                  </SubBillAssign.AutocompleteOptionItem>
                  <SubBillAssign.AutocompleteOptionItem width="25%">
                    {option.articles[1]?.name}
                  </SubBillAssign.AutocompleteOptionItem>
                </SubBillAssign.AutocompleteOptionWrapper>
              )}
              renderInput={(props) => <SubBillAssign.AutocompleteTextfield {...props} />}
              PopperComponent={(props) => <SubBillAssign.AutocompletePopper {...props} />}
              PaperComponent={(props) => {
                return (
                  <SubBillAssign.AutocompletePaper>
                    <SubBillAssign.AutocompleteOptionHeadings>
                      <SubBillAssign.AutocompleteOptionHeadingItem width="50%">
                        Наименование субсчета
                      </SubBillAssign.AutocompleteOptionHeadingItem>
                      <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                        Наименование счета
                      </SubBillAssign.AutocompleteOptionHeadingItem>
                      <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                        Статья {t('BUDGET_TYPE.' + budgetType)}
                      </SubBillAssign.AutocompleteOptionHeadingItem>
                    </SubBillAssign.AutocompleteOptionHeadings>
                    {props.children}
                  </SubBillAssign.AutocompletePaper>
                )
              }}
              {...AutocompleteProps}
            />
            {/* } */}

            <Button
              size="small"
              sx={{
                ml: 2,
              }}
              onClick={switchisUserGuideOpenOpen}>
              <Help sx={{ color: theme.palette.primary.light }} />
            </Button>
          </ControlPanel.InnerContainer>
        )}

        <ControlPanel.InnerContainer align="right">
          <Button variant="outlined" size="small" startIcon={<Close />} onClick={onClose}>
            Закрыть
          </Button>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>
      <SubBillAssign.TableWrapper sx={{ p: 0, ...operationsTableSxProps }}>
        <SubBillAssign.Headings operationType={operationType}>
          <TableCell
            sx={{
              textAlign: 'left',
              width: operationType === 'productionProgram' ? '415px' : '55%',
            }}>
            Наименование операции
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'left',
              width: operationType === 'productionProgram' ? 'auto' : '30%',
              ...(operationType === 'productionProgram' ? { minWidth: '335px' } : {}),
            }}>
            Наименование субсчета
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'left',
              width: operationType === 'productionProgram' ? '415px' : '15%',
            }}>
            Наименование счета
          </TableCell>
          {operationType === 'productionProgram' && (
            <TableCell
              sx={{
                textAlign: 'left',
                maxWidth: '400px',
              }}>
              Статья {t('BUDGET_TYPE.' + budgetType)}
            </TableCell>
          )}
        </SubBillAssign.Headings>

        <SubBillAssign.Body>
          {filteredRows &&
            filteredRows.map((operation, index) => {
              let isSubBillAssigned: boolean
              // console.log(operation);
              if (operationType === 'finCenter') {
                isSubBillAssigned = !!operation.relationships
              } else {
                isSubBillAssigned = !!(operation as IPrPrEstimateOperation).relationships?.[
                  getQueryParam(history, 'contractType') as TEstimateContractType
                ]?.subBill
              }
              const operationPrPrCurrentContractRelationships =
                ((operation as IPrPrEstimateOperationWithLvl).relationships &&
                  (operation as IPrPrEstimateOperationWithLvl).relationships[
                    getQueryParam(history, 'contractType') as TEstimateContractType
                  ]) ||
                null
              return (
                <SubBillAssign.RecordRow
                  key={operation.id}
                  operationType={operationType}
                  activeRelationshipItem={state.activeRelationshipItem}
                  operation={operation}
                  isSubBillToAssignChosen={!!state.activeRelationshipItem}
                  isSubbillAssigned={isSubBillAssigned}
                  isRowHidden={isRowHidden}
                  updateHiddenRows={updateHiddenRows}
                  linkSubBill={() => subBillToOperation(index, 'link', budgetType, operationType)}
                  unLinkSuBBill={() => subBillToOperation(index, 'unlink', budgetType, operationType)}>
                  {!subBillAssignOperationRowCells && (
                    <>
                      <TableCell>{operation.name || ''}</TableCell>
                      {operationType === 'finCenter' ? (
                        <>
                          <TableCell>
                            {(operation.relationships as IReportRelationshipItem)?.subBill.name || ''}
                          </TableCell>
                          <TableCell>
                            {operation.relationships && (operation.relationships as IReportRelationshipItem)?.gostBill
                              ? (operation.relationships as IReportRelationshipItem)?.gostBill.number +
                                ' ' +
                                (operation.relationships as IReportRelationshipItem)?.gostBill.name
                              : ''}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) => option === value}
                              fullWidth
                              options={autocompleteOptions}
                              sx={autocomplete.small}
                              value={
                                operationPrPrCurrentContractRelationships
                                  ? {
                                      label: operationPrPrCurrentContractRelationships.subBill.name,
                                      ...operationPrPrCurrentContractRelationships,
                                    }
                                  : null
                              }
                              onChange={(event, value) =>
                                subBillToOperationForPrPr(value, operation as IPrPrEstimateOperationWithLvl)
                              }
                              noOptionsText={'Нет данных'}
                              renderOption={(props, option) => (
                                <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                                  <SubBillAssign.AutocompleteOptionItem width="50%">
                                    {option.label}
                                  </SubBillAssign.AutocompleteOptionItem>
                                  <SubBillAssign.AutocompleteOptionItem width="25%">
                                    {option.gostBill.number + ' ' + option.gostBill.name}
                                  </SubBillAssign.AutocompleteOptionItem>
                                  <SubBillAssign.AutocompleteOptionItem width="25%">
                                    {option.articles[1]?.name}
                                  </SubBillAssign.AutocompleteOptionItem>
                                </SubBillAssign.AutocompleteOptionWrapper>
                              )}
                              renderInput={(props) => <SubBillAssign.AutocompleteTextfield {...props} inTable={true} />}
                              PopperComponent={(props) => (
                                <SubBillAssign.AutocompletePopper {...props} inTable={true} />
                              )}
                              PaperComponent={(props) => {
                                return (
                                  <SubBillAssign.AutocompletePaper>
                                    <SubBillAssign.AutocompleteOptionHeadings>
                                      <SubBillAssign.AutocompleteOptionHeadingItem width="50%">
                                        Наименование субсчета
                                      </SubBillAssign.AutocompleteOptionHeadingItem>
                                      <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                                        Наименование счета
                                      </SubBillAssign.AutocompleteOptionHeadingItem>
                                      <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                                        Статья {t('BUDGET_TYPE.' + budgetType)}
                                      </SubBillAssign.AutocompleteOptionHeadingItem>
                                    </SubBillAssign.AutocompleteOptionHeadings>
                                    {props.children}
                                  </SubBillAssign.AutocompletePaper>
                                )
                              }}
                              {...AutocompleteProps}
                            />
                          </TableCell>
                          <TableCell>
                            {operationPrPrCurrentContractRelationships?.gostBill
                              ? operationPrPrCurrentContractRelationships.gostBill.number +
                                ' ' +
                                operationPrPrCurrentContractRelationships.gostBill.name
                              : ''}
                          </TableCell>
                          <TableCell>
                            {operationPrPrCurrentContractRelationships?.articles[1]
                              ? operationPrPrCurrentContractRelationships.articles[1].name
                              : ''}
                          </TableCell>
                        </>
                      )}
                    </>
                  )}
                  {subBillAssignOperationRowCells && subBillAssignOperationRowCells(operation)}
                </SubBillAssign.RecordRow>
              )
            })}
        </SubBillAssign.Body>
      </SubBillAssign.TableWrapper>
    </Box>
  )
}
