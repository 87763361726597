import { Button, Stack } from "@mui/material"
import styled from "styled-components"
import { DragWrapperProps } from "./UploadFileDrawer.def"

export const DragWrapper = styled(Stack)<DragWrapperProps>`
  width: 100%;
  height: 300px;
  padding: ${({ theme }) => theme.spacing(2.5)};
  text-align: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  border: ${(props) => (props.drag ? `2px solid ${props.theme.palette.secondary.gray}` : "")};
  & > div {
    box-shadow: ${(props) => (props.drag ? "inset 0px 0px 250px rgba(0, 26, 77, 0.08)" : "")};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const StyledBtn = styled(Button)``

StyledBtn.defaultProps = {
  fullWidth: true,
  variant: "contained",
}
