import { MenuItem, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { changeQueryParams, getQueryParam } from '../../global/functions'
import { STYLED } from '../../global/styles/presets'
import { TMonthName, TRangeValue, TReportType } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { MONTHS } from '../../global/variables'
import { ControlPanel } from '../ControlPanel/ControlPanel'

export interface IBudgetControlsQueryParamsScheme {
  reportType: TReportType
  monthStart: TMonthName
  monthEnd: TMonthName
  yearStart: string
  yearEnd: string
}

interface IBudgetControlsProps {
  variant: 'finCenter' | 'report'
  availableYears: Array<string | number>
  disabled?: boolean
  hideReportType?: boolean
}

export function BudgetControls(props: IBudgetControlsProps) {
  const { variant, availableYears, disabled, hideReportType } = props

  const history = useHistory()

  const { financeCenterID } = useParams() as Params

  const { t } = useTranslation('ENUMS')

  React.useEffect(() => {
    //  console.log('financeCenterID', financeCenterID)
  }, [])

  React.useEffect(() => {
    let defaultQuery = {
      reportType: 'MONTHLY',
      monthStart: 'JANUARY',
      monthEnd: 'DECEMBER',
      yearStart: availableYears[0],
      yearEnd: availableYears[0],
    }
    !history.location.search.includes('reportType') && changeQueryParams(history, defaultQuery)
  }, [history.location.search])

  function changeReportType(reportType: TReportType) {
    let query = {}
    switch (reportType) {
      case 'MONTHLY':
        query = {
          reportType: reportType,
          quarter: undefined,
          monthStart: 'JANUARY',
          monthEnd: 'DECEMBER',
          yearStart: getQueryParam(history, 'yearStart'),
          yearEnd: getQueryParam(history, 'yearStart'),
        }
        break
      case 'QUARTERLY':
        query = {
          reportType: reportType,
          quarter: 'ALL',
          monthStart: undefined,
          monthEnd: undefined,
          yearStart: getQueryParam(history, 'yearStart'),
          yearEnd: getQueryParam(history, 'yearStart'),
        }
        break
      case 'YEARLY':
        query = {
          reportType: reportType,
          quarter: undefined,
          monthStart: undefined,
          monthEnd: undefined,
          yearStart: availableYears[0],
          yearEnd: availableYears[availableYears.length - 1],
        }
        break
    }
    changeQueryParams(history, query)
  }

  function getMonthOptions(range: 'from' | 'to') {
    let monthStart = getQueryParam(history, 'monthStart')
    let monthEnd = getQueryParam(history, 'monthEnd')
    let targetArray: TRangeValue[] = []
    if (range == 'from') {
      targetArray = MONTHS.filter((month: TMonthName, index: number) => {
        return index <= MONTHS.indexOf(monthEnd as TMonthName)
      })
    }
    if (range == 'to') {
      targetArray = MONTHS.filter((month: TMonthName, index: number) => {
        return index >= MONTHS.indexOf(monthStart as TMonthName)
      })
    }
    return targetArray.map((month) => {
      return (
        <MenuItem key={month} value={month}>
          {t('MONTHS_ABBR.' + month)}
        </MenuItem>
      )
    })
  }

  function getYearRangeOptions(range: 'from' | 'to') {
    let yearStart = getQueryParam(history, 'yearStart')
    let yearEnd = getQueryParam(history, 'yearEnd')
    let targetArray = [] as Array<any>
    if (range == 'from') {
      targetArray = availableYears.filter((year: string | number, index: number) => {
        return Number(year) <= Number(yearEnd)
      })
    }
    if (range == 'to') {
      targetArray = availableYears.filter((year: string | number, index: number) => {
        return Number(year) >= Number(yearStart)
      })
    }
    return targetArray.map((year) => {
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )
    })
  }

  function getYearOptions(years: (string | number)[]) {
    return years.map((year) => {
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )
    })
  }

  return history.location.search ? (
    <>
      {!hideReportType && (
        <Tabs
          value={getQueryParam(history, 'reportType')}
          onChange={(e, value) => changeReportType(value as TReportType)}
          disabled={disabled}
          sx={{ ...STYLED.TABS.OUTLINED, mr: '20px' }}>
          <Tab label="МЕСЯЦЫ" value="MONTHLY" disabled={disabled} />
          <Tab label="КВАРТАЛЫ" value="QUARTERLY" disabled={disabled} />
          {(variant == 'report' || (variant == 'finCenter' && getQueryParam(history, 'category') == 'TOTAL')) && (
            <Tab disabled={disabled} label="ГОДЫ" value="YEARLY" />
          )}
        </Tabs>
      )}
      {getQueryParam(history, 'reportType') == 'MONTHLY' && (
        <ControlPanel.CalendarRange
          disabled={disabled}
          rangeFromParams={{
            value: getQueryParam(history, 'monthStart'),
            onChange: (e) => changeQueryParams(history, { monthStart: e.target.value }),
            options: getMonthOptions('from'),
          }}
          rangeToParams={{
            value: getQueryParam(history, 'monthEnd'),
            onChange: (e) => changeQueryParams(history, { monthEnd: e.target.value }),
            options: getMonthOptions('to'),
          }}
        />
      )}
      {getQueryParam(history, 'reportType') == 'YEARLY' && (
        <ControlPanel.CalendarRange
          disabled={disabled}
          rangeFromParams={{
            value: getQueryParam(history, 'yearStart'),
            onChange: (e) => changeQueryParams(history, { yearStart: e.target.value }),
            options: getYearRangeOptions('from'),
          }}
          rangeToParams={{
            value: getQueryParam(history, 'yearEnd'),
            onChange: (e) => changeQueryParams(history, { yearEnd: e.target.value }),
            options: getYearRangeOptions('to'),
          }}
        />
      )}
      {getQueryParam(history, 'reportType') != 'YEARLY' && availableYears && (
        <ControlPanel.Select
          disabled={disabled}
          value={getQueryParam(history, 'yearStart')}
          onSelectChange={(e) =>
            changeQueryParams(history, {
              yearStart: e,
              yearEnd: e,
            })
          }>
          {getYearOptions(availableYears)}
        </ControlPanel.Select>
      )}
    </>
  ) : (
    <></>
  )
}
