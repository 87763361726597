import { SxProps } from '@mui/material'
import { theme } from '../../global/styles/theme'
import { IReportRecord, TOperationCategory } from '../../global/types/commos-def'

const getSumCellStyle = () => {
  return {
    '& .MuiTableCell-root:nth-of-type(n+2)': {
      textAlign: 'center',
    },
  }
}

const getTitleCellStylesByLvl = (lvl: number, category: TOperationCategory) => {
  if (category === 'TOTAL')
    return {
      '& .MuiTableCell-root:nth-of-type(1)': {
        height: 80,
        color: lvl > 1 ? theme.palette.text.dark : theme.palette.primary.main,
      },
    }

  const titleCellPaddingLeft = lvl > 1 ? lvl : 1
  return {
    '& .MuiTableCell-root:nth-of-type(1)': {
      paddingX: 2 + titleCellPaddingLeft,
    },
  }
}

const getCommonCellStyles = (category: TOperationCategory) => {
  if (category === 'TOTAL')
    return {
      color: theme.palette.text.dark,
      boxShadow: 'none',
      borderBottom: '1px solid #d1d8fa',
      borderRight: '1px solid #d1d8fa',
      height: 80,
      ...theme.typography.subtitle2,
      letterSpacing: 0,
    }

  return {
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid rgba(237, 239, 243, 1) !important`,
    ...theme.typography.subtitle2,
  }
}

const getCommonRowStyles = (hasChildren: boolean) => {
  if (hasChildren)
    return {
      ':hover': {
        '& td': {
          cursor: 'pointer !important',
        },
      },
    }

  return {}
}

const getSubTitleStyle = (category: TOperationCategory) => {
  if (category === 'TOTAL')
    return {
      ':hover': {
        '& td': {
          cursor: 'default',
        },
      },
      '& .MuiTableCell-root': {
        background: 'white',
        color: theme.palette.text.dark,
        fontWeight: '400 !important',
        borderBottom: '1px solid #d1d8fa',
        borderRight: '1px solid #d1d8fa',
        height: 80,
        letterSpacing: 0,
      },
    }

  return {
    ':hover': {
      '& td': {
        cursor: 'default',
      },
    },
    '& .MuiTableCell-root': {
      background: 'white',
      // ...theme.typography.subtitle2,
      color: theme.palette.secondary.main,
      fontWeight: '400 !important',
      // borderBottom: `1px solid ${theme.palette.legends.gray} !important`
    },
  }
}

const getStylesByLvl = (lvl: number, hasRecordChildren: boolean, category: TOperationCategory) => {
  const sumCellStyle = getSumCellStyle()
  const titleCellStyles = getTitleCellStylesByLvl(lvl, category)
  const commonCellStyles = getCommonCellStyles(category)
  const commonRowStyles = getCommonRowStyles(hasRecordChildren)

  const styles: { [key: number]: SxProps } = {
    1: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: `${category === 'TOTAL' ? '#edf4fc' : '#E5ECF7'} !important`,
        boxShadow: 'none',
        // borderBottom: "1px solid #7398D5 !important",
        ...theme.typography.subtitle2,
        color: category === 'TOTAL' ? theme.palette.text.dark : theme.palette.primary.main,
        letterSpacing: category === 'TOTAL' ? 0 : 0.1,
      },
    },
    2: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#F2F6FB !important',
        // borderBottom: "1px solid #AAD5EE !important"
      },
    },
    3: {
      background: 'white',
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#F8FCFC !important',
        // borderBottom: "1px solid #CFEEE9 !important"
      },
    },
    4: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FDFBFD !important',
        // borderBottom: "1px solid #E9CFF8 !important"
      },
    },
    5: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    6: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    7: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    8: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    9: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    10: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
    11: {
      ...titleCellStyles,
      ...sumCellStyle,
      ...commonRowStyles,
      '& .MuiTableCell-root': {
        ...commonCellStyles,
        background: '#FFFCFC !important',
        // borderBottom: "1px solid #FFE6E6 !important"
      },
    },
  }

  return styles[lvl] ?? {}
}

export const getReportRowStyleByLvl = (lvl: number, record: IReportRecord, category: TOperationCategory) => {
  const styles = getStylesByLvl(lvl, record.children.length > 0, category)
  const subTitleStyle = getSubTitleStyle(category)

  return !!record.value.subBill ? { ...styles, ...subTitleStyle } : styles
}

const SALDO_CELL_DEFAULT_STYLES = {
  height: 50,
  borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  fontWeight: 700,
  background: '#d1dff5;',
  letterSpacing: 0,
  borderRadius: 0,
}

const SALDO_CELL_STYLES = {
  FIRST: {
    ...SALDO_CELL_DEFAULT_STYLES,
    borderRadius: '0px 0px 0px 8px !important',
    padding: theme.spacing(1),
  },
  LAST: {
    ...SALDO_CELL_DEFAULT_STYLES,
    borderRadius: '0px 0px 8px 0px',
  },
  DEFAULT: SALDO_CELL_DEFAULT_STYLES,
}

export const getReportSaldoCellStyle = (prop: keyof typeof SALDO_CELL_STYLES) => {
  return SALDO_CELL_STYLES[prop]
}
