import * as React from 'react'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import {
  Box,
  Button,
  Stack,
  LinearProgressProps,
  SnackbarContent,
  Snackbar,
  Typography,
  AlertTitle,
  styled,
} from '@mui/material'
import { Loader } from '../../../../assets/SVG/Loader'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { ExcelParseError, TFactType, UploadType } from '../../../../global/types/commos-def'
import { StyledAlert } from './styles'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useHistory, useParams } from 'react-router-dom'
import { Params } from '../../../../global/types/params-def'
import { socketChanel } from '../../../../global/api/socket'
import { changeQueryParams } from '../../../../global/functions'
import { useTranslation } from 'react-i18next'
import { ProjectsAPI } from '../../../../global/api/APIMethods/ProjectsAPI'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface IUploadAlertProps {
  isDrawerOpen: boolean // isUploadDrawerOpen || isIntegDrawerOpen
  progressPercent?: number
  progressTime?: string
  isUploaded?: UploadType
  isIntegrateRM?: UploadType
  onClickDrawerOpen: () => void
  setUploaded: (value: UploadType) => void
  setIsIntegrateRM?: (value: UploadType) => void
  handleRefresh: () => void
  setErrorMsg?: (value: string) => void
  socketChanel?: WebSocket
  factType: TFactType
  setErrorList: (value: ExcelParseError[]) => void
  file: File
}

export default function UploadAlert({
  isDrawerOpen,
  isUploaded /* progressTime, progressPercent, */,
  setUploaded,
  onClickDrawerOpen,
  handleRefresh,
  setIsIntegrateRM,
  isIntegrateRM,
  setErrorMsg,
  /* socketChanel, */ factType,
  setErrorList,
  file,
}: IUploadAlertProps) {
  const [open, setOpen] = React.useState(false)
  const [colorVar, setColor] = React.useState<string>('')

  const [progressTime, setProgressTime] = React.useState<string>('')
  const [progressPercent, setProgressPercent] = React.useState<number>(0)

  const history = useHistory()
  const { t } = useTranslation('production_program')

  React.useMemo(() => {
    if (isUploaded === 'loading' && !isDrawerOpen) {
      setOpen(true)
    } else if (isUploaded === 'loading') {
      setOpen(false)
    }
  }, [isDrawerOpen, isUploaded])

  React.useEffect(() => {
    if (isUploaded === 'loading') {
      setColor('#2B3648')
    }
    if (isUploaded === 'success') {
      setColor('#2E7D32')
    }
    if (isUploaded === 'error') {
      setColor('#F46B6B')
    }
  }, [isUploaded])

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = React.useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      /*  if (reason === 'timeout') {
       setUploaded('start')
     } */
      if (reason === 'clickaway') {
        return
      }
      if (isUploaded === 'success' || isIntegrateRM === 'success') {
        setUploaded('start')
        setIsIntegrateRM?.('start')
      }
      setOpen(false)
    },
    [isUploaded, isIntegrateRM],
  )

  function LinearProgressPercent(props: LinearProgressProps & { value: number }) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: '6px',
      borderRadius: '15px',
      boxShadow: 'inset 0px -1px 1px rgba(52, 89, 254, 0.15), inset 0px 1px 1px rgba(52, 89, 254, 0.15)',
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#6D9ADC' : '#308fe8',
      },
    }))

    return (
      <Box
        sx={{
          width: '96%',
          position: 'absolute',
          bottom: 0,
          left: '10px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" style={{ padding: '0 10px' }} color="#2B3648">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }
  /****************************************** websocket *************************************************/
  const { projectID } = useParams() as Params

  const auth = JSON.parse(localStorage.getItem('auth') || '{}')

  socketChanel.onmessage = function (event) {
    let status = JSON.parse(event.data).type
    let syncId = JSON.parse(event.data).id

    if (status === 'upload_progress' && Number(projectID) === syncId) {
      setUploaded('loading')
      let bodyJson = JSON.parse(event.data).bodyJson
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress
      setProgressPercent!(Number(progress))
      let totalRecords = Number(JSON.parse(bodyJson).total)
      // setUploadRecords!(totalRecords);
    }
    if (status === 'status_ok') {
      setUploaded('success')
      handleRefresh()
    }
    if (status === 'error') {
      let formData = new FormData()
      formData.append('file', file!)
      ProjectsAPI.uploadTemplateFact(projectID, factType, formData!)
        .then(({ success, error, errorList }) => {
          if (success) {
            setUploaded('success')
          }
          if (!success) {
            setErrorList(errorList!)
            setUploaded('error')
          }
        })
        .catch((error) => {
          const errorCustom: ExcelParseError = {
            cell: '',
            col: 0,
            row: 0,
            message: String(error),
            type: 'invalid_cell_value',
          }
          setErrorList([errorCustom])
          setUploaded('error')
        })
    }
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={(isIntegrateRM || isUploaded) === 'success' ? 6000 : null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledAlert
          onClose={handleClose}
          variant="outlined"
          icon={
            ((isIntegrateRM === 'loading' || isUploaded === 'loading') && <Loader />) ||
            ((isIntegrateRM === 'success' || isUploaded === 'success') && <DoneIcon sx={{ color: '#2E7D32' }} />) ||
            ((isIntegrateRM === 'error' || isUploaded === 'error') && <ErrorOutlineIcon sx={{ color: '#F46B6B' }} />)
          }
        >
          <AlertTitle
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: `${colorVar}`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {(isIntegrateRM === 'loading' || isUploaded === 'loading') && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '32px' }}>
                  Идет загрузка данных.
                  {progressPercent > 0 ? ` Осталось примерно  ${progressTime}` : ' Рассчитывается оставшееся время....'}
                </Typography>
                <Button onClick={onClickDrawerOpen} variant="text" style={{ color: '#0044B4' }}>
                  подробнее
                </Button>
              </>
            )}
            {(isIntegrateRM === 'success' || isUploaded === 'success') && `Данные успешно загружены!`}
            {(isIntegrateRM === 'error' || isUploaded === 'error') && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '44px' }}>
                  Не удалось загрузить данные
                </Typography>
                <Button onClick={onClickDrawerOpen} variant="text" style={{ color: '#0044B4' }}>
                  подробнее
                </Button>
              </>
            )}
          </AlertTitle>
          {(isIntegrateRM === 'loading' || isUploaded === 'loading') && (
            <LinearProgressPercent value={progressPercent} />
          )}
        </StyledAlert>
      </Snackbar>
    </Stack>
  )
}
