import React from 'react'
import { Stack, Typography } from '@mui/material'
import { CardSubtitle } from '../styles'
import { StyledCardContent } from './styles'
import { UserCardContentProps } from './UserCardContent.types'

export const UserCardContent: React.FC<UserCardContentProps> = ({ data }) => {
  const { company } = data
  const { userCompanyName, userPosition } = company

  return (
    <StyledCardContent>
      <Stack spacing={1.5}>
        <Stack spacing={0.5} alignItems="start">
          <CardSubtitle>Компания:</CardSubtitle>
          <Typography variant="body2">{userCompanyName}</Typography>
        </Stack>

        <Stack spacing={0.5} alignItems="start">
          <CardSubtitle>Должность:</CardSubtitle>
          <Typography variant="body2">{userPosition}</Typography>
        </Stack>
      </Stack>
    </StyledCardContent>
  )
}
