import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Close, Help } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Button,
  CircularProgress,
  SxProps,
  Tab,
  TableCell,
  Tabs,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  IFinCenterOperation,
  IPrPrEstimateOperation,
  IPrPrEstimateOperationWithLvl,
  IReportRelationshipItem,
  ProjectType,
  TBudgetType,
  TEstimateContractType,
} from '../../../global/types/commos-def'
import { useHistory, useLocation } from 'react-router-dom'
import { useHiddenRows } from 'hooks/useHiddenRows'
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-community'
import { CellRenderer } from 'components/TableAGGrid/CellRenderer/CellRenderer'
import { StyledAgGrid } from '../ProdctionProgram.styles'
import { IAgGridEstimateContext, IProductionProgramState } from '../ProductionProgram-def'
import { changeQueryParams, getDeepCopy, getQueryParam } from 'global/functions'
import { AccountantAPI } from 'global/api/APIMethods/AccountantAPI'
import { ControlPanel } from 'components/ControlPanel/ControlPanel'
import { autocomplete, STYLED } from 'global/styles/presets'
import { SubBillAssign } from 'components/SubBillAssign/SubBillAssign'
import { useAgGridLastRowInView } from '../useGridScroll'

export type TSubBillAssignOperation = IFinCenterOperation | IPrPrEstimateOperationWithLvl

interface isSubBillAssignModeProps {
  operationType: 'finCenter' | 'productionProgram'
  subBillAssignOperationRowCells?: (operation: TSubBillAssignOperation) => React.ReactNode | React.ReactNode[]
  budgetType: TBudgetType
  operations: TSubBillAssignOperation[]
  operationsTableSxProps?: SxProps
  additionalControls?: React.ReactNode | React.ReactNode[]
  autocompleteOptions: IReportRelationshipItem[]
  AutocompleteProps?: Partial<AutocompleteProps<IReportRelationshipItem, undefined, undefined, undefined>>
  open: boolean
  onClose: () => void
  projectType: ProjectType
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
  isFetching: boolean
  isLoading: boolean
  hasNextPage: boolean
  fetchNextPage: () => void
}

export function EstimateSubBillAssignMode(props: isSubBillAssignModeProps) {
  const {
    additionalControls,
    operationType,
    operations,
    onClose,
    budgetType,
    autocompleteOptions,
    AutocompleteProps,
    projectType,
    setState,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = props

  const history = useHistory()
  const { t } = useTranslation('ENUMS')

  const { hiddenRows, isRowHidden, update: updateHiddenRows, reset: resetHiddenRows } = useHiddenRows()
  const [filteredRows, setFilteredRows] = useState<TSubBillAssignOperation[] | null>(null)

  const gridApiRef = useRef<GridApi | null>(null)

  useEffect(() => {
    setFilteredRows(() => {
      if (operations && operations[0] && operationType === 'productionProgram' && 'parentIds' in operations[0]) {
        const filtArr = operations.filter((operation) => {
          // Проверяем, содержится ли любой из parentId в списке скрытых строк
          if ((operation as IPrPrEstimateOperationWithLvl).parentIds?.some((pid) => hiddenRows.includes(pid))) {
            return false
          }
          // Если parentId не содержатся в hiddenRows, включаем операцию в результат
          return true
        })

        return filtArr.length
          ? (filtArr as IPrPrEstimateOperationWithLvl[])
          : ([...operations] as IPrPrEstimateOperationWithLvl[])
      } else {
        return operations ? [...operations] : null
      }
    })
  }, [hiddenRows, operations])

  const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(
    gridApiRef.current,
    filteredRows?.length || 0,
    '[grid-id="estimate-subbills"] .ag-body-viewport',
  )
  const [rendering, setRendering] = useState(false)
  const [outerLoading, setOuterLoading] = useState(false)

  useEffect(() => {
    if (!isFetching) {
      resetInView() // Сбросить только после того, как данные были успешно загружены
    }
  }, [isFetching])

  const shouldFetchMoreData = !isFetching && isLastRowInView && hasNextPage && !rendering

  useEffect(() => {
    if (!shouldFetchMoreData) return
    fetchNextPage()
  }, [shouldFetchMoreData])

  useLayoutEffect(() => {
    if (isFetching || isLoading) {
      setRendering(true)
      if (gridApiRef.current) {
        gridApiRef.current.showLoadingOverlay()
      } else {
        setOuterLoading(true)
      }
    } else {
      if (filteredRows?.length) {
        setTimeout(() => {
          setRendering(false)
          gridApiRef.current?.hideOverlay()
        }, 500)
      } else {
        gridApiRef.current?.showNoRowsOverlay()
      }
    }
  }, [gridApiRef.current, isFetching, isLoading, filteredRows])

  function subBillToOperationForPrPr(value: IReportRelationshipItem | null, operation: IPrPrEstimateOperationWithLvl) {
    let copy = getDeepCopy(operations) as IPrPrEstimateOperationWithLvl[]
    const operationIndex = copy.findIndex((el) => el.id === operation.id)
    const contractType = getQueryParam(history, 'contractType') as TEstimateContractType

    copy[operationIndex].relationships[contractType] = value

    if (operation.hasChild) {
      const financeCenterID = getQueryParam(history, 'financeCenterID') || 0
      let requestBody = {
        groupId: operation.id as number,
        financeCenterId: Number(financeCenterID),
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }
      AccountantAPI.subBillToPrPrOperationGroup(value === null ? 'unlink' : 'link', requestBody, () => {
        const children = copy.filter((op) => op.parentIds?.includes(operation.id as number))
        children.forEach((op) => {
          const operationIndex2 = copy.findIndex((el) => el.id === op.id)
          copy[operationIndex2].relationships[contractType] = value
        })
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      })
    } else {
      let requestBody = {
        estimateID: operation.id as number,
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }
      AccountantAPI.subBillToPrPrOperation(value === null ? 'unlink' : 'link', requestBody, () => {
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      })
    }
  }

  const columnDefs: ColDef[] = [
    {
      field: 'lvl',
      headerName: '',
      sortable: false,
      cellRenderer: CellRenderer.Lvl,
      maxWidth: 44,
      minWidth: 44,
    },
    {
      valueGetter: 'node',
      headerName: '',
      sortable: false,
      cellRenderer: CellRenderer.Arrow,
      maxWidth: 44,
      minWidth: 44,
    },
    {
      field: 'name',
      headerName: 'Наименование операции',
      minWidth: 350,
      maxWidth: 350,
      valueGetter: (params) => params?.data?.name || '',
    },
    {
      headerName: 'Наименование субсчета',
      flex: 3,
      minWidth: 200,
      cellRenderer: (params: ICellRendererParams<IPrPrEstimateOperationWithLvl>) => {
        const operationPrPrCurrentContractRelationships =
          ((params?.data as IPrPrEstimateOperationWithLvl).relationships &&
            (params?.data as IPrPrEstimateOperationWithLvl).relationships[
              getQueryParam(history, 'contractType') as TEstimateContractType
            ]) ||
          null

        return (
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            options={autocompleteOptions}
            sx={autocomplete.small}
            value={
              operationPrPrCurrentContractRelationships
                ? {
                    label: operationPrPrCurrentContractRelationships.subBill.name,
                    ...operationPrPrCurrentContractRelationships,
                  }
                : null
            }
            onChange={(event, value) => subBillToOperationForPrPr(value, params?.data as IPrPrEstimateOperationWithLvl)}
            noOptionsText={'Нет данных'}
            renderOption={(props, option) => (
              <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                <SubBillAssign.AutocompleteOptionItem width="50%">{option.label}</SubBillAssign.AutocompleteOptionItem>
                <SubBillAssign.AutocompleteOptionItem width="25%">
                  {option.gostBill.number + ' ' + option.gostBill.name}
                </SubBillAssign.AutocompleteOptionItem>
                <SubBillAssign.AutocompleteOptionItem width="25%">
                  {option.articles[1]?.name}
                </SubBillAssign.AutocompleteOptionItem>
              </SubBillAssign.AutocompleteOptionWrapper>
            )}
            renderInput={(props) => <SubBillAssign.AutocompleteTextfield {...props} inTable={true} />}
            PopperComponent={(props) => <SubBillAssign.AutocompletePopper {...props} inTable={true} />}
            PaperComponent={(props) => {
              return (
                <SubBillAssign.AutocompletePaper>
                  <SubBillAssign.AutocompleteOptionHeadings>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="50%">
                      Наименование субсчета
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                      Наименование счета
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                      Статья {t('BUDGET_TYPE.' + budgetType)}
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                  </SubBillAssign.AutocompleteOptionHeadings>
                  {props.children}
                </SubBillAssign.AutocompletePaper>
              )
            }}
            {...AutocompleteProps}
          />
        )
      },
    },
    {
      field: 'gostBill',
      headerName: 'Наименование счета',
      flex: 2,
      minWidth: 200,
      wrapText: true,
      cellRenderer: (params: ICellRendererParams<IPrPrEstimateOperationWithLvl>) => {
        const val = params.value
        return <TooltipStringCell val={val} />
      },
      valueGetter: (params) => {
        const operationPrPrCurrentContractRelationships =
          ((params?.data as IPrPrEstimateOperationWithLvl).relationships &&
            (params?.data as IPrPrEstimateOperationWithLvl).relationships[
              getQueryParam(history, 'contractType') as TEstimateContractType
            ]) ||
          null
        return operationPrPrCurrentContractRelationships?.gostBill
          ? operationPrPrCurrentContractRelationships.gostBill.number +
              ' ' +
              operationPrPrCurrentContractRelationships.gostBill.name
          : ''
      },
    },
    {
      field: 'articles',
      headerName: 'Статья',
      flex: 2,
      minWidth: 200,
      wrapText: true,
      cellRenderer: (params: ICellRendererParams<IPrPrEstimateOperationWithLvl>) => {
        const val = params.value
        return <TooltipStringCell val={val} />
      },
      valueGetter: (params) => {
        const operationPrPrCurrentContractRelationships =
          ((params?.data as IPrPrEstimateOperationWithLvl).relationships &&
            (params?.data as IPrPrEstimateOperationWithLvl).relationships[
              getQueryParam(history, 'contractType') as TEstimateContractType
            ]) ||
          null
        return operationPrPrCurrentContractRelationships?.articles[1]
          ? operationPrPrCurrentContractRelationships.articles[1].name
          : ''
      },
    },
  ]

  const tableContext = useMemo(() => {
    return {
      hiddenRows,
      isRowHidden,
      updateHiddenRows,
    } as IAgGridEstimateContext
  }, [])

  const rowData = useMemo(() => {
    return filteredRows
  }, [filteredRows])

  const getRowId = useCallback(({ data }) => {
    return data?.id.toString()
  }, [])

  return (
    <Box>
      <ControlPanel.Wrapper>
        {additionalControls}
        {operationType === 'productionProgram' && projectType === 'GEN' && (
          <ControlPanel.InnerContainer align="left" sx={{ mr: 2 }}>
            <Tabs
              sx={STYLED.TABS.OUTLINED}
              value={getQueryParam(history, 'contractType')}
              onChange={(e, value: string) =>
                changeQueryParams(history, {
                  contractType: value,
                })
              }>
              <Tab label="ГЕНПОДРЯД" value="genContract"></Tab>
              <Tab label="СУБПОДРЯД" value="subContract"></Tab>
            </Tabs>
          </ControlPanel.InnerContainer>
        )}
        <ControlPanel.InnerContainer align="right">
          <Button variant="outlined" size="small" startIcon={<Close />} onClick={onClose}>
            Закрыть
          </Button>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>
      {outerLoading && <CircularProgress />}
      <StyledAgGrid
        gridId="estimate-subbills"
        rowData={rowData}
        getRowId={getRowId}
        columnDefs={columnDefs}
        rowHeight={56}
        context={tableContext}
        suppressScrollOnNewData={true}
        suppressColumnMoveAnimation={true}
        loadingOverlayComponent={CircularProgress}
        noRowsOverlayComponent={() => <div></div>}
        onGridReady={(params) => {
          gridApiRef.current = params.api // Сохраняем ссылку на GridApi
        }}
      />
    </Box>
  )
}

const TooltipStringCell = ({ val }: { val: string }) => {
  return (
    <Tooltip title={val.length > 50 ? val : undefined} arrow>
      <Box
        sx={{
          width: '100%',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          lineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: 1.3,
          wordBreak: 'break-word',
        }}>
        {val}
      </Box>
    </Tooltip>
  )
}
