import { BodyScrollEndEvent, GridApi } from 'ag-grid-community'
import { useEffect, useRef, useState } from 'react'

const intersectionObserverOptions = {
  root: document.querySelector('.ag-body-viewport'),
  rootMargin: '0px 0px 120px 0px',
  threshold: 0,
}

const getAgGridLastRowElement = (api: GridApi, selector: string) => {
  const gridBody = document.querySelector(selector) as HTMLElement

  const rowCount = api.getDisplayedRowCount()
  const lastRowIndex = rowCount - 2

  const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex)

  return gridBody.querySelectorAll(`[row-index="${lastRowNode!.rowIndex}"]`)[0]
}

export const useAgGridLastRowInView = (gridApi: GridApi | null, dataLength: number, selector: string) => {
  const [inView, setInView] = useState<boolean>(false)

  const lastRowElementRef = useRef<Element | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const intersectionObserverCallback: IntersectionObserverCallback = ([entry], observe) => {
    if (!entry.isIntersecting) return

    setInView(true)
    observe.unobserve(lastRowElementRef.current!)
    observerRef.current = null
    lastRowElementRef.current = null
  }

  useEffect(() => {
    if (!gridApi) return

    const handleBodyScrollEnd = (event: BodyScrollEndEvent) => {
      const lastRowElement = getAgGridLastRowElement(event.api, selector)

      if (!lastRowElement) return
      if (lastRowElementRef.current === lastRowElement) return

      if (observerRef.current && lastRowElementRef.current) {
        observerRef.current.unobserve(lastRowElementRef.current)
      }

      lastRowElementRef.current = lastRowElement

      const observer = new IntersectionObserver(intersectionObserverCallback, intersectionObserverOptions)
      observer.observe(lastRowElementRef.current)
      observerRef.current = observer
    }

    gridApi.addEventListener('bodyScrollEnd', handleBodyScrollEnd)

    return () => {
      gridApi.removeEventListener('bodyScrollEnd', handleBodyScrollEnd)
    }
  }, [gridApi, selector])

  const reset = () => {
    setInView(false) // Сбросить флаг при явном сбросе
  }

  return {
    inView,
    reset,
  }
}
