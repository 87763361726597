import styled from "styled-components"
import { Box, Drawer, FormControlLabel, FormLabel, Stack } from "@mui/material"

export const StyledFactUploadDrawer = styled(Drawer)``

export const FactUploadDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
  min-height: 937px;
`

export const FactUploadDrawerTopBar = styled(Box)`
  padding: 20px 20px 18px;
  display: flex;
  justify-content: center;
`

export const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 12px;
  color: #0044b4;
  font-weight: 500;
  font-size: 14px;
`
export const StyledFormControlLabel = styled(FormControlLabel)`
  border-radius: 8px;
  margin: 0;
  margin-bottom: 12px;
  padding: 10px 8px 10px 13px;
  background: ${(props) => (props.checked === true ? "rgba(25, 118, 210, 0.08)" : "#FFFFFF")};

  text-transform: uppercase;
  ont-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.primary.main};

  & .MuiTypography-root {
    font-weight: 500;
    font-size: 13px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.primary.main};
  }

  & .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0;
    margin-right: 13px;
  }
`
