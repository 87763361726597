import { Typography } from '@mui/material'
import styled from 'styled-components'

export const DescriptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-weight: 700;
`

DescriptionTitle.defaultProps = {
  variant: 'tooltip',
}
