import styled from "styled-components"
import { Container, Stack } from "@mui/material"

export const InnerContainer = styled(Container)`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  padding: 0 7px 16px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const UserManagementWrapper = styled(Stack)`
  margin-top: ${(props) => props.theme.spacing(1.25)};
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  overflow: auto;
`
