import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { IEditableCellTooltipErrorProps } from './EditableCellTooltipError.types'
import { Error as ErrorIcon } from '@mui/icons-material'
import { theme } from 'global/styles/theme'

export const EditableCellTooltipError: FC<IEditableCellTooltipErrorProps> = ({ text }) => {
  return (
    <Stack spacing={1.5}>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <ErrorIcon style={{ fill: theme.palette.text.light }} />
        <Typography variant="body1" fontSize={18} color={theme.palette.text.light}>
          Ошибка
        </Typography>
      </Stack>
      <Typography variant="body2" fontSize={16} color={theme.palette.text.light}>
        {text}
      </Typography>
    </Stack>
  )
}
