import React, { Fragment, useState } from 'react'
import { Box, BoxProps, Button, SxProps, Tooltip, Typography } from '@mui/material'
import { TypographyProps } from '@mui/system'
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom'
import { getActivePage } from '../../global/functions'
import { IPage } from '../../global/routing'
import { theme } from '../../global/styles/theme'
import { ArrowBack } from '@mui/icons-material'
import { IQueryObj } from '../../global/api/api'

export interface IBreadcrumb {
  variant: 'link' | 'title'
  title: string | JSX.Element
  path?: string
}

interface ILinkToPrevPageProps extends TypographyProps {
  path: string
  children: React.ReactNode | JSX.Element
  title: string
}

interface IBreadcrumbState {
  activePage: null | IPage
  params: null | IQueryObj | undefined
  breadcrumbs: null | IBreadcrumb[]
}

interface IMyBreadcrumbsProps {
  backPath?: string
  breadcrumbs: null | IBreadcrumb[]
}

const textOverflowEllipsisStyle: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 350,
  display: 'block',
  // lineClamp: 1,
  whiteSpace: 'nowrap',
}

export const Breadcrumbs = {
  Wrapper: function Wrapper(props: BoxProps) {
    const { children, sx } = props

    return <Box sx={{ marginRight: 'auto', alignItems: 'center', display: 'flex', ...sx }}>{children}</Box>
  },
  ActivePageTitle: function ActivePageTitle(props: BoxProps) {
    const { children, title } = props

    return (
      <Tooltip title={title!}>
        <Typography variant={'h2'} color={`${theme.palette.text.primary}`} sx={textOverflowEllipsisStyle}>
          {children}
        </Typography>
      </Tooltip>
    )
  },
  LinkToPrevPage: function LinkToPrevPage(props: ILinkToPrevPageProps) {
    const { children, path, title } = props

    return (
      <Tooltip title={title}>
        <Box display="flex" alignItems="center">
          <Link to={path} style={{ textDecoration: 'none' }}>
            <Typography variant={'h2'} color={`${theme.palette.text.primary}`} sx={textOverflowEllipsisStyle}>
              {children}
            </Typography>
          </Link>
          <Box mx={1.5}>
            <Typography variant={'h2'} color={`${theme.palette.text.primary}`}>
              /
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    )
  },
  Component: undefined as unknown as () => JSX.Element,
}

export function MyBreadcrumbs(props: IMyBreadcrumbsProps) {
  const { breadcrumbs, backPath } = props

  const history = useHistory()

  const location = useLocation()

  const [state, setState] = useState({
    activePage: getActivePage(location.pathname),
    params: matchPath(location.pathname, {
      path: getActivePage(location.pathname).path,
      exact: false,
      strict: true,
    })?.params,
  } as IBreadcrumbState)

  return (
    <Breadcrumbs.Wrapper>
      {backPath && (
        <Button
          size="medium"
          startIcon={<ArrowBack />}
          sx={{
            mr: 1,
            color: theme.palette.primary.main,
          }}
          onClick={(e) => state.activePage && history.push(backPath)}
        >
          Назад
        </Button>
      )}
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.variant == 'title') {
            return (
              <Fragment key={index}>
                <Breadcrumbs.ActivePageTitle title={breadcrumb.title as string}>
                  {breadcrumb.title}
                </Breadcrumbs.ActivePageTitle>
              </Fragment>
            )
          } else {
            return (
              <Fragment key={index}>
                <Breadcrumbs.LinkToPrevPage title={breadcrumb.title as string} path={breadcrumb.path as string}>
                  {breadcrumb.title}
                </Breadcrumbs.LinkToPrevPage>
              </Fragment>
            )
          }
        })}
    </Breadcrumbs.Wrapper>
  )
}
