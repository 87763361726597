import qs from 'qs'
import React, { useContext } from 'react'
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { MappingArticlesAPI } from '../../../../global/api/APIMethods/MappingArticlesAPI'
import {
  IGetArticleByArticleIDResponse,
  IGetSubbillsByArticleIDResponse,
  ISubBillPair,
} from '../../../../global/api/definitions'
import { changeQueryParams, getQueryParam } from '../../../../global/functions'
import { IProjectInfo, ProjectType, TBDRArticleType, TBudgetType } from '../../../../global/types/commos-def'
import { theme } from '../../../../global/styles/theme'
import { SubBillList } from './components/SubBillList'
import { BDR_ROOT_LVL } from '../../../../global/variables'
import { ArticleList } from './components/Articles/ArticleList'
import { scrollableWrapper } from '../../../../global/styles/presets'
import { IMappingPageSubPageProps, TControlBtn } from '../../MappingPage'
import { useDidMountEffect } from '../../../../hooks/useDidMountEffect'
import { ProjectsAPI } from '../../../../global/api/APIMethods/ProjectsAPI'
import { InterfaceContext } from '../../../../contexts/context.interface'
import { useTypedSelector } from '../../../../store/store'

export interface IMappingArticlesState {
  filledWith: 'articles' | 'subBills' | 'empty' | undefined
  articleData: IGetArticleByArticleIDResponse | null
  subBillList: (ISubBillPair & { initial?: boolean })[] | null
  refresh: boolean
}

export interface IMappingArticleQuerySchema {
  BDRArticleType: TBDRArticleType | undefined
  currentArticleID: string | undefined
  editable: 'false' | 'true' | undefined
  budgetType: TBudgetType
}

interface ITableHeadingBreadCrumb {
  id: string | number | undefined
  title: string
  clickable: boolean
}

interface IMappingArticlesProps extends Partial<TableContainerProps>, IMappingPageSubPageProps {}

export function sortSubBillsByName(subBillList: IMappingArticlesState['subBillList']) {
  return subBillList?.sort((pairA, pairB) => {
    let prev = pairA.subBill.name.toLowerCase()
    var next = pairB.subBill.name.toLowerCase()
    if (prev < next) {
      return -1
    }
    if (prev > next) {
      return 1
    }
    return 0
  })
}

export function MappingArticles(props: IMappingArticlesProps) {
  const { controlsState, setControlsState, isRefetch, setIsRefetch } = props

  const organizationID = useTypedSelector((state) => state.profile.currentCompanyId)

  const [state, setState] = React.useState({
    filledWith: undefined,
    articleData: null,
    subBillList: null,
  } as IMappingArticlesState)

  const history = useHistory()

  const location = useLocation()

  let isGoBackAvailable = getQueryParam(history, 'BDRArticleType') || getQueryParam(history, 'currentArticleID')

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingArticleQuerySchema

  React.useEffect(() => {
    if (location.search && organizationID) {
      hideMappingArticleBtns()
      getArticleData(location.search, organizationID).then((data) =>
        setState((prevState) => ({
          ...prevState,
          articleData: data,
        })),
      )
    }
    return () => {
      setState((prevState) => ({
        ...prevState,
        articleData: {
          ...(prevState.articleData as IGetArticleByArticleIDResponse),
          data: null,
        },
        subBillList: null,
      }))
    }
  }, [
    queryParams.currentArticleID,
    queryParams.BDRArticleType,
    queryParams.budgetType,
    state.refresh,
    organizationID,
    isRefetch,
  ])

  React.useEffect(() => {
    state.articleData?.parents &&
      state.articleData?.parents?.length >= 9 &&
      setControlsState((prevState) => ({
        ...prevState,
        addArticleBtn: {
          ...prevState.addArticleBtn,
          hidden: true,
        },
      }))
  }, [state.articleData])

  React.useEffect(() => {
    // let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingArticleQuerySchema
    // Кусок кода отвечает за отображение кнопок, в зависимости от наполнения статьи
    if (state.articleData?.data && state.subBillList != null) {
      setControlsState((prevState) => ({
        ...prevState,
        subBillAssignBtn: {
          ...prevState.subBillAssignBtn,
          hidden: !!state.articleData?.data?.length || 0 > 0,
        },
        addArticleBtn: {
          ...prevState.addArticleBtn,
          hidden:
            queryParams.BDRArticleType || queryParams.currentArticleID
              ? //@ts-ignore
                (state.articleData?.parents && state.articleData?.parents?.length >= 9) ||
                (state.subBillList && state.subBillList.length > 0) ||
                //@ts-ignore
                state.articleData?.data?.[0]?.editable == false
                ? true
                : // : !!state.articleData?.data?.length || 0 > 0
                  false
              : true,
        },
      }))
    }
    // ==============================================================================
  }, [state.articleData?.data, state.subBillList])

  function hideMappingArticleBtns() {
    setControlsState((prevState) => ({
      ...prevState,
      addArticleBtn: {
        ...prevState.addArticleBtn,
        hidden: true,
      },
      subBillAssignBtn: {
        ...prevState.subBillAssignBtn,
        hidden: true,
      },
    }))
  }

  async function getArticleData(
    locationSearch: typeof location['search'],
    orgID: number,
  ): Promise<IGetArticleByArticleIDResponse> {
    let queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true }) as unknown as IMappingArticleQuerySchema
    const { currentArticleID, budgetType, BDRArticleType } = queryParams

    if (queryParams.currentArticleID) {
      MappingArticlesAPI.getSubBillsByArticleID(budgetType, {
        articleID: Number(queryParams.currentArticleID),
        ...(budgetType !== 'bdds'
          ? {
              budgetType: props.budgetType,
            }
          : {}),
      }).then((data) => {
        changeQueryParams(history, {
          filledWith: data.data?.length > 0 ? 'subBills' : undefined,
        })
        setSubBillList(
          data.data.map((pair) => {
            pair.initial = true
            return pair
          }),
        )
      })
    }
    if (queryParams.BDRArticleType && !queryParams.currentArticleID) {
      setSubBillList([])
    }
    const isGenPage = location.pathname.endsWith('New')

    return new Promise((res, rej) => {
      if (currentArticleID == undefined && !BDRArticleType) {
        // console.log("Я отработал")
        res(getRootLvl(budgetType, orgID, isGenPage))
      } else {
        if (budgetType == 'bdr') {
          if (BDRArticleType && currentArticleID == undefined) {
            MappingArticlesAPI.getRootLvl(budgetType, { type: BDRArticleType }).then((data) => res(data))
          }
          if (BDRArticleType && currentArticleID) {
            MappingArticlesAPI.getArticleDataByArticleID(budgetType, {
              articleID: Number(currentArticleID),
              budgetType: props.budgetType,
            }).then((data) => res(data))
          }
        }
        if (budgetType == 'bdds') {
          MappingArticlesAPI.getArticleDataByArticleID(budgetType, {
            articleID: Number(currentArticleID),
            ...(budgetType !== 'bdds'
              ? {
                  budgetType: props.budgetType,
                }
              : {}),
          }).then((data) => res(data))
        }
      }
    })
  }

  function setSubBillList(subBillList: IMappingArticlesState['subBillList']) {
    setState((prevState) => ({
      ...prevState,
      subBillList: sortSubBillsByName(subBillList) || [],
    }))
  }

  function getRootLvl(budgetType: TBudgetType, orgID: number, isGen: boolean): Promise<IGetArticleByArticleIDResponse> {
    return new Promise((res, rej) => {
      return MappingArticlesAPI.getRootLvl(budgetType, {
        budgetType: props.budgetType,
      }).then((data) => res(data))
    })
  }

  function goBackToParent(parentID: number | string | undefined) {
    changeQueryParams(history, {
      currentArticleID: parentID,
      isRoot: 'false',
      BDRArticleType:
        parentID === undefined
          ? undefined
          : !getQueryParam(history, 'currentArticleID')
          ? undefined
          : (getQueryParam(history, 'BDRArticleType') as TBDRArticleType),
      filledWith: undefined,
    })
  }

  function getBreadCrumbs(): ITableHeadingBreadCrumb[] {
    let targetArray =
      state.articleData?.parents?.map((parent, index) => {
        return {
          id: parent?.id || undefined,
          title: parent.name,
          clickable: state?.articleData?.article?.id != parent?.id,
        }
      }) || []

    // if (getQueryParam(history, "budgetType") == "bdr") {
    // 	let rootArticle = BDR_ROOT_LVL?.data?.filter(rootArticle => rootArticle.type == getQueryParam(history, "BDRArticleType"))[0];
    // 	targetArray?.unshift({
    // 		id: undefined,
    // 		title: rootArticle?.name as string,
    // 		clickable: targetArray.length > 0
    // 	});
    // }
    return targetArray || []
  }

  return (
    <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)' }}>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              height: theme.spacing(5.85),
            }}>
            <TableCell width="44px">
              {isGoBackAvailable && (
                <ArrowBack
                  fontSize="small"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    let parentID =
                      state.articleData?.parents &&
                      state.articleData?.parents[state.articleData?.parents!.length - 2]?.id
                    goBackToParent(parentID as number)
                  }}
                />
              )}
            </TableCell>
            <TableCell>
              <Box display="flex">
                {getBreadCrumbs().map((breadCrumb) => {
                  return breadCrumb.clickable ? (
                    <>
                      <Tooltip title={breadCrumb.title.length > 80 ? breadCrumb.title : undefined}>
                        <Box
                          onClick={(e) => goBackToParent(breadCrumb.id)}
                          sx={{
                            ':hover': {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            },
                            textWrapMode: 'nowrap',
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            '&:not(:only-child)': {
                              maxWidth: '40vw',
                            },
                            '&:only-child': {
                              maxWidth: '85vw',
                            },
                          }}>
                          {breadCrumb.title}
                        </Box>
                      </Tooltip>
                      <Typography sx={{ mx: theme.spacing(1) }}>/</Typography>
                    </>
                  ) : (
                    <Tooltip title={breadCrumb.title.length > 80 ? breadCrumb.title : undefined}>
                      <Box
                        sx={{
                          textWrapMode: 'nowrap',
                          maxWidth: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          '&:not(:only-child)': {
                            maxWidth: '40vw',
                          },
                          '&:only-child': {
                            maxWidth: '85vw',
                          },
                        }}>
                        {breadCrumb.title}
                      </Box>
                    </Tooltip>
                  )
                })}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <ArticleList
          budgetType={props.budgetType}
          parentState={state}
          setParentState={setState}
          controlsState={controlsState}
          setControlsState={setControlsState}
          setIsRefetch={setIsRefetch}
        />
        <SubBillList
          budgetType={props.budgetType}
          parentState={state}
          setParentState={setState}
          controlsState={controlsState}
          setControlsState={setControlsState}
        />
      </Table>
    </TableContainer>
  )
}
