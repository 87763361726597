import { Stack } from "@mui/material"
import styled from "styled-components"

export const DrawerScrollableWrapper = styled(Stack)`
  overflow: auto;
  scrollbar-gutter: stable;
  padding: 0 16px 0 20px;
  height: 100%;
`

export const WarningWrapper = styled(Stack)`
  border-radius: 4px;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.error.main};
  gap: 12px;
`
