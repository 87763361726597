import { Box } from "@mui/material"
import styled from "styled-components"
import { theme } from "../../global/styles/theme"

export const StyledGridSettingsItemContainer = styled(Box)`
  display: grid;
  overflow: auto;
  padding-left: ${theme.spacing(2.25)};
  padding-right: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  &::-webkit-scrollbar-thumb {
    /* border-top: 20px solid ${theme.palette.grey[100]} !important; */
    /* border-bottom: 20px solid ${theme.palette.grey[100]} !important; */
  }
  height: calc(100vh - 140px);
  /* height: 100%;
    width: 100%; */
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(580px, 1fr));
  // grid-template-rows: repeat(auto-fit, 400px);
  grid-template-rows: repeat(auto-fit, minmax(400px, 400px));
`
