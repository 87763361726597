import { api } from 'api/api'
import { ExcelUploadResponse, ProjectType, TBudgetType } from 'global/types/commos-def'
import { UploadBdrFileRequest } from './bdr.types'

export const bdrApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBdrFileTemplate: build.query<string, { budgetType: TBudgetType; projectType: ProjectType }>({
      query: ({ budgetType, projectType }) => ({
        url: `/${budgetType === 'bdds' ? 'bdds' : 'bdr/financier'}/articles/excel/template`,
        method: 'GET',
        params: { budgetType: projectType },
        responseHandler: 'text',
      }),
    }),
    uploadBdrFile: build.mutation<ExcelUploadResponse, UploadBdrFileRequest>({
      query: ({ body, params: { budget, ...params } }) => {
        const formData = new FormData()
        formData.append('file', body.file)

        return {
          url: `/${budget === 'bdds' ? 'bdds' : 'bdr/financier'}/articles/excel/upload`,
          method: 'POST',
          body: formData,
          params,
        }
      },
    }),
  }),
})

export const { useLazyGetBdrFileTemplateQuery, useUploadBdrFileMutation } = bdrApi
