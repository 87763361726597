import styled from "styled-components"
import { Box, Drawer, Stack } from "@mui/material"

export const DrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`

export const DrawerTopBar = styled(Box)`
  padding: 20px 20px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`
