import { TableCell, TableRow, Typography } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { CalendarHeadings } from '../../../../../components/CalendarHeadings/CalendarHeadings'
import { getQueryParam } from '../../../../../global/functions'
import { TOperationCategory } from '../../../../../global/types/commos-def'
import { STYLES_FOR_OPERATION_NAME_BY_CATEGORY } from './FinCenterTableHead.styles'

export function FinCenterTableHead() {
  const history = useHistory()

  const [state, setState] = React.useState({
    operationNameTitle: '' as string | JSX.Element,
    unitPriceTitle: '' as string | JSX.Element,
  })

  const category = getQueryParam(history, 'category') as TOperationCategory

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...getTitleByOperationCategory(getQueryParam(history, 'category') as TOperationCategory),
    }))
  }, [history.location.search])

  const getOperationNameStylesByCategory = () => {
    if (!category) return STYLES_FOR_OPERATION_NAME_BY_CATEGORY.DEFAULT
    return STYLES_FOR_OPERATION_NAME_BY_CATEGORY[category] ?? STYLES_FOR_OPERATION_NAME_BY_CATEGORY.DEFAULT
  }

  function getTitleByOperationCategory(operationCategory: TOperationCategory) {
    let operationNameTitle = ''
    let unitPriceTitle = (
      <>
        Цена за ед. <br />
        <Typography fontSize={12} lineHeight={'10px'} component="span">
          (без НДС)
        </Typography>
      </>
    )
    switch (operationCategory) {
      case 'INCOME':
        operationNameTitle = 'Наименование дохода'
        break
      case 'PAYMENTS':
        operationNameTitle = 'Наименование расхода'
        break
      case 'WAGE_FUND':
        operationNameTitle = 'ФИО и должность'
        unitPriceTitle = (
          <>
            Зарплата <br />
            <Typography fontSize={12} lineHeight={'10px'} component="span">
              (в т.ч. НДФЛ)
            </Typography>
          </>
        )
        break
      case 'OPERATING_EXPENSES':
        operationNameTitle = 'Наименование расхода'
        break
      case 'STAFF_MAINTENANCE':
        operationNameTitle = 'Наименование расхода'
        break
      case 'TOTAL':
        operationNameTitle = 'Наименование категории/субсчета'
        break
    }
    return {
      operationNameTitle: operationNameTitle,
      unitPriceTitle: unitPriceTitle,
    }
  }

  return (
    <TableHead>
      <TableRow>
        {category != 'TOTAL' && <TableCell sx={{ width: '44px', padding: '0 !important' }} />}
        <TableCell sx={getOperationNameStylesByCategory()}>{state.operationNameTitle}</TableCell>
        {category != 'TOTAL' && (
          <>
            {getQueryParam(history, 'budgetType') == 'bdr' && (
              <TableCell sx={{ minWidth: '150px', width: '150px' }}>{state.unitPriceTitle}</TableCell>
            )}
            <TableCell sx={{ minWidth: '70px', width: '70px' }} />
          </>
        )}
        <CalendarHeadings category={category} />
      </TableRow>
    </TableHead>
  )
}
