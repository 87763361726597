import styled from "styled-components"
import { Box, FormControlLabel, FormLabel } from "@mui/material"

export const StyledFactUploadDrawerFormWrapper = styled(Box)`
  padding: 20px;
`

export const StyledDownloadTemplate = styled(Box)`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: #0044b4;
  }
`

export const StyledUploadStatusContainer = styled(Box)`
  width: 100%;
  text-align: center;
`
export const StyledUploadStatusCard = styled(Box)`
  width: 100%;
  height: 300px;
  padding: 16px;
  text-align: center;
  background-color: #f6f7fb;
  border-radius: 8px;
`
export const StyledUploadLabelCard = styled(Box)`
  width: 100%;
  text-align: center;
`
