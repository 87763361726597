import styled from "styled-components"
import { Stack, Box, TextField, Typography } from "@mui/material"
//import { ColorsBgLevel } from '../../../../../../types/global'

export const StyledRow = styled(Box)`
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: minmax(150px, 100%) minmax(185px, 100%) minmax(185px, 100%) 395px 121px;
  grid-template-rows: 72px;
  grid-template-areas: ". . . . . . .";
  gap: 0px 0px;

  width: 100%;
  height: 100%;
  max-height: 72px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  &:last-child {
    box-shadow: inset 0px 0px 0px rgba(0, 36, 95, 0.1);
  }
  color: #2b3648;
`

export const StyledTextField = styled(TextField)`
  & .MuiSelect-select {
    color: ${(props) => props.theme.palette.text.dark};
    font-size: 14px;
  }

  & MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-clamp: 1;
  }
`

// TextField
