import { config } from '../config'
import { getBase64 } from '../functions'
import { handleTokenRefresh, ITokenData, setTokenData } from './api'
import { useEffect, useState } from 'react'

const INVALID_TOKEN_MESSAGE = 'ws.invalid_auth_token'

export interface IWSSendFile {
  id: string
  upload: {
    type: string
    id: string
    bodyJsonProps: any
    onClick?: () => void
  }
  upload_cancel: {
    type: string
    id: string
    bodyJsonProps: any
    onClick?: () => void
  }
}

//TODO:Не хватает перевызова события после ошибки инвалидного токена

function useWebSocket(handleMessage: (event: any) => void, ApiProps: IWSSendFile) {
  const [socket, setSocket] = useState<WebSocket | null>(null)

  const additionalOnMessageCallback = (event: any) => {
    let status = JSON.parse(event.data).type
    if (status === 'status_ok') {
      console.log('[General socketChannel-open] соединение установлено.')
    }
    if (status === 'error') {
      let bodyJson = JSON.parse(event.data).bodyJson
      let msg = JSON.parse(bodyJson).msg
      console.log(`[General socketChannel-error] ошибка, причина:${msg}.`)
      if (msg === INVALID_TOKEN_MESSAGE) {
        handleTokenExpiration()
      }
    }
  }

  const createWebSocket = (
    onMessageCallback: (event: any) => void,
    additionalOnMessageCallback?: (event: any) => void,
  ) => {
    const newSocket = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`)

    newSocket.onmessage = (event) => {
      onMessageCallback(event)

      if (additionalOnMessageCallback) {
        additionalOnMessageCallback(event)
      }
    }
    newSocket.onclose = (event) => {
      console.log('[General socketChannel-close] соединение закрыто, причина:', event.code, event.reason)
    }

    newSocket.onerror = (error) => {
      console.error('[General socketChannel-error] ошибка, причина:', error)
    }

    return newSocket
  }

  const handleTokenExpiration = async () => {
    try {
      console.log('[General socketChannel-error] токен устарел, выполняю переподключение...')
      const newTokenData = await handleTokenRefresh()
      setTokenData(newTokenData as ITokenData)
      console.log('[General socketChannel-error] токен обновлен.')
    } catch (error) {
      console.error('[General socketChannel-error] не удалось обновить токен.', error)
    }
  }

  const connectSocketChannel = () => {
    if (!socket || socket.readyState === WebSocket.CLOSED) {
      const newSocket = createWebSocket(handleMessage, additionalOnMessageCallback)
      setSocket(newSocket)

      newSocket.onopen = () => {
        console.log('[General socketChannel-open] успешное создание сокета. установка соединения...')
        const auth = JSON.parse(localStorage.getItem('auth') || '{}')
        newSocket.send(
          JSON.stringify({
            bodyJson: JSON.stringify({ token: auth.token }),
            id: ApiProps.id,
            type: 'auth',
          }),
        )
      }
    } else if (socket.readyState === WebSocket.OPEN) {
      console.log('[General socketChannel-open] сокет уже создан. установка соединения...')
      const auth = JSON.parse(localStorage.getItem('auth') || '{}')
      socket.send(
        JSON.stringify({
          bodyJson: JSON.stringify({ token: auth.token }),
          id: ApiProps.id,
          type: 'auth',
        }),
      )
    }
  }

  const closeSocketChannel = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close()
      console.log('[General socketChannel-close] закрытие соединения...')
    } else {
      console.log('[General socketChannel-close] соединение уже закрыто или не открыто.')
    }
  }

  const sendFile = (file: File) => {
    if (file instanceof File) {
      getBase64(file).then((result: any) => {
        const base64String = result.replace('data:', '').replace(/^.+,/, '')
        const fileWS = {
          type: ApiProps.upload.type,
          id: ApiProps.upload.id,
          bodyJson: JSON.stringify({
            ...ApiProps.upload.bodyJsonProps,
            file: base64String,
          }),
        }
        if (socket && socket.readyState === WebSocket.OPEN) {
          if (ApiProps.upload.onClick) ApiProps.upload.onClick()
          socket.send(JSON.stringify(fileWS))
        } else if (socket && socket.readyState === WebSocket.CLOSED) {
          // Reconnect if the WebSocket is closed
          console.log('[General socketChannel-sendFile] соединение закрыто, выполняю переподключение...')
          connectSocketChannel()
          if (ApiProps.upload.onClick) ApiProps.upload.onClick()
          socket.send(JSON.stringify(fileWS))
        } else {
          console.log('[General socketChannel-sendFile] ошибка.')
        }
      })
    }
  }

  const cancelSendFile = () => {
    const fileWS = {
      type: ApiProps.upload_cancel.type,
      id: ApiProps.upload_cancel.id,
      bodyJson: JSON.stringify({
        ...ApiProps.upload_cancel.bodyJsonProps,
      }),
    }
    if (socket && socket.readyState === WebSocket.OPEN) {
      if (ApiProps.upload_cancel.onClick) ApiProps.upload_cancel.onClick()
      socket?.send(JSON.stringify(fileWS))
    } else if (socket && socket.readyState === WebSocket.CLOSED) {
      console.log('[General socketChannel-sendFile] соединение закрыто, выполняю переподключение...')
      connectSocketChannel()
      if (ApiProps.upload_cancel.onClick) ApiProps.upload_cancel.onClick()
      socket?.send(JSON.stringify(fileWS))
    } else {
      console.log('[General socketChannel-sendFile] ошибка.')
    }
  }

  useEffect(() => {
    if (socket && socket.readyState !== WebSocket.OPEN) connectSocketChannel()
  }, [ApiProps.id])

  return {
    socket,
    connectSocketChannel,
    closeSocketChannel,
    sendFile,
    cancelSendFile,
  }
}

export default useWebSocket
