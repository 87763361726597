import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store/store'
import { LoginByTokenResponse } from './auth/types'
import { setCredentials } from '../store/slices/auth'
import { config } from '../global/config'

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiHost,
  prepareHeaders: (headers, { getState }) => {
    const token = JSON.parse(localStorage.getItem('auth')!).token

    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const api = createApi({
  baseQuery: async (args, queryApi, extraOptions) => {
    const { dispatch, getState } = queryApi
    let result = await baseQuery(args, queryApi, extraOptions)

    // const statusCode = ((result?.error as any)?.originalStatus as number) || 0;

    if (result.error && result.error.status === 401) {
      const refreshToken = JSON.parse(localStorage.getItem('auth')!).refreshToken
      const refreshResult = await baseQuery(
        { url: '/auth/refresh', method: 'POST', body: { token: refreshToken } },
        queryApi,
        extraOptions,
      )

      if (refreshResult.data) {
        dispatch(setCredentials(refreshResult.data as LoginByTokenResponse))
        result = await baseQuery(args, queryApi, extraOptions)
      } else {
        dispatch(setCredentials({}))
      }
    }
    return result
  },
  tagTypes: [
    'Profile',
    'Company',
    'Users',
    'Projects',
    'PaymentTemplates',
    'PaymentTemplate',
    'ProductionProgramEstimate',
  ],
  endpoints: () => ({}),
})
